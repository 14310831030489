import Title from 'components/atoms/Title';
import { t } from 'pages/microservices/masterData/translation/Translation';
import React from 'react';
import { Categories } from './Categories';
import { Functions } from './Functions';
import { Navigation } from '../../form-navigation/Navigation';
import { useFormContext } from '../../context/Context';

export const CategoryAndFunctions = () => {
  const { state, dispatch } = useFormContext();
  return (
    <>
      <div className="row">
        <div className="col">
          <div className="form-border" style={{ paddingTop: "2vw" }}>
            <Categories />
            <Functions />
            {state.functions.length === 0 &&
              <div className="text-danger">{state.errors.categoryAndFunctions}</div>
            }
          </div>
          <div style={{ padding: "0.85vw 0" }}>
            <Navigation />
          </div>
        </div>
      </div>

    </>
  );
}