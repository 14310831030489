import React, { useMemo, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import HelpInfoIcon from "static/images/HelpIcon";
import { RANGE_VALUE, RANGE_VALUE_FOR_GENERAL } from "constants/Constants";
import {
  COMPETENCE_CHANGE,
  COMPETENCE_TAB_DATA,
  COMPETENCE_TAB_OPTION_DATA,
  SET_COMPETENCE_OPTION,
  SET_OPTIONS,
} from "../context/Constants";
import { t } from "pages/microservices/masterData/translation/Translation";
import CustomRangeSlider from "components/atoms/CustomRangeSlider";
import TextEllipsis from "components/atoms/TextEllipsis";
import Button from "components/atoms/Button";
import CompetenceModal from "./AddProjectCompetenceoPopUop";
import { useParams } from "react-router-dom";

interface Option {
  label: string;
  value: string;
  range: string;
}

interface CompetenceProps {
  competenceOptions: any;
  state: any;
  dispatch: any;
  type?: any;
}
const ProjectCompetences: React.FC<CompetenceProps> = ({
  competenceOptions,
  state,
  dispatch,
  type,
}) => {
  const { id } = useParams<{ id: string }>();
  const handleSelect = (
    selectedId: number,
    section: string,
    selected: Option[]
  ) => {
    const selects = selected.map((eachItem: any) => {
      return { ...eachItem };
    });
    dispatch({
      type: COMPETENCE_CHANGE,
      field: "SELECT",
      payload: {
        selection: section,
        selected: selected.length > 0 ? selects : [],
        id: selectedId,
      },
    });
  };
  const functionOption = state.general.data.functions.tableData;

  const [showModal, setShowModal] = useState(false);
  const [validationStatus, setValidationStatus] = useState({
    isValid: false,
    type: "",
  });

  const validStatus = (validation: any) => {
    setValidationStatus({ isValid: validation.isValid, type: validation.type });
  };

  let data = state.competences?.competence;
  console.log(data);
  const getTitleForRating = (range: string) => {
    switch (parseInt(range)) {
      case 1:
        return "A1";
      case 2:
        return "A2";
      case 3:
        return "B1";
      case 4:
        return "B2";
      case 5:
        return "C1";
      case 6:
        return "C2";
      default:
        return "A1";
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    section: string,
    index: number
  ) => {
    dispatch({
      type: COMPETENCE_CHANGE,
      payload: { value: e.target.value, section: section, index: index },
    });
  };

  const className = type == "View" ? "form-border disabled" : "form-border";
  // const activeKeys = data?.filter((section: any) => section?.options?.length > 0).map((section: any) => section?.key);
  const activeKeys = useMemo(() => {
    return competenceOptions?.map((section: any) => section?.key);
  }, [competenceOptions]);

  const handleAddCompetence = (selectedOptions: any) => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleAddCompetenceOption = (selectedOptions: any) => {
    dispatch({
      type: SET_COMPETENCE_OPTION,
      field: "Competence",
      value: selectedOptions,
    });
    dispatch({
      type: COMPETENCE_TAB_OPTION_DATA,
      field: "competences",
      value: selectedOptions, // Directly send the array
    });
    setShowModal(false);
  };
  console.log(competenceOptions);
  return (
    <div className="vacancyEmployeementTab">
      <div className={`${className}`} style={{ paddingTop: "2vw" }}>
        <div className="row">
          <div className="col-12 marginBotttom1">
            <Button
              title={t("Add competence")}
              handleClick={handleAddCompetence}
              className="form-button float-end"
            />
          </div>
        </div>
        <Accordion defaultActiveKey={activeKeys} alwaysOpen>
          {competenceOptions &&
            competenceOptions.map((section: any) => {
              return (
                <Accordion.Item key={section?.key} eventKey={section?.key}>
                  <Accordion.Header>{section?.label}</Accordion.Header>
                  <Accordion.Body>
                    <div className="hideHeight">
                      <SelectWithSearch
                        name={section.key}
                        isMandatory={false}
                        search={true}
                        placeHolder="Select"
                        options={section.options}
                        value={data
                          ?.filter((item: any) => item.key == section.key)?.[0]
                          ?.options?.map((item: any) => item?.value)}
                        onChange={(selected) =>
                          handleSelect(
                            section.competence_id,
                            section.key,
                            selected
                          )
                        }
                        isMulti={true}
                        className="select-field"
                        error={""}
                      />
                    </div>
                    <div className="row">
                      {data?.length > 0 &&
                        data.find((item: any) => item.key === section.key)
                          ?.options && (
                          <div
                            className="text-end"
                            style={{
                              marginTop: "0.5vw",
                            }}
                            data-bs-toggle="tooltip"
                            data-bs-placement="left"
                            title={t(section?.info)}
                          >
                            <HelpInfoIcon />
                          </div>
                        )}
                      {data?.length > 0 &&
                        data
                          ?.find((item: any) => item.key === section.key)
                          ?.options?.map((option: any, index: any) => {
                            return (
                              <div key={index} className="col-3">
                                <div className="row">
                                  <div className="col-4 pe-0">
                                    <span className="fw-bolder">
                                      <TextEllipsis
                                        title={option?.label}
                                        label={option?.label}
                                        width="8vw"
                                      />{" "}
                                    </span>
                                  </div>
                                  <div className="col-8">
                                    <CustomRangeSlider
                                      min={1}
                                      max={section?.maxRange ?? "6"}
                                      step={1} // Step size should match the range
                                      title={getTitleForRating(
                                        option?.range === "0" ||
                                          option?.range === 0
                                          ? "1"
                                          : option?.range
                                      )}
                                      value={
                                        option?.range === "0" ||
                                        option?.range === 0
                                          ? "1"
                                          : option?.range
                                      }
                                      handleChange={(e) =>
                                        handleChange(e, section.key, index)
                                      }
                                    />
                                    <div className="d-flex justify-content-between">
                                      {(section?.maxRange == 4
                                        ? RANGE_VALUE_FOR_GENERAL
                                        : RANGE_VALUE
                                      ).map((value, index) => (
                                        <span key={value}>{value}</span>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
        </Accordion>
        {showModal && (
          <CompetenceModal
            handleClose={handleCloseModal}
            handleAdd={handleAddCompetenceOption}
            functionOptions={functionOption}
            projectId={state?.general?.data?.id || null}
          />
        )}
      </div>
    </div>
  );
};

export default ProjectCompetences;
