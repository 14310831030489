import React, { useEffect, useState } from "react";
import { manageState, tableHeaders } from "./State";
import { useNavigate } from "react-router-dom";
import { ApiCall } from "services/ApiServices";
import { FETCH_TRANSLATIONS, SAVE_TRANSLATION } from "routes/ApiEndpoints";
import CustomNotify from "components/atoms/CustomNotify";
import { t } from "./Translation";
import Popup from "components/molecules/Popup";
import DynamicTableStructure from "components/atoms/DynamicTableStructure";
import TranslationEditPopup from "./TranslationEditPopup";
import { M_MASTER_DATA } from "constants/Constants";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import Title from "components/atoms/Title";
import BackButton from "components/atoms/BackButton";
import { useDispatch } from "react-redux";
import { selectPageHistory, updatePageFilters } from "store/pageHistory/pageHistorySlice";
import FilterOrganism from "components/organism/FilterOrganism";
import PaginationWithPerPage from "components/molecules/PaginationWithPerPage";

const ManageTranslationsOrganism: React.FC = () => {
  const [state, setState] = useState(manageState);
  const navigate = useNavigate();
  const userData = useSelector(selectAuth);
  const dispatch = useDispatch();
  const pageHistory = useSelector(selectPageHistory);
  const history = pageHistory?.history?.[pageHistory?.history?.length - 1] ?? {};

  useEffect(() => {
    fetchData();
  }, [state?.refresh, state?.initialCall]);

  const fetchData = async () => {
    let postData = {
      orderBy: history?.filters?.orderBy ?? "id",
      sortDirection: history?.filters?.sortDirection ?? "desc",
      currentPage: Number(history?.filters?.currentPage ?? 1),
      itemsPerPage: Number(history?.filters?.itemsPerPage ?? 10),
      manage: state?.manage,
      language_id: history?.filters?.language_id ?? userData?.user_language,
      initialCall: state?.initialCall,
      search: history?.filters?.search ?? "",
    };
    const response = await ApiCall.service(FETCH_TRANSLATIONS, "POST", postData, false, M_MASTER_DATA);
    if (response.status === 200) {
      let data: any = {
        data: response?.data?.data,
        totalPages: response?.data?.pagination?.totalPages,
        totalRecords: response?.data?.pagination?.totalRecords,
        initialCall: false,
      };
      if (state?.initialCall) {
        data = {
          ...data,
          options: response?.data?.options,
        };
      }
      setState((prevState: any) => ({ ...prevState, ...data }));
    }
  };

  const handleEditPopUp = (value: any) => {
    handlePopUpState({
      string_id: value?.string_id,
      showpopup: true,
      newstring: value?.translated_string,
    });
  };

  const handlePopUpState = (values: any, newstring?: any) => {
    setState((prevState: any) => ({
      ...prevState,
      popup: {
        ...prevState.popup,
        editpopup: {
          ...prevState.popup.editpopup,
          string_id: values?.string_id,
          showpopup: values?.showpopup,
          newstring: values?.newstring,
        },
      },
      data: newstring
        ? state?.data?.map((item: any) => {
          if (item?.string_id == state?.popup?.editpopup?.string_id) {
            return {
              ...item,
              translated_string: newstring,
            };
          } else {
            return item;
          }
        })
        : state?.data,
    }));
  };

  const handleSave = async (newstring: string) => {
    const postdata = {
      manualUpdate: true,
      string: newstring,
      string_id: state?.popup?.editpopup?.string_id,
      language_id: history?.filters?.language_id ?? userData?.user_language,
    };
    try {
      const response = await ApiCall.service(SAVE_TRANSLATION, "POST", postdata, false, M_MASTER_DATA);
      CustomNotify({ type: response.status === 200 ? "success" : "error", message: response.message });
      if (response.status === 200) {
        handlePopUpState({ value: null, showpopup: false, status: null }, newstring);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const filters = [
    { name: "language_id", default: userData?.user_language, fieldType: "singleSelect", options: state?.options?.Language, placeholder: "Select language", filterType: "search" },
    { name: "search", fieldType: "text", placeholder: "Search development string", filterType: "search" },
  ];

  const handleRefresh = () => setState((prev: any) => ({ ...prev, refresh: !state?.refresh }));

  return (
    <div>
      <div className="search-bar">
        <div className="row">
          <Title title={t("Translations")} />
        </div>
        <div className="row manageTranslationFilters">
          <div className="col-12">
            <FilterOrganism
              dispatch={dispatch}
              history={history}
              updatePageFilters={updatePageFilters}
              filters={filters}
              handleRefresh={handleRefresh}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="position-relative tableMainWrapper mt-0">
            <div className="tableSection manage-translations manageTranslationFilters">
              <div className="manageTranslation">
                <DynamicTableStructure
                  isAction
                  history={history}
                  handleRefresh={handleRefresh}
                  data={state.data}
                  headers={tableHeaders}
                  handleEdit={handleEditPopUp}
                  permission={"Translations"}
                />
              </div>
              {state?.totalPages > 1 && (
                <PaginationWithPerPage
                  handleRefresh={handleRefresh}
                  dispatch={dispatch}
                  history={history}
                  pageData={{
                    totalPages: state?.totalPages,
                    totalRecords: state?.totalRecords,
                  }}
                />
              )}
            </div>
          </div>
          {state?.popup?.editpopup?.showpopup && (
            <Popup
              title={t("Edit string")}
              body={
                <TranslationEditPopup
                  oldstring={state?.popup?.editpopup?.newstring}
                  handleSave={handleSave}
                  handleCancel={() =>
                    handlePopUpState({
                      value: null,
                      showpopup: false,
                      status: null,
                    })
                  }
                />
              }
              close={() =>
                handlePopUpState({ value: null, showpopup: false, status: null })
              }
            />
          )}
          {/* <div className="backPadding">
            <BackButton />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ManageTranslationsOrganism;
