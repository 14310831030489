import React, { useState, useEffect } from "react";
import ModalPopup from "components/atoms/ModalPopup";
import { ApiCall } from "services/ApiServices";
import CustomNotify from "components/atoms/CustomNotify";
import { t } from "pages/microservices/masterData/translation/Translation";
import {
    PATH_CLONE_COOPERATION_AGREEMENT,
    PATH_CREATE_COOPERATION_AGREEMENT,
    PATH_EDIT_COOPERATION_AGREEMENT,
    PATH_TEMP_AGENCY_PROPOSAL,
} from "constants/Paths";
import TableActions from "components/organism/Actions/TableAction";
import { useNavigate, useParams } from "react-router-dom";
import {
    DELETE_TEMP_AGENCY,
    DOWNLOAD_TEMP_AGENCY_PDF,
    FETCH_COMPANY_BU,
} from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "constants/Constants";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";

import {
    getStatusColorForClients,
    getTooltipForClientAndSupplier,
} from "services/util/UtilService";
import EncryptDecryptService from "services/EncryptDecryptService";
import Title from "components/atoms/Title";
import LinkTo from "components/atoms/LinkTo";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import FilterOrganism from "components/organism/FilterOrganism";
import { updatePageFilters } from "store/pageHistory/pageHistorySlice";
import PaginationWithPerPage from "components/molecules/PaginationWithPerPage";
import SortAtomForManagePage from "components/atoms/SortAtomForManagePage";
import { CooperationAgreementHeader } from "TableHeader";

interface ManageCooperationAgreementProps {
    data?: any;
    setTempData: any;
    handleGetVacany: any;
    dispatch: any;
    history: any;
    pageData: any;
    handleRefresh: () => void;
}

const ManageCooperationAgreement: React.FC<ManageCooperationAgreementProps> = ({
    data,
    dispatch,
    history,
    pageData,
    handleRefresh,
}) => {
    const [id, setId] = useState<number | undefined>(undefined);
    const [showModal, setShowModal] = useState(false);

    const params = useParams();

    const [filterOptions, setFilterOptions] = useState({
        businessUnit: [],
        company: [],
        consultants: [],
        status: [],
    });
    const navigate = useNavigate();
    useEffect(() => {
        //getFilterData();
    }, [id]);

    const getFilterData = async () => {
        try {
            const response = await ApiCall.getService(
                FETCH_COMPANY_BU,
                "GET",
                M_MASTER_DATA
            );
            if (response?.status == 200) {
                setFilterOptions((prevState: any) => {
                    const optionData =
                    {
                        ...prevState,
                        businessUnit: response?.data?.businessUnit,
                        company: response?.data?.compnay,
                        consultants: response?.data?.consultants,
                        status: [
                            {
                                value: '0', label: "Draft"
                            },
                            {
                                value: '1', label: "Active"
                            },
                            {
                                value: '2', label: "Inactive"
                            }
                        ],
                    }
                    return optionData;
                });
            } else {
                console.log("Error");
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleSaveChanges = async () => {
        setShowModal(false);
        let postData = {
            id: id,
        };

        if (id) {
            const response = await ApiCall.service(
                DELETE_TEMP_AGENCY,
                "POST",
                postData,
                false,
                M_MASTER_DATA
            );

            if (response?.status === 200) {
                CustomNotify({ type: "success", message: response.message });
                handleRefresh();
            } else {
                CustomNotify({ type: "error", message: response.message });
            }
        }
    };

    useEffect(() => {
        getFilterData();
    }, []);

    const handleModalClose = () => setShowModal(!showModal);

    const handleEdit = (id: number) => {
        navigate(`${PATH_EDIT_COOPERATION_AGREEMENT}/${params?.tempAgId}/${id}`);
    };

    const handleClone = (id: number) => {
        navigate(`${PATH_CLONE_COOPERATION_AGREEMENT}/${params?.tempAgId}/${id}`);
    }

    const handleDelete = (id: number) => {
        setShowModal(true);
        setId(id);
    };
    const userData = useSelector(selectAuth);
    const handleSendProjectProposal = (
        tempAgencyId: number,
        clientMailStatus: any,
        managerMailStatus: any,
        user: any
    ) => {
        const userIds = user.map((item: { user_id: any }) => item.user_id);
        const data = {
            id: tempAgencyId,
            clientMailStatus: clientMailStatus,
            ManagerMailStatus: managerMailStatus,
            users: userIds,
        };
        const append = EncryptDecryptService.encryptData(JSON?.stringify(data));
        const encodedData = encodeURIComponent(append);
        navigate(`${PATH_TEMP_AGENCY_PROPOSAL}/?data=${encodedData}`);
    };
    const handleDownload = async (id: number) => {
        const response = await ApiCall.getService(
            `${DOWNLOAD_TEMP_AGENCY_PDF}/${id}`,
            "GET",
            M_MASTER_DATA
        );
        if (response && response.download_url) {
            const link = document.createElement("a");
            link.href = response.download_url;
            link.target = "_blank";
            link.setAttribute("download", "");
            document.body.appendChild(link);
            link.click();
            setTimeout(() => {
                document.body.removeChild(link);
            }, 1000);
        } else {
            //   console.error("Invalid response or missing download URL");
        }
    };

    const handleCreateAgreement = (tempAgId: number) => {
        navigate(`${PATH_CREATE_COOPERATION_AGREEMENT}/${tempAgId}`);
    }

    const filters = [
        { name: 'businessUnit', fieldType: "multiSelect", options: filterOptions?.businessUnit, placeholder: "Business unit", filterType: 'search' },
        { name: 'company', fieldType: "singleSelect", options: filterOptions?.company, placeholder: "Company", filterType: 'search' },
        { name: 'consultant', fieldType: "singleSelect", options: filterOptions?.consultants, placeholder: "Consultant", filterType: 'search' },
        { name: 'startDate', fieldType: "date", end: history?.filters?.startDate, placeholder: "Start date", filterType: 'search' },
        // { name: 'status', fieldType: "singleSelect", options: filterOptions?.status, placeholder: "Status", filterType: 'search' },
    ];

    return (
        <AccessControl
            requiredPermissions={[
                {
                    permission: "Temp agency",
                    read: true,
                },
            ]}
            renderNoAccess={true}
            override={userData.isSuperAdmin}
        >
            <>
                <div className="search-bar">
                    <Title title={t("Cooperation agreement")} />
                    <FilterOrganism
                        dispatch={dispatch}
                        history={history}
                        updatePageFilters={updatePageFilters}
                        filters={filters}
                        class={{
                            rowClass: 'd-grid manageCarSearchCol manageSupplierSearchCol',
                            searchBtnClass: 'col-2 ms-auto'
                        }}
                        handleRefresh={handleRefresh}
                    />
                </div>

                <div className="position-relative tableMainWrapper">
                    <div>
                        <AccessControl
                            requiredPermissions={[
                                {
                                    permission: "Temp agency",
                                    create: true,
                                },
                            ]}
                            override={userData.isSuperAdmin}
                        >
                            <div className="row">
                                <div className="ManageCreateBtn">
                                    <LinkTo
                                        pagelink={`${PATH_CREATE_COOPERATION_AGREEMENT}/${params?.tempAgId}`}
                                        title={t("Create cooperation agreement")}
                                        icon={faPlus}
                                    />
                                </div>
                            </div>
                        </AccessControl>
                    </div>
                    <div className="table-responsive manage-clients tableSection">
                        <div className="manageTempAgencyHeightNew">
                            <table className="table table-hover">
                                <thead>
                                    <tr className="TableHeader">
                                        {CooperationAgreementHeader?.map((header) => (
                                            <th key={header.alias}>
                                                <>
                                                    {t(header.name)}
                                                    {header.issort && (
                                                        <SortAtomForManagePage
                                                            value={{ alias: header.alias }}
                                                            dispatch={dispatch}
                                                            history={history}
                                                            updatePageFilters={updatePageFilters}
                                                            handleRefresh={handleRefresh}
                                                        />
                                                    )}
                                                </>
                                            </th>
                                        ))}
                                        <th style={{ width: "15%" }}>
                                            <AccessControl
                                                requiredPermissions={[
                                                    {
                                                        permission: "Cooperation agreement",
                                                        update: true,
                                                        delete: true,
                                                    },
                                                ]}
                                                actions={true}
                                                strict={false}
                                                override={userData.isSuperAdmin}
                                            >
                                                {t("Actions")}
                                            </AccessControl>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data && data.length > 0 ? (
                                        data.map((record: any, key: any) => {
                                            return (
                                                <tr key={key}>
                                                    <td className="align-middle">{record?.businessUnit}</td>
                                                    <td className="align-middle">{record?.companyName}</td>
                                                    <td className="align-middle">{record?.consultantName}</td>
                                                    <td className="align-middle">{record?.start_date}</td>
                                                    {/* <td className="align-middle">
                                                        <div
                                                            className={getStatusColorForClients(record?.status)}
                                                            data-bs-toggle="tooltip"
                                                            data-bs-placement="top"
                                                            title={getTooltipForClientAndSupplier(record?.status)}
                                                        ></div>
                                                    </td> */}
                                                    <td className="align-middle">
                                                        <TableActions
                                                            isAction={true}
                                                            handleEdit={handleEdit}
                                                            //handleArchive={handleDelete}
                                                            handleClone={handleClone}
                                                            value={record.agreementId}
                                                            permission={"Temp agency"}
                                                        />
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan={5} className="text-center">
                                                {t("No records found")}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <div
                            className=""
                            style={{ paddingTop: "0.25vw" }}
                        >
                            <PaginationWithPerPage
                                handleRefresh={handleRefresh}
                                dispatch={dispatch}
                                history={history}
                                pageData={pageData}
                            />
                        </div>
                    </div>
                </div>
                <ModalPopup
                    show={showModal}
                    onHide={handleModalClose}
                    title={t("Delete confirmation")}
                    body={t("Are you sure want to delete?")}
                    onCloseButtonClick={handleModalClose}
                    onConfirmButtonClick={handleSaveChanges}
                    closeTitle="No"
                    confirmTitle="Yes"
                />
            </>
        </AccessControl>
    );
};
export default ManageCooperationAgreement;
