import React from "react";
import WeekCalendar from "./weekCalendar";
import { useFilterOptions } from "../timesheets-redux/context/FiltersContext/FiltersContext";
import PerformanceAndInvoice from "../atoms/performanceAndInvoice";
import { t } from "pages/microservices/masterData/translation/Translation";
import { useTImesheetContext } from "../timesheets-redux/context/TimesheetContext/TImesheetContex";

const ProjectParameters = (props) => {
  const { planTimingsData } = props;
  const { filterOptionsState } = useFilterOptions();
  const { initialTimesheetState } = useTImesheetContext();

  return (
    <>
      {/* <tr>
        <td colSpan={10}>
          <div className="text-center py-2">Project parameters</div>
        </td>
      </tr> */}

      {/* <ProjectParameterHeader
        headerCloumns={
          initialProjectParameterState ? initialProjectParameterState : []
        }
      /> */}

      <WeekCalendar />

      <PerformanceAndInvoice
        title={t("Performance")}
        performanceAndInvoiceData={planTimingsData}
        modalName="performance"
        values={[
          {key: 'type', name: 'Type', options: filterOptionsState?.optionState?.performanceTypes},
          {key: 'hours', name: 'Hours'},
          {key: 'standardPrice', name: 'Standard price'},
          {key: 'totalPrice', name: 'Total price'}
        ]}
        isDisabled={initialTimesheetState?.isDisabled}
      />
      <PerformanceAndInvoice
        title={t("Reimbursements")}
        performanceAndInvoiceData={planTimingsData}
        modalName="reimbursement"
        values={[
          {key: 'type', name: 'Type', options: filterOptionsState?.optionState?.reimbursementTypes},
          {key: 'numbers', name: 'Number'},
          {key: 'price', name: 'Price'},
          {key: 'totalPrice', name: 'Total price'}
        ]}
        isDisabled={initialTimesheetState?.isDisabled}
      />
      {/* <PerformanceAndInvoice
        title="Invoice"
        performanceAndInvoiceData={planTimingsData}
        modalName="invoice"
      /> */}
    </>
  );
};
export default ProjectParameters;
