import { useFormContext } from "pages/microservices/tempAgancy/context/Context";
import CheckBoxField from "components/atoms/CheckBoxField";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { validateFormField } from "services/validation/ValidationService";
import { ChangeEvent, useState } from "react";
import { AddressProps } from "pages/microservices/companyCreation/annotations/CompanyAnnotations";

import { t } from "pages/microservices/masterData/translation/Translation";
import { AddressTabValidationRules } from "../validationRules/TempAgencyValidationRules";
import { SET_FIELD_ERROR_ADDRESS_TAB } from "../context/Constants";
import LabelField from "components/atoms/LabelField";

const Address = () => {
  const { state, dispatch } = useFormContext();
  const addressData = state.address?.data;
  const copyBillingFieldsFromOfficial = (isChecked: boolean) => {
    if (isChecked) {
      const billingFields = [
        "street",
        "number",
        "box",
        "zipCode",
        "city",
        "country",
        "initialLat",
        "initialLn",
      ];
      billingFields.forEach((fieldName) => {
        const billingFieldName =
          "bi" + fieldName.charAt(0).toUpperCase() + fieldName.slice(1);
        const fieldValue = state.address.data[fieldName as keyof AddressProps];
        dispatch({
          type: "UPDATE_ADDRESS_FIELD",
          field: billingFieldName,
          value: fieldValue,
        });
        let validatedFieldError = validateFormField(
          addressData,
          AddressTabValidationRules,
          billingFieldName,
          fieldValue
        );
        console.log(validatedFieldError);
        dispatch({
          type: SET_FIELD_ERROR_ADDRESS_TAB,
          fieldName: billingFieldName,
          error: validatedFieldError,
        });
      });
    } else {
      const billingFields = [
        "biStreet",
        "biNumber",
        "biBox",
        "biZipCode",
        "biCity",
        "biCountry",
        "biInitialLat",
        "biItialLn",
      ];
      billingFields.forEach((fieldName) => {
        dispatch({
          type: "UPDATE_ADDRESS_FIELD",
          field: fieldName,
          value: "",
        });
        let validatedFieldError = validateFormField(
          addressData,
          AddressTabValidationRules,
          fieldName,
          ""
        );
        console.log(validatedFieldError);
        dispatch({
          type: SET_FIELD_ERROR_ADDRESS_TAB,
          fieldName: fieldName,
          error: validatedFieldError,
        });
      });
    }
  };

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;
    if (type === "checkbox") {
      const newValue = checked ? 1 : 0;
      dispatch({ type: "UPDATE_ADDRESS_FIELD", field: name, value: newValue });
      if (name === "sameAddress") {
        copyBillingFieldsFromOfficial(checked);
      }
    } else {
      dispatch({ type: "UPDATE_ADDRESS_FIELD", field: name, value });
      let validatedFieldError = validateFormField(
        addressData,
        AddressTabValidationRules,
        name,
        value
      );
      dispatch({
        type: SET_FIELD_ERROR_ADDRESS_TAB,
        fieldName: name,
        error: validatedFieldError,
      });
      //  // validation(name, value, true);
      if (state.address.data.sameAddress) {
        const billingFieldName =
          "bi" + name.charAt(0).toUpperCase() + name.slice(1);
        dispatch({
          type: "UPDATE_ADDRESS_FIELD",
          field: billingFieldName,
          value,
        });
        let validatedFieldError = validateFormField(
          addressData,
          AddressTabValidationRules,
          billingFieldName,
          value
        );
        dispatch({
          type: SET_FIELD_ERROR_ADDRESS_TAB,
          fieldName: billingFieldName,
          error: validatedFieldError,
        });
      }
      // }
    }
  };
  const handleSelectChange = (selectedOption: any, name: string) => {
    dispatch({
      type: "UPDATE_ADDRESS_FIELD",
      field: name,
      value: selectedOption,
    });
    if (state.address.data.sameAddress) {
      const billingFieldName =
        "bi" + name.charAt(0).toUpperCase() + name.slice(1);
      dispatch({
        type: "UPDATE_ADDRESS_FIELD",
        field: billingFieldName,
        value: selectedOption,
      });
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        <div
          className="form-border"
          style={{ paddingTop: "2vw", paddingBottom: "1vw" }}
        >
          <div className="row">
            <LabelField
              title={t("Official address")}
              className="tab-subtitle pb-1"
            />
            <div className="col-sm-12 col-md-6">
              <LabelWithInputField
                isMandatory={true}
                name="street"
                handleChange={handleFieldChange}
                value={state.address.data.street || ""}
                id="street"
                label="Street"
                type="text"
                error={state.address.errors.street}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <LabelWithInputField
                isMandatory={true}
                name="number"
                handleChange={handleFieldChange}
                value={state.address.data.number}
                id="number"
                label="Number"
                type="text"
                error={state.address.errors.number}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <LabelWithInputField
                isMandatory={false}
                name="box"
                handleChange={handleFieldChange}
                value={state.address.data.box}
                id="box"
                label="Box"
                type="text"
              />
            </div>
            <div className="col-4">
              <LabelWithInputField
                isMandatory={true}
                name="zipCode"
                handleChange={handleFieldChange}
                value={state.address.data.zipCode}
                id="zipCode"
                label="Zip code"
                type="text"
                error={state.address.errors.zipCode}
              />
            </div>
            <div className="col-4">
              <LabelWithInputField
                isMandatory={true}
                name="city"
                handleChange={handleFieldChange}
                value={state.address.data.city}
                id="city"
                label="City"
                type="text"
                error={state.address.errors.city}
              />
            </div>
            <div className="col-4">
              <SelectWithSearch
                title="Country"
                name="country"
                isMandatory={true}
                search={true}
                options={state.options.countries}
                placeHolder="Select"
                value={state.address.data.country}
                onChange={(e) => handleSelectChange(e, "country")}
                isMulti={false}
                className="select-field"
                error={state.address.errors.country}
              />
            </div>
            <div className="col-md-12">
              <CheckBoxField
                label="This is the Headquarters address"
                name="hqAddress"
                onChangeHandler={handleFieldChange}
                isChecked={state.address.data.hqAddress === 1}
                id="hqAddress"
                lineHeight="1.5vw"
              />
            </div>
            <div className="col-md-12">
              <CheckBoxField
                label="Same as official address"
                name="sameAddress"
                onChangeHandler={handleFieldChange}
                isChecked={
                  state.address.data.sameAddress === 1 ||
                  state.address.data.sameAddress === true
                }
                id="sameAddress"
                lineHeight="1.5vw"
              />
            </div>
            <div className="col-md-12" style={{ paddingTop: "1vw" }}>
              <LabelField
                title={t("Billing address")}
                className="tab-subtitle pb-1"
              />
            </div>
            <div className="col-sm-12 col-md-6">
              <LabelWithInputField
                isMandatory={true}
                name="biStreet"
                handleChange={handleFieldChange}
                value={state.address.data.biStreet}
                isDisabled={
                  state.address.data.sameAddress === 1 ||
                  state.address.data.sameAddress === true
                }
                id="street"
                label="Street"
                type="text"
                error={state.address.errors.biStreet}
              />
            </div>
            <div className="col-3">
              <LabelWithInputField
                isMandatory={true}
                name="biNumber"
                handleChange={handleFieldChange}
                value={state.address.data.biNumber}
                isDisabled={
                  state.address.data.sameAddress === 1 ||
                  state.address.data.sameAddress === true
                }
                id="number"
                label="Number"
                type="text"
                error={state.address.errors.biNumber}
              />
            </div>
            <div className="col-3">
              <LabelWithInputField
                isMandatory={false}
                name="biBox"
                handleChange={handleFieldChange}
                value={state.address.data.biBox}
                isDisabled={
                  state.address.data.sameAddress === 1 ||
                  state.address.data.sameAddress === true
                }
                id="box"
                label="Box"
                type="text"
              />
            </div>
            <div className="col-4">
              <LabelWithInputField
                isMandatory={true}
                name="biZipCode"
                handleChange={handleFieldChange}
                value={state.address.data.biZipCode}
                isDisabled={
                  state.address.data.sameAddress === 1 ||
                  state.address.data.sameAddress === true
                }
                id="zipCode"
                label="Zip code"
                type="text"
                error={state.address.errors.biZipCode}
              />
            </div>
            <div className="col-4">
              <LabelWithInputField
                isMandatory={true}
                name="biCity"
                handleChange={handleFieldChange}
                value={state.address.data.biCity}
                isDisabled={
                  state.address.data.sameAddress === 1 ||
                  state.address.data.sameAddress === true
                }
                id="biCity"
                label="City"
                type="text"
                error={state.address.errors.biCity}
              />
            </div>
            <div className="col-4">
              <SelectWithSearch
                title="Country"
                name="biCountry"
                isMandatory={true}
                search={true}
                placeHolder="Select"
                options={state.options.countries}
                isDisabled={
                  state.address.data.sameAddress === 1 ||
                  state.address.data.sameAddress === true
                }
                value={state.address.data.biCountry}
                onChange={(e) => handleSelectChange(e, "biCountry")}
                isMulti={false}
                error={state.address.errors.biCountry}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Address;
