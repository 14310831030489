export const filterForUniqueOptions = (data: any, notRequiredData: any) => {
    const unWantedValues = notRequiredData?.map((eachItem: any) => eachItem?.pc?.value);
    const filteredData = data.filter((eachData: any) => !unWantedValues.includes(eachData?.value));
    return filteredData;
}

export const validateData = (previousData: any, currentValues: any, type: string) => {
    let hasNotError = true;
    if (type == "function") {
        const validatedData = previousData?.map((eachValue: any) => {
            const presentValues = eachValue?.function?.value ?? eachValue?.function;
            if (presentValues == currentValues?.function) {
                hasNotError = hasNotError && false;
            }
        });
        return hasNotError;
    }
    else if (type == "employeeType") {
        const validateEmpTypes = previousData?.map((eachValue: any) => {
            const presentValues = {
                employeeType: eachValue?.employeeType?.value ?? eachValue?.employeeType,
                level: eachValue?.level?.value ?? eachValue?.level,
                type: eachValue?.type?.value ?? eachValue?.type
            }
            if ((presentValues?.employeeType == currentValues?.employeeType &&
                presentValues?.level == currentValues?.level &&
                presentValues?.type == currentValues?.type)) {
                hasNotError = hasNotError && false;
            }
        });
    }
    return hasNotError;
}

export const validateDuplicateCoefficients = (data: any) => {
    let hasNotError = true;

    const updatedState = data?.map((eachPc: any) => {
        const functionsData: any = [];
        const newFunctions = eachPc?.funEmpType?.map((eachFun: any) => {
            const duplicateFunction = validateData(functionsData, { function: eachFun?.function?.value ?? eachFun?.function }, 'function'); //Validate function here
            const empTypeData: any = [];
            const newEmpTypeData = eachFun?.empTypeData?.map((eachEmp: any) => {
                const currentValues = {
                    employeeType: eachEmp?.employeeType?.value ?? eachEmp?.employeeType,
                    level: eachEmp?.level?.value ?? eachEmp?.level,
                    type: eachEmp?.type?.value ?? eachEmp?.type
                }
                const duplicateCombination = validateData(empTypeData, currentValues, 'employeeType'); //Validate employee type,level,duration type here
                empTypeData.push(eachEmp);
                if (!duplicateCombination) {
                    hasNotError = hasNotError && false;
                    return {
                        ...eachEmp, errors: {
                            employeeType: "Duplicate value",
                            level: "Duplicate value",
                            type: "Duplicate value",
                            duration: "",
                        }
                    }
                }
                return {
                    ...eachEmp, errors: {
                        employeeType: "",
                        level: "",
                        type: "",
                        duration: "",
                    }
                };
            });
            functionsData.push(eachFun);
            if (!duplicateFunction) {
                hasNotError = hasNotError && false;
                return {
                    ...eachFun, errors: {
                        function: "Duplicate value",
                    },
                    empTypeData: newEmpTypeData,
                }
            }
            return { ...eachFun, empTypeData: newEmpTypeData };
        });
        return { ...eachPc, funEmpType: newFunctions };
    });
    return { coefficients: updatedState, hasError: hasNotError };
}
