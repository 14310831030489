import React, { useEffect, useState } from "react";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import Button from "components/atoms/Button";
import LoadingIcon from "utils/LoadingIcon";
import { t } from "pages/microservices/masterData/translation/Translation";
import { TextAreaMolecule } from "components/molecules/TextAreaMolecule";
import MaxiconFileUpload from "components/molecules/MaxiconFileUpload";
import { handleSingleFileUpload } from "utils/S3Bucket/Index";
import CheckBoxField from "components/atoms/CheckBoxField";
import { validateEmail, validateForm, validateRequired } from "services/validation/ValidationService";
import { ValidationRules } from "utils/TypeAnnotations";
import { PATH_MANAGE_APPLICANTS } from "constants/Paths";
import { useNavigate } from "react-router-dom";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { Option } from "utils/TypeAnnotations";
import { ApiCall } from "services/ApiServices";
import { GET_VACANCY_OPTIONS, SAVE_VACANCY_APPLICANTION } from "routes/ApiEndpoints";
import { M_IDENTITY_MANAGER, M_MASTER_DATA } from "constants/Constants";

const ApplicantVacancyIntegration = () => {
  const navigate = useNavigate();
  const [vacancyOptions, setVacancyOptions] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    postalCode: "",
    cv: {
      fileName: '',
      accessUrl: ''
    },
    motivation: "",
    vacancy: null,
    termsAccepted: 0,
  });

  const [errors, setErrors] = useState<any>({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getVacancyOptions();
  }, []);

  const getVacancyOptions = async () => {
    const response = await ApiCall.getService(GET_VACANCY_OPTIONS, "GET", M_MASTER_DATA, false);
    setVacancyOptions(response);
  }

  const validation = (
    name: string,
    value: string | number | null | boolean | Date | object[] | undefined,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      postalCode: [validateRequired],
      phoneNumber: [validateRequired],
      email: [validateEmail],
    };
    const validationErrors = validateForm(
      { ...formData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation) {
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const handleFieldChange = (e: any) => {
    const { name, value, type, checked } = e.target;
    let updatedValue = value;

    if (type === "checkbox") {
      updatedValue = checked ? 1 : 0
    }

    if (name === 'postalCode' || name === 'phoneNumber') {
      updatedValue = updatedValue.replace(/[^0-9,]/g, '');
    }

    setFormData((prevFormData) => ({ ...prevFormData, [name]: updatedValue }));
    validation(name, value, true);
  };

  const handleFileChange = async (event: any, field: any) => {
    let fileData: any = null;
    let fileName: string = '';

    if (event !== null) {
      fileData = await handleSingleFileUpload(event, "documents");
      fileName = event?.name;
    }

    const index = fileName.lastIndexOf(".");
    const name = index !== -1 ? fileName.slice(0, index - 1) : fileName;
    const extension = index !== -1 ? fileName.slice(index) : "";

    if (fileData !== null) {
      setFormData((prevData: any) => ({
        ...prevData,
        cv: {
          fileName: name + extension,
          accessUrl: fileData?.accessUrl,
        },
      }));
    } else {
      setFormData((prevData: any) => ({
        ...prevData,
        cv: {
          fileName: '',
          accessUrl: '',
        },
      }));
    }
  };

  const handleSelectChange = (selectedOption: Option | null, fieldName: string) => {
    setFormData((prevFormData: any) => ({ ...prevFormData, [fieldName]: selectedOption }));
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    const { name, value } = e.target as HTMLInputElement;
    const validationResult = validation(name, value);
    if (validationResult) {
      try {
        const response = await ApiCall.service(SAVE_VACANCY_APPLICANTION, "POST", formData, false, M_IDENTITY_MANAGER);
        if (response.status === 200) {
          navigate(PATH_MANAGE_APPLICANTS);
        }
      } catch (error) {
        console.error("Vacancy application failed:", error);
      }
    }
    setLoading(false);
  };

  return (
    <div className="container-fluid d-flex justify-content-center align-items-center vh-100 bg-light">
      <div className="row justify-content-center align-items-center w-100">
        <div className="col-md-6 col-12">
          <div className="card shadow-lg p-3">
            <div className="text-center mb-3">
              <img src="static/images/logo.svg" alt="Logo" className="logo-width mb-3" />
              <h4>{t("Vacancy registration")}</h4>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <LabelWithInputField
                      name="name"
                      label={t("Name")}
                      value={formData?.name}
                      handleChange={handleFieldChange}
                      placeholder={t("Enter your  name")}
                    />
                  </div>
                  <div className="mb-3">
                    <LabelWithInputField
                      name="postalCode"
                      label={t("Postal Code")}
                      value={formData?.postalCode}
                      handleChange={handleFieldChange}
                      placeholder={t("Enter your postal code")}
                      isMandatory={true}
                      error={errors?.postalCode}
                    />
                  </div>
                  <div className="mb-3">
                    <LabelWithInputField
                      name="phoneNumber"
                      label={t("Mobile Number")}
                      value={formData?.phoneNumber}
                      handleChange={handleFieldChange}
                      placeholder={t("Enter your phone number")}
                      isMandatory={true}
                      error={errors.phoneNumber}
                    />
                  </div>
                  <div className="mb-3">
                    <LabelWithInputField
                      name="email"
                      label={t("Email")}
                      value={formData.email}
                      handleChange={handleFieldChange}
                      type="email"
                      placeholder={t("Enter your email")}
                      isMandatory={true}
                      error={errors.email}
                    />
                  </div>
                  <div className="mb-3">
                    <MaxiconFileUpload
                      label={t('CV')}
                      name="cv"
                      id="cv"
                      edit={false}
                      fileId={null}
                      fileObj={JSON.stringify(formData.cv)}
                      multiple={false}
                      uploadPath="vacancy-application"
                      formats="application/pdf, application/msword, image/*"
                      handleChange={(e: any) => handleFileChange(e, 'cv')}
                      maxFileSize={10000}
                    />
                  </div>

                </div>
                <div className="col">
                  <div className="mb-3">
                    <TextAreaMolecule
                      label={t('Motivation')}
                      name="motivation"
                      value={formData.motivation}
                      handleChange={handleFieldChange}
                      placeholder={t("Motivation")}
                    />
                  </div>
                  <div className="col">
                    <SelectWithSearch
                      title={t("Vacancy")}
                      isMandatory={true}
                      search={true}
                      options={vacancyOptions}
                      onChange={(e) => handleSelectChange(e, "vacancy")}
                      isMulti={false}
                      name={t("vacancy")}
                      value={formData.vacancy}
                      placeHolder="Select"
                    />
                  </div>
                  <div className="mb-3">
                    <CheckBoxField
                      label={t("Yes, I wish to be informed of offers, promotions or news items from Maxicon.")}
                      name={t("termsAccepted")}
                      onChangeHandler={handleFieldChange}
                      isChecked={formData.termsAccepted === 1}
                      className="document-checkbox"
                      id="active"
                      lineHeight="1.5vw"
                    />
                  </div>
                </div>
              </div>
              <div className="text-center col-md-10 mx-auto">
                {!loading ? (
                  <Button className="form-button" type="submit" title="SEND" />
                ) : (
                  <LoadingIcon iconType="bars" color="#00a5ce" height={"3vw"} width={"3vw"} />
                )}
              </div>
            </form>
          </div>
        </div>
      </div >
    </div >
  );
};

export default ApplicantVacancyIntegration;
