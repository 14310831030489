import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import AuthReducer from "../features/auth/AuthSlice";
import pageHistoryReducer from "./pageHistory/pageHistorySlice";

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["pageHistory"], // Exclude pageHistory from persistence
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    auth: AuthReducer,
    pageHistory: pageHistoryReducer,
  })
);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          // Ignore specific action types
          ignoredActions: ["persist/PERSIST"],
        },
      }),
  });
  

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;