import React, { useEffect, useState } from "react";
import PlanningEmployees from "../molecules/planningEmployees";
import PlanningWeekly from "../molecules/planningWeekly";
import {
  usePlanning,
  usePlanningsDispatch,
} from "../planning-redux/context/PlanningContext/PlanningContext";
import { getEmployeeNameById, getRandomInt, getPlanningTitle } from "../utils/utils";
import Popup from "components/molecules/Popup";
import { dateUtils } from "../utils/dateUtils";
import {
  WEEK_VIEW_NUMBER,
  MONTH_VIEW_NUMBER,
  YEAR_VIEW_NUMBER,
} from "../constants/planningConstants";
import { PLANNING_ACTIONS } from "../planning-redux/actions/PlanningActions";
import PlanningMonthly from "../molecules/planningMonthly";
import PlanningYear from "../molecules/planningYear";
import PlanningModal from "../molecules/planningModal";
import { t } from "pages/microservices/masterData/translation/Translation";
import {
  usePlanningModal,
  usePlanningsModalDispatch,
} from "../planning-redux/context/PlanningModalContext/PlanningModalContext";
import { useEmployee } from "../planning-redux/context/EmployeeContext/EmployeeContext";
import { CREATE_PLANNING } from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";
import { M_MASTER_DATA } from "constants/Constants";
import { initialPlanningModalProps } from "../planning-redux/context/PlanningModalContext/intialPlanningModalProps";
import { PLANNING_MODAL_ACTIONS } from "../planning-redux/actions/PlanningModalActions";
import { validateRequired } from "services/validation/ValidationService";
import CustomNotify from "components/atoms/CustomNotify";
import { PLANNING_FILTER_ACTIONS } from "../planning-redux/actions/PlanningFilterActions";
import {
  useFilterOptionsDispatch,
  useFilterOptions,
} from "../planning-redux/context/FiltersContext/FiltersContext";
import PlanningDateFilters from "../molecules/planningDateFilters";
import { WEEK2_KEYWORD } from "../constants/planningConstants";

const PlanningOverview = ({ type }) => {
  const { planningState, view } = usePlanning();
  const { dispatchPlanning } = usePlanningsDispatch();
  const { planningModalState } = usePlanningModal();
  const { dispatchPlanningModal } = usePlanningsModalDispatch();
  const { initialEmployeeState } = useEmployee();
  const { dispatchFilterOptions } = useFilterOptionsDispatch();
  const { filterOptionsState } = useFilterOptions();
  const planningComponent = [];

  const [popup, setPopup] = useState({
    isModelOpen: false,
    isErrorPopup: false,
    errorTitle: "",
  });
  let permissions = {
    submitPermissions: {
      permission: type === "hr" ? "Planning" : "Sales planning",
      create: true,
    },
    extraButtonPermissions: {
      permission: type === "hr" ? "Planning" : "Sales planning",
      delete: true,
    },
  };
  if (planningModalState.state.id != "") {
    permissions = {
      ...permissions,
      submitPermissions: {
        permission: type === "hr" ? "Planning" : "Sales planning",
        update: true,
      },
    };
  }
  const openPlanningModal = (e, planid = "") => {
    e.preventDefault();
    setPopup((prev) => ({ ...prev, isModelOpen: true }));
  };

  const closeModal = (event, newplan = false) => {
    let dependatestate = planningModalState.dependentState;
    setPopup((prev) => ({
      ...prev,
      isModelOpen: false,
      isErrorPopup: false,
      title: "",
    }));
    clearStateValues();
    if (newplan) {
      dispatchPlanningModal({
        type: PLANNING_MODAL_ACTIONS.BULK_UPDATE_PLANNING_MODAL_DEPENDENT_FIELD,
        payload: {
          employee_id: dependatestate.employee_id,
          frequency: dependatestate.frequency,
          start_date: dependatestate.start_date,
          company: dependatestate.company,
          project: dependatestate.project
        },
      });
      setTimeout(() => {
        setPopup((prev) => ({ ...prev, isModelOpen: true }));
      }, 10);
    }
  };

  const validateplanningModalState = () => {
    let errorlist = planningModalState.errorState;
    let count = 0;
    Object.entries(planningModalState.errorState).forEach(([key, value]) => {
      let val = validateRules(key, planningModalState.state[key]);
      if (!(val == "" || val == null)) {
        count++;
      }
      errorlist[key] = val;
    });
    dispatchPlanningModal({
      type: PLANNING_MODAL_ACTIONS.BULK_UPDATE_PLANNING_MODEL_ERROR_FIELD,
      payload: errorlist,
    });
    return count > 0 ? false : true;
  };

  const validateRules = (type, value) => {
    let res = "";
    let project = null;
    switch (type) {
      case "company":
      case "project":
        res = planningState.planningOverview.value == 'project' ? null : validateSelectField(value);
        break;
      case "start_date":
        const givendate = new Date(value);
        const currentdate = new Date();
        res =
          dateUtils.getFormattedDates(value, true) >
            dateUtils.getFormattedDates(planningModalState.state.start_date, true)
            ? "Please enter valid date"
            : "";
        break;
      case "typeid":
        project = validateSelectField(planningModalState.state.project);
        let typeidres = validateSelectField(value);
        res =
          project == null
            ? value != ""
              ? ""
              : validateSelectField(value)
            : "";
        break;
      case "week_number":
        project = validateSelectField(planningModalState.state.project);
        res =
          project == null && planningModalState.state.type == "Sequence"
            ? validateSelectField(value)
            : "";
        break;
      case "end_date":
        res =
          dateUtils.getFormattedDates(value, true) <
            dateUtils.getFormattedDates(planningModalState.state.start_date, true)
            ? "Please enter valid date"
            : "";
        break;
      case "type":
        let proj = validateSelectField(planningModalState.state.project);
        res = proj == null ? validateRequired(value) : "";
      default:
        break;
    }
    return res;
  };
  const validateSelectField = (selectedObject) => {
    if (
      !selectedObject ||
      selectedObject.value === "" ||
      selectedObject.value == undefined
    ) {
      return t("This field is required");
    }
    return null;
  };

  const savePlanning = async (e, deleteplan = false, id = null, copy = false) => {
    let isvalidated = (deleteplan == false && copy == false) ? validateplanningModalState() : true;
    if (deleteplan || isvalidated) {
      let plandetails = await savePlan(deleteplan, id, copy);
      if (plandetails != null && plandetails.length > 0) {
        let plans = [];
        plandetails.map((employee) => {
          plans[employee.employee_number] = employee;
        });
        dispatchPlanning({
          type: PLANNING_ACTIONS.SET_PLANNING_EVENTS,
          payload: plans,
        });
      }
      closeModal();
    }
  };

  const clearStateValues = () => {
    dispatchPlanningModal({
      type: PLANNING_MODAL_ACTIONS.BULK_UPDATE_PLANNING_MODEL_FIELD,
      payload: initialPlanningModalProps.state,
    });
    dispatchPlanningModal({
      type: PLANNING_MODAL_ACTIONS.BULK_UPDATE_PLANNING_MODEL_ERROR_FIELD,
      payload: initialPlanningModalProps.errorState,
    });
    dispatchPlanningModal({
      type: PLANNING_MODAL_ACTIONS.BULK_UPDATE_PLANNING_MODAL_DEPENDENT_FIELD,
      payload: initialPlanningModalProps.dependentState,
    });
  };

  const savePlan = async (deleteplan, planid, copy) => {
    let filters = {
      ...filterOptionsState.filteredState,
      viewtype: type,
      planningOverview: planningState.planningOverview
    };
    planid = planid != null ? planid : planningModalState.state.id
    let postdata = {
      id: planid,
      filters: filters,
      frequency: "weekly"
    };

    if (!deleteplan) {
      const startdate = new Date( copy == true ? dateUtils.getDate(planningState.cellState.date) : planningModalState.state.start_date);
      const enddate = new Date(copy == true ? dateUtils.getDate(planningState.cellState.date) :  planningModalState.state.end_date);
      postdata = {
        ...postdata,
        employee_id: copy == true ? planningState.cellState.employee : planningModalState.dependentState.employee_id,
        company: copy == true ? planningState.copydetails.company_id : planningModalState.state.company.value,
        project: copy == true ? planningState.copydetails.project_id : planningModalState.state.project.value,
        start_date: dateUtils.getFormattedDates(startdate, true),
        end_date: dateUtils.getFormattedDates(enddate, true),
        typeid: copy == true ? planningState.copydetails.type_id : 
          (planningModalState.state.typeid.value && !deleteplan
            ? planningModalState.state.typeid.value
            : planningModalState.state.typeid),
        week_number: copy == true ? planningState.copydetails.week : 
          (planningModalState.state.type == "Sequence" && !deleteplan
            ? planningModalState.state.week_number.value
            : ""),
        from_time: copy == true ? planningState.copydetails.start_time : 
          (planningModalState.state.type == "Shift"
            ? planningModalState.state.from_time
            : ""),
        to_time: copy == true ? planningState.copydetails.end_time : 
          (planningModalState.state.type == "Shift"
            ? planningModalState.state.to_time
            : ""),
        type: copy == true ? planningState.copydetails.type : planningModalState.state.type,
        plan_status: "active",
        status: true,
      };
    } else {
      postdata = {
        ...postdata,
        action: "delete"
      };
    }
    const response = await ApiCall.service(
      CREATE_PLANNING,
      "POST",
      postdata,
      true,
      M_MASTER_DATA
    );
    let plandetails = {};
    let restype =
      response?.status == 200 || response?.status == 201 ? "success" : "error";
    CustomNotify({ type: restype, message: t(copy == true ? "Plan pasted successfully" : response?.message) });
    if (response && (response?.status == 201 || response?.status == 200)) {
      plandetails = response.plandetails.details
        ? response.plandetails.details
        : response.plandetails;
    }
    return plandetails;
  };


  //switch case to render the planning view based on user selection
  const renderPlanningView = () => {
    switch (view.value) {
      case "week2":
        return createWeeklyPlanningView(2);
      case "week4":
        return createWeeklyPlanningView(4);
      case "day":
        // return createDailyPlanningView();
        break;
      case "month":
        return createMonthlyPlanningView();
      case "year":
        return createYearPlanningView();
      default:
        return null; //createWeeklyPlanning();
    }
  };

  const updateFilterDates = (startdate, enddate) => {
    let list = {
      startdate: startdate,
      enddate: enddate,
    };
    if (
      filterOptionsState.filteredState.startdate !== startdate ||
      filterOptionsState.filteredState.enddate !== enddate
    ) {
      dispatchFilterOptions({
        type: PLANNING_FILTER_ACTIONS.BULK_UPDATE_PLANNING_SELECTED_FILEDS,
        payload: list,
      });
    }
  };


  const navigateTo = (event, direction, type) => {
    event.preventDefault()

    let curstartdate = planningState.startdate;
    let curenddate = planningState.enddate;
    let startdate = null;
    let enddate = null;
    switch (type) {
      case "month":
        startdate = dateUtils.getDateAfterWeeks(curstartdate, 4, direction, false)
        enddate = dateUtils.getDateAfterWeeks(curenddate, 4, direction, false)
        break;
      case "week":
        startdate = dateUtils.getDateAfterWeeks(curstartdate, 1, direction, false)
        enddate = dateUtils.getDateAfterWeeks(curenddate, 1, direction, false)
        break;
      case "date":
        startdate = new Date(curstartdate.setDate(curstartdate.getDate() + (direction === "prev" ? -1 : 1)));
        enddate = new Date(curenddate.setDate(curenddate.getDate() + (direction === "prev" ? -1 : 1)));
        break;
      default:
        startdate = curstartdate
        enddate = curenddate
        break;
    }
    let payload = {
      enddate: enddate,
      startdate: startdate
    }
    dispatchPlanning({
      type: PLANNING_ACTIONS.UPDATE_MUTIPLE_FIELDS,
      payload: payload,
    });
  }

  const createWeeklyPlanningView = (weeknumber = WEEK_VIEW_NUMBER) => {
    // Initialize a counter for unique identifiers
    let weeklyDates = dateUtils.getGroupedDatesByWeek(planningState.startdate, planningState.enddate)
    // Push the PlanningDateFilters and PlanningWeekly components inside the same loop
    planningComponent.push(
      <>
        <PlanningDateFilters weeklydates={weeklyDates}
          navigateTo={navigateTo} />
        <PlanningWeekly
          weeklyDates={weeklyDates}
          openPlanningModal={openPlanningModal}
          type={type}
          savePlanning={savePlanning}
        />
      </>
    );
    return planningComponent;
  };

  const createMonthlyPlanningView = () => {
    const year = planningState.currentViewYear;
    let monthlyDates = dateUtils.getMonthlyDates(
      planningState.currentViewUnit,
      MONTH_VIEW_NUMBER,
      year
    );
    let mdates = monthlyDates[0];
    const startd = mdates[0].value;
    const endd = mdates[mdates.length - 1].value;
    updateFilterDates(startd, endd);
    for (const nestedArray of monthlyDates) {
      planningComponent.push(
        <div
          className={`ps-0 col-12`}
          key={getRandomInt(1, 1000)}
        >
          <PlanningMonthly
            monthlyDates={nestedArray}
            openPlanningModal={openPlanningModal}
            type={type}
          />
        </div>
      );
    }
    return planningComponent;
  };

  const createYearPlanningView = () => {
    const year = planningState.currentViewYear;
    const monthlyDates = dateUtils.getYearlyDates(
      planningState.currentViewUnit,
      YEAR_VIEW_NUMBER,
      year
    );
    const planningComponent = [];
    let dates = monthlyDates[0];
    const startd = dates[0][0].value;
    const endd = dates[2][dates[2].length - 1].value;
    updateFilterDates(startd, endd);
    for (const quarterlyDates of monthlyDates) {
      const quarterlyPlanningComponent = []; // Initialize an array to store quarterly planning components
      for (const nestedArray of quarterlyDates) {
        quarterlyPlanningComponent.push(
          <div className={`col pe-0`} key={getRandomInt(1, 10000)}>
            <PlanningYear
              yearlyDates={nestedArray}
              openPlanningModal={openPlanningModal}
              type={type}
            />
          </div>
        );
      }

      // Add an additional div to wrap the quarterly planning components
      planningComponent.push(
        <div className={`col ps-0`} key={getRandomInt(1, 10000)}>
          <div className="row">{quarterlyPlanningComponent}</div>
        </div>
      );
    }
    return planningComponent;
  };

  const handleDelete = (e) => {
    savePlanning(e, true);
  };

  return (
    <>
      <div>
        <div className="col-11 ms-auto">
          <div className="d-flex justify-content-between mb-2">
          </div>
        </div>
        <div className="row mx-0 planningHeight">
          <div className="col-1 text-center p-0 align-self-end colFixed">
            {/* add employee molecule */}
            <PlanningEmployees key={Math.floor(Math.random() * 1000000)} navigateTo={navigateTo} />
          </div>
          <div className={`${(planningState.view != null && planningState.view.value == WEEK2_KEYWORD) ? 'col-5' : 'col-11'}  px-0 planningRightSection`}> {/* if */}
            {renderPlanningView()}
          </div>
        </div>
      </div>
      {popup.isModelOpen && (
        <Popup
          title={t(
            `Planning: ${getPlanningTitle(
              planningState.planningOverview.value,
              initialEmployeeState,
              planningModalState.dependentState,
              filterOptionsState.state.projects
            )}`
          )}
          modalclass="planningPopupModal"
          body={<PlanningModal onClose={closeModal} />}
          cancel={closeModal}
          modalSize="lg"
          notext="Close"
          bodyclassName="text-start"
          yestext={planningModalState.state.id != "" ? "Update plan" : "Save"}
          submit={savePlanning}
          cancelButtonClass="close-button marginRightPoint5"
          extraButtonFunction={handleDelete}
          extraButtonText={
            planningModalState.state.id != "" ? "Delete plan" : ""
          }
          permissions={permissions}
        />
      )}

      {popup.isErrorPopup && (
        <Popup
          title="Error"
          body={popup.title}
          cancel={closeModal}
          modalSize="lg"
          notext="Close"
          bodyclassName="text-start"
          cancelButtonClass="close-button"
        />
      )}
    </>
  );
};

export default PlanningOverview;
