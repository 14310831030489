import { t } from "pages/microservices/masterData/translation/Translation";

export const UserStatus = [
  "Not yet activated",
  "Active",
  "Inactive",
  "Archived",
];
export const reCaptchaSiteKey = "6Lf-YWAnAAAAAMQdpyIuyzbF65oE1hjGzihyVFWW";
export const ABSOLUTE_JOBS_APP = "absolute_jobs";
export const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB in bytes
export const CandidateStatus = ["Not yet active", "Active"];
export const formStatus = ["Drafted form", "Completed form"];

// Microservices
export const M_IDENTITY_MANAGER = "m-identitymanager";
export const M_MASTER_DATA = "m-masterdata";
export const M_COMPANY_CREATION = "m-companycreation";
export const M_WEB_CONNECTOR = "m-webconnector";


export const BusinessUnitStatus = ["Active", "Inactive"];

export const ERROR = "Error fetching data";
export const CONTACT_PERSON_CREATED_SECCESFULL =
  "Contact Person created successfully!";

//location
export const LOCATION = "/location";
export const MANAGE_COMPANY = "/manage-companies";
export const MANAGE_LOCATION = "/manage-locations";

// Calendar
export const WEEK_DAYS_TRANSLATABLE = [
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
  "Sun",
];

export const TIMETABLE_WEEK_DAYS = [
  "Default",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export const DISABLED_DAYS = ["Unknown"];

export const MONTHS_TRANSLATABLE = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const DATE_OPTIONS = [
  { label: "Immediately", value: "1" },
  { label: "1 month", value: "2" },
  { label: "2 month", value: "3" },
  { label: "3 month", value: "4" },
  { label: "4 month", value: "5" },
  { label: "5 month", value: "6" },
  { label: "6 month", value: "7" },
  { label: "7 month", value: "8" },
  { label: "8 month", value: "9" },
  { label: "9 month", value: "10" },
  { label: "10 month", value: "11" },
  { label: "11 month", value: "12" },
  { label: "12 month", value: "13" },
]
// Calendar range
export const CALENDAR_RANGE_TOOLBAR = {
  today: t("Today"),
  deselect: t("Clear"),
  close: t("Close"),
};
export const CALENDAR_RANGE_MOBILE_LABELS = {
  OK: t("Confirm"),
  CANCEL: t("Close"),
};

export const CALENDAR_PLUGIN_DATEPANEL_HEADER = t("Dates");
export const CALENDAR_RANGE_DATE_SEPARATOR = " to ";
export const CALENDAR_RANGE_MULTIPLE_RANGE_SEPARATOR = "&";

//employee type
export const EMPLOYEE_TYPE_EXISTS = t("Employee type already exists");

export const RANGE_VALUE = ["1", "2", "3", "4", "5", "6"];
export const RANGE_VALUE_FOR_GENERAL = ["1", "2", "3", "4"];

//tag

//competence
export const COMPETENCE_NAME_EXISTS = "This name already exists";
export const COMPETENCE_TYPE_EXISTS = "This type already exists";
export const TAG_EXISTS = t("Tag already exists");

//Timetable
export const TIMETABLE_ERROR = t("From time should be less than till time");
export const FILL_TIMESHEET_ERROR = t("Fill the shift timetable");
export const FROM_REQUIRED = t("From field is required");
export const TILL_REQUIRED = t("Till field is required");

//Sales Dashboard
export const ADMIN = "ADMIN";
export const INFANION_ADMIN = "INFANION_ADMIN";
export const C_LEVEL = 'C_LEVEL';
export const CANDIDATE_ROLE = 'CANDIDATE';
export const EMPLOYER = 'EMPLOYER';
export const RECRUITER = 'RECRUITER';
export const AREA_MANAGER = "AREA_MANAGER";
export const SALES = "SALES";

//Job application status
export const ApplicationStatus = [
  { value: '1', label: "New" },
  { value: '2', label: "In treatment" },
  { value: '3', label: "Completed" },
  { value: '4', label: "Everything" },
]

