import { M_MASTER_DATA } from "constants/Constants";
import { Suspense, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { ApiCall } from "services/ApiServices";
import { selectPageHistory, updatePageFilters } from "store/pageHistory/pageHistorySlice";
import { ARCHIEVE_VAT_RATE, MANAGE_VAT_RATE } from "routes/ApiEndpoints";
import { selectAuth } from "features/auth/AuthSlice";
import AccessControl from "services/AccessControl";
import CustomNotify from "components/atoms/CustomNotify";
import { PATH_VAT_RATE_CREATE } from "constants/Paths";
import { useNavigate } from "react-router-dom";
import TitleAtom from "components/atoms/Title";
import { t } from "../translation/Translation";
import LinkTo from "components/atoms/LinkTo";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import FilterOrganism from "components/organism/FilterOrganism";
import { VatRateTableHeader } from "TableHeader";
import SortAtomForManagePage from "components/atoms/SortAtomForManagePage";
import TextEllipsis from "components/atoms/TextEllipsis";
import StatusComponent from "components/atoms/ManageStatus";
import TableActions from "components/organism/Actions/TableAction";
import PaginationWithPerPage from "components/molecules/PaginationWithPerPage";
import ModalPopup from "components/atoms/ModalPopup";

const ManageVatRate: React.FC<any> = () => {
    const dispatch = useDispatch();
    const pageHistory = useSelector(selectPageHistory);
    const history = pageHistory?.history?.[pageHistory?.history?.length - 1] ?? {};
    const userData = useSelector(selectAuth);
    const navigate = useNavigate();

    const [state, setState] = useState({
        data: [],
        options: {
            status: [
                { value: 1, label: 'Active' },
                { value: 2, label: 'Inactive' },
            ]
        },
        initialCall: true,
        totalRecords: 0,
        totalPages: 0,
        popup: {
            delete: {
                show: false,
                data: {
                    id: null,
                    status: false
                }
            }
        },
        refresh: true
    })

    useEffect(() => {
        const postData = {
            currentPage: Number(history?.filters?.currentPage ?? 1),
            itemsPerPage: Number(history?.filters?.itemsPerPage ?? 10),
            initialCall: state?.initialCall,
            search: {
                name: history?.filters?.name ?? "",
                percentage: history?.filters?.percentage ?? "",
                status: history?.filters?.status ?? "",
                orderBy: history?.filters?.orderBy ?? "id",
                sortDirection: history?.filters?.sortDirection ?? "desc",
            },
        };
        fetchData(postData);
    }, [state?.refresh, state?.initialCall]);

    const fetchData = async (postData: any) => {
        const response = await ApiCall.service(MANAGE_VAT_RATE, "POST", postData, false, M_MASTER_DATA);
        if (response?.status === 200) {
            let data: any = { ...state, data: response?.data ?? [], initialCall: false, totalPages: response?.totalPages, totalRecords: response?.totalRecords };
            setState(data);
        }
        return response ?? [];
    };

    const handleUpadateStatus = async () => {
        let postData = {
            id: state?.popup?.delete?.data?.id,
            status: state?.popup?.delete?.data?.status ? false : true,
        };
        if (state?.popup?.delete?.data?.id) {
            const response = await ApiCall.service(ARCHIEVE_VAT_RATE, "POST", postData, true, M_MASTER_DATA);
            if (response?.status === 200) {
                CustomNotify({ type: "success", message: response.message });
                handleModal();
                setState((prev: any) => ({
                    ...prev,
                    data: state?.data?.map((item: any) => {
                        if (item?.id == state?.popup?.delete?.data?.id) {
                            return {
                                ...item, status: state?.popup?.delete?.data?.status ? false : true
                            };
                        }
                        return item;
                    }),
                }));
            } else {
                CustomNotify({ type: "error", message: response.message });
            }
        }
    };

    const handleModal = (value?: any) => {
        setState((prev: any) => ({
            ...prev,
            popup: {
                ...prev?.popup,
                delete: {
                    ...prev?.popup?.delete,
                    data: value ?? {},
                    show: value ? true : false,
                },
            },
        }));
    };


    const handleEdit = (value: any) => {
        navigate(`${PATH_VAT_RATE_CREATE}/${value?.id}`);
    };

    const handleRefresh = () => setState((prev: any) => ({ ...prev, refresh: !state?.refresh }));

    const filters = [
        { name: "name", fieldType: "text", placeholder: "Name", filterType: "search" },
        { name: "percentage", fieldType: "text", placeholder: "Percentage", filterType: "search" },
        { name: "status", fieldType: "singleSelect", options: state?.options?.status ?? [], placeholder: "Status", filterType: "search" },
    ];


    return (
        <AccessControl
            requiredPermissions={[{ permission: "Vat rate", read: true }]}
            renderNoAccess={true}
            override={userData.isSuperAdmin}
        >
            <div className="search-bar" style={{ paddingBottom: "0.25vw" }}>
                <div className="row">
                    <TitleAtom title={t("Manage vat rate")} />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="position-relative tableMainWrapper">
                        <div>
                            <AccessControl
                                requiredPermissions={[
                                    {
                                        permission: "Vat rate",
                                        create: true,
                                    },
                                ]}
                                override={userData.isSuperAdmin}
                            >
                                <div className="row">
                                    <div className="ManageCreateBtn">
                                        <LinkTo
                                            pagelink={PATH_VAT_RATE_CREATE}
                                            title={t("Create vat rate")}
                                            icon={faPlus}
                                        />
                                    </div>
                                </div>
                            </AccessControl>
                        </div>
                        <div className="tableSection manage-Vacancies">
                            <FilterOrganism
                                dispatch={dispatch}
                                history={history}
                                updatePageFilters={updatePageFilters}
                                filters={filters}
                                handleRefresh={handleRefresh}
                            />
                            <div className="table-responsive manage-Vacancies tableWithSearchBar tablePadding">
                                <table className="table table-hover">
                                    <thead>
                                        <tr className="TableHeader">
                                            {VatRateTableHeader?.map((header) => (
                                                <th key={header?.alias} className={"table_actions"}>
                                                    <>
                                                        {t(header?.name)}
                                                        {header?.issort && (
                                                            <SortAtomForManagePage
                                                                value={header}
                                                                dispatch={dispatch}
                                                                history={history}
                                                                updatePageFilters={updatePageFilters}
                                                                handleRefresh={handleRefresh}
                                                            />
                                                        )}
                                                    </>
                                                </th>
                                            ))}
                                            <th className="table_actions">{t("Action")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {state?.data && state?.data?.length > 0 ? (
                                            state?.data?.map((record: any, key: any) => {
                                                return (
                                                    <tr key={key}>
                                                        <td className="align-middle">
                                                            <TextEllipsis
                                                                label={record?.name}
                                                                title={record?.name}
                                                                width="5vw"
                                                            />
                                                        </td>
                                                        <td className="align-middle">
                                                            <TextEllipsis
                                                                label={record?.percentage}
                                                                title={record?.percentage}
                                                                width="8vw"
                                                            />
                                                        </td>
                                                        <td className="align-middle">
                                                            <StatusComponent
                                                                initialStatus={record?.status ? 1 : 2}
                                                                tooltip={record?.status ? t("Active") : t("Inactive")}
                                                            />
                                                        </td>
                                                        <td className="align-middle">
                                                            <TableActions
                                                                isAction={true}
                                                                handleEdit={handleEdit}
                                                                handleArchive={handleModal}
                                                                value={record}
                                                                permission={"Vat rate"}
                                                            />
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td colSpan={9} className="text-center">
                                                    {t("No records found")}
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <div className="pagination d-block" style={{ paddingTop: "0.25vw" }}>
                                <PaginationWithPerPage
                                    handleRefresh={handleRefresh}
                                    dispatch={dispatch}
                                    history={history}
                                    pageData={{
                                        totalPages: state?.totalPages,
                                        totalRecords: state?.totalRecords,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalPopup
                show={state?.popup?.delete?.show}
                onHide={() => handleModal()}
                title={t("Delete confirmation")}
                body={t(`Are you sure want to ${state?.popup?.delete?.data?.status ? "archive" : "unarchive"}`) + "?"}
                onCloseButtonClick={() => handleModal()}
                onConfirmButtonClick={handleUpadateStatus}
                closeTitle={t("No")}
                confirmTitle={t("Yes")}
            />
        </AccessControl>
    );
};

export default ManageVatRate;
