import TitleFieldMolecule from "components/molecules/TitleField"
import { t } from "../masterData/translation/Translation"
import { Link, useNavigate, useParams } from "react-router-dom"
import { PATH_EMPLOYEE_MANAGE } from "constants/Paths"
import Car from "./car/Car"
import Material from "./material/Material"
import Clothes from "./clothes/Clothes"
import { useEffect, useState } from "react"
import "./css/fleetAndMaterial.css";
import BackButton from "components/atoms/BackButton"
import { useDispatch, useSelector } from "react-redux"
import { selectPageHistory } from "store/pageHistory/pageHistorySlice"

const FleetEmployeeOverview = () => {

    const staticTabs = [
        { id: "car", tabName: "Cars", component: Car },
        { id: "material", tabName: "Materials", component: Material },
        { id: "clothes", tabName: "Clothes", component: Clothes },
    ];

    const dispatch = useDispatch();
    const pageHistory = useSelector(selectPageHistory);
    const history = pageHistory?.history?.[pageHistory?.history?.length - 1] ?? {};
    const params = useParams();

    const type = params.userType;

    const navigate = useNavigate();

    const [tabs, setTabsData] = useState(staticTabs);

    const [activeTab, setActiveTabs] = useState(tabs[0]);

    const userType = type == '1' ? 'client' : 'employee';

    // useEffect(() => {
    //     updateCurrentTab();
    // }, []);

    // const updateCurrentTab = () => {
    //     const tabType = params.type;
    //     const updatedCurrentTab = tabs.filter((eachTab: any) => {
    //         if (eachTab.id == tabType) {
    //             return eachTab;
    //         }
    //         return false;
    //     });
    //     setActiveTabs(updatedCurrentTab[0]);
    // }


    const handleChangeTab = (event: any) => {
        const { id } = event.target;
        const updatedTab = tabs.filter((eachItem: any) => {
            if (eachItem.tabName === event.target.textContent) {
                setActiveTabs(eachItem)
                return { ...eachItem };
            }
            else {
                return { ...eachItem };
            }
        });
    }

    const renderActiveComponent = (component: any) => {
        switch (component) {
            case "car":
                return (<Car type="fleet" />);
            case "material":
                return (<Material type="fleet" />);
            default:
                return (<Clothes type="fleet" />)
        }
    }

    return (
        <div className="fleetEmployeeOverview">
            <TitleFieldMolecule
                title={t(`Fleet ${userType} overview`)}
            />
            <div className="w-75 mx-auto d-flex justify-content-center createFormTabs pt-0" style={{ gap: "0 1vw" }}>
                {tabs.map((eachItem) =>
                    <button className={`createStepBtn ${eachItem.id == activeTab.id ? 'active' : 'inactive'}`} id={eachItem.id} onClick={handleChangeTab} >{eachItem.tabName}</button>)}
            </div>
            {/* {renderActiveComponent(activeTab.id)} */}
            {activeTab.component && <activeTab.component dispatch={dispatch} history={history} setfunc={setTabsData} type="fleet" />}
            <div className="col-md-6 align-self-center" style={{ padding: "0.8vw 0" }}>
                <BackButton />
            </div>

        </div>
    )
}
export default FleetEmployeeOverview