import { t } from "pages/microservices/masterData/translation/Translation";
import React from "react";
import InfoIcon from "static/images/InfoIcon";

interface InfoProps {
  createdBy?: any;
  updatedBy?: any;
  createdAt?: Date | null | any;
  updatedAt?: Date | null | any;
}

const UserInfoTooltip: React.FC<InfoProps> = ({
  createdBy,
  updatedBy,
  createdAt,
  updatedAt,
}) => {
  return (
    <div className="d-inline-block">
      <div className="position-relative manageInfoIcon table-action-btn cursor-pointer">
        <InfoIcon />
        <span className="infoHelpText text-center">
          {t("Created by ")}
          <span className="fw-bold">{createdBy ?? ""}</span> on <br />
          <span className="fw-bold">
            {createdAt ?? ""}
          </span>
          <br />
          {t("Last modified by")}{" "}
          <span className="fw-bold">{updatedBy ?? ""}</span> on <br />
          <span className="fw-bold">
            {updatedAt ?? ""}
          </span>
        </span>
      </div>
    </div>
  );
};

export default UserInfoTooltip;
