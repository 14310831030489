import { dateUtils } from 'pages/microservices/planning/utils/dateUtils';
import { PLANNING_VIEW_OPTIONS, SALES_VIEW_OPTIONS, OVERVIEW_TYPE } from 'pages/microservices/planning/constants/planningConstants';
import { setCurrentViewUnit } from 'pages/microservices/planning/utils/utils';

let [startdate, enddate] = setCurrentViewUnit(PLANNING_VIEW_OPTIONS[1]);
export const initialPlanningProps = {
    view: PLANNING_VIEW_OPTIONS[1],
    planning_type: null,
    planningOverview: OVERVIEW_TYPE[0],
    currentViewUnit: 42,
    currentViewYear: new Date().getFullYear(),
    startdate: startdate,
    enddate:enddate,
    planningEvents: [],
    type:'',
    salesview: SALES_VIEW_OPTIONS[0],
    copydetails:null,
    cellState: {
        plan: null,
        id: '',
        employee: null,
        date: null,
        project: null,
        company: null
    }
}


