import Popup from 'components/molecules/Popup'
import React from 'react'
import NewRulePopUp from './NewRulePopUp'
import PaymentPopUp from './PaymentPopUp'

const PopUps = (props: any) => {
    
    const renderPopup = () => {
        switch (props?.tabData?.popup?.name) {
            case 'newrule':
                return <Popup
                    title={("New rule")}
                    body={<NewRulePopUp {...props} />}
                    cancel={props?.handleClosePopup}
                />

            case 'payment':
                return <Popup
                    title={("Add payment")}
                    body={<PaymentPopUp {...props} />}
                    cancel={props?.handleClosePopup}
                />

            default:
                break;
        }
    }

    return (
        <>
            {props?.tabData?.popup?.name && renderPopup()}
        </>
    )     
}

export default PopUps