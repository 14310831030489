import { useEffect, useState } from "react";
import { t } from "../translation/Translation";
import AccessControl from "services/AccessControl";
import { useNavigate } from "react-router-dom";
import Title from "components/atoms/Title";
import FilterOrganism from "components/organism/FilterOrganism";
import { useDispatch, useSelector } from "react-redux";
import { selectPageHistory, updatePageFilters } from "store/pageHistory/pageHistorySlice";
import PaginationWithPerPage from "components/molecules/PaginationWithPerPage";
import LinkTo from "components/atoms/LinkTo";
import { selectAuth } from "features/auth/AuthSlice";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { PATH_CREATE_INVOICE } from "constants/Paths";
import { ApiCall } from "services/ApiServices";
import { CREATE_MULTIPLE_PAYMENTS, MANAGE_INVOICE } from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "constants/Constants";
import TextEllipsis from "components/atoms/TextEllipsis";
import { InvoiceTableHeader } from "TableHeader";
import SortAtomForManagePage from "components/atoms/SortAtomForManagePage";
import CheckBoxField from "components/atoms/CheckBoxField";
import Button from "components/atoms/Button";
import CustomNotify from "components/atoms/CustomNotify";
import Popup from "components/molecules/Popup";
import CommonServices from "services/CommonService";

interface ManageDataProps {
    id: number;
    businessUnit: string;
    documentNumber: string;
    reference: string;
    company: string;
    invoiceDate: string;
    expirationDate: string;
    status: string;
    amountExcludingVAT: string;
    amountIncludingVAT: string;
    toBePaid: string;
}

export const ManageInvoicing: React.FC = () => {
    const userData = useSelector(selectAuth);
    const navigate = useNavigate();
    const pageHistory = useSelector(selectPageHistory);
    const history = pageHistory?.history?.[pageHistory?.history?.length - 1] ?? {};
    const dispatch = useDispatch();
    const [pageData, setPageData] = useState<any>({
        totalPages: 0,
        totalRecords: 0,
        refresh: true,
        initialCall: true,
        selected: [],
        selectAll: false,
        paymentPopup: {
            show: false,
            data: {},
        },
    });
    const [manageData, setManageData] = useState<ManageDataProps[]>([]);
    const [businessUnits, setBusinessUnits] = useState([]);

    const getSearchData = () => {
        return {
            businessUnits: history?.filters?.businessUnits ?? [],
            documentNumber: history?.filters?.documentNumber ?? "",
            reference: history?.filters?.reference ?? "",
            company: history?.filters?.company ?? "",
            startDate: CommonServices?.getTimezoneOffset(history?.filters?.startDate ?? null),
            endDate: CommonServices?.getTimezoneOffset(history?.filters?.endDate ?? null),
            invoiceAmount: history?.filters?.invoiceAmount ?? "",
            startPayment: CommonServices?.getTimezoneOffset(history?.filters?.startPayment ?? null),
            endPayment: CommonServices?.getTimezoneOffset(history?.filters?.endPayment ?? null),
            paymentStatus: history?.filters?.paymentStatus ?? "",
            toBePaid: history?.filters?.toBePaid ?? ""
        };
    };

    const filters = [
        { name: '', fieldType: "", placeholder: "", filterType: 'search' },
        { name: 'businessUnits', fieldType: "multiSelect", options: businessUnits, placeholder: "Business unit", filterType: 'search' },
        { name: 'documentNumber', fieldType: "text", placeholder: "Document no.", filterType: 'search' },
        { name: 'reference', fieldType: "text", placeholder: "Reference", filterType: 'search' },
        { name: 'company', fieldType: "text", placeholder: "Company", filterType: 'search' },
        {
            filterType: "multiple",
            filters: [
                { name: 'startDate', fieldType: "date", end: history?.filters?.endDate, placeholder: "Start", filterType: 'search' },
                { name: 'endDate', fieldType: "date", start: history?.filters?.startDate, placeholder: "End", filterType: 'search' },
            ]
        },
        {
            filterType: "multiple",
            filters: [
                { name: 'startPayment', fieldType: "date", end: history?.filters?.endPayment, placeholder: "Start", filterType: 'search' },
                { name: 'endPayment', fieldType: "date", start: history?.filters?.startPayment, placeholder: "End", filterType: 'search' },
            ]
        },        
        { name: 'paymentStatus', fieldType: "singleSelect", placeholder: "Select", options: [{value: "0", label: 'Open'}, {value: "1", label: 'Closed'}], filterType: 'search' },
        { name: '', fieldType: "", placeholder: "", filterType: 'search' },
        { name: '', fieldType: "", placeholder: "", filterType: 'search' },
    ];

    useEffect(() => {
        fetchInvoiceDetails();
    }, [pageData?.refresh, pageData?.initialCall]);

    const fetchInvoiceDetails = async () => {
        const postData = {
            currentPage: Number(history?.filters?.currentPage ?? 1),
            itemsPerPage: Number(history?.filters?.itemsPerPage ?? 10),
            search: getSearchData(),
            initialCall: pageData?.initialCall,
        };
        const response = await ApiCall.service(MANAGE_INVOICE, 'POST', postData, false, M_MASTER_DATA);
        if (response?.status === 200) {
            setManageData(response?.data?.manageData);
            setBusinessUnits(response?.data?.businessUnits);
        }
        setPageData((prev: any) => ({
            ...prev,
            totalPages: response?.data?.totalPages,
            totalRecords: response?.data?.totalRecords,
            selected: pageData?.selectAll ? manageData : [],
            initialCall: false
        }));
    };

    const handleRefresh = () => setPageData((prev: any) => ({ ...prev, refresh: !pageData?.refresh }));

    const handleSelectCheckbox = (e: any, invoice?: any) => {
        let value = e?.target?.checked ?? false;
        let selected: any = [...pageData?.selected];
        let selectAll = pageData?.selectAll;
        
        if (!invoice) {
            selected = value ? manageData : [];            
            selectAll = value;
        } else {
            if (value) {
                selected?.push(invoice);
                selectAll = selected?.length === manageData?.length
            } else {
                selected = selected?.filter((item: any) => invoice?.id != item?.id);
                selectAll = false;
            }
        }
        setPageData((prev: any) => ({ ...prev, selectAll, selected }))
    }

    const isChecked = (id: any) => pageData?.selected?.some((item: any) => item?.id === id);

    const hasSingleCompanyAndHasClosed = () => new Set(pageData?.selected?.map((item: any) => item?.company_id)).size === 1 && !pageData?.selected?.some((item: any) => item?.status?.toLowerCase() === 'closed');

    const handlePopup = () => {
        if (pageData?.selected?.length === 0) {
            CustomNotify({ type: 'error', 'message': "No invoices have been selected"});
        } else if (!hasSingleCompanyAndHasClosed()) {
            CustomNotify({ type: 'error', 'message': "Invoices must be from the same customer and not yet closed"}); 
        } else {
            setPageData((prev: any) => ({
                ...prev, paymentPopup: {
                    show: true,
                    data: {
                        companyname: pageData?.selected?.[0]?.company ?? "",
                        documentNumbers: pageData?.selected?.map((item: any) => item?.documentNumber).join(', '),
                        total: parseFloat(pageData?.selected?.reduce((acc: any, item: any) => acc + parseFloat(item?.toBePaid), 0).toFixed(2))
                    }
                },
            }));
        }
    }

    const handleAddPayment = async () => {
        try {
            const postData = {
                payments: pageData?.selected?.map((item: any) => {
                    return { invoice_id: item?.id, amount: item?.toBePaid }
                }),
                filters: {
                    currentPage: Number(history?.filters?.currentPage ?? 1),
                    itemsPerPage: Number(history?.filters?.itemsPerPage ?? 10),
                    search: getSearchData(),
                },
                date: CommonServices?.getTimezoneOffset(new Date()),
                user_id: userData?.userId ?? null,
            }
            const response = await ApiCall.service(CREATE_MULTIPLE_PAYMENTS, 'POST', postData, true, M_MASTER_DATA);
            if (response?.status === 200) {
                CustomNotify({ type: 'success', 'message': response?.message });
                setManageData(response?.data?.manageData);
                setPageData((prev: any) => ({ ...prev, selected: [], selectAll: false, paymentPopup: { show: false, data: {}}}));
            }
        } catch (error) {
            console.log(error);  
        }
    }

    return (
        <AccessControl
            requiredPermissions={[
                {
                    permission: "Invoices",
                    read: true,
                },
            ]}
            override={userData.isSuperAdmin}
            renderNoAccess={true}
        >
            <div>
                <Title title={t("Manage Invoices")} />
                <div className="position-relative tableMainWrapper">
                    <AccessControl
                        requiredPermissions={[
                            {
                                permission: "Invoices",
                                create: true,
                            },
                        ]}
                        override={userData.isSuperAdmin}
                    >
                        <div className="row">
                            <div className="ManageCreateBtn">
                                <LinkTo
                                    pagelink="/create/invoice"
                                    title={t("Create Invoice")}
                                    icon={faPlus}
                                />
                                <Button
                                    title={t("Add payment")}
                                    className="form-button ms-3"
                                    handleClick={() => handlePopup()}
                                />
                            </div>
                        </div>
                    </AccessControl>
                    <div className="table-responsive tableSection manageInvoicingTable">
                        <FilterOrganism
                            dispatch={dispatch}
                            history={history}
                            updatePageFilters={updatePageFilters}
                            filters={filters}
                            handleRefresh={handleRefresh}
                        />
                      <div className="TableHeight">
                      <table className="table table-hover">
                            <thead>
                                <tr className="TableHeader" >
                                    {manageData && manageData?.length > 0 && <th key={"select_all"} className="border-bottom-0" style={{ width: '3%' }} >
                                        <CheckBoxField
                                            name="select_all"
                                            isChecked={pageData?.selectAll ?? false}
                                            onChangeHandler={(e) => {
                                                e.stopPropagation();
                                                handleSelectCheckbox(e);
                                            }}
                                        />
                                    </th>}
                                    {InvoiceTableHeader?.map((header: any) => (
                                        <th key={header?.alias}>
                                            <>
                                                {t(header.name)}
                                                {header?.issort && (
                                                    <SortAtomForManagePage
                                                        value={{ alias: header?.alias }}
                                                        dispatch={dispatch}
                                                        history={history}
                                                        updatePageFilters={updatePageFilters}
                                                        handleRefresh={handleRefresh}
                                                    />
                                                )}
                                            </>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {manageData && manageData?.length > 0 ? (manageData?.map((item: ManageDataProps, index: number) => (
                                        <tr
                                            onClick={() => navigate(`${PATH_CREATE_INVOICE}/${item?.id}`)}
                                            key={index}
                                            className="border mb-3 box-shadow align-middle cursor-pointer"
                                        >
                                            <td key={("select" + item?.id)} className="border-bottom-0" style={{ width: '3%' }} >
                                                <CheckBoxField
                                                    name={("checkbox" + item?.id)}
                                                    isChecked={isChecked(item?.id)}
                                                    isClicked={(e) => {
                                                        e.stopPropagation();
                                                        handleSelectCheckbox(e, item)
                                                    }}
                                                />
                                            </td>
                                            <td className="cursor-pointer">
                                                <TextEllipsis title={item?.businessUnit} label={item?.businessUnit} width="6.5vw" />
                                            </td>
                                            <td className="cursor-pointer">
                                                <TextEllipsis title={item?.documentNumber} label={item?.documentNumber} width="6.5vw" />
                                            </td>
                                            <td className="cursor-pointer">
                                                <TextEllipsis title={item?.reference} label={item?.reference} width="6.5vw" />
                                            </td>
                                            <td className="cursor-pointer">
                                                <TextEllipsis title={item?.company} label={item?.company} width="6.5vw" />
                                            </td>
                                            <td className="cursor-pointer">
                                                <TextEllipsis title={item?.invoiceDate} label={item?.invoiceDate} width="6.5vw" />
                                            </td>
                                            <td className="cursor-pointer">
                                                <TextEllipsis title={item?.expirationDate} label={item?.expirationDate} width="6.5vw" />
                                            </td>
                                            <td className="cursor-pointer">
                                                <TextEllipsis title={item?.status} label={item?.status} width="6.5vw" />
                                            </td>
                                            <td className="cursor-pointer">
                                                <TextEllipsis title={item?.amountExcludingVAT} label={item?.amountExcludingVAT} width="6.5vw" />
                                            </td>
                                            <td className="cursor-pointer">
                                                <TextEllipsis title={item?.amountIncludingVAT} label={item?.amountIncludingVAT} width="6.5vw" />
                                            </td>
                                            <td className="cursor-pointer">
                                                <TextEllipsis title={item?.toBePaid} label={item?.toBePaid} width="6.5vw" />
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <>{t("No records found")}</>
                                )}
                            </tbody>
                        </table>
                      </div>
                        <div className="">
                            <PaginationWithPerPage
                                handleRefresh={handleRefresh}
                                dispatch={dispatch}
                                history={history}
                                pageData={pageData}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {pageData?.paymentPopup?.show && <Popup
                title={t("Add payment")}
                body={<>
                    <div><b>{pageData?.paymentPopup?.data?.companyname}</b> {t("This will register all selected invoices for")}</div>
                    <div>{t("Invoice numbers")}: {pageData?.paymentPopup?.data?.documentNumbers}</div>
                    <div>{t("Total outstanding")}: {pageData?.paymentPopup?.data?.total}</div>
                </>}
                yestext={t("Add")}
                notext={t("Cancel")}
                submit={() => handleAddPayment()}
                cancel={() => setPageData((prev: any) => ({...prev, paymentPopup: {show: false, data: {}}}))}
            />}
        </AccessControl>
    );
};