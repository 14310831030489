import { TIMESHEET_ACTIONS } from "../timesheets-redux/actions/TimesheetActions";
import { addTimes, calculateWorkedTime } from "./utils";

/***
 * To handle calculation for whole week at once
*/
const calculateTotalForWholeWeek = ({ data, key, dispatch, parameters, options, strict = false }: any) => {
    let totalTime = "00:00";
    // Separate data into planned and not planned
    const [notPlannedData, plannedData] = data.reduce(([notPlanned, planned]: any, item: any) => {
        if (item?.plan_id && item?.plan_date) {
            item.totalHours = item?.start_time && item?.end_time
                ? calculateWorkedTime(
                    item.start_time.split(" ")[1],
                    item.end_time.split(" ")[1],
                    item.pause_time || "00:00:00"
                  )
                : "00:00";
            totalTime = addTimes(totalTime, item.totalHours || "00:00");
            planned.push(item);
        } else {
            notPlanned.push(item);
        }
        return [notPlanned, planned];
    }, [[], []]);
    
    // Update planned data and calculate daily totals
    const updatedData = plannedData.map((timesheet: any, index: number) => {
        const total = (index === plannedData.length - 1) ? totalTime : undefined;
        return calculateTotalForDay({ timesheet, parameters, key, options, total, strict });
    });
    const sortedData = [...notPlannedData, ...updatedData]
        .sort((a: any, b: any) => {
            const dateA = new Date(a?.plan_date).getTime();
            const dateB = new Date(b?.plan_date).getTime();
            return dateA - dateB;
        }).slice(0, 7);
    // Dispatch the updated data
    dispatch({
        type: TIMESHEET_ACTIONS.UPDATE_TIMESHEET_TOTAL,
        payload: sortedData,
    });
};

/***
 * To handle calculation for a day when some data is changed
*/
const calculateTotalForDay = ({ timesheet, key, parameters, options, total, strict = false}: any) => {    
    let prepareData: any = { ...timesheet };
    if (key === 'performance') {
        prepareData.performance = calculatePerformance({timesheet, parameters, options, total, strict});
    } else if (key === 'reimbursement') {
        prepareData.reimbursement = calculateReimbursement({timesheet, parameters, options, strict});
    } else {
        prepareData.performance = calculatePerformance({timesheet, parameters, options, total, strict: strict || emptyCheck(timesheet?.performance)});
        prepareData.reimbursement = calculateReimbursement({timesheet, parameters, options, strict: strict || emptyCheck(timesheet?.reimbursement)});
    }    
    return prepareData;
}

/***
 * To claculate performance related data
*/
const calculatePerformance = ({ timesheet, parameters, options, strict, total }: any) => {
    try {
        if (!strict && !emptyCheck(timesheet?.performance)) return [];
        const overtime = getOverTime({ timesheet, parameters, total });
        const surcharge = getTotalPercentage({ timesheet, parameters, overtime, options });
        const performanceTypesMap = (options?.performanceTypes ?? []).reduce((map: any, type: any) => {
            if (type?.label) map[type.label] = type;
            return map;
        }, {});
        const standardPrice = getPerHourPrice(parameters, timesheet?.plan_date);
        const existingTypes: any = Object.values(timesheet?.performance ?? {}).reduce((map: any, item: any) => {
            const typeKey = Object.keys(performanceTypesMap).find(label => performanceTypesMap[label]?.value === item?.type);
            if (typeKey) map[item.type] = performanceTypesMap[typeKey];
            return map;
        }, {});

        let diffhrs = normalizedForm(timesheet?.totalHours) - (overtime ?? 0);
        const totalHours = diffhrs < 0 ? 0 : diffhrs;
    
        const createPerformanceEntry = (typeLabel: string, hours: number, percentage: number) => {
            const type = performanceTypesMap[typeLabel];            
            return (type && hours > 0) ? {
                date: timesheet?.plan_date,
                type: type.value,
                hours: formatToTime(hours?.toString() ?? "00:00"),
                standardPrice: formatWithSeparator(normalizedForm(standardPrice ?? 0)) || "0",
                percentage: normalizedForm(percentage),
                totalPrice: calculatePerformanceTotalPrice({ hours, standardPrice, percentage })
            } : null;
        };

        const performance = Object?.entries(timesheet?.performance)?.reduce((acc: any, [key, item]: any) => {
            const data = { ...item, percentage: surcharge?.general, hours: (totalHours) };
            if (parameters?.overtime?.['Weekly limit']?.value && (existingTypes?.['Overtime allowance on weekly basis']?.value === item?.type)) {
                data.percentage = surcharge?.overtime;
                data.hours = overtime;
            } else if (parameters?.overtime?.['Day limit']?.value && (existingTypes?.['Overtime']?.value === item?.type)) {
                data.percentage = surcharge?.overtime;
                data.hours = overtime;
            }
            acc[key] = { ...data, totalPrice: calculatePerformanceTotalPrice(data)};
            return acc;
        }, {});        
        if (strict) {
            if ((!existingTypes?.['Worked']) && totalHours) {                                
                performance[performanceTypesMap['Worked']?.value] = createPerformanceEntry('Worked', (totalHours), surcharge.general);
            }
            if (overtime) {
                if (parameters?.overtime?.['Weekly limit']?.value && !existingTypes?.['Overtime allowance on weekly basis'] && total) {
                    performance[performanceTypesMap['Overtime allowance on weekly basis']?.value] = createPerformanceEntry('Overtime allowance on weekly basis', overtime, (surcharge.general + surcharge?.overtime));
                } else if (parameters?.overtime?.['Day limit']?.value && !existingTypes?.['Overtime']) {
                    performance[performanceTypesMap['Overtime']?.value] = createPerformanceEntry('Overtime',  overtime, (surcharge.general + surcharge?.overtime));
                }
            }
        }    
        return performance;
    } catch (error) {
        console.error('Error calculating performance:', error);
        return [];
    }
};

/**
 * Calculate reimbursement related data
 */
const calculateReimbursement = ({ timesheet, parameters, options, strict }: any) => {
    try {
        if ((!strict && !emptyCheck(timesheet?.reimbursement) || !timesheet?.invoice_milage)) return [];
        const reimbursementTypesMap = (options?.reimbursementTypes || []).reduce((map: any, type: any) => {
            if (type?.label) map[type.label] = type;
            return map;
        }, {});
        const existingTypes: any = Object.values(timesheet?.reimbursement || {}).reduce((map: any, item: any) => {
            const typeKey = Object.keys(reimbursementTypesMap).find(label => reimbursementTypesMap[label]?.value === item?.type);
            if (typeKey) map[item.type] = reimbursementTypesMap[typeKey];
            return map;
        }, {});
        const createReimbursementEntry = (typeLabel: string, numbers: number, price: number) => {
            const type = reimbursementTypesMap[typeLabel];
            return type ? {
                date: timesheet?.plan_date,
                type: type.value,
                numbers: normalizedForm(numbers),
                price: formatWithSeparator(normalizedForm(price)) || "0",
                totalPrice: calculateReimbursementTotalPrice({ numbers, price })
            } : null;
        };
        const reimbursement = Object.entries(timesheet?.reimbursement || {}).reduce((acc: any, [key, item]: any) => {
            const data = { ...item };
            if (parameters?.['km_package_fee'] && existingTypes?.['Milage allowance']?.value === item?.type) {
                data.price = parameters['km_package_fee'];
                data.numbers = parameters['km_package_fee']['parameter'] === 'km' ? parameters['round_trip_km']?.value ?? data?.numbers : 1;
            }
            acc[key] = { ...data, totalPrice: calculateReimbursementTotalPrice(data) };
            return acc;
        }, {});
        if (strict && !existingTypes['Milage allowance'] && parameters?.['round_trip_km']?.value && parameters?.['km_package_fee']?.value) {
            reimbursement[reimbursementTypesMap['Milage allowance']?.value] = createReimbursementEntry(
                'Milage allowance',
                parameters?.['km_package_fee']['parameter'] === 'km' ? parameters?.['round_trip_km']?.value : 1,
                parameters?.['km_package_fee']?.value
            );
        }
        return reimbursement;
    } catch (error) {
        console.error("Error calculating reimbursement:", error);
        return [];
    }
};


/***
 * To get percentage from data
*/
const getTotalPercentage = ({timesheet, parameters, overtime, options}: any) => {
    let surcharge = {
        overtime: 0,
        general: 100
    };
    if (overtime > 0 && parameters?.['overtime_percentage']?.value && normalizedForm(parameters?.['overtime_percentage']?.value)) {
        surcharge.overtime = normalizedForm(parameters?.['overtime_percentage']?.value);        
    }
    if (parameters?.overtime?.["weekend_saturdays"]?.value && getDayName(timesheet?.plan_date) === 'Saturday') {
        surcharge.general += normalizedForm(parameters?.overtime?.['weekend_saturdays']?.value);        
    } else if(parameters?.overtime?.["weekend_sundays_and_holidays"]?.value && (timesheet?.holiday || getDayName(timesheet?.plan_date) === 'Sunday')) {
        surcharge.general += normalizedForm(parameters?.overtime?.['weekend_sundays_and_holidays']?.value);        
    }
    let regime = options?.regimen?.find((item: any) => item?.label?.trim().toLowerCase() === "night");    
    if (timesheet?.regime ==  regime?.value && parameters?.overtime?.["night"]?.value) {        
        surcharge.general += normalizedForm(parameters?.overtime?.["night"]?.value);        
    }
    return surcharge;
}

//To get Over time for that day
const getOverTime = ({ timesheet, parameters, total }: any) => {
    let overtime = "00:00";
    if (parameters?.template_name !== "Hourly rate") return 0;
    let totalHours = timesheet?.totalHours;    
    const dayName = getDayName(timesheet?.plan_date);
    // Check all exceptions first
    if (parameters?.overtime?.["Exception friday"]?.value && dayName === 'Friday') {
        overtime = parameters?.overtime?.["Exception friday"]?.value;
    } else if (parameters?.overtime?.["Exception monday"]?.value && dayName === 'Monday') {
        overtime = parameters?.overtime?.["Exception monday"]?.value;
    } else if (parameters?.overtime?.["Exception tuesday"]?.value && dayName === 'Tuesday') {
        overtime = parameters?.overtime?.["Exception tuesday"]?.value;
    } else if (parameters?.overtime?.["Exception wednesday"]?.value && dayName === 'Wednesday') {
        overtime = parameters?.overtime?.["Exception wednesday"]?.value;
    } else if (parameters?.overtime?.["Exception thursday"]?.value && dayName === 'Thursday') {
        overtime = parameters?.overtime?.["Exception thursday"]?.value;
    } else if (parameters?.overtime?.["Exception saturday"]?.value && dayName === 'Saturday') {
        overtime = parameters?.overtime?.["Exception saturday"]?.value;
    } else if (parameters?.overtime?.["Exception saturday & holidays"]?.value && (timesheet?.holiday || dayName === 'Sunday')) {
        overtime = parameters?.overtime?.["Exception saturday & holidays"]?.value;
    } 
    // Check weekly limit and day limit at the end
    else if (parameters?.overtime?.['Weekly limit']?.value) {
        totalHours = total ? total : "00:00";
        overtime = parameters?.overtime?.['Weekly limit']?.value;
    } else if (parameters?.overtime?.['Day limit']?.value) {
        overtime = parameters?.overtime?.['Day limit']?.value;
    } else {
        return 0;
    }
    return (normalizedForm(totalHours) > normalizedForm(overtime) ? normalizedForm(totalHours) - normalizedForm(overtime) : 0);
};

//To check the data is empty or not
const emptyCheck = (input: any) => {
    return Array.isArray(input) ? input.length > 0 :
        (input && typeof input === 'object') ? Object.entries(input).length > 0 :
        false;
};

//Helper function to get Day name
const getDayName = (dateString: string) => new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(new Date(dateString));

// Helper function to normalize input for calculation
const normalizedForm = (val: any): number => {
    const parsedValue = parseFloat(String(val)?.replace?.(/[:,]/g, '.')?.replace?.(/[%]/g, ''));
    return isNaN(parsedValue) ? 0 : parseFloat(parsedValue?.toFixed(2));
};  

// Helper function to format the result with a desired separator
const formatWithSeparator = (val: any, separator = ',') => String(val)?.replace('.', separator);

const formatToTime = (val: any): string => {
    const [hours, minutes] = val ? val?.split?.(/[.,:]/) : ["00", "00"];         
    return `${normalizedForm(hours)?.toString()?.padStart(2, '0')}:${normalizedForm(minutes)?.toString()?.padEnd(2, '0')}`;
};  

const calculatePerformanceTotalPrice = ({ hours, standardPrice, percentage }: any) => {  
  return (hours && standardPrice && percentage)
    ? formatWithSeparator(((normalizedForm(hours) * normalizedForm(standardPrice)) * (normalizedForm(percentage) / 100))?.toFixed(2))
    : "00,00";
}

const calculateReimbursementTotalPrice = ({ numbers, price }: any) => {
  return (numbers && price)
    ? formatWithSeparator((normalizedForm(numbers) * normalizedForm(price))?.toFixed(2))
    : "00,00";
}

const getPerHourPrice = (parameters: any, planDate: any) => {    
    const templateName = parameters.template_name ?? "";
    let hourlyPrice = 0;
    switch (templateName) {
      case "Hourly rate":
      case "Daily rate": {
        const projectRate = parseFloat(parameters?.project_rate?.value ?? 0);        
        // Adjust project cost for hourly or daily rate
        hourlyPrice = projectRate / (templateName === "Hourly rate" ? 1 : 8);
        break;
      }
  
      case "Project price":
      case "Selectie fee": {
        const pricePerEmp = parseFloat(parameters.amount ?? 0) / (parameters.workingemployeesperday?.[planDate] ?? 1);
        const timePeriod = templateName === "Project price"
            ? getTimePeriodInMonths(parameters?.time_period ?? "")
            : parseFloat(parameters?.time_period ?? "0");
        hourlyPrice = pricePerEmp / (timePeriod * 21 * 8);
        break;
      }

      default:
        break;
    }    
    return hourlyPrice;
  };

const getTimePeriodInMonths = (time: string) => ({ weekly: 0.5, monthly: 1, quarterly: 3, biannually: 6, yearly: 12 }[time.trim().toLowerCase()] ?? 0);  
  
export {
    calculateTotalForWholeWeek,
    calculateTotalForDay,
    calculateReimbursementTotalPrice,
    calculatePerformanceTotalPrice,
    formatWithSeparator,
    normalizedForm,
    getTotalPercentage,
    formatToTime,
    emptyCheck,
    getPerHourPrice,
}