import React, { useState } from "react";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import { useTimesheetDispatchContext } from "../timesheets-redux/context/TimesheetContext/TImesheetContex";
import { TIMESHEET_ACTIONS } from "../timesheets-redux/actions/TimesheetActions";
import { addTimes, calculateWorkedTime } from "../utils/utils";
import { useFilterOptions } from "../timesheets-redux/context/FiltersContext/FiltersContext";
import TimeField from "react-simple-timefield";
import { t } from "pages/microservices/masterData/translation/Translation";

const WorkTimings = (props) => {
  const { planTimingsData, header1, header2, total, type, isDisable, name } = props;
  const { timesheetDispatch } = useTimesheetDispatchContext();
  const { filterOptionsState } = useFilterOptions()

  const handleChange = (name, value, plan) => {
    if (plan?.plan_id) {
      timesheetDispatch({
        type: TIMESHEET_ACTIONS.UPDATE_TIMESHEET_DATA,
        payload: {
          planId: plan?.plan_id,
          value: name == "pause_time" ? value : plan?.plan_date + " " + value,
          name: name,
          extraprops: {
            filterOptionsState,
          }
        },
      });
    }
  };

  var totalTime = "00:00";

  const renderItems = () => {
    const weekDays = [
      <td key={"heading1"} className="text-start">
        {t(header1)}
      </td>,
      <td key={"heading2"} className="text-start">{t(header2)}</td>,
    ];
    const dataToRender = planTimingsData && planTimingsData.length > 0 ? planTimingsData : Array(7).fill({});
    dataToRender.forEach((plan, i) => {
      const totalHours = (plan["start_time"] && plan["end_time"] && plan["start_time"] != "" && plan["end_time"] != "")
        ? calculateWorkedTime(
            plan["start_time"].split(" ")[1],
            plan["end_time"].split(" ")[1],
            plan["pause_time"] ? plan["pause_time"] : "00:00:00"
          )
        : "";
      totalTime = addTimes(totalTime, totalHours === "" ? "00:00" : totalHours);  
      weekDays.push(
        <td key={i} className="p-0 timeTimingCol position-relative">
          {/* <LabelWithInputField
            labelClassName="d-none"
            errorclass="d-none"
            label={""}
            name={name}
            isMandatory={false}
            value={name == "totalHours" ? totalHours : value}
            handleChange={(event) => handleChange(event, plan)}
            id={name}
            type={type ? type : "time"}
            error={""}
            isDisabled={isDisable}
          /> */}
          <TimeField
            name={name}
            value={name == "totalHours" ? totalHours : plan[name] ? plan[name] : ""}
            onChange={(event, value) => handleChange(name, value, plan)}
            colon=":"
            showSeconds={false}
            style={{
              width: '100%',
              height:"100%",
              borderRadius: 0,
              border:"0.052vw solid transparent",
              outline:"0",
              textAlign:"right",
              pointerEvents: (isDisable || !plan?.plan_id) ? "none" : "auto",
              backgroundColor: (isDisable || !plan?.plan_id) ? "#e8f8fb75" : "",
              position:"absolute",
              left:"0",
              top:"0",
              width:"100%",
              height:"100%"
            }}
            isDisable={isDisable}
          />
        </td>
      );
    });
    return weekDays;
  };

  return (
    <tr>
      {renderItems()}
      <td className="fw-bold">
        {total == "Weekly total" ? total : (name == "totalHours" ? (totalTime && totalTime !== 'NaN:NaN' ? totalTime : "") : "")}
      </td>
    </tr>
  );
};
export default WorkTimings;
