import BackButton from "components/atoms/BackButton"
import Button from "components/atoms/Button"
import CustomNotify from "components/atoms/CustomNotify"
import TitleAtom from "components/atoms/Title"
import { LabelWithInputField } from "components/molecules/LabelWithInputField"
import LabelWithTextAreaField from "components/molecules/LabelWithTextAreaField"
import LocationMolecule from "components/molecules/LocationMolecule"
import AddressFormOrganism from "components/organism/address/AddressFormOrganism"
import { M_MASTER_DATA } from "constants/Constants"
import { t } from "pages/microservices/masterData/translation/Translation"
import { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { FLEET_LOCATION_CREATE, FLEET_LOCATION_EDIT, PROJECTS_OPTIONS } from "routes/ApiEndpoints"
import { ApiCall } from "services/ApiServices"
import { getCityAndCountry, getCountryFormat } from "services/util/UtilService"
import { validateForm, validateRequired, validateSelectField } from "services/validation/ValidationService"

const CreateFleetLocation = () => {

  const [locationData, setLocationData] = useState({
    locationName: '',
    street: '',
    number: '',
    box: '',
    zipcode: '',
    city: '',
    country: '',
    locationInfo: '',
    initialLat: '50.9804738',
    initialLn: '3.139242',
  });

  const [options, setOptions] = useState({
    country: [],
  });

  const [showPopup, setShowPopup] = useState(false);


  const [locationError, setLocationError] = useState({
    locationName: '',
    street: '',
    number: '',
    box: '',
    zipcode: '',
    city: '',
    country: '',
  })

  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    fetchOptions();
    if (params?.id) {
      fetchPreviousData();
    }
  }, []);

  const fetchPreviousData = async () => {
    try {
      const response = await ApiCall.service(
        FLEET_LOCATION_EDIT,
        "POST",
        { id: params?.id },
        false,
        M_MASTER_DATA,
      );
      if (response?.status == 200) {
        setLocationData((prevState) => ({ ...prevState, ...response?.data }));
      }
      else {
        CustomNotify({ type: "warning", message: response?.message });
      }
    }
    catch (error) {
      console.log(error)
    }
  }

  const handleAddPopup = () => {
    setShowPopup(true); // Close the popup without saving
  };
  const handleClosePopup = () => {
    setShowPopup(false); // or whatever logic you have to close the popup
  };

  const fetchOptions = async () => {
    try {
      const optionsResponse = await ApiCall.service(
        PROJECTS_OPTIONS,
        'POST',
        { options: ["Country"] },
        false,
        M_MASTER_DATA,
      );
      if (optionsResponse?.status == 200) {
        setOptions((prevOptions) => ({ ...prevOptions, country: optionsResponse?.data?.Country }))
      }
      else {
        CustomNotify({ type: "warning", message: optionsResponse.message })
      }
    }
    catch (error) {
      console.log("Error");
    }
  }

  const handleFieldChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    let updatedData: any = {};
    if (name == 'zipcode') {
      try {
        const resposne = await getCityAndCountry(value);
        updatedData = {
          ...resposne, country: value.length > 3 ?
            getCountryFormat(options?.country, resposne?.country) : ''
        };
      }
      catch (error) {
        console.log(error)
      }
    }
    updatedData[name] = value;

    setLocationData((prevState) => ({
      ...prevState,
      ...updatedData,
    }))
    if (name !== 'box' && name !== 'locationInfo') {
      validationFunction(name, value, true);
    }
  }

  const handleSelectChange = (event: any, category: string) => {
    setLocationData((prevState) => ({
      ...prevState,
      [category]: event,
    }));
    validationFunction(category, event, true);
  }

  const validationRules: any = {
    locationName: [validateRequired],
    street: [validateRequired],
    number: [validateRequired],
    zipcode: [validateRequired],
    city: [validateRequired],
    country: [validateSelectField],
  };

  const validationFunction = (
    name: any = null,
    value: any = null,
    isSingleFieldValidation = false
  ) => {
    const validationErrors: any = validateForm(
      { ...locationData, [name]: value },
      isSingleFieldValidation ? validationRules[name] : validationRules
    );
    if (isSingleFieldValidation) {
      setLocationError((prevErrors: any) => {
        if (validationErrors.hasOwnProperty(name)) {
          return { ...prevErrors, [name]: validationErrors[name] };
        }
        const { [name]: ty, ...remains } = prevErrors;
        return { ...remains };
      });
    } else {
      setLocationError(validationErrors);
      if (Object.keys(validationErrors).length > 0) {
        return false;
      } else {
        return true;
      }
    }
  };

  const updateLocationData = (data: any) => {
    setLocationData((prevState: any) => ({ ...prevState, ...data }));
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const result = validationFunction()
    if (result) {
      try {
        const data = params?.id ? { ...locationData, id: params?.id } : { ...locationData }
        const response = await ApiCall.service(
          FLEET_LOCATION_CREATE,
          "POST",
          { data: { ...data } },
          false,
          M_MASTER_DATA,
        );
        if (response?.status == 200) {
          CustomNotify({ type: "success", message: response?.message });
          navigate("/fleetOverview/locations");
        }
        else {
          CustomNotify({ type: "warning", message: response?.message });
        }
      }
      catch (error) {
        console.log(error)
      }
    }
  }

  return (<>
    <TitleAtom title={params?.id ? t("Edit location") : t("Create location")} />
    <form onSubmit={handleSubmit}>
      <div className="form-border">
        <div
          className="bg-white"
          style={{
            padding: "2vw",
            borderRadius: "1vw",
            marginBottom: "1.6vw",
            marginTop: "1.6vw",
          }}
        >
          {(<LocationMolecule
            handleAddPopup={handleAddPopup}
            handleClosePopup={handleClosePopup}
            showPopup={showPopup}
            addressObj={locationData}
            updateLocationData={updateLocationData}
            setShowPopup={setShowPopup}
            countryList={options?.country}
          />)}
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <LabelWithInputField
                isMandatory={true}
                name="locationName"
                handleChange={(event) => handleFieldChange(event)}
                value={locationData.locationName ?? ""}
                id="locationName"
                label={t("Location name")}
                error={locationError.locationName}
              />
            </div>
            <AddressFormOrganism
              formOptions={{ countries: options.country }}
              formData={{
                street: locationData.street,
                number: locationData.number,
                box: locationData.box,
                zipcode: locationData.zipcode,
                city: locationData.city,
                country: locationData.country,
              }}
              formErrors={{
                street: locationError.street,
                number: locationError.number,
                zipcode: locationError.zipcode,
                city: locationError.city,
                country: locationError.country,
              }}
              handleSelectChange={(e) => handleSelectChange(e, "country")}
              handleChange={handleFieldChange}
            ></AddressFormOrganism>
            <div className="row">
              <div className="col-lg-12 hideHeight">
                <LabelWithTextAreaField
                  name="locationInfo"
                  handleChange={(event) => handleFieldChange(event)}
                  value={locationData.locationInfo ?? ""}
                  label={t("Info")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div className="d-flex justify-content-between my-3 align-items-center">
      <div className="">
        <BackButton />
      </div>

      <div className="">
        <Button
          title={params?.id ? t("Save") : t("Submit")}
          type={"submit"}
          handleClick={handleSubmit}
          className={"form-button"}
        />
      </div>
    </div>
  </>
  )
}
export default CreateFleetLocation