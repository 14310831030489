import CustomNotify from "components/atoms/CustomNotify";
import ModalPopup from "components/atoms/ModalPopup";
import { M_MASTER_DATA } from "constants/Constants";
import { PATH_CONTRACT_TYPE_CREATE, PATH_MARGIN_CONFIG_CREATE } from "constants/Paths";
import { useNavigate } from "react-router-dom";
import { CONTRACT_TYPE_ARCHIVE, MARGIN_CONFIG_ARCHIVE } from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";
import { t } from "../../translation/Translation";
import PaginationWithPerPage from "components/molecules/PaginationWithPerPage";
import TableActions from "components/organism/Actions/TableAction";
import StatusComponent from "components/atoms/ManageStatus";
import TextEllipsis from "components/atoms/TextEllipsis";
import AccessControl from "services/AccessControl";
import LinkTo from "components/atoms/LinkTo";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import FilterOrganism from "components/organism/FilterOrganism";
import { updatePageFilters } from "store/pageHistory/pageHistorySlice";
import SortAtomForManagePage from "components/atoms/SortAtomForManagePage";
import { ContractTypeTableHeader, MarginConfigTableHeader } from "TableHeader";
import TitleAtom from "components/atoms/Title";

interface ManageMarginConfigOrganismProps {
  state: any;
  setState: any;
  dispatch: any;
  history: any;
  userData: any;
}

export const ManageMarginConfigOrganism: React.FC<ManageMarginConfigOrganismProps> = ({
  state,
  setState,
  dispatch,
  history,
  userData,
}) => {
  const navigate = useNavigate();

  const handleUpadateStatus = async () => {
    let postData = {
      id: state?.popup?.delete?.data?.id,
      status: state?.popup?.delete?.data?.status ? false : true,
    };
    if (state?.popup?.delete?.data?.id) {
      const response = await ApiCall.service(MARGIN_CONFIG_ARCHIVE, "POST", postData, true, M_MASTER_DATA);
      if (response?.status === 200) {
        CustomNotify({ type: "success", message: response.message });
        handleModal();
        setState((prev: any) => ({
          ...prev,
          data: state?.data?.map((item: any) => {
            if (item?.id == state?.popup?.delete?.data?.id) {
              return {
                ...item, status: state?.popup?.delete?.data?.status ? false : true
              };
            }
            return item;
          }),
        }));
      } else {
        CustomNotify({ type: "error", message: response.message });
      }
    }
  };

  const handleModal = (value?: any) => {
    setState((prev: any) => ({
      ...prev,
      popup: {
        ...prev?.popup,
        delete: {
          ...prev?.popup?.delete,
          data: value ?? {},
          show: value ? true : false,
        },
      },
    }));
  };


  const handleEdit = (value: any) => {
    navigate(`${PATH_MARGIN_CONFIG_CREATE}/${value?.id}`);
  };

  const handleRefresh = () => setState((prev: any) => ({ ...prev, refresh: !state?.refresh }));

  const filters = [
    {name: "name", fieldType: "text", placeholder: "Name", filterType: "search"},
    {name: "type", fieldType: "singleSelect", options: state?.options?.types ?? [], placeholder: "Type", filterType: "search"},
    {name: "amount", fieldType: "text", placeholder: "Amount", filterType: "search"},
    {name: "status", fieldType: "singleSelect", options: state?.options?.status ?? [], placeholder: "Status", filterType: "search"},
  ];

  return (
    <>
      <div className="search-bar" style={{ paddingBottom: "0.25vw" }}>
        <div className="row">
          <TitleAtom title={t("Manage margin config")} />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="position-relative tableMainWrapper">
            <div>
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Margin config",
                    create: true,
                  },
                ]}
                override={userData.isSuperAdmin}
              >
                <div className="row">
                  <div className="ManageCreateBtn">
                    <LinkTo
                      pagelink={PATH_MARGIN_CONFIG_CREATE}
                      title={t("Create margin config")}
                      icon={faPlus}
                    />
                  </div>
                </div>
              </AccessControl>
            </div>
            <div className="tableSection manage-Vacancies">
              <FilterOrganism
                dispatch={dispatch}
                history={history}
                updatePageFilters={updatePageFilters}
                filters={filters}
                handleRefresh={handleRefresh}
              />
              <div className="table-responsive manage-Vacancies tableWithSearchBar tablePadding">
                <table className="table table-hover">
                  <thead>
                    <tr className="TableHeader">
                      {MarginConfigTableHeader?.map((header) => (
                        <th key={header?.alias} className={"table_actions"}>
                          <>                            
                            {t(header?.name)}
                            {header?.issort && (
                              <SortAtomForManagePage
                                value={header}
                                dispatch={dispatch}
                                history={history}
                                updatePageFilters={updatePageFilters}
                                handleRefresh={handleRefresh}
                              />
                            )}
                          </>
                        </th>
                      ))}
                      <th className="table_actions">{t("Action")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {state?.data && state?.data?.length > 0 ? (
                      state?.data?.map((record: any, key: any) => {
                        return (
                          <tr key={key}>
                            <td className="align-middle">
                              <TextEllipsis
                                label={record?.name}
                                title={record?.name}
                                width="5vw"
                              />
                            </td>
                            <td className="align-middle">
                              <TextEllipsis
                                label={(state?.options?.types ?? [])?.find((item: any) => record?.type === item.value)?.label}
                                title={(state?.options?.types ?? [])?.find((item: any) => record?.type === item.value)?.label}
                                width="8vw"
                              />
                            </td>
                            <td className="align-middle">
                              <TextEllipsis
                                label={record?.amount}
                                title={record?.amount}
                                width="8vw"
                              />
                            </td>
                            <td className="align-middle">
                              <StatusComponent
                                initialStatus={ record?.status ? 1 : 2 }
                                tooltip={record?.status ? t("Active") : t("Inactive")}
                              />
                            </td>
                            <td className="align-middle">
                              <TableActions
                                isAction={true}
                                handleEdit={handleEdit}
                                handleArchive={handleModal}
                                value={record}
                                permission={"Margin config"}
                              />
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={9} className="text-center">
                          {t("No records found")}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="pagination d-block" style={{ paddingTop: "0.25vw" }}>
                <PaginationWithPerPage
                  handleRefresh={handleRefresh}
                  dispatch={dispatch}
                  history={history}
                  pageData={{
                    totalPages: state?.totalPages,
                    totalRecords: state?.totalRecords,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalPopup
        show={state?.popup?.delete?.show}
        onHide={() => handleModal()}
        title={t("Delete confirmation")}
        body={t(`Are you sure want to ${state?.popup?.delete?.data?.status ? "archive" : "unarchive"}`) + "?"}
        onCloseButtonClick={() => handleModal()}
        onConfirmButtonClick={handleUpadateStatus}
        closeTitle={t("No")}
        confirmTitle={t("Yes")}
      />
    </>
  );
};
export default ManageMarginConfigOrganism;
