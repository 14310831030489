import SelectWithSearch from "components/atoms/SelectWithSearch";
import React from "react";
import { t } from "../../translation/Translation";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import LabelWithTextAreaField from "components/molecules/LabelWithTextAreaField";
import { validateForm, validateRequired, validateSelectField } from "services/validation/ValidationService";
import Button from "components/atoms/Button";

const NewRulePopUp = (props: any) => {

    const handleFieldChange = (e: any, name: any) => {
        let value = e?.target?.value ?? e;
        switch (name) {
            case 'btw':
            case 'salesaccount':
                value = value?.value;
                break;

            case 'quantity':
            case 'price':
                value = value?.replace(/[^0-9.,]/g, '').replace(/(\..*)\./g, '$1').replace(/(,.*),/g, '$1');
                break;

            default:
                break;
        }
        
        const errors: any = validateInput(name, value, true);
        
        props?.setTabData((prev: any) => ({
            ...prev, popup: {
                ...prev?.popup,
                errors: {...prev?.popup?.errors, [name]: errors},
                data: {...prev?.popup?.data, [name]: value},
            }
        }));
    }

    const validateInput = (name: string | any, value: string | boolean | any, isSingleFieldValidation: boolean = false) => {
        const validationRules: any = {
            quantity: [validateRequired],
            price: [validateRequired],
            btw: [validateSelectField],
            salesaccount: [validateSelectField],
            description: [validateRequired],
        };
    
        // Validate the form data based on the rules
        const validationErrors = validateForm({ ...props?.tabData?.popup?.data, [name]: value }, validationRules);
    
        // Update errors state based on the validation results
        if (isSingleFieldValidation) {
            return validationErrors[name] ?? "";
        } else {
            props?.setTabData((prev: any) => ({
                ...prev, popup: {
                    ...prev?.popup,
                    errors: {...prev?.popup?.errors, ...validationErrors},
                }
            }));
        }
    
        // Check if there are any validation errors
        return !(Object?.keys(validationErrors).length > 0)
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const isFormValid = validateInput("quantity", props?.tabData?.popup?.data?.quantity);
        if (isFormValid) {
            let ruleData = [...props?.tabData?.ruleData];
            let updateData = {
                description: props?.tabData?.popup?.data?.description,
                number: props?.tabData?.popup?.data?.quantity,
                price: props?.tabData?.popup?.data?.price,
                vatrateid: props?.tabData?.popup?.data?.btw,
                standard_account_id: props?.tabData?.popup?.data?.salesaccount,
                ruletype: false,
            }
            if (props?.tabData?.popup?.data?.index !== null && props?.tabData?.popup?.data?.index !== undefined) {
                ruleData[props?.tabData?.popup?.data?.index] = updateData;
            } else {
                ruleData?.push(updateData);
            }
            props?.setTabData((prev: any) => ({
                ...prev, ruleData, calcRefresh: !props?.tabData?.calcRefresh,
                popup: { name: '', data: null, errors: null},
            }))
        }
    }

    return (
        <>
            <div className="d-grid grid-col-2">
                <div className="col">
                    <LabelWithInputField
                        isMandatory
                        name="quantity"
                        handleChange={(e) => handleFieldChange(e, 'quantity')}
                        id="quantity"
                        label={t("Quantity")}
                        type="text"
                        value={props?.tabData?.popup?.data?.quantity}
                        error={props?.tabData?.popup?.errors?.quantity}
                    />
                </div>
                <div className="col">
                    <LabelWithInputField
                        isMandatory
                        name="price"
                        handleChange={(e) => handleFieldChange(e, 'price')}
                        id="price"
                        label={t("Price")}
                        type="text"
                        value={props?.tabData?.popup?.data?.price}
                        error={props?.tabData?.popup?.errors?.price}
                    />
                </div>
                <div className="col">
                    <SelectWithSearch
                        title={t("Btw")}
                        isMandatory
                        search
                        options={props?.dropdownData?.standardVatRates}
                        onChange={(e) => handleFieldChange(e, "btw")}
                        name="btw"
                        value={props?.tabData?.popup?.data?.btw}
                        error={props?.tabData?.popup?.errors?.btw}
                        placeHolder="Select"
                    />
                </div>
                <div className="col">
                <SelectWithSearch
                        title={t("Sales account")}
                        isMandatory
                        search
                        options={props?.dropdownData?.standardSalesAccounts}
                        onChange={(e) => handleFieldChange(e, "salesaccount")}
                        name="project"
                        value={props?.tabData?.popup?.data?.salesaccount}
                        error={props?.tabData?.popup?.errors?.salesaccount}
                        placeHolder="Select"
                    />
                </div>
            </div>
            <div>
                <div className="col">
                    <LabelWithTextAreaField
                        isMandatory
                        name="description"
                        handleChange={(e) => handleFieldChange(e, "description")}
                        label={t("Description")}
                        value={props?.tabData?.popup?.data?.description}
                        error={props?.tabData?.popup?.errors?.description}
                    />
                </div>
            </div>
            <div className="d-flex justify-content-between backPadding align-items-center">
                <Button
                  title={t("Cancel")}
                  handleClick={props?.handleClosePopup}
                  className="close-button float-end shadow-none"
                />
                <Button
                  title={t("Save")}
                  handleClick={handleSubmit}
                  className="form-button float-end shadow-none"
                />
            </div>
        </>
    );
};

export default NewRulePopUp;
