import { t } from "pages/microservices/masterData/translation/Translation";
import React from "react";

const ProjectParameterHeader = ({ projectParameters, initialTimesheetState }) => {
  const renderExceptions = () => {
    const exceptionDays = [
      "Exception monday",
      "Exception tuesday",
      "Exception wednesday",
      "Exception thursday",
      "Exception friday",
      "Exception saturday",
      "Exception saturday & holidays",
    ];

    return exceptionDays.map((day) => {
      const value = projectParameters?.overtime?.[day]?.value;
      if (value) {
        return (
          <tr key={day}>
            <td className="w-50">{t(day)}</td>
            <td className="w-50">{value}hr/day</td>
          </tr>
        );
      }
      return "";
    });
  };

  const renderSurcharges = () => {
    const surcharges = [
      { key: "overtime_percentage", label: t("Overtime") },
      { key: "night", label: t("Night") },
      { key: "weekend_saturdays", label: t("WE saturday") },
      { key: "weekend_sundays_and_holidays", label: t("WE sunday's and holiday's") },
    ];

    return surcharges.map((surcharge) => {
      const value = surcharge?.key !== 'overtime_percentage' ? projectParameters?.overtime?.[surcharge.key]?.value : projectParameters?.[surcharge?.key]?.value;
      if (value) {
        return (
          <tr key={surcharge.key}>
            <td className="w-50">{surcharge.label}</td>
            <td className="w-50">{value} %</td>
          </tr>
        );
      }
      return "";
    });
  };

  const renderProjectParameter = () => {
    const template = projectParameters?.template_name;

    if (template === "Daily rate" || template === "Hourly rate") {
      return (
        <>
          <tr>
            <td className="fw-bold">{t("Rates")}</td>
          </tr>
          <tr>
            <td className="w-50">{t("Project rate")}</td>
            <td className="w-50">
            €{projectParameters?.project_rate?.value}/{template === "Hourly rate" ? "hour" : "day"}
            </td>
          </tr>
          <tr>
            <td className="w-50">{t("KM allowance")}</td>
            <td className="w-50">
            €{projectParameters?.km_to_be_invoiced ? projectParameters?.km_package_fee?.value : "0"}/{projectParameters?.km_package_fee?.parameter ?? "package"}
            </td>
          </tr>
          <tr>
            <td className="fw-bold">{t("Overtime")}</td>
          </tr>
          <tr>
            <td className="w-50">
              {projectParameters?.overtime?.["Weekly limit"]?.value ? t("Weekly limit") : t("Day limit")}
            </td>
            <td className="w-50">
              {projectParameters?.overtime?.["Weekly limit"]?.value ?? projectParameters?.overtime?.["Day limit"]?.value ?? "00:00"}hr
            </td>
          </tr>
          {renderExceptions()}
          <tr>
            <td className="fw-bold">{t("Surcharges")}</td>
          </tr>
          {renderSurcharges()}
        </>
      );
    } else if (template === "Project price" || template === "Selectie fee") {
      return (
        <>
          <tr>
            <td className="fw-bold">{t("Rates")}</td>
          </tr>
          <tr>
            <td className="w-50">{t("Project amount")}</td>
            <td className="w-50">
              €{projectParameters?.amount} /
              {template === "Selectie fee"
                ? `${projectParameters?.time_period} ${Number(projectParameters?.time_period) === 1 ? t("month") : t("months")}`
                : projectParameters?.time_period}
            </td>
          </tr>
        </>
      );
    } else {
      return <tr>
        <td colSpan={2} className="text-center">
          {t("No template")}
        </td>
      </tr>;
    }
  };

  return (
    <>
      <table className="w-100 projectParameterTable">
        <thead>
          <tr>
            <th colSpan="2" className="bg-lightskyblue">
              {t("More info")}
            </th>
          </tr>
        </thead>
        <tbody>
          {initialTimesheetState?.more_info && (
            <>
              <tr>
                <td className="fw-bold">{t("Company")}</td>
              </tr>
              <tr className="border-bottom">
                <td>{initialTimesheetState?.more_info?.company?.name}</td>
              </tr>
              <tr>
                <td className="fw-bold">{t("Project")}</td>
              </tr>
              <tr className="border-bottom">
                <td>{initialTimesheetState?.more_info?.project?.name}</td>
              </tr>
              <tr>
                <td className="fw-bold">{t("Employee")}</td>
              </tr>
              <tr className="border-bottom">
                <td>{initialTimesheetState?.more_info?.user?.name}</td>
              </tr>
            </>
          )}
        </tbody>
      </table>
      <table className="w-100 projectParameterTableTwo">
        <thead>
          <tr>
            <th colSpan="2" className="bg-lightskyblue projectParameterTableTwoTitle">
              {t("Project parameters")}
            </th>
          </tr>
        </thead>
        <tbody>
          {projectParameters && (
            <>
              <tr className="fw-bold">
                <td className="w-50">{t("Template name")}</td>
                <td className="w-50">{projectParameters?.template_name}</td>
              </tr>
              {renderProjectParameter()}
            </>
          )}
        </tbody>
      </table>
    </>
  );
};

export default ProjectParameterHeader;
