import React, { useState } from "react";
import { Link } from "react-router-dom";
import RecruitmentCard from "components/atoms/RecruitmentCard";
import Count from "components/atoms/Count";
import CardTitle from "components/atoms/CardTitle";
import CardCircle from "components/atoms/CardCircle";
import { rolesTilesData } from "pages/dashboards/DashboardConstants";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";

const Targets: React.FC = () => {
  const userData = useSelector(selectAuth);
  const tiles = rolesTilesData(userData);

  return (
    <>
      {tiles &&
        Object?.values(tiles)?.map((role: any, index: number) => (
          <AccessControl
            key={index}
            requiredPermissions={role?.requiredPermissions}
            override={userData.isSuperAdmin}
          >
            <div
              className={"recruitmentTabsAdmin"}
              key={role.id}
            >
              <Link
                to={role.path}
                className="card border-0 card-shadow justify-content-center h-100"
                style={{ padding: "1vw" }}
              >
                <RecruitmentCard
                  className={`d-flex justify-content-between align-items-center w-100 h-100`}
                >
                  <div>
                    <Count
                      value={role.value}
                      totalValue={role.totalValue}
                      value_punctuation={"of"}
                      className={`recruitment-card-num`}
                    ></Count>
                    <CardTitle
                      title={role.title}
                      className={`recruitment-card-title`}
                    />
                  </div>
                  <CardCircle
                    width="3.5vw"
                    height="3.5vw"
                    boxShadow="rgb(0, 165, 206) 0vw 0.104vw 0.417vw 0vw"
                    className="float-end justify-content-center hoverShadow saledDashboard"
                  >
                    {role.imageSrc ? (
                      <>
                        <img
                          src={role.imageSrc}
                          className="img-fluid"
                          width="1vw"
                          alt={role.altText}
                        />
                      </>
                    ) : (
                      <>{role.component}</>
                    )}
                  </CardCircle>
                </RecruitmentCard>
              </Link>
            </div>
          </AccessControl>
        ))
      }
    </>
  );
};
export default Targets;
