
export const tableHeaders = [
    { name: 'Development string', alias: 'original_string', issort: true },
    { name: 'Translation string', alias: 'translated_string', issort: true },
];

export const MANAGE_TRANSLATION_CONSTANTS = {
    RESET: 'Reset',
    SUBMIT: 'Submit',
}

export const manageState = {
    popup: {
        editpopup: {
            string_id: null,
            showpopup: false,
            newstring: ""
        }
    },
    options: {
        Language: []
    },
    data: [] as any,
    manage: true,
    initialCall: true,
    status: null,
    totalPages: 0,
    totalRecords: 0,
    refresh: false,
};
