import { t } from "pages/microservices/masterData/translation/Translation";
import React from "react";


interface Props {
  headerLength?: number;
  isDiv?: boolean;
  message?: string; // Optional message prop for customization
  className?: string;
}

const NoRecords: React.FC<Props> = ({
  headerLength,
  isDiv = false,
  message = t("No records"), // Default to translated "No records" if not provided,
  className,
}) => {
  return (
    <>
      {isDiv ? (
        <div className={`text-center text-danger ${className}`}>{message}</div>
      ) : (
        <tr>
          <td
            colSpan={headerLength}
            className="border text-danger text-center py-2"
          >
            {message}
          </td>
        </tr>
      )}
    </>
  );
};

export default NoRecords;