import Button from "components/atoms/Button";
import React, { useState, useEffect } from "react";
import { t } from "../translation/Translation";
import { validateForm, validateRequired } from "services/validation/ValidationService";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import { ApiCall } from "services/ApiServices";
import { CREATE_VAT_RATE, MANAGE_VAT_RATE } from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "constants/Constants";
import CustomNotify from "components/atoms/CustomNotify";   
import { useNavigate, useParams } from "react-router-dom";
import { PATH_VAT_RATE_MANAGE } from "constants/Paths";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import Title from "components/atoms/Title";
import EditIcon from "static/images/EditIcon";
import DeleteIcon from "static/images/DeleteIcon";
import BackButton from "components/atoms/BackButton";

function CreatVatRate() {
    const [state, setState] = useState<any>({
        data: {
            id: null,
            name: "",
            percentage: "",
        },
        tableData: [],
        editIndex: null,
        errors: {
            name: "",
            percentage: "",
        },
    });
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        if (id) {
            fetchData();
        }
    }, [id]);

    const fetchData = async () => {
        try {
            const response = await ApiCall.service(MANAGE_VAT_RATE, "POST", { id }, true, M_MASTER_DATA);
            if (response?.status === 200) {
                setState((prev: any) => ({ ...prev, data: response?.data }));
            }
        } catch (error) {
            console.error(error);
        }
    };

    const changeHandler = (e: any, name: any) => {
        let value = e?.target?.value ?? "";
        switch (name) {
            case 'percentage':
                value = value.replace(/[^0-9.,]/g, '').replace(/(\..*)\./g, '$1').replace(/(,.*),/g, '$1');
                break;
            default:
                break;
        }
        const errors: any = validateInput(name, value, true);
        setState((prev: any) => ({
            ...prev,
            data: { ...prev.data, [name]: value },
            errors: { ...prev.errors, [name]: errors[name] ?? "" },
        }));
    };

    const validateInput = (name: string | any, value: string | boolean | any, isSingleFieldValidation: boolean = false, strict = true) => {
        const validationRules: any = {
            name: [validateRequired],
            percentage: [validateRequired],
            type: [validateRequired],
        };

        // Validate the form data based on the rules
        const validationErrors = validateForm({ ...state?.data, [name]: value }, validationRules);

        // Update errors state based on the validation results
        if (isSingleFieldValidation) {
            return validationErrors;
        } else {
            (strict || state?.tableData?.length <= 0) && setState((prev: any) => ({ ...prev, errors: validationErrors }));
        }

        // Check if there are any validation errors
        return !(Object.keys(validationErrors).length > 0)
    };

    const handleRemove = (index: number) => {
        const updatedTableData = [...state?.tableData];
        updatedTableData?.splice(index, 1);
        setState((prev: any) => ({ ...prev, tableData: updatedTableData }));
    };

    const handleEdit = (index: number) => {
        const editedData = state?.tableData[index];
        setState((prev: any) => ({ ...prev, data: editedData, errors: { name: "", percentage: "" }, editIndex: index }));
    };

    const getRowClass = (index: any) => {
        return state?.editIndex === index ? "highlighted-row" : "";
    };

    const handleAddClick = () => {
        const isFormValid = validateInput("name", state?.data?.name);
        if (isFormValid) {
            // Editing existing entry
            const updatedTableData = [...state?.tableData];
            if (state?.editIndex !== null) {
                updatedTableData[state?.editIndex] = state?.data;
            } else {
                updatedTableData?.push(state?.data);
            }
            setState((prev: any) => ({
                ...prev,
                tableData: [...updatedTableData],
                data: { name: "", id: null, percentage: "" },
                editIndex: null
            }));
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        let finalData = [...state?.tableData];
        const isFormValid = validateInput("name", state?.data?.name, false, false);

        if (isFormValid) {
            finalData?.push(state?.data);
        }

        if (isFormValid || state?.tableData?.length > 0) {
            const response = await ApiCall.service(CREATE_VAT_RATE, "POST", finalData, true, M_MASTER_DATA);
            if (response?.status === 200) {
                CustomNotify({ type: "success", message: response?.message });
                navigate(PATH_VAT_RATE_MANAGE);
            } else {
                CustomNotify({ type: "error", message: response?.message });
            }
        }
    };

    const permissionType = id ? "update" : "create";
    const permissionObject: any = { permission: "Vat rate" };
    permissionObject[permissionType] = true;
    const userData = useSelector(selectAuth);

    return (
        <AccessControl
            requiredPermissions={[permissionObject]}
            renderNoAccess={true}
            override={userData.isSuperAdmin}
        >
            <>
                <div className="search-bar">
                    <div className="row">
                        <Title title={id ? t("Update vat rate") : t("Create vat type")} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <form onSubmit={handleSubmit} style={{ marginBottom: "1vw" }}>
                            <div className="form-border">
                                <div className="row">
                                    <div className="col-md-6">
                                        <LabelWithInputField
                                            isMandatory
                                            name="Name"
                                            handleChange={(e) => changeHandler(e, 'name')}
                                            value={state?.data?.name}
                                            id="name"
                                            label={t("Name")}
                                            placeholder={t("Name")}
                                            type="text"
                                            error={state?.errors?.name}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <LabelWithInputField
                                            isMandatory
                                            name="percentage"
                                            handleChange={(e) => changeHandler(e, 'percentage')}
                                            value={state?.data?.percentage}
                                            id="percentage"
                                            label={t("Percentage")}
                                            placeholder={t("Percentage")}
                                            type="text"
                                            error={state?.errors?.percentage}
                                        />
                                    </div>
                                    <div className="col-md-3 searchBtnWrapper">
                                        {!id && (
                                            <Button
                                                title={state?.editIndex != null ? t("Update") : t("+ Add another")}
                                                handleClick={handleAddClick}
                                                className="form-button float-end"
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </form>

                        {/* table below the form */}
                        <div className="table-responsive">
                            {state?.tableData?.length > 0 && (
                                <table className="table table-hover">
                                    <thead className="TableHeader">
                                        <tr>
                                            <th style={{ width: "10%" }}>{t("Name")}</th>
                                            <th style={{ width: "10%" }}>{t("Percentage")}</th>
                                            <th style={{ width: "15%" }}>{t("Actions")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {state?.tableData?.map((item: any, index: number) => (
                                            <tr key={index} className={getRowClass(index)}>
                                                <td className="align-middle ps-4">{item?.name}</td>
                                                <td className="align-middle">{item?.percentage}</td>
                                                <td className="table-action-icons">
                                                    <span
                                                        onClick={() => handleEdit(index)}
                                                        className="table-action-btn me-2 cursor-pointer"
                                                        title={t("Edit")}
                                                    >
                                                        <EditIcon />
                                                    </span>
                                                    <span
                                                        onClick={() => handleRemove(index)}
                                                        className="table-action-btn cursor-pointer"
                                                        title={t("Delete")}
                                                    >
                                                        <DeleteIcon />
                                                    </span>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            )}
                        </div>
                        <div className="d-flex justify-content-between backPadding align-items-center">
                            <div>
                                <BackButton />
                            </div>

                            <div>
                                <Button
                                    title={t("Save")}
                                    handleClick={handleSubmit}
                                    className="form-button float-end shadow-none"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </AccessControl>
    );
}

export default CreatVatRate;
