import React, { useState, useEffect } from "react";
import { ApiCall } from "services/ApiServices";
import CustomNotify from "components/atoms/CustomNotify";
import { t } from "pages/microservices/masterData/translation/Translation";
import TableActions from "components/organism/Actions/TableAction";
import { useNavigate } from "react-router-dom";
import { M_MASTER_DATA } from "constants/Constants";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import Title from "components/atoms/Title";
import LinkTo from "components/atoms/LinkTo";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import FilterOrganism from "components/organism/FilterOrganism";
import { updatePageFilters } from "store/pageHistory/pageHistorySlice";
import PaginationWithPerPage from "components/molecules/PaginationWithPerPage";
import SortAtomForManagePage from "components/atoms/SortAtomForManagePage";
import { PATH_DEFAULT_COEFFICIENTS_CREATE, PATH_DEFAULT_COEFFICIENTS_EDIT, PATH_MANAGE_DEFAULT_COEFFICIENTS } from "constants/Paths";

interface ManageTempAgencyProps {
    data?: any;
    setCoefficientData: any;
    handleGetVacany: any;
    dispatch: any;
    history: any;
    pageData: any;
    handleRefresh: () => void;
    dropDownOptions: any;
}

const ManageDefaultCoefficientPage: React.FC<ManageTempAgencyProps> = ({
    data,
    dispatch,
    history,
    pageData,
    handleRefresh,
    dropDownOptions,
}) => {
    const [id, setId] = useState<number | undefined>(undefined);
    // const [filterOptions, setFilterOptions] = useState({
    //     company: [],
    // });
    const navigate = useNavigate();
    // useEffect(() => {
    //     getFilterData();
    // }, [id]);

    // const getFilterData = async () => {
    //     //filtering data
    // };

    // useEffect(() => {
    //     getFilterData();
    // }, []);


    const handleEdit = (id: number) => {
        navigate(`${PATH_DEFAULT_COEFFICIENTS_EDIT}/${id}`);
    };

    const userData = useSelector(selectAuth);

    const filters = [
        { name: 'company', fieldType: "singleSelect", options: dropDownOptions?.companies, placeholder: "Company", filterType: 'search' },
    ];

    return (
        <AccessControl
            requiredPermissions={[
                {
                    permission: "Default coefficients",
                    read: true,
                },
            ]}
            renderNoAccess={true}
            override={userData.isSuperAdmin}
        >
            <>
                <div className="search-bar">
                    <Title title={t("Default coefficients")} />
                    <FilterOrganism
                        dispatch={dispatch}
                        history={history}
                        updatePageFilters={updatePageFilters}
                        filters={filters}
                        class={{
                            rowClass: 'd-grid manageCarSearchCol manageSupplierSearchCol',
                            searchBtnClass: 'col-2 ms-auto'
                        }}
                        handleRefresh={handleRefresh}
                    />
                </div>

                <div className="position-relative tableMainWrapper">
                    <div>
                        <AccessControl
                            requiredPermissions={[
                                {
                                    permission: "Default coefficients",
                                    create: true,
                                },
                            ]}
                            override={userData.isSuperAdmin}
                        >
                            <div className="row">
                                <div className="ManageCreateBtn">
                                    <LinkTo
                                        pagelink={PATH_DEFAULT_COEFFICIENTS_CREATE}
                                        title={t("Create default coefficients")}
                                        icon={faPlus}
                                    />
                                </div>
                            </div>
                        </AccessControl>
                    </div>
                    <div className="table-responsive manage-clients tableSection">
                        <div className="manageTempAgencyHeightNew">
                            <table className="table table-hover">
                                <thead>
                                    <tr className="TableHeader">
                                        <th key={'company'}>
                                            <>
                                                {t('Company')}
                                                {
                                                    <SortAtomForManagePage
                                                        value={{ alias: 'company' }}
                                                        dispatch={dispatch}
                                                        history={history}
                                                        updatePageFilters={updatePageFilters}
                                                        handleRefresh={handleRefresh}
                                                    />
                                                }
                                            </>
                                        </th>
                                        <th style={{ width: "15%" }}>
                                            <AccessControl
                                                requiredPermissions={[
                                                    {
                                                        permission: "Default coefficients",
                                                        update: true,
                                                    },
                                                ]}
                                                actions={true}
                                                strict={false}
                                                override={userData.isSuperAdmin}
                                            >
                                                {t("Actions")}
                                            </AccessControl>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data && data.length > 0 ? (
                                        data.map((record: any, key: any) => {
                                            return (
                                                <tr key={key}>
                                                    <td className="align-middle">{record?.companyName}</td>
                                                    <td className="align-middle">
                                                        <TableActions
                                                            isAction={true}
                                                            handleEdit={handleEdit}
                                                            value={record.id}
                                                            permission={"Default coefficients"}
                                                        />
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan={5} className="text-center">
                                                {t("No records found")}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <div
                            className=""
                            style={{ paddingTop: "0.25vw" }}
                        >
                            <PaginationWithPerPage
                                handleRefresh={handleRefresh}
                                dispatch={dispatch}
                                history={history}
                                pageData={pageData}
                            />
                        </div>
                    </div>
                </div>
            </>
        </AccessControl>
    );
};
export default ManageDefaultCoefficientPage;
