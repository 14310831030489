import React, { useEffect, useRef, useState } from "react";
import { useFormContext } from "pages/microservices/project/context/Context";
import CheckBoxField from "components/atoms/CheckBoxField";
import { ApiCall } from "services/ApiServices";
import { M_MASTER_DATA } from "constants/Constants";
import {
  APPROVE_PROJECT_PROPOSAL,
  DYNAMIC_TODO_CREATION,
  PROJECT_PROPOSAL_PDF,
  SAVE_SIGNATURE,
  SEND_PROJECT_PROPOSAL,
} from "routes/ApiEndpoints";
import { t } from "pages/microservices/masterData/translation/Translation";
import { useNavigate, useParams } from "react-router-dom";
import {
  PATH_ACCESS_DENIED,
  PATH_PROJECTS_MANAGE,
  PATH_TEMPLATE_NOT_FOUND,
} from "constants/Paths";
import Button from "components/atoms/Button";
import EmployerAuthenticModal from "../Proposals/EmployerAuthenticModal";
import SignatureModal from "../Proposals/SignatureModel";
import SignatureCanvas from "react-signature-canvas";
import WindowHeightComponent from "components/atoms/WindowHeightComponent";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import EncryptDecryptService from "services/EncryptDecryptService";
import CustomNotify from "components/atoms/CustomNotify";
import BackButton from "components/atoms/BackButton";

interface ProjectsProps {
  id?: string | number;
}
interface Preview {
  throughMail: any;
  iframeUrl: string;
  projectId: string | number;
  showApproveButton: boolean;
  showSignatureButton: boolean;
  clientMailStatus: boolean;
  managerMailStatus: boolean;
  userId: number[];
  showButton: boolean;
  isAuthenticated: boolean;
  showSignatureModal: boolean;
  authenticatedUserId: string;
}

const ProjectOverview: React.FC<ProjectsProps> = () => {
  const url = new URL(window.location.href);
  const dataParam = url.searchParams.get("data");
  const aggreeParam = url.searchParams.get("aggree");
  const userParam = url.searchParams.get("user");
  const sinature = url.searchParams.get("isSignature");
  const navigate = useNavigate();
  const initialState = {
    iframeUrl: "",
    projectId: "",
    showApproveButton: false,
    showSignatureButton: false,
    clientMailStatus: false,
    managerMailStatus: false,
    userId: [],
    showButton: false,
    isAuthenticated: false,
    showSignatureModal: false,
    authenticatedUserId: "",
    throughMail: false,
  };
  const [state, setState] = useState<Preview>(initialState);
  const [proposalPathData, setProposalPathData] = useState({
    preview: null,
    salesPreview: null,
    employerPreview: null,
  });

  const userAuth = useSelector(selectAuth);
  const getProposalDocument = async (projectId: any, queryParam?: any) => {
    const data = { projectId: projectId };
    if (queryParam) {
      // Assuming queryParam is an object like { isSignature: true, userId: 421 }
      Object.assign(data, queryParam);
    }

    // Make the API call
    const response = await ApiCall.service(
      PROJECT_PROPOSAL_PDF,
      "POST",
      data, // Send data (projectId + any queryParams)
      true,
      M_MASTER_DATA
    );
    if (response?.status === 200) {
      setProposalPathData({
        preview: response.data.preview,
        salesPreview: response.data.sales?.preview || null,
        employerPreview: response.data.employer?.preview || null,
      });
      return response;
    } else {
      navigate(`${PATH_TEMPLATE_NOT_FOUND}/${"Template not avilable"}`);
    }
  };

  useEffect(() => {
    const fetchDocument = async () => {
      if (dataParam) {
        const showSignatureButton = sinature === "1";
        if (aggreeParam !== null) {
          const decryptedProjectId = atob(dataParam);
          const decryptedUserId = atob(aggreeParam);
          const queryParams = {
            isSignature: showSignatureButton,
            userId: decryptedUserId,
          };
          const proposalDocumentPath = await getProposalDocument(
            decryptedProjectId,
            queryParams
          );
          setState((prevState) => ({
            ...prevState,
            projectId: decryptedProjectId,
            showApproveButton: showSignatureButton,
            showSignatureButton: !showSignatureButton,
            iframeUrl: proposalDocumentPath?.data?.preview,
            authenticatedUserId: decryptedUserId,
            throughMail: true,
          }));

          if (userParam !== null) {
            const decryptedUsertId = Number(atob(userParam));
            if (decryptedUsertId !== userAuth.userId) {
              navigate(PATH_ACCESS_DENIED);
            }
          }
        } else {
          const decodedData = decodeURIComponent(dataParam);
          const append = EncryptDecryptService.decryptData(decodedData);
          const parsedData = JSON.parse(append);
          const proposalDocumentPath = await getProposalDocument(parsedData.id);

          setState((prevState) => ({
            ...prevState,
            iframeUrl: proposalDocumentPath?.data?.preview,
            userId: parsedData.users || prevState.userId,
            projectId: parsedData.id || prevState.projectId,
            showApproveButton:
              parsedData.approve === "approve"
                ? true
                : prevState.showApproveButton,
            managerMailStatus:
              parsedData.ManagerMailStatus === 1
                ? true
                : prevState.managerMailStatus,
            clientMailStatus:
              parsedData.clientMailStatus === 1
                ? true
                : prevState.clientMailStatus,
          }));
        }
      }
    };
    fetchDocument();
  }, [dataParam, aggreeParam, userParam, userAuth.userId, navigate]);

  const [showModal, setShowModal] = useState(false);
  const [showCheckBox, setShowCheckbox] = useState(true);

  const [signatureErrorMessage, setSignatureErrorMessage] = useState("");
  const [checked, setChecked] = useState({
    check: 0,
    checkedButton: 0,
  });
  // const [isAuthenticated, setIsAuthenticated] = useState(false);
  const signatureRef = useRef<SignatureCanvas | null>(null);

  const handleBackButtonClick = () => {
    navigate(`${PATH_PROJECTS_MANAGE}`);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setChecked((prevState) => ({
      ...prevState,
      check: 0,
      checkedButton: 1,
    }));
    setState((prevState) => ({
      ...prevState,
      showSignatureModal: false,
    }));
    // setSignatureModal(false);
  };

  const handleAuthenticationSuccess = async (
    success: any,
    email: any,
    signatureImage: any,
    userId: any
  ) => {
    const isSignaturePresent = !!signatureImage; // true if present, false if not
    const isUserIdPresent = !!userId; //
    const queryParams = {
      isSignature: isSignaturePresent,
      userId: isUserIdPresent ? userId : "",
    };
    if (success) {
      setShowCheckbox(false);
      setChecked((prevState) => ({
        ...prevState,
        check: 1,
        checkedButton: 1,
      }));
      //   const finalIframeUrl = `${process.env.REACT_APP_MASTER_DATA_URL}/project-pdf/${state.projectId}${queryParams}`;
      const proposalPath = await getProposalDocument(
        state.projectId,
        queryParams
      );
      setState((prevState) => ({
        ...prevState,
        iframeUrl: proposalPath.data.employer.preview,
        isAuthenticated: success,
        showApproveButton: isSignaturePresent,
        authenticatedUserId: userId,
      }));
    } else {
      setShowCheckbox(true);
      setChecked((prevState) => ({
        ...prevState,
        check: 1,
        checkedButton: 0,
      }));
      setState((prevState) => ({
        ...prevState,
        isAuthenticated: success,
      }));
    }
  };

  const handleSendToEmployer = async () => {
    const response = await ApiCall.service(
      SEND_PROJECT_PROPOSAL,
      "POST",
      { projectId: state.projectId },
      true,
      M_MASTER_DATA
    );
    if (response?.status === 200) {
      CustomNotify({ type: "success", message: `${response.message}` });
      navigate(`${PATH_PROJECTS_MANAGE}`);
      //todo creation
      const isSalesRole = userAuth?.role?.filter(
        (role: any) => role === "SALES"
      );
      if (Array.isArray(isSalesRole) && isSalesRole?.length > 0) {
        const todoData = {
          name: "project",
          entityId: state.projectId,
          description: "Project registered successfully",
          loginUserId: userAuth?.userId,
          userId: response?.data, //contact person id's
          todoType: "Proposal agreement",
        };
        await ApiCall.service(
          DYNAMIC_TODO_CREATION,
          "POST",
          todoData,
          false,
          M_MASTER_DATA
        );
      }
    }
  };

  const handleClearSignature = () => {
    signatureRef.current?.clear();
  };

  const handleSaveSignature = async () => {
    if (!signatureRef.current?.isEmpty()) {
      const signatureImage = signatureRef.current?.toDataURL();
      handleModalClose();
      setSignatureErrorMessage("");
      const requestData = {
        image: signatureImage,
        userId: state.authenticatedUserId,
        projectId: state.projectId,
      };
      // Now you can use the requestData as needed
      const response = await ApiCall.service(
        SAVE_SIGNATURE,
        "POST",
        requestData,
        false,
        M_MASTER_DATA
      );

      if (response?.status === 200) {
        const queryParams = {
          isSignature: true,
          userId: response.userId,
        };
        // const queryParams = `?isSignature=${true}&userId=${response.userId}`;
        const proposalPath = await getProposalDocument(
          state.projectId,
          queryParams
        );
        setState((prevState) => ({
          ...prevState,
          iframeUrl: proposalPath.data.employer.preview,
          authenticatedUserId: response.userId,
          showSignatureButton: false,
          showApproveButton: true,
        }));
        setShowModal(false);
        setChecked((prevState) => ({
          ...prevState,
          check: 1,
          checkedButton: 1,
        }));
        setShowCheckbox(false);
      }
    } else {
      setSignatureErrorMessage(t("Please add signature before saving."));
    }
  };

  const handleCheckbox = (checked: boolean) => {
    setChecked((prevState) => ({
      ...prevState,
      check: checked ? 1 : 0,
      checkedButton: checked ? 1 : 0,
    }));
    if (checked) {
      setState((prevState) => ({
        ...prevState,
        showButton: true,
        iframeUrl: proposalPathData?.salesPreview || prevState.iframeUrl,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        showButton: false,
      }));
    }
  };
  const handleSignAsEmployer = () => {
    setShowCheckbox(true);
    setShowModal(true);
    // setShowButton(false);
    setState((prevState) => ({
      ...prevState,
      showButton: false,
    }));
  };
  const handleAddSignature = () => {
    // setSignatureModal(true);
    setState((prevState) => ({
      ...prevState,
      showSignatureModal: true,
    }));
  };
  const handleAppoveProposal = async () => {
    const requestData = {
      projectId: state.projectId,
      userId: state.authenticatedUserId,
    };
    if (state.projectId) {
      const url = `${APPROVE_PROJECT_PROPOSAL}`;
      const response = await ApiCall.service(
        url,
        "POST",
        requestData,
        true,
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        CustomNotify({ type: "success", message: `${response.message}` });
        if (state.throughMail) {
          navigate(`/`);
        } else {
          navigate(PATH_PROJECTS_MANAGE);
        }
      }
    }
  };
  return (
    <>
      <WindowHeightComponent>
        <div
          className="overflow-auto flex-column"
          style={{ height: "inherit" }}
        >
          <div
            className="flex-1 overflow-auto"
            style={{ height: "calc(100vh - 10vw)" }}
          >
            {state.iframeUrl ? (
              <iframe src={state.iframeUrl} height="93%" width="100%" />
            ) : (
              <p>{t("No document available")}</p>
            )}
          </div>

          <div>
            {showCheckBox && (
              <CheckBoxField
                className=""
                name={"checkbox"}
                onChangeHandler={(e) => handleCheckbox(e.target.checked)}
                isChecked={checked.check === 1}
                label={t(
                  "I hereby declare that I have reviewed the document and confirming that I agree with all details"
                )}
                lineHeight={"1.5vw"}
                id="declaration"
              />
            )}
            <div className="d-flex justify-content-between my-3">
              {/* <Button
                type="button"
                title={t("Back")}
                handleClick={handleBackButtonClick}
                className="text-uppercase back-btn text-decoration-underline btn p-0 "
              /> */}
              <BackButton />

              {checked.check === 1 &&
                state.showButton &&
                !state.showApproveButton &&
                !state.showSignatureButton && (
                  <div className="align-self-center d-flex">
                    <Button
                      type="button"
                      title={t("Sign as client")}
                      handleClick={handleSignAsEmployer}
                      className="me-3 form-button shadow-none text-uppercase"
                    />

                    <Button
                      type="button"
                      title={
                        state.clientMailStatus
                          ? t("Resent to client")
                          : t("Send to client")
                      }
                      handleClick={handleSendToEmployer}
                      className="me-3 form-button shadow-none text-uppercase"
                    />
                    {/* <Button
                      type="button"
                      title={
                        state.managerMailStatus
                          ? t("Resent to Manager")
                          : t("Send to Manager")
                      }
                      handleClick={handleSendToManager}
                      className="me-3 form-button shadow-none text-uppercase"
                    /> */}
                  </div>
                )}
              {(state.isAuthenticated && !state.showApproveButton) ||
              (checked.checkedButton === 1 && state.showSignatureButton) ? (
                <Button
                  type="button"
                  title={t("Add Signature")}
                  handleClick={handleAddSignature}
                  className="form-button shadow-none text-uppercase"
                />
              ) : null}
              {checked.checkedButton === 1 &&
                state.showApproveButton && ( // Conditionally render buttons based on authentication status
                  <Button
                    type="button"
                    title={t("Approve")}
                    handleClick={handleAppoveProposal}
                    className="form-button shadow-none  text-uppercase"
                  />
                )}
            </div>
            {showModal && (
              <EmployerAuthenticModal
                handleClose={handleModalClose}
                onAuthenticationSuccess={handleAuthenticationSuccess}
                users={state.userId}
              />
            )}
            <div>
              {state.showSignatureModal && (
                <SignatureModal
                  handleClose={handleModalClose}
                  handleClearSignature={handleClearSignature}
                  handleSaveSignature={handleSaveSignature}
                  signatureRef={signatureRef}
                  signatureErrorMessage={signatureErrorMessage}
                />
              )}
            </div>
          </div>
        </div>
      </WindowHeightComponent>
    </>
  );
};

export default ProjectOverview;
