import React from "react";
import { t } from "pages/microservices/masterData/translation/Translation";
import { useFormContext } from "pages/microservices/project/context/Context";

import Button from "components/atoms/Button";
import CheckBoxField from "components/atoms/CheckBoxField";
import SelectWithSearch from "components/atoms/SelectWithSearch";

import Calender from "components/molecules/Calender";
import LabelWithTextAreaField from "components/molecules/LabelWithTextAreaField";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";

import { Option } from "components/common/CommonInterfaces";
import {
  validateDateRange,
  validateForm,
  validateFormField,
  validateTimeRange,
} from "../../../../services/validation/ValidationService";
import {
  COMPETENCE_TAB_DATA,
  SET_CREATE_LOCATION_GENERAL_TAB,
  SET_ERROR_SUBFIELD_GENERAL_TAB,
  SET_FIELD_ERROR_GENERAL_TAB,
  SET_FIELD_GENERAL_TAB,
  SET_LOCATION_OPTION,
  SET_OPTIONS,
  SET_PROJECT_LOCATION_FIELD_EMPTY,
  UPDATE_CONTACT_SUBFORM_FIELD,
  UPDATE_SELECTED_CONTACT_FIELD,
} from "../context/Constants";

import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  GeneralTabSubformLocationRules,
  GeneralTabValidationRules,
} from "../validationRules/ProjectFormValidationRules";
import { GeneralTabData, GeneralTabErrors } from "../context/Interfaces";
import { FormMode } from "components/common/CommonEnums";
import LocationFormOrganism from "components/organism/location/LocationFormOrganism";
import { getProjectStatusColor } from "services/util/UtilService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ApiCall } from "services/ApiServices";
import { M_COMPANY_CREATION, M_MASTER_DATA } from "constants/Constants";
import {
  CREATE_LOCATION_FROM_PROJECT,
  GET_CONSULTANT_BUSINESSUNIT,
  PROJECTS_OPTIONS,
} from "routes/ApiEndpoints";
import FunctionWithCapacity from "./FunctionWithCapacity";
import axios from "axios";
import { Modal } from "react-bootstrap";

// Define the prop types for the component
interface IProjectFormGeneralTabProps {
  // id?: string | number;
  type?: string;
}

// Functional component using TypeScript
const General: React.FC<IProjectFormGeneralTabProps> = ({ type }) => {
  const { state, dispatch } = useFormContext();

  // Init
  const selectOptions = state.options;
  const generalData: GeneralTabData = state.general.data;

  const generalErrors: GeneralTabErrors = state.general.errors;
  const createLocationFormIsVisible: boolean =
    generalData.createLocationIsVisible as boolean;

  // Helper functions
  const handleFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    if (event.target) {
      const { name, value, type, checked } = event.target as HTMLInputElement;
      if (name === "projectCode") {
        // Remove non-alphabetic characters and spaces, and convert to uppercase
        const formattedValue = value.replace(/[^A-Z]/g, "").toUpperCase();

        // Check if formattedValue is empty and prevent dispatching if so
        if (formattedValue.length <= 6) {
          dispatch({
            type: SET_FIELD_GENERAL_TAB,
            field: "projectCode",
            value: formattedValue, // Dispatch the cleaned value
          });
        }
      }
      if (type === "checkbox") {
        dispatch({
          type: SET_FIELD_GENERAL_TAB,
          field: name,
          value: checked,
        });
      } else {
        if (name === "projectName") {
          const formattedValue = value.replace(/[^a-zA-Z]/g, "").toUpperCase();
          const truncatedValue = formattedValue.slice(0, 6);
          dispatch({
            type: SET_FIELD_GENERAL_TAB,
            field: "projectCode",
            value: truncatedValue,
          });
        }

        dispatch({
          type: SET_FIELD_GENERAL_TAB,
          field: name,
          value: value,
        });
      }

      let validatedFieldError = validateFormField(
        generalData,
        GeneralTabValidationRules,
        name,
        value
      );

      dispatch({
        type: SET_FIELD_ERROR_GENERAL_TAB,
        fieldName: name,
        error: validatedFieldError,
      });
    }
  };
  const getConsultantBusinessUnit = async (consultantId: any) => {
    const response = await ApiCall.service(
      GET_CONSULTANT_BUSINESSUNIT,
      "POST",
      { consultant: consultantId },
      false,
      M_COMPANY_CREATION
    );
    if (response?.status === 200) {
      if (response?.data?.length > 0) {
        dispatch({
          type: SET_FIELD_GENERAL_TAB,
          field: "businessUnitNumber",
          value: response.data[0],
        });
      } else {
        dispatch({
          type: SET_FIELD_GENERAL_TAB,
          field: "businessUnitNumber",
          value: null, // Default or fallback value
        });
      }
    }
  };

  const handleSelectChange = (
    value: Option | Option[],
    fieldName: string,
    isMultiSelect: boolean = false
  ) => {
    if (isMultiSelect) {
      dispatch({
        type: SET_FIELD_GENERAL_TAB,
        field: fieldName,
        value: value as Option[],
      });
    } else {
      if (fieldName === "consultant") {
        if (value && !Array.isArray(value) && value.value) {
          getConsultantBusinessUnit(value.value);
          dispatch({
            type: SET_FIELD_GENERAL_TAB,
            field: "businessUnitNumber",
            value: "",
          });
        }
      }
      dispatch({
        type: SET_FIELD_GENERAL_TAB,
        field: fieldName,
        value: value,
      });
      const fieldNames = [
        "timeSheet",
        "billing",
        "leave",
        "sickness",
        "holiday",
        "safety",
      ];

      fieldNames.forEach((fieldName) => {
        dispatch({
          type: UPDATE_SELECTED_CONTACT_FIELD,
          field: fieldName,
          value: [],
        });
      });
      if (fieldName === "company") {
        dispatch({
          type: UPDATE_CONTACT_SUBFORM_FIELD,
          field: "company",
          value: value,
        });
        dispatch({
          type: SET_CREATE_LOCATION_GENERAL_TAB,
          field: "company",
          value: value,
        });
        dispatch({
          type: SET_FIELD_GENERAL_TAB,
          field: "functions",
          value: {
            isEdit: false,
            selected: { value: "", label: "Select", capacity: 0 },
            tableData: [],
          },
        });
      }
    }

    let validatedFieldError = validateFormField(
      generalData,
      GeneralTabValidationRules,
      fieldName,
      value
    );

    dispatch({
      type: SET_FIELD_ERROR_GENERAL_TAB,
      fieldName: fieldName,
      error: validatedFieldError,
    });
  };
  const handleDateChangeCheck = (value: Date | null, fieldName: string) => {
    const updatedDate = value ?? new Date();

    let startDate =
      fieldName === "startDate"
        ? updatedDate
        : generalData.startDate ?? new Date();
    let endDate =
      fieldName === "endDate" ? updatedDate : generalData.endDate ?? new Date();

    let validatedFieldErrorDateSequence = validateDateRange(startDate, endDate);
    dispatch({
      type: SET_FIELD_GENERAL_TAB,
      field: fieldName,
      value: updatedDate,
    });
    if (validatedFieldErrorDateSequence) {
      if (fieldName === "startDate") {
        // dispatch({
        //   type: SET_FIELD_ERROR_GENERAL_TAB,
        //   fieldName: "startDate",
        //   error: validatedFieldErrorDateSequence,
        // });
        dispatch({
          type: SET_FIELD_ERROR_GENERAL_TAB,
          fieldName: "endDate",
          error: "",
        });
      } else if (fieldName === "endDate") {
        dispatch({
          type: SET_FIELD_ERROR_GENERAL_TAB,
          fieldName: "endDate",
          error: validatedFieldErrorDateSequence,
        });
        dispatch({
          type: SET_FIELD_ERROR_GENERAL_TAB,
          fieldName: "startDate",
          error: "",
        });
      }
    } else {
      dispatch({
        type: SET_FIELD_ERROR_GENERAL_TAB,
        fieldName: "startDate",
        error: "",
      });
      dispatch({
        type: SET_FIELD_ERROR_GENERAL_TAB,
        fieldName: "endDate",
        error: "",
      });
    }

    // Perform additional field-specific validation if any
    let validatedFieldError = validateFormField(
      generalData,
      GeneralTabValidationRules,
      fieldName,
      value
    );

    // If there's a field-specific validation error and it's not for the start or end date fields, dispatch it
    if (
      validatedFieldError &&
      fieldName !== "startDate" &&
      fieldName !== "endDate"
    ) {
      dispatch({
        type: SET_FIELD_ERROR_GENERAL_TAB,
        fieldName: fieldName,
        error: validatedFieldError,
      });
    }
  };

  const handleButtonClick = () => {
    dispatch({
      type: SET_FIELD_GENERAL_TAB,
      field: "createLocationIsVisible",
      value: !generalData.createLocationIsVisible,
    });
    dispatch({
      type: SET_PROJECT_LOCATION_FIELD_EMPTY,
      field: "createLocationIsVisible",
    });
  };
  const getCityAndCountry = async (zipcode: string) => {
    try {
      if (zipcode.length === 4) {
        const response = await axios.get(
          `https://api.zippopotam.us/be/${zipcode}`
        );
        // Check if the response contains places data and is in the expected format
        if (
          response.data &&
          response.data.places &&
          response.data.places.length > 0
        ) {
          return {
            city: response.data.places?.[0]?.["place name"] || "Not found",
            country: response.data?.country || "Belgium",
          };
        } else {
          return { city: "Not found", country: "Belgium" };
        }
      } else {
        return { city: "", country: "" };
      }
    } catch (error) {
      return { city: "Error", country: "Error" };
    }
  };

  // Create location subform handling
  const handleCreateLocationSubformFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    if (event.target) {
      const { name, value, type, checked } = event.target as HTMLInputElement;
      if (name === "zipcode") {
        const city = getCityAndCountry(value);
        city.then((response: any) => {
          const { city, country } = response;
          dispatch({
            type: SET_CREATE_LOCATION_GENERAL_TAB,
            field: "city",
            value: city,
          });
          const countryValue: any =
            country !== "Error"
              ? selectOptions.countries.find(
                  (coun: any) =>
                    coun?.label?.toLowerCase() === country?.toLowerCase()
                )
              : null;
          dispatch({
            type: SET_CREATE_LOCATION_GENERAL_TAB,
            field: "country", // Convert to domFieldName
            value: countryValue,
          });
        });
      }

      dispatch({
        type: SET_CREATE_LOCATION_GENERAL_TAB,
        field: name,
        value: type === "checked" ? checked : value,
      });

      let validatedFieldError = validateFormField(
        state.projectLocation.data,
        GeneralTabSubformLocationRules,
        name,
        type === "checked" ? checked : value
      );

      dispatch({
        type: SET_ERROR_SUBFIELD_GENERAL_TAB,
        field: name,
        error: validatedFieldError,
      });
    }
  };
  const getCompetencesAndQuestionOnFunction = async (function_id: any) => {
    const postData = {
      options: ["Competence", "Question"],
      function_id: function_id ?? [],
    };
    const response = await ApiCall.service(
      PROJECTS_OPTIONS,
      "POST",
      postData,
      false,
      M_MASTER_DATA
    );
    if (response?.status === 200) {
      const { Competence, Question } = response?.data;
      dispatch({ type: SET_OPTIONS, field: "Competence", value: Competence });
      dispatch({
        type: COMPETENCE_TAB_DATA,
        field: "competences",
        value: { competence: Competence },
      });
      dispatch({
        type: SET_OPTIONS,
        field: "Question",
        value: Question,
      });
    }
  };

  const handleSelectChangeLocationSubform = (
    value: Option | Option[],
    fieldName: string,
    isMultiSelect: boolean = false
  ) => {
    // set value
    dispatch({
      type: SET_CREATE_LOCATION_GENERAL_TAB,
      field: fieldName,
      value: isMultiSelect ? (value as Option[]) : [value as Option],
    });

    // validate field
    let validatedFieldError = validateFormField(
      state.projectLocation.data,
      GeneralTabSubformLocationRules,
      fieldName,
      value
    );

    dispatch({
      type: SET_ERROR_SUBFIELD_GENERAL_TAB,
      field: fieldName,
      error: validatedFieldError,
    });
  };
  const updateError = (fieldName: string, errorMessage: string) => {
    dispatch({
      type: SET_ERROR_SUBFIELD_GENERAL_TAB,
      field: fieldName,
      error: errorMessage,
    });
  };
  const onSaveLocation = async () => {
    const validationErrors = validateForm(
      state.projectLocation.data,
      GeneralTabSubformLocationRules
    );

    // If there are validation errors, update the state
    if (Object.keys(validationErrors).length > 0) {
      Object.keys(validationErrors).forEach((field) => {
        updateError(field, validationErrors[field]);
      });
      return;
    }
    const response = await ApiCall.service(
      CREATE_LOCATION_FROM_PROJECT,
      "POST",
      state.projectLocation.data,
      true,
      M_MASTER_DATA
    );
    if (response?.status === 200) {
      const locationOption = Array.isArray(response?.locations)
        ? response?.locations
        : [response?.locations];
      dispatch({
        type: SET_LOCATION_OPTION,
        field: "locations",
        value: locationOption,
      });
      dispatch({
        type: SET_PROJECT_LOCATION_FIELD_EMPTY,
        field: "createLocationIsVisible",
      });

      dispatch({
        type: SET_FIELD_GENERAL_TAB,
        field: "createLocationIsVisible",
        value: !generalData.createLocationIsVisible,
      });
    }
  };
  const handleClose = () => {
    dispatch({
      type: SET_FIELD_GENERAL_TAB,
      field: "createLocationIsVisible",
      value: !generalData.createLocationIsVisible,
    });
    dispatch({
      type: SET_PROJECT_LOCATION_FIELD_EMPTY,
      field: "createLocationIsVisible",
    });
  };
  const handleSaveGoogleMapData = (GoogleMapData: any) => {
    // List of fields to process
    const fieldMappings = {
      street: GoogleMapData.street,
      number: GoogleMapData.number,
      box: GoogleMapData.box,
      zipcode: GoogleMapData.zipcode,
      city: GoogleMapData.city,
      country: GoogleMapData.country,
      initialLat: GoogleMapData.initialLat,
      initialLn: GoogleMapData.initialLn,
    };

    // Loop through the mappings and dispatch each field
    Object.entries(fieldMappings).forEach(([fieldName, value]) => {
      dispatch({
        type: SET_CREATE_LOCATION_GENERAL_TAB,
        field: fieldName,
        value, // Use the mapped value
      });
    });
  };

  const renderLocationCreateForm = () => {
    return createLocationFormIsVisible ? (
      <div>
        <Modal
          size="xl"
          show={true}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          centered
          className="generalAddLocationPopup"
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("Add location")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <LocationFormOrganism
              formMode={state.mode}
              formOptions={{
                companyOptions: selectOptions.company,
                countryOptions: selectOptions.countries,
              }}
              formData={state.projectLocation.data}
              company={state.projectLocation.data.company}
              tab={"general"}
              formErrors={state.projectLocation.errors}
              formErrorCompany={generalErrors}
              handleChange={handleCreateLocationSubformFieldChange}
              handleSelectChange={handleSelectChangeLocationSubform}
              handleSaveGoogleMapData={handleSaveGoogleMapData}
            />
            <div className="marginBotttom1 d-flex align-items-center justify-content-end">
              <Button
                title={t("Save")}
                handleClick={() => onSaveLocation()} // Optional chaining
                className="form-button"
              />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    ) : null;
  };

  const className =
    type !== "View"
      ? "form-border projectsGeneral"
      : "form-border projectsGeneral disabled";

  return (
    <div className="row">
      <div className="col-12">
        <div className={`${className}`} style={{ paddingTop: "2vw" }}>
          <div className="row">
            <div className="col-4">
              <SelectWithSearch
                search={true}
                onChange={(value) =>
                  handleSelectChange(value, "company", false)
                }
                title={t("Company")}
                placeHolder={t("Select")}
                options={selectOptions.company}
                value={generalData.company}
                isDisabled={false}
                isMulti={false}
                isMandatory={true}
                error={generalErrors.company}
                name={"company"}
                id={"companyId"}
              ></SelectWithSearch>
            </div>
            <div className="col-4">
              <SelectWithSearch
                search={true}
                onChange={(value) =>
                  handleSelectChange(value, "consultant", false)
                }
                title={t("Consultant")}
                placeHolder={t("Select")}
                options={selectOptions.consultant}
                value={generalData.consultant}
                isDisabled={false}
                isMulti={false}
                isMandatory={true}
                error={generalErrors.consultant}
                name={"consultant"}
                id={"consultant"}
              ></SelectWithSearch>
            </div>
            <div className="col-4">
              <SelectWithSearch
                onChange={(value) =>
                  handleSelectChange(value, "businessUnitNumber", false)
                }
                title={t("Maxicon Business Unit")}
                placeHolder={t("Select")}
                search={true}
                options={selectOptions.businessUnit}
                value={generalData.businessUnitNumber}
                isDisabled={false}
                isMulti={false}
                isMandatory={true}
                error={generalErrors.businessUnitNumber}
                name={"businessUnitNumber"}
                id={"businessUnitNumberId"}
              ></SelectWithSearch>
            </div>
            <div className="col-4">
              <LabelWithInputField
                type={"text"}
                handleChange={handleFieldChange}
                label={t("Project name")}
                value={generalData.projectName}
                readOnly={false}
                isDisabled={false}
                isMandatory={true}
                error={generalErrors.projectName}
                name={"projectName"}
                id={"projectNameId"}
              ></LabelWithInputField>
            </div>
            <div className="col-4">
              <LabelWithInputField
                type={"text"}
                handleChange={handleFieldChange}
                label={t("Project code")}
                value={generalData.projectCode?.toUpperCase()}
                readOnly={false}
                isDisabled={false}
                isMandatory={true}
                error={generalErrors.projectCode}
                name={"projectCode"}
                id={"projectCodeId"}
                maxLenght={6}
              ></LabelWithInputField>
            </div>
            <div className="col-3">
              <SelectWithSearch
                search={true}
                onChange={(value) =>
                  handleSelectChange(value, "locations", true)
                }
                title={t("Location(s)")}
                placeHolder={t("Select")}
                options={selectOptions.locations}
                value={generalData.locations}
                isDisabled={false}
                isMulti={true}
                isMandatory={true}
                error={generalErrors.locations}
                name={"locations"}
                id={"locationsId"}
              ></SelectWithSearch>
            </div>
            <div className="col-1 table-action-icons plusIconSPace">
              <span
                className="table-action-btn cursor-pointer"
                onClick={handleButtonClick}
                // disabled={false}
                title={
                  createLocationFormIsVisible ? t("Minimise") : t("Expand")
                }
              >
                <FontAwesomeIcon
                  icon={createLocationFormIsVisible ? faMinus : faPlus}
                />
              </span>
            </div>
            <div className="col-2">
              <Calender
                onChange={(date) => handleDateChangeCheck(date, "startDate")}
                selectedDate={generalData.startDate}
                label={t("Start date")}
                isMandatory={true}
                name={"startDate"}
                error={generalErrors.startDate}
                isDisabled={false}
                placeHolder="dd-mm-yyyy"
              ></Calender>
            </div>
            <div className="col-2">
              <Calender
                onChange={(date) => handleDateChangeCheck(date, "endDate")}
                selectedDate={generalData.endDate}
                label={t("End date")}
                isMandatory={false}
                name={"endDate"}
                error={generalErrors.endDate}
                isDisabled={false}
                placeHolder="dd-mm-yyyy"
              ></Calender>
            </div>
            {renderLocationCreateForm()}
            <FunctionWithCapacity
              getCompetencesOnFunction={getCompetencesAndQuestionOnFunction}
            />
            <div className="col-12">
              <LabelWithTextAreaField
                label={t("Info")}
                name={"info"}
                value={generalData.info}
                isDisabled={false}
                isMandatory={false}
                handleChange={handleFieldChange}
                error={generalErrors.info}
              ></LabelWithTextAreaField>
            </div>
            <div className="col-sm-12 col-md-12">
              <CheckBoxField
                label={t("Active")}
                name={"isActive"}
                id={"isActiveId"}
                isChecked={
                  getProjectStatusColor(generalData.isActive) === "success"
                    ? true
                    : false
                }
                onChangeHandler={handleFieldChange}
                disable={false}
                lineHeight={"1.6vw"}
              ></CheckBoxField>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default General;
