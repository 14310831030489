import React from "react";
import ShiftTable from "components/organism/projects/shiftOrganism/ShiftTable";
import { useFormContext } from "pages/microservices/project/context/Context";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { t } from "pages/microservices/masterData/translation/Translation";
import { ApiCall } from "services/ApiServices";
import { GET_SHIFT_DETAILS } from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "constants/Constants";

interface ProjectsShiftProps {
  id?: string | number;
  type?: string;
}
const ShiftsForm: React.FC<ProjectsShiftProps> = ({ type }) => {
  const { state, dispatch } = useFormContext();

  const handleSelectChange = async (selectedValues: any) => {
    try {
      const selectedShiftIds = selectedValues.map((shift: any) => shift.value);
      const currentShiftIds = Object.keys(state.shifts.data.shiftTable || {}).map(Number);
      const newShiftIds = selectedShiftIds.filter((id: any) => !currentShiftIds.includes(id));
      const removedShiftIds = currentShiftIds.filter(id => !selectedShiftIds.includes(id));
      if (newShiftIds.length > 0) {
        const response = await ApiCall.service(
          GET_SHIFT_DETAILS,
          "POST",
          { id: newShiftIds, action: 'projects' },
          false,
          M_MASTER_DATA
        );
        if (response?.status === 200) {
          const { shiftTable, shiftErrors } = response.data;
          // Update both shifts data and errors in a single dispatch
          dispatch({
            type: "SHIFT_UPDATE_FIELD",
            field: "shifts",
            value: {
              data: {
                ...state.shifts.data,
                shiftTable: {
                  ...state.shifts.data.shiftTable,
                  ...shiftTable
                },
                shifts: selectedValues
              },
              errors: {
                ...state.shifts.errors,
                name: selectedValues.length > 0 ? "" : "The field is required",
                shifts: {
                  ...state.shifts.errors?.shifts,
                  ...shiftErrors
                }
              }
            }
          });
        }
      }
      if (removedShiftIds.length > 0) {
        const updatedShiftTable = Object.fromEntries(
          Object.entries(state.shifts.data.shiftTable).filter(([id]) => !removedShiftIds.includes(Number(id)))
        );
        const updatedShiftErrors = Object.fromEntries(
          Object.entries(state.shifts?.errors?.shifts).filter(([id]) => !removedShiftIds.includes(Number(id)))
        );
        // Update both shifts data and errors in a single dispatch
        dispatch({
          type: "SHIFT_UPDATE_FIELD",
          field: "shifts",
          value: {
            data: {
              ...state.shifts.data,
              shiftTable: updatedShiftTable,
              shifts: selectedValues
            },
            errors: {
              ...state.shifts.errors,
              name: selectedValues.length > 0 ? "" : "The field is required",
              shifts: updatedShiftErrors
            }
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const data = state?.shifts?.data;
  const errors = state.shifts.errors;
  const options = state.options;

  const className = type == 'View' ? "form-border disabled" : "form-border";

  return (
    <>
      <form>
        <div className={className} style={{ paddingTop: "2vw", paddingBottom: "1vw" }}>
          <div className="row">
            <div className="col-sm-12 col-md-12">
              <SelectWithSearch
                title={t("Shifts")}
                name="shifts"
                isMandatory={true}
                search={true}
                options={options?.shifts}
                placeHolder={t("Select")}
                value={data?.shifts}
                onChange={handleSelectChange}
                isMulti={true}
                className="select-field"
                error={errors?.name}
              />
            </div>
            {Array.isArray(data.shifts) &&
              data.shifts.map(
                (selectedOption: any) => (
                  (
                    <ShiftTable
                      key={selectedOption.value}
                      selectedOption={selectedOption}
                    />
                  )
                )
              )}
          </div>
        </div>
      </form>
    </>
  );
};

export default ShiftsForm;
