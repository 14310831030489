import InputTextfield from "components/atoms/InputTextField";
import React, { ChangeEvent, useEffect, useState } from "react";

interface InputTextFieldWithDebounceProps {
    type?: string;
    className?: string;
    placeholder?: string;
    handleChange: (value: string | number) => void;
    value?: string | number;
    name?: string;
    id?: string;
    isDisabled?: boolean;
    customStyle?: React.CSSProperties;
    min?: number;
    step?: number;
    max?: number;
    readOnly?: boolean;
    debounceDelay?: number;
}

export const InputTextFieldWithDebounce: React.FC<InputTextFieldWithDebounceProps> = ({
    type = "text",
    className = "form-control",
    placeholder,
    handleChange,
    value = "",
    name,
    id = "",
    isDisabled,
    customStyle,
    min,
    step,
    max,
    readOnly,
    debounceDelay = 500,
}) => {
    const [inputValue, setInputValue] = useState<string | number>(value);
    const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);
    const [loading, setLoading] = useState<boolean>(false); // State to manage loading

    useEffect(() => {
        setInputValue(value);
    }, [value]);
     
    // Handle input change with debouncing
    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setInputValue(newValue);
        setLoading(true); // Set loading to true when typing starts

        // Clear the previous timeout if the user is still typing
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }

        // Set a new timeout to trigger the API call when typing stops
        const timeout = setTimeout(() => {
            handleChange(newValue);
            setLoading(false); // Set loading to false after the change is handled
        }, debounceDelay);

        setTypingTimeout(timeout);
    };

    return (
        <div style={{ position: "relative" }}>
            <InputTextfield
                id={id}
                type={type}
                className={`${className} form-control field-shadow`}
                value={inputValue}
                isDisabled={isDisabled}
                placeholder={placeholder}
                customStyle={customStyle}
                handleChange={handleInputChange}
                name={name}
                min={min}
                step={step}
                max={max}
                readOnly={readOnly}
            />
            {loading && ( // Show loading spinner based on loading state
                <div style={{ position: "absolute", right: "1vw", top: "50%", transform: "translateY(-50%)" }}>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                </div>
            )}
        </div>
    );
};
