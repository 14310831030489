import React, { useState, useEffect } from "react";
import FormBuilder from "services/form/FormBuilder";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  ADD_CONTRACT_TEMPLATE,
  GET_DROPDOWN_DATA,
  GET_DOCUMENT_MODULE_OPTION,
  GET_TEMPLATE_BY_ID,
} from "routes/ApiEndpoints";
import { ContractValidation } from "services/validation/ValidationRules";
import { PATH_MANAGE_CONTRACT_TEMPLATE } from "constants/Paths";
import { M_MASTER_DATA } from "constants/Constants";
import { ApiCall } from "services/ApiServices";
import { t } from "../translation/Translation";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { Option } from "components/common/CommonInterfaces";
import LabelWithCKEditorField from "components/molecules/LabelWithCKEditorField";
import AccessControl from "services/AccessControl";
import {
  validateForm,
  validateMultiSelectField,
  validateSelectField,
} from "services/validation/ValidationService";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import Button from "components/atoms/Button";
import CustomNotify from "components/atoms/CustomNotify";
import TitleAtom from "components/atoms/Title";
import BackButton from "components/atoms/BackButton";

interface TemplateProps {
  id: string;
  category?: string;
  documentCategory?: Option;
  company?: Option | string | number | null | undefined;
  employementType?: Option;
  scheduleType?: Option;
  body?: string;
  language?: Option;
}
interface TemplateErrors {
  category?: string;
  employementType?: string;
  scheduleType?: string;
  documentCategory?: string;
  company?: string;
  body?: string;
  language?: string;
}

interface ValidationRules {
  [key: string]: Function[];
}
interface ContractDetails {
  [key: string]: string;
}

export default function AddContractsTemplate() {
  const params = useParams();
  const location = useLocation();
  const [isDataReady, setIsDataReady] = useState(false);
  const path = location.pathname;
  const viewMode = location.pathname.includes("view");

  const isCloneMode = location.pathname.includes("clone");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [displayedTokens, setDisplayedTokens] = useState<{
    [key: string]: string;
  }>({});
  const [formData, setFormData] = useState<TemplateProps>({
    id: "",
    category: "",
    company: [],
    documentCategory: [],
    employementType: [],
    scheduleType: [],
    body: "",
    language: [],
  });
  const [optionList, selectedOptionList] = useState<any>({
    documentTypes: [],
    employementType: [],
    scheduleType: [],
    company: [],
    tokens: [],
    documentModulee: [],
    language: [],
  });
  const [id, setId] = useState<number | string | null>(null);
  const navigate = useNavigate();
  const [errors, setErrors] = useState<TemplateErrors>({});

  const extractIdFromUrl = (url: string): number | null => {
    const segments = url.split("/");
    const idSegment = segments.pop();
    const parsedId = idSegment ? parseInt(idSegment, 10) : NaN;
    return isNaN(parsedId) ? null : parsedId;
  };
  useEffect(() => {
    fetchModules().then(() => setIsDataReady(true));
    const idFromUrl = extractIdFromUrl(window.location.href);
    if (idFromUrl !== null) setId(idFromUrl);
  }, []);

  useEffect(() => {
    if (id !== null && isDataReady) {
      fetchTemplateById(id);
    }
  }, [id, isDataReady]);

  const fetchTemplateById = async (id: any) => {
    const url = `${GET_TEMPLATE_BY_ID}/${id}`;
    const response = await ApiCall.getService(url, "GET", M_MASTER_DATA);
    if (response?.status === 200) {
      if (response?.category === 1 || response?.category === 4) {
        setSelectedCategory(response?.category?.toString() || "");
        setFormData((prevData) => ({
          ...prevData,
          category: response.category?.toString() || "",
          documentCategory: response?.documentType,
          company: response?.company,
          employementType: response?.employementType,
          scheduleType: response?.scheduleType,
          body: response?.template,
          language: response?.language,
        }));
      }
      if (response?.documentType.value == 2 && response.documentType) {
        updateDisplayedTokens(response.documentType);
      }
      if (response.documentType.value == 1 && response.documentType) {
        updateDisplayedTokens(response.documentType);
      }
      if (response.documentType.value == 8 && response.documentType) {
        updateDisplayedTokens(response.documentType);
      }
    }
  };

  const fetchModules = async () => {
    const response = await ApiCall.getService(
      GET_DROPDOWN_DATA,
      "GET",
      M_MASTER_DATA
    );
    if (response.status === 200) {
      const data = response.data;

      selectedOptionList(response.data);
    }
  };

  const updateDisplayedTokens = (
    selectedOption: Option,
    category?: string,
    contractName?: string
  ) => {
    const effectiveCategory = selectedOption.value;

    if (effectiveCategory == 8 && selectedOption && selectedOption.label) {
      setDisplayedTokens(optionList.tokens[selectedOption.label] || {});
    } else if (
      effectiveCategory == 2 &&
      selectedOption &&
      selectedOption.label
    ) {
      const selectedOptionName = selectedOption?.label;
      setDisplayedTokens(optionList.tokens[selectedOptionName] || {});
    } else if (
      effectiveCategory == 1 &&
      selectedOption &&
      selectedOption.label
    ) {
      const selectedOptionName = selectedOption?.label;
      setDisplayedTokens(optionList.tokens[selectedOptionName] || {});
    } else {
      setDisplayedTokens({});
    }
  };
  const validateSelectFields = (selectedObject: any) => {
    if (
      !selectedObject ||
      selectedObject.value === "" ||
      selectedObject.value == undefined
    ) {
      return t("This field is required");
    }
    return null;
  };
  const validation = (
    name: string,
    value: Option | string | number | null | undefined,
    isSingleFieldValidation: boolean = false
  ): boolean => {
    // Define validation rules
    const validationRules: ValidationRules = {
      category: [validateSelectField],
      body: [validateCkeditorData],
    };

    if (
      selectedCategory === "1" &&
      formData.documentCategory?.label == "Wage Proposal"
    ) {
      validationRules.company = [validateSelectFields];
      validationRules.documentCategory = [validateSelectFields];
    } else if (
      selectedCategory === "1" &&
      formData.documentCategory?.label == "Contract proposal"
    ) {
      validationRules.company = [validateSelectFields];
      validationRules.documentCategory = [validateSelectFields];
      validationRules.employementType = [validateSelectFields];
      validationRules.scheduleType = [validateSelectFields];
    }

    const validationErrors = validateForm(
      { ...formData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );
    if (isSingleFieldValidation) {
      setErrors((prevErrors: TemplateErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
      return !validationErrors[name];
    } else {
      setErrors(validationErrors);
      return Object.keys(validationErrors).length === 0;
    }
  };

  const validateCkeditorData = (value: string): string | null | undefined => {
    const spaceValueRegex = /^<p>&nbsp;<\/p>$/;
    if (spaceValueRegex.test(value) || !value) {
      return t("This field is required");
    }
    return null;
  };

  const handleCKEditorChange = (event: any, editor: any, fieldName: string) => {
    const content = editor.getData();
    const name = fieldName;
    setFormData((prevData) => ({ ...prevData, [name]: content }));
    validation(name, content, true);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const relevantFields: TemplateProps = {
      category: formData.category,
      body: formData.body,
      language: formData.language,
      id: "",
    };
    // Only set id if not in clone mode
    if (!isCloneMode) {
      if (id !== null) {
        relevantFields.id = id.toString();
      }
    }
    if (
      (selectedCategory === "1" &&
        formData.documentCategory?.label === "Wage proposal") ||
      (selectedCategory === "4" &&
        formData.documentCategory?.label === "Project proposal")
    ) {
      relevantFields.company = formData.company;
      relevantFields.documentCategory = formData.documentCategory;
    } else if (
      selectedCategory === "1" &&
      formData.documentCategory?.label == "Contract proposal"
    ) {
      relevantFields.company = formData.company;
      relevantFields.documentCategory = formData.documentCategory;
      relevantFields.employementType = formData.employementType;
      relevantFields.scheduleType = formData.scheduleType;
    }
    const validationResults = Object.entries(relevantFields).map(
      ([key, value]) => {
        return validation(key as keyof TemplateProps, value, true);
      }
    );
    const isValid = !validationResults.includes(false);
    if (!isValid) {
      return false;
    }
    if (isValid) {
      const response = await ApiCall.service(
        ADD_CONTRACT_TEMPLATE,
        "POST",
        relevantFields,
        false,
        M_MASTER_DATA
      ); //api call to store formdata
      if (response.status === 200) {
        CustomNotify({ type: "success", message: response.message });
        navigate(`${PATH_MANAGE_CONTRACT_TEMPLATE}`);
      } else {
        CustomNotify({ type: "error", message: response.message });
      }
    }
  };

  const handleSelectChange = async (selectedOption: Option, name: string) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: selectedOption,
    }));
    if (name === "category") {
      // Update the selected category and form data
      setSelectedCategory(selectedOption.value?.toString() || "");
      setFormData((prevData) => ({
        ...prevData,
        category: selectedOption.value?.toString() || "",
        company: [],
        documentCategory: [],
        employementType: [],
        scheduleType: [],
        body: "",
      }));
      // Reset tokens and errors
      setDisplayedTokens({});
      setErrors({});
      // Check if the selected value is not empty or undefined
      if (selectedOption.value) {
        const response = await ApiCall.service(
          GET_DOCUMENT_MODULE_OPTION,
          "POST",
          selectedOption,
          false,
          M_MASTER_DATA
        );
        if (response?.status === 200) {
          // Update the state with the response data
          selectedOptionList((prevOptionList: any) => ({
            ...prevOptionList, // Keep the existing state
            documentModulee: response.data, // Update `documentModulee`
          }));
        }
      }
    }
    if (name === "documentCategory") {
      updateDisplayedTokens(selectedOption);
    }
    if (name === "documentCategory") {
      updateDisplayedTokens(selectedOption);
    }
    if (name === "contractType") {
      setFormData((prevData) => ({
        ...prevData,
        documentCategory: [],
      }));
    }

    validation(name, selectedOption, true);
    // if (name === "category") {
    // setSelectedCategory(selectedOption.value?.toString() || "");
    // setFormData((prevData) => ({
    //   ...prevData,
    //   category: selectedOption.value?.toString() || "",
    //   company: [],
    //   documentCategory: [],
    //   employementType: [],
    //   scheduleType: [],
    //   body: "",
    // }));
    // setDisplayedTokens({});
    // setErrors({});
    //  }
    //validation(name, selectedOption, true);
  };
  const handleBack = () => {
    navigate(PATH_MANAGE_CONTRACT_TEMPLATE);
  };

  const userData = useSelector(selectAuth);
  const isCategoryDisabled =
    (viewMode && !isCloneMode) || (!viewMode && isCloneMode);
  return (
    <>
      <AccessControl
        requiredPermissions={[
          {
            permission: "Contract template",
            create: true,
          },
        ]}
        renderNoAccess={true}
        override={userData.isSuperAdmin}
      >
        <>
          <div className="search-bar">
            <div className="row">
              <TitleAtom
                title={
                  viewMode
                    ? t("View template")
                    : isCloneMode
                    ? t("Clone template")
                    : t("Add template")
                }
              />
            </div>
          </div>
          <form>
            <div className="row">
              <div className="col-12">
                <div className="form-border" style={{ paddingBottom: "1vw" }}>
                  <div className="row">
                    <div className="col-3">
                      <SelectWithSearch
                        title={t("Category")}
                        name="category"
                        id="category"
                        isMandatory={true}
                        search={true}
                        options={optionList.documentTypes}
                        placeHolder="Select"
                        value={formData.category}
                        onChange={(e) => handleSelectChange(e, "category")}
                        isMulti={false}
                        className="select-field"
                        error={errors.category}
                        isDisabled={isCategoryDisabled}
                      />
                    </div>
                    <div className="col-3">
                      <SelectWithSearch
                        title={t("Language")}
                        name="language"
                        id="language"
                        isMandatory={true}
                        search={true}
                        options={optionList.language}
                        placeHolder="Select"
                        value={formData.language}
                        onChange={(e) => handleSelectChange(e, "language")}
                        isMulti={false}
                        className="select-field"
                        error={errors?.language}
                        isDisabled={viewMode}
                      />
                    </div>
                    {(formData.category === "1" ||
                      formData.category === "4") && (
                      <>
                        <div className="col-3">
                          <SelectWithSearch
                            title={t("Document category")}
                            name="documentCategory"
                            id="documentCategory"
                            isMandatory={true}
                            search={true}
                            options={optionList.documentModulee}
                            placeHolder="Select"
                            value={formData.documentCategory}
                            onChange={(e) =>
                              handleSelectChange(e, "documentCategory")
                            }
                            isMulti={false}
                            className="select-field"
                            error={errors?.documentCategory}
                            isDisabled={viewMode}
                          />
                        </div>
                      </>
                    )}

                    {(formData.documentCategory?.label === "Wage proposal" ||
                      formData.documentCategory?.label ===
                        "Project proposal") && (
                      <>
                        <div className="col-3">
                          <SelectWithSearch
                            title={t("Company")}
                            name="company"
                            id="company"
                            isMandatory={true}
                            search={true}
                            options={optionList.company}
                            placeHolder="Select"
                            value={formData.company}
                            onChange={(e) => handleSelectChange(e, "company")}
                            isMulti={false}
                            className="select-field"
                            error={errors?.company}
                            isDisabled={viewMode}
                          />
                        </div>
                      </>
                    )}
                    {formData.documentCategory?.label ==
                      "Contract proposal" && (
                      <>
                        <div className="col-3">
                          <SelectWithSearch
                            title={t("Company")}
                            name="company"
                            id="company"
                            isMandatory={true}
                            search={true}
                            options={optionList.company}
                            placeHolder="Select"
                            value={formData.company}
                            onChange={(e) => handleSelectChange(e, "company")}
                            isMulti={false}
                            className="select-field"
                            error={errors?.company}
                            isDisabled={viewMode}
                          />
                        </div>
                        <div className="col-3">
                          <SelectWithSearch
                            title={t("Employement type")}
                            name="employementType"
                            id="employementType"
                            isMandatory={true}
                            search={true}
                            options={optionList.employementType}
                            placeHolder="Select"
                            value={formData.employementType}
                            onChange={(e) =>
                              handleSelectChange(e, "employementType")
                            }
                            isMulti={false}
                            className="select-field"
                            error={errors?.employementType}
                            isDisabled={viewMode}
                          />
                        </div>
                        <div className="col-3">
                          <SelectWithSearch
                            title={t("Schedule type")}
                            name="scheduleType"
                            id="scheduleType"
                            isMandatory={true}
                            search={true}
                            options={optionList.scheduleType}
                            placeHolder="Select"
                            value={formData.scheduleType}
                            onChange={(e) =>
                              handleSelectChange(e, "scheduleType")
                            }
                            isMulti={false}
                            className="select-field"
                            error={errors?.scheduleType}
                            isDisabled={viewMode}
                          />
                        </div>
                      </>
                    )}
                    <div className="col-md-12">
                      <LabelWithCKEditorField
                        label={t("Body")}
                        name="body"
                        value={formData.body}
                        placeholder={t("Type here...")}
                        handleChange={(event, editor) =>
                          handleCKEditorChange(event, editor, "body")
                        }
                        isMandatory={true}
                        error={errors?.body}
                        isDisabled={viewMode}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row" style={{ padding: "0.85vw 0" }}>
              <div className="col-6 align-self-center">
                <BackButton />
              </div>
              {!viewMode && (
                <div className="col-6 text-end">
                  <Button
                    title={t("Save")}
                    handleClick={handleSubmit}
                    className="form-button float-end"
                  />
                </div>
              )}
            </div>
          </form>
          <div className="row">
            <div className="col-12">
              <div className="table-responsive tableSection p-0 border-0 rounded-0">
                <div
                  style={{ maxHeight: "calc(100vh - 30vw)", overflow: "auto" }}
                >
                  <table className="table table-hover">
                    <thead>
                      <tr className="TableHeader">
                        <th style={{ width: "50%" }}>{t("Name")}</th>
                        <th>{t("Value")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(displayedTokens).length > 0 ? (
                        Object.entries(displayedTokens).map(
                          ([token, description]) => (
                            <tr key={token}>
                              <td>{token}</td>
                              <td>{description}</td>
                            </tr>
                          )
                        )
                      ) : (
                        <tr>
                          <td className="text-center py-3" colSpan={2}>
                            <span className="text-danger">
                              {t("No tokens")}
                            </span>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      </AccessControl>
    </>
  );
}
