import { t } from 'pages/microservices/masterData/translation/Translation'
import React from 'react'

const MatchingInfoModal = () => {
    return (
        <>
            <table className='table'>
                <thead>
                    <tr className='TableHeader'>
                        <th className='w-50'>{t("Type")}</th>
                        <th className='w-50'>{t("Weight")}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{t("Margin")}</td>
                        <td>40%</td>
                    </tr>
                    <tr>
                        <td>{t("Competence")}</td>
                        <td>30%</td>
                    </tr>
                    <tr>
                        <td>{t("Functions")}</td>
                        <td>20%</td>
                    </tr>
                    <tr>
                        <td>{t("Location and radius")}</td>
                        <td>10%</td>
                    </tr>
                </tbody>
            </table>
        </>
    )
}

export default MatchingInfoModal