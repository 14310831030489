import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { RANGE_VALUE, RANGE_VALUE_FOR_GENERAL } from "constants/Constants";
import HelpInfoIcon from "static/images/HelpIcon";
import { t } from "pages/microservices/masterData/translation/Translation";
import LabelWithTextAreaField from "./LabelWithTextAreaField";
import { useParams } from "react-router-dom";
import CustomRangeSlider from "components/atoms/CustomRangeSlider";

interface Option {
  label: string;
  value: string;
  range: string;
}

interface CompetenceProps {
  competenceOptions?: any;
  data?: any;
  handleChange?: any;
  setFormData?: any;
  isViewmode?: boolean;
}
const CompetenceMolecule: React.FC<CompetenceProps> = ({
  competenceOptions,
  data,
  setFormData,
  isViewmode,
}) => {

  const handleSelect = (selectedId: number, section: string, selected: Option[]) => {
    const selects = selected.map((eachItem: any) => {
      return { ...eachItem }
    });

    const payload = { selection: section, selected: selected.length > 0 ? selects : [], id: selectedId };

    setFormData((prevData: any) => {
      return {
        ...prevData,
        competence:
          competenceSelected(payload)
      }
    });
  };


  const competenceSelected = (payload: any) => {
    const selection = payload.selection;
    const selectedData = payload.selected;
    const selectedId = payload.id;
    let updatedForm: any = [...data];
    if (selectedData.length === 0) {
      // Remove the item if selectedData is empty
      updatedForm = updatedForm.filter((item: { key: any }) => item.key !== selection);
    } else {
      const existingSelection = updatedForm.find((item: { key: any }) => item.key === selection);
      if (existingSelection) {
        // Update existing selection options
        const filteredData = selectedData.map((eachItem: any) => {
          const optionData = existingSelection.options.find((eachIt: any) => (eachIt.value == eachItem.value))
          if (optionData) {
            return { ...eachItem, range: optionData.range };
          }
          return eachItem;
        });
        existingSelection.options = filteredData;
      } else {
        // Add new selection
        const newSelection = {
          competence_id: selectedId,
          key: selection,
          label: selection,
          options: selectedData,
          additionalInfo: '',
        };
        updatedForm.push(newSelection);
      }
    }
    // Return  updated competence array
    return updatedForm;
  };

  // Reducer function to handle COMPETENCE_RANGE action
  const competenceRange = (payload: any) => {
    const { value, section, index } = payload;
    let updatedForm: any = [...data];
    updatedForm.forEach((item: any) => {
      if (item.key === section && item.options[index]) {
        item.options[index].range = value; // Update range
      }
    });
    // Return updated competence array
    return updatedForm;
  };

  //Function to update input text fields in competence
  const competenceInput = (payload: any) => {
    const { value, section } = payload;

    let currentState: any = [...data];

    currentState?.forEach((eachItem: any) => {
      if (eachItem?.key?.toLowerCase() == section.toLowerCase()) {
        eachItem.additionalInfo = value;
      }
    });
    return currentState;
  }




  const getTitleForRating = (range: string) => {
    switch (parseInt(range)) {
      case 1:
        return "A1";
      case 2:
        return "A2";
      case 3:
        return "B1";
      case 4:
        return "B2";
      case 5:
        return "C1";
      case 6:
        return "C2";
      default:
        return "A1";
    }
  };

  const handleChange = (
    e: any,                                    //React.ChangeEvent<HTMLInputElement>,
    section: string,
    index?: number
  ) => {

    const payload = {
      value: e.target.value,
      section: section,
      index: index
    }

    setFormData((prevData: any) => {

      const resultantData = index == undefined ? competenceInput(payload) : competenceRange(payload)

      return {
        ...prevData,
        competence:
          resultantData
      }
    });
  };

  const activeKeys = data?.filter((section: any) => section?.options?.length > 0).map((section: any) => section?.key);

  const getInfoValue = (section: string) => {
    const infoValue = data?.filter((item: any) => item.key.toLowerCase() == section.toLowerCase());
    return infoValue.length > 0 ? infoValue[0]?.additionalInfo : '';
  }

  const mode = isViewmode ? true : false;

  return (
    <div className="vacancyEmployeementTab h-100">
      <div className="form-border h-100">
        <div className="form">
          <Accordion defaultActiveKey={activeKeys ?? []} alwaysOpen>
            {competenceOptions && competenceOptions.map((section: any) => {
              return (<Accordion.Item key={section?.key} eventKey={section?.key}>
                <Accordion.Header>{section?.label}</Accordion.Header>
                <Accordion.Body>
                  <div className="hideHeight marginBottomPoint5">
                    <SelectWithSearch
                      name={section.key}
                      isMandatory={false}
                      search={true}
                      placeHolder="Select"
                      options={section.options}
                      value={data?.filter((item: any) => item.key == section.key)?.[0]?.options?.map((item: any) => item?.value)}
                      onChange={(selected) => handleSelect(section.competence_id, section.key, selected)}
                      isMulti={true}
                      className="select-field"
                      error={""}
                      isDisabled={mode}
                    />
                  </div>
                  {data?.length > 0 &&
                    data.find((item: any) => item.key === section.key)?.options && (
                      <div className="row marginBottomPointTwo5">
                        <div
                          className="text-end"
                          data-bs-toggle="tooltip"
                          data-bs-placement="left"
                          title={t(section?.info)}
                        >
                          {/* `A. Basic user (A1/A2: beginner)\nB. Independent user (B1/B2: advanced)\nC. Skilled user (C1/C2: advanced)` */}
                          <HelpInfoIcon />
                        </div>
                      </div>
                    )}
                  <div className="d-grid competenceGrid">
                    {data?.length > 0 && data?.find((item: any) => item.key === section.key)?.options?.map((option: any, index: any) => {
                      return (
                        <div key={index} className="marginBottomPoint5">
                          <div className="marginBottomPoint5 fw-bolder">{option?.label}</div>
                          <div className="w-100">
                            <CustomRangeSlider
                              min={1}
                              max={section?.maxRange ?? "6"}
                              title={getTitleForRating(option?.range === "0" ? "1" : option?.range)}
                              value={option?.range === "0" ? "1" : option?.range}
                              className="w-100"
                              handleChange={(e) => handleChange(e, section.key, index)}
                            />
                            <div className="d-flex justify-content-between">
                              {(section?.maxRange == 4 ? RANGE_VALUE_FOR_GENERAL : RANGE_VALUE).map((value, index) => (
                                <span key={value}>{value}</span>
                              ))}
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                  <div className="hideHeight">
                    <LabelWithTextAreaField
                      label={t("Additional info")}
                      name={"additionalInfo"}
                      value={getInfoValue(section?.key)}
                      isDisabled={mode}
                      isMandatory={false}
                      handleChange={(e) => { handleChange(e, section.key) }}
                    />
                  </div>
                </Accordion.Body>
              </Accordion.Item>)
            })}
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default CompetenceMolecule;
