import { faPlus } from "@fortawesome/free-solid-svg-icons";
import LinkTo from "components/atoms/LinkTo";
import StatusComponent from "components/atoms/ManageStatus";
import SortAtomForManagePage from "components/atoms/SortAtomForManagePage";
import PaginationWithPerPage from "components/molecules/PaginationWithPerPage";
import FilterOrganism from "components/organism/FilterOrganism";
import { M_MASTER_DATA } from "constants/Constants";
import { PATH_MANAGE_TIMESHEET_OVERVIEW } from "constants/Paths";
import { t } from "pages/microservices/masterData/translation/Translation";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ApiCall } from "services/ApiServices";
import { addPageToHistory, selectPageHistory, updatePageFilters } from "store/pageHistory/pageHistorySlice";
import { TimesheetsTableHeader } from "TableHeader";
import Title from "components/atoms/Title";
import { CREATE_TIMESHEET, MANAGE_TIMEHSHEET_OVERVIEW } from "routes/ApiEndpoints";
import { checkForPermission, ROLES } from "../constants/timesheetConstants";
import { PAGENAMES } from "store/pageHistory/ConstantPageNames";
import Popup from "components/molecules/Popup";
import CheckBoxField from "components/atoms/CheckBoxField";
import CustomNotify from "components/atoms/CustomNotify";
import Button from "components/atoms/Button";
import Calender from "components/molecules/Calender";
import CommonServices from "services/CommonService";

interface Props {
  type: string;
  user: any;
}

const ManageTimesheetOverviewOrganism: React.FC<Props> = ({ type, user }) => {
  const navigate = useNavigate();
  const pageHistory = useSelector(selectPageHistory);
  const history = pageHistory?.history?.[pageHistory?.history?.length - 1] ?? {}
  const dispatch = useDispatch();
  const [pageData, setPageData] = useState<any>({
    totalPages: 0,
    totalRecords: 0,
    refresh: true,
    initialCall: true,
    data: [],
    options: {
      businessUnits: [],
      status: [
        { value: 1, label: "To be approved" },
        { value: 2, label: "To be invoiced" },
        { value: 3, label: "Approved" },
      ],
    },
    selected: [],
    selectAll: false,
    invoicepopup: {
      show: false,
      data: {},
    },
  });

  const getSearchData = () => {
    return {
      businessUnits: history?.filters?.businessUnits ?? [],
      week: history?.filters?.week ?? "",
      month: history?.filters?.month ?? "",
      projectName: history?.filters?.projectName ?? "",
      employeeName: history?.filters?.employeeName ?? "",
      week_number: history?.filters?.week_number ?? "",
      invoice_no: history?.filters?.invoice_no ?? "",
      status: history?.filters?.status ?? "",
      orderBy: history?.filters?.orderBy ?? "start_time",
      sortDirection: history?.filters?.sortDirection ?? "desc",
      role: history?.filters?.role ?? []
    }
  };

  useEffect(() => {
    const postData = {
      currentPage: Number(history?.filters?.currentPage ?? 1),
      itemsPerPage: Number(history?.filters?.itemsPerPage ?? 10),
      search: getSearchData(),
      initialCall: pageData?.initialCall,
      user_id: user?.userId,
      type,
    };
    fetchTimesheets(postData);
  }, [pageData?.refresh, pageData?.initialCall]);

  const fetchTimesheets = async (postData: any) => {
    const response = await ApiCall.service(MANAGE_TIMEHSHEET_OVERVIEW, "POST", postData, false, M_MASTER_DATA);
    if (response?.status === 200) {
      let data: any = {
        ...pageData,
        data: response?.data,
        totalPages: response?.totalPages,
        totalRecords: response?.totalRecords,
        initialCall: false,
        selected: pageData?.selectAll ? response?.data?.filter((item: any) => item?.status < 5) : [],
      }
      if (pageData?.initialCall) {
        data.options = { ...data?.options, ...(response?.options ?? []) }
      }
      setPageData(data);
    }
  };

  const handleClick = (item: any) => {    
    dispatch(addPageToHistory({
      pageName: PAGENAMES.MANAGE_TIMESHEET, route: '/timesheet/overview',
      filters: { project_id: item?.project_id, employee_id: item?.employee_id, currentDate: item?.start_time }
    }));
    // Navigate to the timesheet overview page with the calculated start of the week date
    navigate(PATH_MANAGE_TIMESHEET_OVERVIEW);
  };

  const handleRefresh = () => setPageData((prev: any) => ({ ...prev, refresh: !pageData?.refresh }));

  const filters = [
    { name: 'dummy', fieldType: "", placeholder: "", filterType: 'search' },
    { name: 'businessUnits', fieldType: "multiSelect", options: pageData?.options?.businessUnits, placeholder: "Business unit", filterType: 'search' },
    { name: 'month', fieldType: "text", placeholder: "Month", filterType: 'search' },
    { name: 'week', fieldType: "text", placeholder: "Week", filterType: 'search' },
    { name: 'projectName', fieldType: "text", placeholder: "Project name", filterType: 'search' },
    { name: 'employeeName', fieldType: "text", placeholder: "Employee name", filterType: 'search' },
    { name: 'week_number', fieldType: "text", placeholder: "Worksheet number", filterType: 'search' },
    { name: 'invoice_no', fieldType: "text", placeholder: "Invoice number", filterType: 'search' },
    { name: 'status', fieldType: "singleSelect", options: pageData?.options?.status, placeholder: "Status", filterType: 'search' },
  ];
  
  const getTitleOrClass = (item: any, title = false) => {
    let string = "";
    if (item?.status < 3) {
      string = title ? "To be approved" : "table_left_side_color_to_be_approved";
    } else if (item?.status === 4) {
      string = title ? "To be invoiced" : "table_left_side_color_to_be_invoiced";
    } else if (item?.status === 5) {
      string = title ? "Approved" : "";
    } 
    return string;
  }

  const handleSelectCheckbox = (e: any, invoice?: any) => {
    let value = e?.target?.checked ?? false;
    let selected: any = [...pageData?.selected];
    let selectAll = pageData?.selectAll;
      
    if (!invoice) {
        selected = value ? pageData?.data?.filter((item: any) => item?.status < 5) : [];            
        selectAll = value;
    } else {
        if (value) {
            selected?.push(invoice);
            selectAll = selected?.length === pageData?.data?.filter((item: any) => item?.status < 5)?.length;
        } else {
            selected = selected?.filter((item: any) => invoice?.week_number != item?.week_number || invoice?.timesheet_id != item?.timesheet_id);            
            selectAll = false;
        }
    }
    setPageData((prev: any) => ({ ...prev, selectAll, selected }))
  }

  const isChecked = (record: any) => pageData?.selected?.some((item: any) => record?.week_number == item?.week_number && record?.timesheet_id == item?.timesheet_id);

  const withinSameMonthAndBu = () => new Set(pageData?.selected?.map((item: any) => `${item?.bu_id}-${item?.month}`)).size === 1;

  const handlePopup = () => {
    if (pageData?.selected?.length === 0) {
        CustomNotify({ type: 'error', 'message': "No work orders have been selected"});
    } else if (!withinSameMonthAndBu()) {
        CustomNotify({ type: 'error', 'message': "Only work orders within the same office and same month can be invoiced"}); 
    } else {      
      let date = pageData?.selected?.[0]?.last_invoice_date ? new Date(pageData?.selected?.[0]?.last_invoice_date) : new Date();
      setPageData((prev: any) => ({ ...prev, invoicepopup: { show: true, data: { date, last_invoice_date: date}}}));
    }
  }

  const handleAddPayment = async () => {
    try {
        const postData = {
          timesheet: pageData?.selected?.map((item: any) => {
              return { weeknumber: item?.week_number, timesheetid: item?.timesheet_id, has_performance_or_reimbursement: item?.has_performance_or_reimbursement }
          }),
          bulkInvoice: true,
          invoice_date: CommonServices?.getTimezoneOffset(pageData?.invoicepopup?.data?.date ?? new Date())
        }
        const response = await ApiCall.service(CREATE_TIMESHEET, 'POST', postData, true, M_MASTER_DATA);
        if (response?.status === 200) {
          CustomNotify({ type: 'success', 'message': "Timesheet invoiced successfully" });
          setPageData((prev: any) => ({ ...prev, selectAll: false, selected: [], refresh: !pageData?.refresh, invoicepopup: { show: false, data: {}}}));
        }
    } catch (error) {
        console.log(error);  
    }
  }

  return (
    <>
      <div className="search-bar">
        <div className="row">
          <Title title={t("Timesheets")} />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="position-relative tableMainWrapper">
            <div className="row">
              <div className="ManageCreateBtn">
                <LinkTo
                  pagelink={PATH_MANAGE_TIMESHEET_OVERVIEW}
                  title={t("Create timesheet")}
                  icon={faPlus}
                />
                <Button
                  title={t("Invoice selected")}
                  className="form-button ms-3"
                  handleClick={() => handlePopup()}
                />
              </div>
            </div>
            <div className="table-responsive Manage-timesheet tableSection">
              <FilterOrganism
                dispatch={dispatch}
                history={history}
                updatePageFilters={updatePageFilters}
                filters={filters?.filter((item: any) => !checkForPermission(type) ? !['dummy', 'week_number', 'invoice_no', 'status']?.includes?.(item?.name) : true)}
                handleRefresh={handleRefresh}
              />
              <div className="TableHeight">
                <table className="table table-hover">
                  <thead>
                    <tr className="TableHeader">
                      {checkForPermission(type) && pageData?.data && pageData?.data?.length > 0 && <th key={"select_all"} className="border-bottom-0" style={{ width: '5%' }} >
                        <CheckBoxField
                          name="select_all"
                          isChecked={pageData?.selectAll ?? false}
                          onChangeHandler={(e) => {
                              e.stopPropagation();
                              handleSelectCheckbox(e);
                          }}
                        />
                      </th>}
                      {TimesheetsTableHeader?.filter((item: any) => !checkForPermission(type) ? !['week_number', 'invoice_no', 'approved', 'invoiced']?.includes?.(item?.alias) : true)
                      ?.map((header: any) => (
                        <th key={header?.alias}>
                          <>
                            {t(header.name)}
                            {header?.issort && (
                              <SortAtomForManagePage
                                value={{ alias: header?.alias }}
                                dispatch={dispatch}
                                history={history}
                                updatePageFilters={updatePageFilters}
                                handleRefresh={handleRefresh}
                              />
                            )}
                          </>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {pageData?.data?.length > 0 ? (
                      pageData?.data?.map((item: any, index: any) => (
                        <tr key={index}
                          onClick={() => handleClick(item)}
                          title={getTitleOrClass(item, true)}
                          className={checkForPermission(type) ? ("cursor-pointer " + getTitleOrClass(item)) : ""}
                        >
                          {checkForPermission(type) && <td key={("select" + item?.id)} className="border-bottom-0" style={{ width: '5%' }} >
                            <CheckBoxField
                              name={("checkbox" + item?.id)}
                              isChecked={isChecked(item)}
                              disable={item?.status > 4}
                              isClicked={(e) => {
                                  e.stopPropagation();
                                  handleSelectCheckbox(e, item)
                              }}
                            />
                          </td>}
                          <td>{item?.businessUnits}</td>
                          <td>{item?.month}</td>
                          <td>{item?.week}</td>
                          <td>{item?.projectName}</td>
                          <td>{item?.employeeName}</td>
                          {checkForPermission(type) && (<>
                            <td>{item?.week_number}</td>
                            <td>{item?.invoice_no}</td>
                            <td><StatusComponent initialStatus={item?.status > 3 ? 1 : 2} tooltip={item?.week_number ? item?.week_number : ""} /></td>
                            <td><StatusComponent initialStatus={item?.status > 4 ? 1 : 2} tooltip={item?.invoice_no ? item?.invoice_no : ""} /></td>
                          </>)}
                        </tr>
                      ))
                    ) : (
                      <tr className="border">
                        <td colSpan={10} className="border-0 text-center py-3">
                          <span className="text-danger fw-bold">{t("No records")}</span>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div>
                <PaginationWithPerPage
                  handleRefresh={handleRefresh}
                  dispatch={dispatch}
                  history={history}
                  pageData={pageData}
                />
              </div>
            </div>
          </div>
          {pageData?.invoicepopup?.show && <Popup
            title={t("Invoice")}
            body={<>
                <div>
                  <Calender
                    placeHolder="dd-mm-yyyy"
                    onChange={(date: any) => setPageData((prev: any) => ({...prev, invoicepopup: { ...prev?.invoicepopup, data: {...prev?.invoicepopup?.data, date }}}))}
                    label={t("Invoice date") + ":"}
                    name="invoiceDate"
                    selectedDate={pageData?.invoicepopup?.data?.date ?? null}
                  />
                </div>
                <div>{t("Last invoice date")}: 
                  {pageData?.invoicepopup?.data?.last_invoice_date 
                    ? new Date(pageData?.invoicepopup?.data?.last_invoice_date)?.toLocaleDateString('en-GB') 
                    : ''
                  }
                </div>
            </>}
            yestext={t("Ok")}
            notext={t("Cancel")}
            submit={() => handleAddPayment()}
            cancel={() => setPageData((prev: any) => ({...prev, invoicepopup: {show: false, data: {}}}))}
          />}
        </div>
      </div>
    </>
  );
};

export default ManageTimesheetOverviewOrganism;
