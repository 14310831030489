import Button from 'components/atoms/Button';
import ErrorMessage from 'components/atoms/ErrorMessage';
import InputTextfield from 'components/atoms/InputTextField';
import React, { useEffect, useState } from 'react';
import { t } from './Translation';
interface Props {
    oldstring: string;
    handleSave: (value: any) => void;
    handleCancel: () => void;
}

const TranslationEditPopup: React.FC<Props> = ({ oldstring, handleSave, handleCancel }) => {

    const [state, setState] = useState({
        string: oldstring,
        error: ""
    });

    const handleSubmit = () => {
        let error = "";
        if ((!state?.string || state?.string == "")) {
            error = 'The field is required';
        } else if (state?.string?.length > 255) {
            error = 'The field length exceeds 255 characters';
        }
        if (error) {
            setState((prev) => ({ ...prev, error: error }));
        } else {
            handleSave(state?.string);
        }
    }
    return (
        <div>
            <InputTextfield
                name='string'
                value={state?.string}
                className={`border rounded text-gray w-100 px-3 form-control ${state?.error ? "border-danger" : ""}`}
                handleChange={(e) => setState((prev) => ({ ...prev, string: e?.target?.value }))}
            />
            <ErrorMessage
                errorMessage={state?.error}
            />
            <div className="d-flex justify-content-between mt-2">
                <Button
                    title={t("Cancel")}
                    handleClick={handleCancel}
                    className='modal-cancel-btn'
                />
                <Button
                    title={t("Save")}
                    handleClick={handleSubmit}
                    className='form-button'
                />
            </div>
        </div>
    )
}

export default TranslationEditPopup