import React, { useEffect, useState } from "react";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import PhoneInput from "react-phone-number-input";
import LabelField from "components/atoms/LabelField";
import Button from "components/atoms/Button";
import { ApiCall } from "services/ApiServices";
import CustomNotify from "components/atoms/CustomNotify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  validateForm,
  validateEmail,
  validateRequired,
  validateTextFieldAlpha,
  validatePhoneNumber,
  validateMultiSelectField,
  validateRadioField,
} from "services/validation/ValidationService";
import LoadingIcon from "utils/LoadingIcon";
import { GET_USER, REGISTER, UPDATE_USER } from "routes/ApiEndpoints";
import { t } from "pages/microservices/masterData/translation/Translation";
import { E164Number } from "libphonenumber-js";
import TitleAtom from "components/atoms/Title";
import BackButton from "components/atoms/BackButton";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { Option, ValidationRules } from "utils/TypeAnnotations";
import { M_IDENTITY_MANAGER } from "constants/Constants";
import RadioField from "components/atoms/RadioField";

interface CreateUserFormProps {
  id?: string | undefined;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string | E164Number;
  roles?: Option[];
  businessUnit?: Option[];
  status?: number;
}

interface DropdownData {
  roleList: Option[];
  businessUnitList: Option[];
}

const CreateUserPage: React.FC<CreateUserFormProps> = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const isEdit = location?.pathname?.includes('edit');
  const isView = location?.pathname?.includes('view');
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [loading, setLoading] = useState(false);
  let [formData, setFormData] = useState<CreateUserFormProps>({
    id: params.id,
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "" as E164Number,
    roles: [],
    businessUnit: [],
    status: 1,
  });
  const [dropdownData, setDropdownData] = useState<DropdownData>({
    roleList: [],
    businessUnitList: []
  });

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    const response = await ApiCall.service(GET_USER, "POST", formData, false, M_IDENTITY_MANAGER);
    if (response?.status === 200) {
      setDropdownData(response?.dropdownData);
      if (isEdit || isView) {
        const editData = response?.data;
        setFormData({
          firstName: editData?.firstName,
          lastName: editData?.lastName,
          email: editData?.email,
          phoneNumber: editData?.phoneNumber,
          businessUnit: editData?.businessUnit,
          roles: editData?.roles,
          status: editData?.status ?? 1
        });
      }
    }

  }

  const validation = (
    name: string,
    value: string | boolean | Option | E164Number | undefined,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      firstName: [validateRequired, validateTextFieldAlpha],
      lastName: [validateRequired, validateTextFieldAlpha],
      email: [validateRequired, validateEmail],
      phoneNumber: [validatePhoneNumber],
      roles: [validateMultiSelectField],
      businessUnit: [validateMultiSelectField],
      status: [validateRadioField]
    };

    const validationErrors = validateForm(
      { ...formData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation && Object.keys(errors).length > 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }

    return true;
  };

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type } = event.target as HTMLInputElement;

    if (type === "radio") {
      // Update the status based on the selected radio button
      setFormData((prevData) => ({
        ...prevData,
        status: value === "1" ? 1 : 0,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }

    validation(name, value, true);
  };


  const handlePhoneNumberChange = (e: string | E164Number | undefined) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      phoneNumber: e,
    }));
    validation("phoneNumber", e, true);
  };

  const handleSelectChange = (selectedOption: Option, fieldName: string) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: selectedOption,
    }));
    validation(fieldName, selectedOption, true);
  }

  const handleSubmit = async (e: React.FormEvent) => {
    setLoading(true);
    e.preventDefault();
    const { name, value } = e.target as HTMLInputElement;
    if (validation(name, value)) {
      if (isEdit) {
        const postData = {
          ...formData,
          id: params.id
        }
        const response = await ApiCall.service(UPDATE_USER, "POST", postData, false, M_IDENTITY_MANAGER);
        if (response.status === 200) {
          CustomNotify({ type: "success", message: response?.message });
          navigate("/manage-users");
        }
      } else {
        const response = await ApiCall.service(REGISTER, "POST", formData, false, M_IDENTITY_MANAGER);
        if (response.status === 200) {
          CustomNotify({ type: "success", message: response?.message });
          navigate("/manage-users");
        } else if (response.status === 400) {
          setErrors({
            email: response.errors.email,
            phoneNumber: response.errors.phoneNumber,
          });
        }
      }
    }
    setLoading(false);
  };

  return (
    <div style={isView ? { pointerEvents: 'none' } : {}}>
      <TitleAtom title={t("Create user")} />
      <div className="form-border">
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <LabelWithInputField
              isMandatory={true}
              name="firstName"
              handleChange={changeHandler}
              value={formData.firstName}
              id="fname"
              label={t("First name")}
              placeholder={"First name"}
              error={errors.firstName}
            />
          </div>
          <div className="col-sm-12 col-md-6">
            <LabelWithInputField
              isMandatory={true}
              name="lastName"
              handleChange={changeHandler}
              value={formData.lastName}
              id="lname"
              label={t("Last name")}
              placeholder={t("Last name")}
              error={errors.lastName}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <LabelWithInputField
              isMandatory={true}
              name="email"
              handleChange={changeHandler}
              value={formData.email}
              id="email"
              label={t("Email")}
              placeholder={t("Email")}
              type="email"
              error={errors.email}
            />
          </div>
          <div className="col">
            <LabelField
              title={t("Phone number")}
              isMandatory={false}
              key="PhoneInput"
            />
            <div className="form-control field-shadow">
              <PhoneInput
                defaultCountry="BE"
                international
                placeholder="Enter phone number"
                onChange={(mobileNumber) =>
                  handlePhoneNumberChange(mobileNumber)
                }
                value={formData?.phoneNumber}
              />
            </div>
            <div>
              {errors?.phoneNumber &&
                <span className="text-danger">{errors?.phoneNumber}</span>}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <SelectWithSearch
              title={t("Role")}
              isMandatory={true}
              search={true}
              options={dropdownData?.roleList}
              onChange={(e) => handleSelectChange(e, "roles")}
              isMulti={true}
              name="roles"
              value={formData?.roles}
              error={errors?.roles}
              placeHolder="Select"
            />
          </div>
          <div className="col">
            <SelectWithSearch
              title={t("Business unit")}
              isMandatory={true}
              search={true}
              options={dropdownData?.businessUnitList}
              onChange={(e) => handleSelectChange(e, "businessUnit")}
              isMulti={true}
              name="businessUnit"
              value={formData?.businessUnit}
              error={errors?.businessUnit}
              placeHolder="Select"
            />
          </div>
        </div>
        {(isEdit || isView) && (
          <>
            <LabelField title={t("Status")} isMandatory={true} key="status" />
            <div className="row">
              <div className="col">
                <RadioField
                  name="status"
                  value={1}  // You don't need conditional value here, just use 1 for active
                  ischecked={formData.status === 1}
                  handleChange={changeHandler}
                  label={t("Active")}
                  className="mx-3"
                  {...(formData.status === 0 || formData.status === undefined ? { disabled: true } : {})}
                />

                <RadioField
                  name="status"
                  value={0}  // You don't need conditional value here, just use 0 for inactive
                  ischecked={formData.status === 0}
                  handleChange={changeHandler}
                  label={t("Inactive")}
                  className="mx-3"
                  {...(formData.status === 1 ? { disabled: true } : {})}
                />
              </div>
            </div>
          </>
        )}

      </div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="align-self-center" style={isView ? { pointerEvents: 'auto' } : {}}>
          <BackButton />
        </div>
        <div className="text-end">
          {!loading  ? (
            <Button
              title={t("Save")}
              type="submit"
              className="btn form-button rounded shadow-none text-uppercase"
              handleClick={handleSubmit}
            />
          ) : (
            <LoadingIcon
              iconType="bars"
              color="#00a5ce"
              className="float-end"
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default CreateUserPage;
