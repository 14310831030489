import { SpecialAgreement } from "./Constants";

export const initialState = {
    tabs: [
        {
            id: "general",
            title: "General",
            showStatus: true,
            error: false,
            draft: false,
        },
        {
            id: "coefficient",
            title: "Coefficients",
            showStatus: true,
            error: false,
            draft: false,
        },
        {
            id: "specialAgreements",
            title: "Special agreements",
            showStatus: true,
            error: false,
            draft: false,
        },
        {
            id: "info",
            title: "Info",
            showStatus: true,
            error: false,
            draft: false,
        },
    ],
    tabErrors: [],
    currentTab: "general",
    fetchFunction: true,
    general: {
        data: {
            id: null,
            status: false,
            company: "",
            consultant: "",
            businessUnit: "",
            startDate: null,
            contactPerson: [],
            type: "coefficientBased",
            showCreateContactForm: false,
            whiteCollarPcs: {
                data: {
                    id: null,
                    pc: "",
                    employeeType: ""
                },
                editIndex: null,
                pcsList: [],
            },
            blueCollarPcs: {
                data:
                {
                    id: null,
                    pc: "",
                    employeeType: ""
                },
                editIndex: null,
                pcsList: []
            },
        },
        errors: {
            company: "",
            consultant: "",
            businessUnit: "",
            startDate: null,
            contactPerson: [],
            whiteCollarPcs: {
                errors: {
                    pc: "",
                    employeeType: ""
                },
            },
            blueCollarPcs: {
                errors:
                {
                    pc: "",
                    employeeType: ""
                },
            },
        }
    },
    specialAgreement: [],
    info: {
        data: {
            noOfDays: '',
            billingType: '',
            settlementType: '',
            comments: "",
            accountNo: "",
            mandateReference: "",
            type: "",
            date: "",
            selectionForEmpOrWorker: false,
            invoiceForLocation: false,
            selectionForEmployee: false,
            invoiceForPayroll: false,
            subTotals: false,
            totalHours: false,
            mergeDetails: false,
            lineOfHoursWorked: false,
            functionOfContract: false,
            paymentByDirectDebit: false,
        },
        errors: {
            noOfDays: '',
            billingType: '',
        }

    },
    coefficient: [],
    contactPersons: {
        data: {
            tittle: null,
            userId: null,
            fName: "",
            lName: "",
            email: "",
            company: null,
            mobNumber: "",
            language: null,
            functionData: "",
            linkedIn: "",
            roles: [],
            dob: null,
            locations: [],
            businessUnit: [],
            info: "",
        },
        errors: {
            fName: "",
            lName: "",
            email: "",
            mobNumber: "",
        }
    },
    options: {
        companies: [],
        businessUnits: [],
        consultants: [],
        consultantBusinessUnits: [],
        locations: [],
        whiteCollarPcs: [],
        blueCollarPcs: [],
        contactPersons: [],
        benefit_type: [],
        benefit_value_type: [],
        defaultCoefficients: [],
        paymentTerms: [],
    }
}

// {
//     pcName: "",
//     funEmpType: [
//         {
//             function: '',
//             empTypeData: [
//                 {
//                     employeeType: "",
//                     level: "",
//                     startDate: null,
//                     coefficient: [{
//                         selection: "",
//                         selectionReduced: "",
//                         payroll: "",
//                         selectionCent: ""
//                     }]
//                 }
//             ]
//         }
//     ]
// }