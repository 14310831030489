import LabelField from 'components/atoms/LabelField';
import { t } from 'pages/microservices/masterData/translation/Translation';
import React, { useState } from 'react';
import { useFormContext } from '../../context/Context';
import { initialAgeList } from '../../helpers/PCHelperFunctions';
import DeleteIcon from 'static/images/DeleteIcon';
import EditIcon from 'static/images/EditIcon';
import { ADD_AGE_DATA, REMOVE_AGE_DATA } from '../../annotations/PCConstants';
import Button from 'components/atoms/Button';
import { age } from '../../annotations/PCInterface';
import ModalPopup from 'components/atoms/ModalPopup';
import { LabelWithInputField } from 'components/molecules/LabelWithInputField';
import { LabelWithInputFieldAndUnit } from 'pages/microservices/masterData/templates/atoms/LabelWithInputFieldAndUnit';
import { ValidationRules } from 'utils/TypeAnnotations';
import { validateForm, validatePercentage, validateRequired, validateTimeFormat } from 'services/validation/ValidationService';
import { Navigation } from '../../form-navigation/Navigation';

export const Age = () => {
  const { state, dispatch } = useFormContext();
  const [modalPopup, setModalPopup] = useState(false);
  const [index, setIndex] = useState(0);
  const intialErrors: { [key: string]: string } = {};
  const [errors, setErrors] = useState<{
    [key: string]: string;
  }>(intialErrors);
  const [ageData, SetAgeData] = useState<age>(initialAgeList);

  const validation = (
    name: string,
    value: string | number | null | boolean | Date | object[] | undefined,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      age: [validateRequired],
      minSalary: [validatePercentage],
      endTime: [validateRequired, validateTimeFormat],
    };
    const validationErrors = validateForm(
      { ...ageData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };


  const editData = (index: number) => {
    setIndex(index); // Set the index for dispatch
    setModalPopup(true);
    const AgeDataToEdit =
      state.age[index]; // Get the age to edit
    if (AgeDataToEdit) {
      // Populate the  data in the modal fields
      SetAgeData({
        id: AgeDataToEdit.id,
        age: AgeDataToEdit.age,
        minSalary: AgeDataToEdit.minSalary,
        endTime: AgeDataToEdit.endTime,
      });
    }
  };

  const addAgeData = (length: number) => {
    setIndex(length);
    setModalPopup(true);
    setErrors({});
    SetAgeData(initialAgeList);
  }

  const removeFieldSet = (indexToRemove: number) => {
    dispatch({ type: REMOVE_AGE_DATA, indexToRemove });
  };

  const handleCloseModalPopup = () => {
    setModalPopup(false);
    setErrors({});
    SetAgeData(initialAgeList);
  }

  const handleFieldChange = (event: any) => {
    const { name, value } = event.target;
    SetAgeData((prevData: any) => ({
      ...prevData,
      [name]: value
    }));
    validation(name, value, true);
  }

  const onOkButtonClick = async (e: React.FormEvent) => {
    e.preventDefault();
    const { name, value } = e.target as HTMLInputElement;
    if (validation(name, value)) {
      dispatch({
        type: ADD_AGE_DATA,
        data: ageData,
        index: index,
      });
      setModalPopup(false); // Close modal
      resetFields();
      setErrors({});
    }
  };

  const resetFields = () => {
    SetAgeData(initialAgeList);
  }

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="form-border" style={{ paddingTop: "2vw" }}>
            <div className="row">
              <div className="col-12">
                <LabelField title={t("Add Age")} className="tab-subtitle" />
                <div className="table-responsive">
                  <table className="table table-hover">
                    <thead>
                      <tr className="TableHeader bg-white">
                        <th className="border-0 align-middle col">
                          {t("Age")}
                        </th>
                        <th className="border-0 align-middle col">
                          {t("Minimum salary")}
                        </th>
                        <th className="border-0 align-middle col">
                          {t("End time ")}
                        </th>
                        <th
                          className="border-0 align-middle col">
                          {t("Action")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {state.age && state.age.length > 0 && state.age.map(
                        (ageData, index) => (
                          <tr key={index}>
                            <td
                              className="align-middle text-break"
                              data-label={t("Age")}
                            >
                              {ageData.age}
                            </td>
                            <td
                              className="align-middle text-break"
                              data-label={t("Minimum salary")}
                            >
                              {ageData.minSalary}
                            </td>
                            <td
                              className="align-middle text-break"
                              data-label={t("End time")}
                            >
                              {ageData.endTime}
                            </td>
                            <td className="align-middle text-break table-action-icons">
                              <span
                                title={t("Edit")}
                                onClick={() => editData(index)}
                                className="table-action-btn cursor-pointer shadow-none marginRightPoint5"
                              >
                                <EditIcon />
                              </span>

                              <span
                                title={t("Delete")}
                                onClick={() => removeFieldSet(index)}
                                className="table-action-btn cursor-pointer shadow-none"
                              >
                                <DeleteIcon />
                              </span>
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="row" style={{ marginTop: "1vw" }}>
              <div className="col me-0">
                <Button
                  title={"+ " + t("Add")}
                  handleClick={() =>
                    addAgeData(state.age.length)
                  }
                  className="form-button float-end"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {modalPopup &&
        <ModalPopup
          show={modalPopup}
          onHide={handleCloseModalPopup}
          title={t("Age")}
          body={
            <>
              <div className="d-grid" style={{ gridTemplateColumns: "repeat(2, 1fr)", gap: "0 1vw" }}>
                <div>
                  <LabelWithInputField
                    isMandatory={false}
                    name="age"
                    handleChange={(e) => handleFieldChange(e)}
                    value={ageData.age}
                    id="age"
                    label={t("Age")}
                    type="text"
                    error={errors.age}
                    placeholder="Age"
                  />
                </div>
                <div>
                  <LabelWithInputFieldAndUnit
                    type="text"
                    placeholder="Minimun salary"
                    handleChange={handleFieldChange}
                    label={t("Minimum salary (percentage)")}
                    value={ageData.minSalary}
                    name={'minSalary'}
                    id="minSalary"
                    isMandatory={true}
                    error={errors.minSalary}
                    min={0}
                    unit={"%"}
                    containerClassName="col-12"
                  />
                </div>
                <div>
                  <LabelWithInputField
                    isMandatory={false}
                    name="endTime"
                    handleChange={(e) => handleFieldChange(e)}
                    value={ageData.endTime}
                    id="endTime"
                    label={t("End time")}
                    type="text"
                    error={errors.endTime}
                    placeholder="End time"
                  />
                </div>
              </div>
              <div className="col-12 d-flex justify-content-end">
                <Button
                  title={t('Add')}
                  handleClick={onOkButtonClick}
                  className="form-button"
                />
              </div>
            </>
          }
        />}
      {state.age.length === 0 &&
        <div className="text-danger">{state.errors.age}</div>
      }
      <div className="row">
        <div className="col-12">
          <div style={{ padding: "0.85vw 0" }}>
            <Navigation />
          </div>
        </div>
      </div>
    </>
  );
}