
import { Link, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import AccessControl from "services/AccessControl";
import GetQueryParams from "services/util/GetQueryParams";
import CompanyCornerProfileCard from "./CompanyCornerProfileCard";
import Staricons from "static/images/Staricons";
import CardCircle from "components/atoms/CardCircle";
import DashboardCard from "./DashboardCard";
import { t } from "pages/microservices/masterData/translation/Translation";
import Microphone from "static/images/Microphone";
import CardTitle from "components/atoms/CardTitle";
import StarterIcon from "static/images/StarterIcon";
import Count from "components/atoms/Count";
import XmarkIcon from "static/images/XmarkIcon";
import LabelField from "components/atoms/LabelField";
import TextEllipsis from "components/atoms/TextEllipsis";
import PhoneCallIcon from "static/images/phoneIcon";
import RightSideBar from "pages/microservices/masterData/follow-up-todo/RightSideBar";
import { ApiCall } from "services/ApiServices";
import { COMPANY_CORNER } from "routes/ApiEndpoints";
import { M_COMPANY_CREATION } from "constants/Constants";
import NoRecords from "./NoRecords";
import EnvelopIcon from "static/images/EnvelopIcon";

interface FollowUpCounts {
  presented: number;
  interview: number;
  starters: number;
  stoppers: number;
}

interface ContactData {
  name: string;
  email: string;
  phone: string;
  userId: number;
  id: number;
  roles: string | null;
}

interface Location {
  id: number;
  location: string;
}

export interface CompanyCornerProps {
  companyName: string;
  officialName: string;
  info: string;
  consultant: string;
  vat: number | string | null;
  office: string;
  website?: string | null;
  street?: string;
  number?: string;
  box?: string;
  zipCode?: string;
  city?: string;
  country?: string;
  functions: any;
  companyId?: number | null;
  brightId: number | null;
  contacts: ContactData[];
  locations: Location[];
}

interface Vacancy {
  id: number;
  functionName: string;
  locationId: number | null;
  office: string | null;
}

const CompanyCorner = () => {
  const [profileCardData, setProfileCardData] = useState<CompanyCornerProps>({
    companyName: "",
    officialName: "",
    consultant: "",
    vat: "",
    office: "",
    info: "",
    website: "",
    street: "",
    number: "",
    box: "",
    zipCode: "",
    city: "",
    country: "",
    functions: [],
    companyId: null,
    brightId: null,
    contacts: [],
    locations: [],
  });
  const [counts, setCounts] = useState<FollowUpCounts>({
    presented: 0,
    interview: 0,
    starters: 0,
    stoppers: 0,
  });
  const [vacancies, setVacancies] = useState<Vacancy[]>([]);
  const queryParams = GetQueryParams();
  const destination = queryParams.get("destination") ?? "/manage-companies";

  const dashboardClass =
    "card border-0 card-shadow justify-content-center h-100";
  const { companyId } = useParams();
  console.log(companyId);

  useEffect(() => {
    if (companyId) {
      fetchDetails(companyId);
      fetchCounts(companyId);
    }
  }, [companyId]);

  const fetchDetails = async (id: string) => {
    const response = await ApiCall.service(
      COMPANY_CORNER,
      "POST",
      { id: id },
      true,
      M_COMPANY_CREATION
    );
    if (response.status === 200) {
      setProfileCardData(response.data);
    }
  };

  const fetchCounts = async (id: string) => {
    // const response = await ApiCall.service(
    //   COMPANY_COUNTS,
    //   "POST",
    //   {
    //     id: id,
    //     entityId: id,
    //     entityTypeId: 1, //company
    //   },
    //   true,
    //   CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    // );
    // if (response.status === 200) {
    //   setCounts(response.data.counts);
    //   setVacancies(response.data.vacancies);
    // }
  };

  // Sales note
  const [salesNote, setSalesNote] = useState({
    name: "",
    phone: "",
    id: null,
    navigate: "",
    companyId: null,
    activeRecordingId: null as number | null,
    triggerMeeting: false as boolean,
    type: null as number | null,
    prevId: null as number | null | undefined,
  });

  const handleMeeting = async (
    name: string,
    phone: string | null,
    id: any,
    companyId: any,
    contactId: number | null,
    navigate?: string
  ) => {
    setSalesNote({
      name: name,
      phone: phone !== null ? phone : "",
      id: id,
      companyId: companyId,
      navigate: navigate ?? "",
      activeRecordingId: contactId,
      triggerMeeting: true,
      type: 3, // For company contacts type is 3
      prevId: null,
    });
  };

  const updateSalesNote = (updatedSalesNote: any) => {
    setSalesNote(updatedSalesNote);
  };

  // const dispatch = useDispatch();

  const countFiltersForFollowUps = (types: number[]) => {
    // const { startDate, endDate } = getStartAndEndDate(userAuth?.dateInfo?.type, userAuth?.dateInfo?.year, userAuth?.dateInfo?.value);
    // dispatch(clearDestinationPath());
    // // Add currentPage and recordsPerPage to filters
    // const updatedFilters = {
    //   fromDashboard: true,
    //   isRecruiter: true,
    //   userIds: [],
    //   createdBy: null,
    //   followedBy: null,
    //   office: [],
    //   taskType: types,
    //   status: null,
    //   company: profileCardData.companyName,
    //   taskStart: "",
    //   taskEnd: "",
    //   followUpStart: "",
    //   followUpEnd: "",
    //   currentPage: 1,
    //   recordsPerPage: 10,
    // };
    // const searchUrl = createQueryParams(updatedFilters);
    // dispatch(setDestinationPath(searchUrl));
  };

  //Reminder related code start
  const [selectedSubType, setSelectedSubType] = useState({
    subType: {
      value: 1,
      label: "When opening company file",
    },
    intial: true,
  });
  const [subTypeDropdown, setSubTypeDropdown] = useState([]);
  const handleSubTypeDropdownData = (data: any) => {
    setSubTypeDropdown(data);
  };
  //Reminder related code end

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Company",
          read: true,
        },
      ]}
      renderNoAccess={true}
    >
      <div className="candidateCornerPage">
        <CompanyCornerProfileCard profileCardProps={profileCardData} />
        <div
          className="row equal-cols recruiter-action-tabs"
          style={{ marginTop: "2vw" }}
        >
          <div
            className="col-md-3"
            onClick={() => countFiltersForFollowUps([6, 7, 8])}
          >
            <Link to={`/manage/follow-up`}>
              <DashboardCard className={`${dashboardClass}`}>
                <div className="row">
                  <div className="col-lg-8 align-self-center col-md-6">
                    <Count
                      totalValue={counts.presented}
                      className={`recruitment-card-num`}
                    />
                    <CardTitle
                      title={t("Presented")}
                      className={`recruitment-card-title`}
                    />
                  </div>
                  <div className="col-lg-4 px-0 align-self-center col-md-6 text-end">
                    <CardCircle
                      width={"3vw"}
                      height={"3vw"}
                      boxShadow="rgb(239 206 224) 0px 0.156vw 0.365vw 0.156vw"
                      className="justify-content-center"
                      // margin="auto"
                    >
                      <Staricons />
                    </CardCircle>
                  </div>
                </div>
              </DashboardCard>
            </Link>
          </div>
          <div
            className="col-md-3"
            onClick={() => countFiltersForFollowUps([9, 10, 11])}
          >
            <Link to={`/manage/follow-up`}>
              <DashboardCard className={`${dashboardClass}`}>
                <div className="row">
                  <div className="col-lg-8 align-self-center col-md-6">
                    <Count
                      totalValue={counts.interview}
                      className={`recruitment-card-num`}
                    />
                    <CardTitle
                      title={t("Interviewed")}
                      className={`recruitment-card-title`}
                    />
                  </div>
                  <div className="col-lg-4 px-0 align-self-center col-md-6">
                    <CardCircle
                      width={"3vw"}
                      height={"3vw"}
                      boxShadow="rgb(239 206 224) 0px 0.156vw 0.365vw 0.156vw"
                      className="justify-content-center"
                      // margin="auto"
                    >
                      <Microphone />
                    </CardCircle>
                  </div>
                </div>
              </DashboardCard>
            </Link>
          </div>
          <div
            className="col-md-3"
            onClick={() => countFiltersForFollowUps([21, 22])}
          >
            <Link to={`/manage/follow-up`}>
              <DashboardCard className={`${dashboardClass}`}>
                <div className="row">
                  <div className="col-lg-8 align-self-center col-md-6">
                    <Count
                      totalValue={counts.starters}
                      className={`recruitment-card-num`}
                    />
                    <CardTitle
                      title={t("Starters")}
                      className={`recruitment-card-title`}
                    />
                  </div>
                  <div className="col-lg-4 px-0 align-self-center col-md-6">
                    <CardCircle
                      width={"3vw"}
                      height={"3vw"}
                      boxShadow="rgb(239 206 224) 0px 0.156vw 0.365vw 0.156vw"
                      className="justify-content-center"
                      // margin="auto"
                    >
                      <StarterIcon />
                    </CardCircle>
                  </div>
                </div>
              </DashboardCard>
            </Link>
          </div>
          <div
            className="col-md-3"
            onClick={() => countFiltersForFollowUps([23, 24])}
          >
            <Link to={`/manage/follow-up`}>
              <DashboardCard className={`${dashboardClass}`}>
                <div className="row">
                  <div className="col-lg-8 align-self-center col-md-6">
                    <Count
                      totalValue={counts.stoppers}
                      className={`recruitment-card-num`}
                    />
                    <CardTitle
                      title={t("Stoppers")}
                      className={`recruitment-card-title`}
                    />
                  </div>
                  <div className="col-lg-4 px-0 align-self-center col-md-6">
                    <CardCircle
                      width={"3vw"}
                      height={"3vw"}
                      boxShadow="rgb(239 206 224) 0px 0.156vw 0.365vw 0.156vw"
                      className="justify-content-center"
                      // margin="auto"
                    >
                      <XmarkIcon />
                    </CardCircle>
                  </div>
                </div>
              </DashboardCard>
            </Link>
          </div>
        </div>
        <div className="row marginTop1 equal-cols recruiter-action-tabs">
          <div className="col-md-6 align-self-center recruitment-card-num ">
            <div>
              {t("The company")}
              &nbsp;
              <span className="fraunces-italic color-dark-pink">
                {t("corner")} !
              </span>
            </div>
          </div>
        </div>
        <div
          className="row equal-cols marginTop1"
          style={{ paddingBottom: "1vw" }}
        >
          <div className="col-6">
            {profileCardData?.info?.trim() !== "" && (
              <div className=" marginBotttom1">
                <div
                  className="card border-0 card-shadow position-relative bg-pink text-white h-100"
                  style={{ padding: "2vw" }}
                >
                  <div className="position-relative jobDescription mb-5">
                    <div>{profileCardData.info}</div>
                  </div>
                </div>
              </div>
            )}
            <div
              className="card border-0 card-shadow position-relative dashboard-myBudget-wrapper h-100 marginBotttom1"
              style={{ padding: "1.5vw" }}
            >
              <div className="row">
                <div className="col-md-12">
                  <LabelField
                    title={t("Company contacts")}
                    className="tab-subtitle"
                  />
                </div>
                <div
                  className="col-md-12 position-relative overflow-auto scrollBarDesign"
                  style={{ maxHeight: "15vw" }}
                >
                  <table className="table table-hover companyCornerTable mb-0">
                    <thead className="position-sticky top-0">
                      <tr>
                        <th>{t("Name")}</th>
                        <th>{t("Role")}</th>
                        <th>{t("Actions")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <>
                        {profileCardData?.contacts &&
                        profileCardData?.contacts.length > 0 ? (
                          profileCardData?.contacts.map((contact, index) => (
                            <tr
                              key={index}
                              className="border-bottom mb-3 box-shadow align-middle"
                            >
                              <td className="text-break">{contact.name}</td>
                              <td>
                                <TextEllipsis
                                  label={contact.roles ?? "-"}
                                  title={contact.roles ?? "-"}
                                  width="8vw"
                                />
                              </td>
                              <td className="align-middle">
                                {contact.email ? (
                                  <Link
                                    to={`mailto:${contact.email}`}
                                    target="_blank"
                                    title={contact.email}
                                    className="table-action-icons me-2"
                                  >
                                    <EnvelopIcon />
                                  </Link>
                                ) : (
                                  "-"
                                )}
                                {contact.phone ? (
                                  <Link
                                    to={""}
                                    onClick={() => {
                                      // Uncomment and implement handleMeeting if needed
                                      handleMeeting(
                                        contact.name,
                                        contact.phone,
                                        contact.userId,
                                        companyId,
                                        contact.id
                                        // CALL_3CX
                                      );
                                    }}
                                    title={contact.phone}
                                    className="table-action-icons"
                                  >
                                    <PhoneCallIcon />
                                  </Link>
                                ) : (
                                  "-"
                                )}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <NoRecords
                            message={t("No contacts found")}
                            headerLength={3}
                          />
                        )}
                      </>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div
              className="card border-0 card-shadow position-relative dashboard-myBudget-wrapper h-100"
              style={{ padding: "1.5vw" }}
            >
              <div className="row">
                <div className="col-md-12">
                  <LabelField
                    title={t("Company vacancies")}
                    className="tab-subtitle"
                  />
                </div>
                <div
                  className="col-md-12 position-relative overflow-auto scrollBarDesign"
                  style={{ maxHeight: "15vw" }}
                >
                  <table className="table table-hover companyCornerTable">
                    <thead className="position-sticky top-0">
                      <tr>
                        <th>{t("Office")}</th>
                        <th>{t("Vacancy")}</th>
                        <th>{t("Location")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <>
                        {vacancies && vacancies.length > 0 ? (
                          vacancies.map((vacancy, index) => (
                            <tr
                              key={index}
                              className="border-bottom mb-3 box-shadow align-middle"
                            >
                              <td className="text-break">{vacancy.office}</td>
                              <td className="text-break">
                                <Link to={`/vacancy/${vacancy.id}`}>
                                  <TextEllipsis
                                    title={vacancy.functionName ?? ""}
                                    label={vacancy.functionName ?? ""}
                                    width="7vw"
                                  />
                                </Link>
                              </td>
                              <td className="text-break" width="40%">
                                {
                                  profileCardData.locations.find(
                                    (item: Location) =>
                                      vacancy.locationId === item.id
                                  )?.location
                                }
                              </td>
                            </tr>
                          ))
                        ) : (
                          <NoRecords
                            message={t("No vacancies found")}
                            headerLength={3}
                          />
                        )}
                      </>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 companyCornerFollowUps">
            <RightSideBar
              title={"Follow-ups"}
              entityType={"company"}
              entityId={companyId ?? null}
              // isInBright={profileCardData?.brightId !== null ? true : false}
              recordsLimit={10}
              className="dashboard-myBudget-wrapper card-shadow companyCornerRightSideBar scrollBarDesign"
              titleClassName="pb-4 mt-0 followUpTitle"
              onClose={function (): void {
                throw new Error("Function not implemented.");
              }}
            />
          </div>
          {/* <Reminder
            entityId={companyId}
            entityTypeId={1}
            entitySubType={selectedSubType}
            onDropdownDataFetch={handleSubTypeDropdownData}
          /> */}
        </div>
        {/* <SalesNote salesnote={salesNote} setSalesNote={updateSalesNote} /> */}
        <div
          className="col-md-6 align-self-center"
          style={{ padding: "1vw 0" }}
        >
          <Link
            to={destination}
            className=" back-btn text-decoration-underline"
          >
            {t("Back")}
          </Link>
        </div>
      </div>
    </AccessControl>
  );
};

export default CompanyCorner;
