import { validateEmail, validateMultiSelectField, validatePhoneNumber, validateRequired, validateSelectField } from "services/validation/ValidationService";

export const InfoTabValidationRules: any = {
    noOfDays: [validateSelectField],
    billingType: [validateSelectField],
}

export const ContactPersonValidationRules: any = {
    fName: [validateRequired],
    lName: [validateRequired],
    email: [validateRequired, validateEmail],
    mobNumber: [validatePhoneNumber],
}

export const PcsValidationRules: any = {
    pc: [validateSelectField],
    employeeType: [validateMultiSelectField],
}

export const GeneralTabValidationRules: any = {
    company: [validateSelectField],
    consultant: [validateSelectField],
    businessUnit: [validateMultiSelectField],
    startDate: [validateRequired],
    contactPerson: [validateMultiSelectField],
}

export const SpecialAgreementRules: any = {
    benefit_value: [validateRequired],
}

export const CoefficientsValidationRules: any = {
    selection: [validateRequired],
    selectionReduced: [validateRequired],
    payroll: [validateRequired],
    selectionCent: [validateRequired],
}

export const EmployeeTypeValidationRules: any = {
    employeeType: [validateRequired],
    level: [validateSelectField],
    duration: [validateRequired],
    type: [validateSelectField],
}

export const FunctionValidationRules: any = {
    function: [validateSelectField],
}