const initialState = {
    data: {
        display: false,
        durationAndAllowance: [
            {
                hours: '00:00',
                projectAllowance: '',
                technicianAllowance: '',
                startTime: '00:00',
                endTime: '00:00',
                pauseTime: '00:00',
                projectAllowanceHours: '',
                technicianAllowanceHours: '',
                revenue: '0,00',
                cost: '0,00',
                marginCost: '0,00',
                marginPercent: '0,00%'
            },
            {
                hours: '00:00',
                projectAllowance: '',
                technicianAllowance: '',
                startTime: '00:00',
                endTime: '00:00',
                pauseTime: '00:00',
                projectAllowanceHours: '',
                technicianAllowanceHours: '',
                revenue: '0,00',
                cost: '0,00',
                marginCost: '0,00',
                marginPercent: '0,00%'
            },
            {
                hours: '00:00',
                projectAllowance: '',
                technicianAllowance: '',
                startTime: '00:00',
                endTime: '00:00',
                pauseTime: '00:00',
                projectAllowanceHours: '',
                technicianAllowanceHours: '',
                revenue: '0,00',
                cost: '0,00',
                marginCost: '0,00',
                marginPercent: '0,00%'
            },
            {
                hours: '00:00',
                projectAllowance: '',
                technicianAllowance: '',
                startTime: '00:00',
                endTime: '00:00',
                pauseTime: '00:00',
                projectAllowanceHours: '',
                technicianAllowanceHours: '',
                revenue: '0,00',
                cost: '0,00',
                marginCost: '0,00',
                marginPercent: '0,00%'

            },
            {
                hours: '00:00',
                projectAllowance: '',
                technicianAllowance: '',
                startTime: '00:00',
                endTime: '00:00',
                pauseTime: '00:00',
                projectAllowanceHours: '',
                technicianAllowanceHours: '',
                revenue: '0,00',
                cost: '0,00',
                marginCost: '0,00',
                marginPercent: '0,00%'
            },
            {
                hours: '00:00',
                projectAllowance: '',
                technicianAllowance: '',
                startTime: '00:00',
                endTime: '00:00',
                pauseTime: '00:00',
                projectAllowanceHours: '',
                technicianAllowanceHours: '',
                revenue: '0,00',
                cost: '0,00',
                marginCost: '0,00',
                marginPercent: '0,00%'
            },
            {
                hours: '00:00',
                projectAllowance: '',
                technicianAllowance: '',
                startTime: '00:00',
                endTime: '00:00',
                pauseTime: '00:00',
                projectAllowanceHours: '',
                technicianAllowanceHours: '',
                revenue: '0,00',
                cost: '0,00',
                marginCost: '0,00',
                marginPercent: '0,00%'
            },
        ],
        fixedProjectParameters: {
            project: '',
            projectParameters: '',
            timeTable: '',
            hourlyRate: '',
            noOfKms: '',
            euroPerKmRate: '',
            // hourlyWage: '',
            // noOfKmsWage: '',
            // euroPerKm: '',
        },
        statute: {
            form: {
                name: {},
            },
        },
        employee: {
            form: {
                name: '',
                amount: '',
                coefficient: '',
                perHour: false,
                invoicing: false,
                empType: '',
            },
            dataList: [],
        },
        car: {
            form: {
                name: '',
                amount: '',
                // coefficient: '',
                // perHour: false,
                // invoicing: false,
                empType: '',
            },
            dataList: [],
        },
        material: {
            form: {
                name: '',
                amount: '',
                // coefficient: '',
                // perHour: false,
                // invoicing: false,
                empType: '',
            },
            dataList: [],
        },
        clothes: {
            form: {
                name: '',
                amount: '',
                // coefficient: '',
                // perHour: false,
                // invoicing: false,
                empType: '',
            },
            dataList: [],
        },
        dynamic: {
            form: {
                name: '',
                amount: '',
                // coefficient: '',
                // perHour: false,
                // invoicing: false,
                empType: '',
            },
            dataList: [],
        },
        options: {
            template: [],
            timeTable: [],
            projects: [],
            projectParameters: [],
            employees: [],
            car: [],
            clothes: [],
            material: [],
            statute: [],
            dynamic: [],
        },
        marginTotal: {
            totalHours: '0',
            revenue: '0',
            cost: '0',
            margin: '0',
            marginPercentage: '0',
        }
    },

    errors: {
        durationAndAllowance: [
            {
                projectAllowance: '',
                technicianAllowance: '',
                pauseTime: '',
            },
            {
                projectAllowance: '',
                technicianAllowance: '',
                pauseTime: '',
            },
            {
                projectAllowance: '',
                technicianAllowance: '',
                pauseTime: '',
            },
            {
                projectAllowance: '',
                technicianAllowance: '',
                pauseTime: '',
            },
            {
                projectAllowance: '',
                technicianAllowance: '',
                pauseTime: '',
            },
            {
                projectAllowance: '',
                technicianAllowance: '',
                pauseTime: '',
            },
            {
                projectAllowance: '',
                technicianAllowance: '',
                pauseTime: '',
            },
        ],
        fixedProjectParameters: {
            project: '',
            projectParameters: '',
            timeTable: '',
            hourlyRate: '',
            noOfKms: '',
            euroPerKmRate: '',
            // hourlyWage: '',
            // noOfKmsWage: '',
            // euroPerKm: '',
        },
        // statute: {
        //     name: '',
        //     amount: '',
        //     coefficient: '',
        // },
        car: {
            name: '',
            amount: '',
            // coefficient: '',
        },
        material: {
            name: '',
            amount: '',
            // coefficient: '',
        },
        employee: {
            name: '',
            amount: '',
            coefficient: '',
        },
        clothes: {
            name: '',
            amount: '',
            // coefficient: '',
        },
        dynamic: {
            name: '',
            amount: '',
            // coefficient: '',
        },
    }
}

export default initialState