
export const initialPlanningModalProps = {

    state: {
        company: [],
        project: [],
        start_date: "",
        end_date: "",
        typeid: [],
        week_number: [],
        from_time: "",
        to_time: "",
        type: "",
        plan_status: "active",
        status: true,
        id:""
    },
    errorState: {
        company: "",
        project: "",
        start_date: "",
        end_date: "",
        type: "",
        typeid: "",
        week_number: "",
        employee:null
    },
    dependentState: {
        employee_id: "",
        start_date: "",
        frequency: "",
        company: [],
        project: []
    }
}
