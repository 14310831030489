import React from "react";
import { t } from "../../translation/Translation";
import TextEllipsis from "components/atoms/TextEllipsis";
import EditIcon from "static/images/EditIcon";
import DeleteIcon from "static/images/DeleteIcon";
import LabelField from "components/atoms/LabelField";
import "./css/Invoice.css";

const InvoiceTab = (props: any) => {

    const detailsLineHeader = [
        { name: "Description", alias: "discription" },
        { name: "Number", alias: "number" },
        { name: "Price", alias: "price" },
        { name: "Subtotal", alias: "subtotal" },
        { name: "Btw", alias: "btw" },
        { name: "Total", alias: "total_price" },
        { name: "Sales account", alias: "salesaccount" },
    ];

    const btwHeader = [
        { name: "Code", alias: "code" },
        { name: "Basis", alias: "basis" },
        { name: "Btw", alias: "btw" },
    ];

    return (
        <>
            <LabelField title={t("Detail lines")}  className="tab-subtitle pb-0 invoiceTitle"/>
            <div className="table-responsive createInvoiceTable tableWithSearchBar tablePadding border">
                <table className="table table-hover">
                    <thead>
                        <tr className="TableHeader">
                            {detailsLineHeader?.map((header) => (
                                <th key={header?.alias} className={"table_actions"}>
                                    <>{t(header?.name)}</>
                                </th>
                            ))}
                            <th>{t("Action")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props?.tabData?.ruleData && props?.tabData?.ruleData?.length > 0 ? (
                            props?.tabData?.ruleData?.map((record: any, index: any) => {
                                const standardSalesAccount = props?.dropdownData?.standardSalesAccounts?.find((item: any) => item?.value == (record?.standard_account_id ?? props?.formData?.standardSalesAccount));
                                return (
                                    <tr key={index}>
                                        <td className="align-middle">
                                            <TextEllipsis
                                                label={record?.description}
                                                title={record?.description}
                                                width="5vw"
                                            />
                                        </td>
                                        <td className="align-middle">
                                            <TextEllipsis
                                                label={record?.number}
                                                title={record?.number}
                                                width="8vw"
                                            />
                                        </td>
                                        <td className="align-middle">
                                            <TextEllipsis
                                                label={record?.price}
                                                title={record?.price}
                                                width="8vw"
                                            />
                                        </td>
                                        <td className="align-middle">
                                            <TextEllipsis
                                                label={record?.subtotal}
                                                title={record?.subtotal}
                                                width="8vw"
                                            />
                                        </td>
                                        <td className="align-middle">
                                            <TextEllipsis
                                                label={record?.btw}
                                                title={record?.btw}
                                                width="8vw"
                                            />
                                        </td>
                                        <td className="align-middle">
                                            <TextEllipsis
                                                label={record?.total_price}
                                                title={record?.total_price}
                                                width="8vw"
                                            />
                                        </td>
                                        <td className="align-middle">
                                            <TextEllipsis
                                                label={standardSalesAccount?.label}
                                                title={standardSalesAccount?.label}
                                                width="8vw"
                                            />
                                        </td>
                                        <td>
                                            {!record?.ruletype && <>
                                                <span
                                                    onClick={() => props?.setTabData((prev: any) => ({
                                                        ...prev, popup: {...prev?.popup, name: 'newrule',
                                                        data: {
                                                            quantity: record?.number ?? "", price: record?.price ?? "", btw: record?.vatrateid,
                                                            salesaccount: record?.standard_account_id ?? props?.formData?.standardSalesAccount,
                                                            description: record?.description, index
                                                        },
                                                        errors: {quantity: "", price: "", btw: "",salesaccount: "", description: ""}
                                                    }}))}
                                                    className="table-action-btn me-2 cursor-pointer"
                                                    title={t("Edit")}
                                                >
                                                    <EditIcon />
                                                </span>
                                                <span
                                                    onClick={() => {
                                                        const updatedRuleData = [...props?.tabData?.ruleData];
                                                        updatedRuleData?.splice(index, 1);
                                                        props?.setTabData((prev: any) => ({
                                                            ...prev, calcRefresh: !props?.tabData?.calcRefresh, ruleData: updatedRuleData
                                                        }))
                                                    }}
                                                    className="table-action-btn cursor-pointer"
                                                    title={t("Delete")}
                                                >
                                                    <DeleteIcon />
                                                </span>
                                            </>}
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan={10} className="text-center">
                                    {t("No records found")}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div className="d-grid grid-2 marginTopPoint5">
                <div>
                    <LabelField title={t("Btw")} className="tab-subtitle pb-0 invoiceTitle" />
                </div>
                <div>
                    <LabelField title={t("Totals")} className="tab-subtitle pb-0 invoiceTitle" />
                </div>
            </div>
            <div className="d-grid grid-2">
                <div>
                    <div className="createInvoiceTable border h-100">
                        <table className="table table-hover">
                            <thead>
                                <tr className="TableHeader">
                                    {btwHeader?.map((header) => (
                                        <th key={header?.alias} className={"table_actions"}>
                                            <>{t(header?.name)}</>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {Object?.values(props?.tabData?.btw)?.map((item: any, index: number) => (
                                    <>
                                        <tr key={index}>
                                            <td className="align-middle">
                                                <TextEllipsis
                                                    label={item?.code}
                                                    title={item?.code}
                                                    width="5vw"
                                                />
                                            </td>
                                            <td className="align-middle">
                                                <TextEllipsis
                                                    label={item?.basis}
                                                    title={item?.basis}
                                                    width="8vw"
                                                />
                                            </td>
                                            <td className="align-middle">
                                                <TextEllipsis
                                                    label={item?.btw}
                                                    title={item?.btw}
                                                    width="8vw"
                                                />
                                            </td>
                                        </tr>
                                    </>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div>
                    <div className="createInvoiceTable border h-100 invoiceTotals">
                        <table className="table table-hover">
                            <thead>
                                <tr className="TableHeader">
                                    <th key={"subtotal"} className={"table_actions"}>
                                        <>{t("Subtotal")}</>
                                    </th>
                                    <td key={"1"} className={"table_actions"}>
                                        <>{props?.tabData?.totals?.subtotal}</>
                                    </td>
                                </tr>
                                <tr className="TableHeader">
                                    <th key={"btw"} className={"table_actions"}>
                                        <>{t("Btw")}</>
                                    </th>
                                    <td key={2} className={"table_actions"}>
                                        <>{props?.tabData?.totals?.btw}</>
                                    </td>
                                </tr>
                                <tr className="TableHeader">
                                    <th key={"total"} className={"table_actions"}>
                                        <>{t("Total")}</>
                                    </th>
                                    <td key={3} className={"table_actions"}>
                                        <>{props?.tabData?.totals?.total}</>
                                    </td>
                                </tr>
                                <tr className="TableHeader">
                                    <th key={"tobepaid"} className={"table_actions"}>
                                        <>{t("To be paid")}</>
                                    </th>
                                    <td key={4} className={"table_actions"}>
                                        <>{props?.tabData?.tobepaid}</>
                                    </td>
                                </tr>
                            </thead>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};

export default InvoiceTab;
