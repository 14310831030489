import React from "react";
import PageLayout from "./timesheetsTemplate";
import TimesheetsOverview from "./organisms/timesheetsOverview";
import TimesheetProvider from "./timesheets-redux/context/TimesheetContext/TImesheetContex";
import TimeSheetModalProvider from "./timesheets-redux/context/TimesheetModalContext/TimesheetModalContext";
import { FilterOptionsProvider } from "./timesheets-redux/context/FiltersContext/FiltersContext";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import { TimesheetRoleMap, TimesheetRolePriority, ROLES } from "./constants/timesheetConstants";

export default function Timesheet() {
  const userData = useSelector(selectAuth);

  const type = TimesheetRolePriority?.find((priorityRole: any) => userData?.role?.includes(priorityRole)) 
      ? TimesheetRoleMap[TimesheetRolePriority?.find((priorityRole: any) => userData?.role?.includes(priorityRole)) as string]
      : ROLES?.EMPLOYEE;

  return (
    <FilterOptionsProvider>
      <TimesheetProvider>
        <TimeSheetModalProvider>
          <PageLayout>
            <TimesheetsOverview type={type} user={userData} />
          </PageLayout>
        </TimeSheetModalProvider>
      </TimesheetProvider>
    </FilterOptionsProvider>
  );
}
