import React, { useState, useEffect, useRef } from "react";

import { t } from "../translation/Translation";
import { useNavigate, useParams } from "react-router-dom";
import Button from "components/atoms/Button";
import {
  MANAGE_CONTRACT_PROPOSAL,
  PATH_ACCESS_DENIED,
  PATH_TEMPLATE_NOT_FOUND,
} from "constants/Paths";
import LoadingIcon from "utils/LoadingIcon";
import { ApiCall } from "services/ApiServices";
import { M_MASTER_DATA } from "constants/Constants";
import {
  APPROVE_CONTRACT,
  CONTRACT_DATA,
  DYNAMIC_TODO_CREATION,
  SEND_CONTRACT_MAIL,
  SIGNATURE,
} from "routes/ApiEndpoints";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import CheckBoxField from "components/atoms/CheckBoxField";
import EmployerAuthenticModal from "pages/microservices/project/Proposals/EmployerAuthenticModal";
import SignatureModal from "pages/microservices/project/Proposals/SignatureModel";
import SignatureCanvas from "react-signature-canvas";
import CustomNotify from "components/atoms/CustomNotify";
import EncryptDecryptService from "services/EncryptDecryptService";
import BackButton from "components/atoms/BackButton";
interface ContractProposalData {
  businessUnit: any;
  userId: number;
  wageId: any;
  throughMail: any;
  reject?: any;
  employmentTypeId?: any;
  scheduleTypeId?: any;
  isSignaturePresent?: any;
  contractType?: any;
}
const ContractOverview: React.FC = () => {
  const url = new URL(window.location.href);
  const [parsedData, setParsedData] = useState<ContractProposalData | null>(
    null
  );
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showSignatureModal, setSignatureModal] = useState(false);
  const [AuthenticatedMail, setAuthenticatedMail] = useState("");
  const [signatureErrorMessage, setSignatureErrorMessage] = useState("");
  const signatureRef = useRef<SignatureCanvas | null>(null);
  const [agreement, setAgreement] = useState(false);
  const [approve, setApprove] = useState(false);
  const [approveAndReject, setApproveAndReject] = useState(false);
  const userAuth = useSelector(selectAuth);
  const [htmlPath, setHtmlPath] = useState("");
  const [checked, setChecked] = useState(false);
  const [showCheckBox, setShowCheckbox] = useState(true);
  const [isSignaturePresent, setIsSignaturePresent] = useState(false);
  // Base64 decoding helper function
  const base64Decode = (data: string) => {
    return decodeURIComponent(escape(atob(data)));
  };
  // Decode or decrypt the data parameter from the URL
  // useEffect(() => {
  const queryParams = new URLSearchParams(window.location.search);
  const dataParam = queryParams.get("data");

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const dataParam = queryParams.get("data");

    if (dataParam) {
      try {
        let decodedData;
        try {
          decodedData = base64Decode(dataParam);
          const parsedDatas = JSON.parse(decodedData);
          if (parsedDatas.throughMail) {
            if (parsedDatas.userId != userAuth.userId) {
              navigate(PATH_ACCESS_DENIED);
            }
          }
          setParsedData(parsedDatas);
        } catch (base64Error) {
          decodedData = decodeURIComponent(dataParam);
          const decryptedData = EncryptDecryptService.decryptData(decodedData);
          const parsedData = JSON.parse(decryptedData);
          setParsedData(parsedData);
        }
      } catch (error) {
        console.error("Error processing data:", error);
        CustomNotify({ type: "error", message: "Invalid or corrupted data." });
      }
    }
  }, [userAuth.userId, navigate]); // Add dependencies to prevent unnecessary executions

  useEffect(() => {
    if (parsedData) {
      setIsSignaturePresent(parsedData.isSignaturePresent);
      if (parsedData?.throughMail) {
      }
      generateContractProposal();
    }
  }, [parsedData]);

  const generateContractProposal = async () => {
    try {
      const url = `${CONTRACT_DATA}`;
      const response = await ApiCall.service(
        url,
        "POST",
        parsedData,
        false,
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        const htmlPath = response?.data?.preview;
        setHtmlPath(""); // Clear the current path
        setTimeout(() => setHtmlPath(htmlPath), 0); // Set the new path
      } else {
        CustomNotify({ type: "error", message: `Error: ${response.message}` });
        navigate(`${PATH_TEMPLATE_NOT_FOUND}/${response.message}`);
      }
    } catch (error) {
      console.error(error);
      CustomNotify({
        type: "error",
        message: "An error occurred while sending the wage proposal.",
      });
    }
    // setLoading(false);
  };
  const handleAuthenticationSuccess = async (
    success: any,
    email: any,
    signatureImage: any
  ) => {
    const isSignature = !!signatureImage;
    // setIsSignaturePresent(isSignaturePresent);
    // setIsAuthenticated(success);

    if (success) {
      setIsSignaturePresent(isSignature);
      setShowCheckbox(false);
      handleModalClose();
      setIsAuthenticated(success);
      const url = `${CONTRACT_DATA}`;
      const updatedData = { ...parsedData, isSignature };
      const response = await ApiCall.service(
        url,
        "POST",
        updatedData,
        false,
        M_MASTER_DATA
      );
      if (response.status === 200) {
        const htmlPath = response?.data?.preview;
        setHtmlPath(""); // Clear the current path
        setTimeout(() => setHtmlPath(htmlPath), 0); // Set the new path
      }
    } else {
      setShowCheckbox(true);
      setIsAuthenticated(success);
    }
    setChecked(false);
  };
  const handleCheckbox = (checked: boolean) => {
    setChecked(checked);
    if (parsedData?.throughMail) {
      setApproveAndReject(true);
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
    setSignatureModal(false);
    setChecked(false);
  };
  const [loading, setLoading] = useState(false);
  const handleSend = async () => {
    setLoading(true);
    try {
      const url = `${SEND_CONTRACT_MAIL}`;
      const response = await ApiCall.service(
        url,
        "POST",
        parsedData,
        false,
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        CustomNotify({ type: "success", message: ` ${response?.message}` });
        navigate(`${MANAGE_CONTRACT_PROPOSAL}/${response?.userId}`);
        //todo creation
        const isHrRole = userAuth?.role?.filter((role: any) => role === "HR");
        if (Array.isArray(isHrRole) && isHrRole?.length > 0) {
          let todoData = {
            name: "contract proposal",
            entityId: parsedData?.wageId,
            loginUserId: userAuth?.userId,
            userId: [parsedData?.userId],
          };
          if (
            parsedData?.contractType &&
            parsedData?.contractType?.label?.toLowerCase() === "freelancer"
          ) {
            Object.assign(todoData, {
              description:
                "Review and sign your freelancer employment contract from HR to finalize your employment.",
              todoType: "Contract proposal to freelancer employee",
            });
          } else if (
            parsedData?.contractType &&
            parsedData?.contractType?.label?.toLowerCase() === "permanent"
          ) {
            Object.assign(todoData, {
              description:
                "Review and sign your permanent employment contract from HR to finalize your employment.",
              todoType: "Contract proposal to permanent employee",
            });
          }
          await ApiCall.service(
            DYNAMIC_TODO_CREATION,
            "POST",
            todoData,
            false,
            M_MASTER_DATA
          );
        }
      } else {
        CustomNotify({ type: "error", message: `Error: ${response.message}` });
      }
    } catch (error) {
      //console.error(error);
    }
    setLoading(false);
  };
  const handleApprove = async (approveOrReject: any) => {
    try {
      const requestData = {
        ...parsedData,
        approve: approveOrReject, // or false, based on your requirement
      };
      const url = `${APPROVE_CONTRACT}`;
      const response = await ApiCall.service(
        url,
        "POST",
        requestData,
        true,
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        CustomNotify({ type: "success", message: ` ${response?.message}` });
        if (parsedData?.throughMail) {
          navigate("/");
        } else {
          navigate(`${MANAGE_CONTRACT_PROPOSAL}/${parsedData?.userId}`);
        }
      } else {
        CustomNotify({ type: "error", message: `Error: ${response.message}` });
      }
    } catch (error) {
      //console.error(error);
    }
  };

  const handleSaveSignature = async () => {
    if (!signatureRef.current?.isEmpty()) {
      const signatureImage = signatureRef.current?.toDataURL();
      handleModalClose();
      setSignatureErrorMessage("");
      const url = `${SIGNATURE}`;
      const data = {
        ...parsedData, // Spread parsedData into the payload
        signature: signatureImage,
      };
      const response = await ApiCall.service(
        url,
        "POST",
        data,
        true,
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        setIsSignaturePresent(response.employee_signature);
        const htmlFilePath = response?.data?.preview;
        setHtmlPath(""); // Clear the current path
        setTimeout(() => setHtmlPath(htmlFilePath), 0); // Set the new path
        setApproveAndReject(true);
        setShowCheckbox(false);
        const userId = response.userId;
      }
    } else {
      setSignatureErrorMessage(t("Please add signature before saving."));
    }
  };
  const handleAddSignature = () => {
    setSignatureModal(true);
  };
  const handleApproveAsCandidate = () => {
    setShowModal(true);
  };
  // const handleAddSignature = () => {};
  const handleClearSignature = () => {
    signatureRef.current?.clear();
  };
  const handleBackButton = () => {
    if (parsedData?.throughMail) {
      navigate("/");
    } else {
      navigate(`${MANAGE_CONTRACT_PROPOSAL}/${parsedData?.userId}`);
    }
  };
  return (
    <>
      <>
        <div className="d-flex overflow-auto flex-column">
          <div
            className="flex-1 overflow-auto"
            style={{ height: "calc(100vh - 10vw)" }}
          >
            {htmlPath ? (
              <iframe src={htmlPath} height="99%" width="100%" />
            ) : (
              <div>Loading contract...</div>
            )}
          </div>
        </div>
      </>
      <div className="d-flex align-items-center">
        {showCheckBox && (
          <CheckBoxField
            name={"checkbox"}
            onChangeHandler={(e) => handleCheckbox(e.target.checked)}
            isChecked={checked}
            label={t(
              "I hereby declare that I have reviewed the document and confirming that I agree with all details"
            )}
            id="approval"
          />
        )}
      </div>
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ marginTop: "0.8vw" }}
      >
        <div className="w-25">
          <Button
            type="button"
            title={t("Back")}
            handleClick={handleBackButton}
            className="form-button shadow-none text-uppercase"
          />
        </div>
        {checked && !parsedData?.throughMail && (
          <div className="d-inline-flex justify-content-end align-items-center w-75">
            <>
              <Button
                type="button"
                title={t("Approve as candidate")}
                handleClick={handleApproveAsCandidate}
                className="form-button shadow-none text-uppercase"
              />
              {!loading ? (
                <Button
                  type="button"
                  title={t("Send to candidate")}
                  handleClick={handleSend}
                  className="form-button ms-3 shadow-none text-uppercase"
                />
              ) : (
                <LoadingIcon
                  className="ms-3 h-100"
                  iconType="bars"
                  color="#00a5ce"
                />
              )}
            </>
          </div>
        )}
        {((checked && parsedData?.throughMail && !isSignaturePresent) ||
          (!parsedData?.throughMail &&
            isAuthenticated &&
            !isSignaturePresent)) && (
          <div className="w-75 d-flex justify-content-end">
            <Button
              type="button"
              title={t("Add Signature")}
              handleClick={handleAddSignature}
              className="form-button shadow-none text-uppercase"
            />
          </div>
        )}
        {!parsedData?.throughMail && isSignaturePresent && (
          <div>
            <Button
              type="button"
              title={t("Approve")}
              handleClick={() => handleApprove(true)}
              className="form-button shadow-none text-uppercase"
            />
          </div>
        )}
        {parsedData?.throughMail && approveAndReject && isSignaturePresent && (
          <div className="d-flex align-items-center justify-content-end w-75">
            <div>
              <Button
                type="button"
                title={t("Approve")}
                handleClick={() => handleApprove(true)}
                className="form-button shadow-none text-uppercase marginRightPoint5"
              />
            </div>
            <div>
              <Button
                type="button"
                title={t("Reject")}
                handleClick={() => handleApprove(false)} // Assuming this is the reject functionality
                className="form-button shadow-none text-uppercase"
              />
            </div>
          </div>
        )}
      </div>

      {showModal && parsedData?.userId && (
        <EmployerAuthenticModal
          handleClose={handleModalClose}
          onAuthenticationSuccess={handleAuthenticationSuccess}
          users={[parsedData.userId]}
        />
      )}
      <div>
        {showSignatureModal && (
          <SignatureModal
            handleClose={handleModalClose}
            handleClearSignature={handleClearSignature}
            handleSaveSignature={handleSaveSignature}
            signatureRef={signatureRef}
            signatureErrorMessage={signatureErrorMessage}
          />
        )}
      </div>
    </>
  );
};

export default ContractOverview;
