import SelectWithSearch from "components/atoms/SelectWithSearch";
import { useFormContext } from "../context/Context"
import { t } from "pages/microservices/masterData/translation/Translation";
import Calender from "components/molecules/Calender";
import { EDIT_OR_DELETE_PARTICULAR_PC, INITIAL_CONTACT_PERSON, optionsData, pcList, SET_OPTIONS, typesBased, UPDATE_CONTACT_PERSONS_ERRORS, UPDATE_FIELD_ERRORS, UPDATE_FIELD_VALUE, UPDATE_PCS_FIELD_ERROR, UPDATE_PCS_TO_TABLE, UPDATE_SHOW_CONTACT_PERSON, UPDATE_SPECIAL_AGREEMENT_TAB } from "../context/Constants";
import RadioField from "components/atoms/RadioField";
import TitleAtom from "components/atoms/Title";
import PCTable from "./PCTable";
import Button from "components/atoms/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "react-bootstrap";
import ContactForm from "components/organism/contactForm";
import { String } from "aws-sdk/clients/appstream";
import { validateForm } from "services/validation/ValidationService";
import { ContactPersonValidationRules, GeneralTabValidationRules, PcsValidationRules } from "../validationRules/ValidationRules";
import { ApiCall } from "services/ApiServices";
import { M_MASTER_DATA } from "constants/Constants";
import CustomNotify from "components/atoms/CustomNotify";
import { CREATE_CONTACT_PERSON_IN_COOP_AGREE } from "routes/ApiEndpoints";
import { useParams } from "react-router-dom";
import { filterForUniqueOptions } from "../utils/Utils";

const General = () => {
    const { state, dispatch } = useFormContext();

    const generalData: any = state?.general?.data;
    const generalTabErrors: any = state?.general?.errors;

    const options: any = state?.options;

    const contactsData = state?.contactPersons?.data;
    const contactsErrors = state?.contactPersons?.errors;
    const { tempAgId: routeId } = useParams();

    const handleSelectChange = (value: any, name: string, validate: boolean, type: string | null = null) => {
        if (name == "consultant") {
            const getConsultants = state?.options?.consultantBusinessUnits?.[value?.value];
            dispatch({
                type: UPDATE_FIELD_VALUE,
                field: 'businessUnit',
                tab: 'general',
                value: getConsultants ?? [],
                entityType: type,
            });
        }

        if (name == "pc" && (type == "whiteCollarPcs" || type == "blueCollarPcs")) {
            dispatch({
                type: UPDATE_FIELD_VALUE,
                field: 'employeeType',
                tab: 'general',
                value: "",
                entityType: type,
            });
        }

        dispatch({
            type: UPDATE_FIELD_VALUE,
            field: name,
            tab: 'general',
            value,
            entityType: type,
        });
        validateGeneralData(name, value);
    }

    const handleContactSelectChange = (value: any, name: string, validate: boolean) => {
        dispatch({
            type: UPDATE_FIELD_VALUE,
            field: name,
            tab: 'contactPersons',
            value,
        });
        if (validate) {
            validateContactPerson(name, value);
        }
    }

    const handleDateChange = (event: any, name: string, validate: boolean, entityType: string | null = null) => {
        if (event) {
            const date = `${event.getFullYear()}-${event.getMonth() + 1 < 10
                ? "0" + (event.getMonth() + 1)
                : event.getMonth() + 1
                }-${event.getDate() < 10 ? "0" + event.getDate() : event.getDate()}`;
            dispatch({
                type: UPDATE_FIELD_VALUE,
                field: name,
                tab: 'general',
                value: date,
            });
        }
        validateGeneralData(name, event);
    }

    const handleInputChange = (event: any, entityType: string | null = null) => {
        const { name, value, type, id } = event.target;

        let fieldValue = null
        if (type == 'radio') {
            fieldValue = id;
        }
        else {
            fieldValue = value;

        }
        dispatch({
            type: UPDATE_FIELD_VALUE,
            field: name,
            tab: 'general',
            value: fieldValue,
        });

        if (type !== 'radio') {
            validateGeneralData(name, value);
        }
    }

    const handleContactInputChange = (event: any, validate: boolean) => {
        const { name, value, checked, type } = event.target;
        dispatch({
            type: UPDATE_FIELD_VALUE,
            field: name,
            tab: 'contactPersons',
            value: value,
        });
        if (validate) {
            validateContactPerson(name, value);
        }
    }

    const handleContactDateChange = (event: any, field: string, validate: boolean) => {
        if (event) {
            const date = `${event.getFullYear()}-${event.getMonth() + 1 < 10
                ? "0" + (event.getMonth() + 1)
                : event.getMonth() + 1
                }-${event.getDate() < 10 ? "0" + event.getDate() : event.getDate()}`;
            dispatch({
                type: UPDATE_FIELD_VALUE,
                field,
                tab: 'contactPersons',
                value: date,
            });
            if (validate) {
                validateContactPerson(field, event);
            }
        }
    }

    const handleEditTableData = (index: number, type: string) => {
        dispatch({
            type: EDIT_OR_DELETE_PARTICULAR_PC,
            tab: 'general',
            index,
            entityType: type,
            delete: false,
        })
    }

    const handleDeleteTableData = (index: number, type: string) => {
        dispatch({
            type: EDIT_OR_DELETE_PARTICULAR_PC,
            tab: 'general',
            index,
            entityType: type,
            delete: true,
        })
    }

    const handleAddAnother = (type: string) => {
        const currentPcData = generalData?.[type]?.data;

        const validationErrors = validateForm(currentPcData, PcsValidationRules);
        if (Object.keys(validationErrors).length > 0) {
            //update errors for particular pc
            dispatch({
                type: UPDATE_PCS_FIELD_ERROR,
                errors: validationErrors,
                tab: 'general',
                entityType: type,
            })
        }
        else {
            //update options and table structure
            dispatch({
                type: UPDATE_PCS_TO_TABLE,
                entityType: type,
                tab: "general"
            })
        }
    }

    const validateContactPerson = (name: string, value: any) => {
        const validationErrors = validateForm({ [name]: value }, ContactPersonValidationRules);
        dispatch({
            type: UPDATE_FIELD_ERRORS,
            field: name,
            tab: 'contactPersons',
            error: validationErrors.hasOwnProperty(name) ? validationErrors[name] : '',
        });
    }

    const toggleFormVisibility = () => {
        dispatch({
            type: UPDATE_FIELD_VALUE,
            field: "showCreateContactForm",
            tab: "general",
            value: !generalData?.showCreateContactForm
        })
    }

    const handleSaveContact = async () => {
        const validationErrors = validateForm(contactsData, ContactPersonValidationRules);
        if (Object.keys(validationErrors).length > 0) {
            dispatch({
                type: UPDATE_CONTACT_PERSONS_ERRORS,
                tab: "contactPersons",
                errors: validationErrors,
            });
            return;
        }

        try {
            const response = await ApiCall.service(
                CREATE_CONTACT_PERSON_IN_COOP_AGREE,
                "POST",
                { data: [state?.contactPersons?.data], tempAgId: routeId, locations: options?.locations },
                false,
                M_MASTER_DATA,
            );
            if (response?.status == 200) {
                const newOptions = { value: response?.data?.userId, label: response?.data?.name }
                CustomNotify({ type: "success", message: response?.message });
                dispatch({
                    type: SET_OPTIONS,
                    data: [...state?.options?.contactPersons, newOptions],     //Update contact person options with new one
                });

                dispatch({
                    type: UPDATE_FIELD_VALUE,
                    field: "contactPerson",
                    tab: "general",
                    value: [newOptions],
                });

                //Update status of the pop up
                dispatch({
                    type: UPDATE_FIELD_VALUE,
                    field: "showCreateContactForm",
                    tab: "general",
                    value: !generalData?.showCreateContactForm
                });

                //Update contact person object to initial state
                dispatch({
                    type: UPDATE_SPECIAL_AGREEMENT_TAB,
                    data: INITIAL_CONTACT_PERSON,
                    tab: "contactPersons",
                });

            }
            else {
                CustomNotify({ type: "error", message: response?.message });
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    const handleContactPhoneNumberChange = (field: String, event: any, validate: boolean) => {
        dispatch({
            type: UPDATE_FIELD_VALUE,
            field,
            tab: 'contactPersons',
            value: event,
        });
        if (validate) {
            validateContactPerson(field, event);
        }
    }

    const validateGeneralData = (name: string, value: any) => {
        const validationErrors = validateForm({ [name]: value }, GeneralTabValidationRules);
        dispatch({
            type: UPDATE_FIELD_ERRORS,
            field: name,
            tab: 'general',
            error: validationErrors.hasOwnProperty(name) ? validationErrors[name] : '',
        });
    }

    const getEmployeeTypes = (pcId: number, type: string) => {
        const employeeType = options?.[type]?.find((eachType: any) => eachType?.value == pcId);
        return employeeType?.employee_types;
    }

    const getUniquePcs = (entityType: any) => {
        const pcType = generalData?.[entityType]?.pcsList;
        return filterForUniqueOptions(options?.[entityType], pcType);
    }

    const renderContactPersonCreateForm = () => {
        return (
            <div className="marginBotttom1">
                <Modal
                    size="xl"
                    show={true}
                    onHide={toggleFormVisibility}
                    backdrop="static"
                    keyboard={false}
                    centered
                    className="addContactPersonsModal"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{t("Add contact person")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ContactForm
                            contacts={contactsData}
                            contactErrors={contactsErrors}
                            onFieldChange={handleContactInputChange}
                            onPhoneNumberChange={handleContactPhoneNumberChange}
                            onSelectChange={handleContactSelectChange}
                            languageOptions={options?.language}
                            companyBusinessUnitOptions={options?.businessUnits}
                            rolesOptions={options?.roles}
                            onSaveContact={handleSaveContact}
                            locationsOptions={options?.locations}
                            tittleOptions={options?.tittle}
                            handleDateChange={handleContactDateChange}
                            fromType={"project"}
                            mode={"tempAgency"}
                        />
                    </Modal.Body>
                </Modal>
            </div>)
    }

    return (
        <div className="row">
            <div className="col-12">
                <div className="form-border" style={{ paddingTop: "3vw" }}>
                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <SelectWithSearch
                                onChange={(value) =>
                                    handleSelectChange(value, "company", true)
                                }
                                title={t("Company")}
                                placeHolder={t("Select")}
                                search={true}
                                options={options?.companies}
                                value={generalData?.company}
                                isDisabled={false}
                                isMulti={false}
                                isMandatory={true}
                                error={generalTabErrors?.company}
                                name={"company"}
                                id={"company"}
                            />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <SelectWithSearch
                                onChange={(value) =>
                                    handleSelectChange(value, "consultant", true)
                                }
                                title={t("Consultant")}
                                placeHolder={t("Select")}
                                search={true}
                                options={options?.consultants}
                                value={generalData?.consultant}
                                isDisabled={false}
                                isMulti={false}
                                isMandatory={true}
                                error={generalTabErrors?.consultant}
                                name={"consultant"}
                                id={"consultant"}
                            />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <SelectWithSearch
                                onChange={(value) =>
                                    handleSelectChange(value, "businessUnit", true)
                                }
                                title={t("Business unit")}
                                placeHolder={t("Select")}
                                search={true}
                                options={options?.businessUnits}
                                value={generalData?.businessUnit}
                                isDisabled={false}
                                isMulti={true}
                                isMandatory={true}
                                error={generalTabErrors?.businessUnit}
                                name={"businessUnit"}
                                id={"businessUnit"}
                            />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4 position-relative">
                            <Calender
                                onChange={(event) => {
                                    handleDateChange(event, "startDate", false);
                                }}
                                selectedDate={generalData?.startDate}
                                label={t("Start date")}
                                isMandatory={true}
                                name={"startDate"}
                                error={generalTabErrors?.startDate}
                                isDisabled={false}
                                //maxDate={new Date()}
                                placeHolder="dd-mm-yyyy"
                            />
                        </div>
                        <div className="col-sm-10 col-md-4 col-lg-3">
                            <SelectWithSearch
                                onChange={(value) =>
                                    handleSelectChange(value, "contactPerson", true)
                                }
                                title={t("Contact person")}
                                placeHolder={t("Select")}
                                search={true}
                                options={options?.contactPersons}
                                value={generalData?.contactPerson ?? ''}
                                isDisabled={false}
                                isMulti={true}
                                isMandatory={true}
                                error={generalTabErrors?.contactPerson}
                                name={"contactPerson"}
                                id={"contactPerson"}
                            />
                        </div>
                        <div className="col-sm-2 col-md-2 col-lg-1 plusIconSPace table-action-icons">
                            <span
                                onClick={toggleFormVisibility}
                                className="table-action-btn cursor-pointer"
                                title={generalData?.showCreateContactForm ? t("Minimise") : t("Expand")}
                            >
                                <FontAwesomeIcon
                                    icon={generalData?.showCreateContactForm ? faMinus : faPlus}
                                />
                            </span>
                            {generalData?.showCreateContactForm ? renderContactPersonCreateForm() : <></>}
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4 disabled">
                            {typesBased.map((eachType: any) => {
                                return (
                                    <RadioField
                                        name={"type"}
                                        ischecked={eachType.name === generalData?.type}
                                        handleChange={handleInputChange}
                                        label={t(eachType.label)}
                                        id={eachType.name}
                                        key={eachType?.index}
                                        className="halfDaROptions"
                                    />
                                )
                            })}
                        </div>
                    </div>
                    <div className="row">
                        {pcList.map((eachPcType: any) => {
                            return (
                                <div className="col-sm-12 col-md-6">
                                    <TitleAtom
                                        title={t(eachPcType?.title)}
                                    />
                                    <PCTable
                                        data={generalData?.[eachPcType?.type]?.pcsList}
                                        pcChangeHandler={handleEditTableData}
                                        pcDeleteHandler={handleDeleteTableData}
                                        type={eachPcType?.type}
                                    />
                                    <SelectWithSearch
                                        onChange={(value) =>
                                            handleSelectChange(value, eachPcType?.pcName, true, eachPcType?.type)
                                        }
                                        title={t(eachPcType?.pcLabel)}
                                        placeHolder={t("Select")}
                                        search={true}
                                        options={getUniquePcs(eachPcType?.type) ?? []}
                                        value={generalData?.[eachPcType?.type]?.data?.[eachPcType?.pcName]}
                                        isDisabled={false}
                                        isMulti={false}
                                        isMandatory={true}
                                        error={generalTabErrors?.[eachPcType?.type]?.errors?.[eachPcType?.pcName]}
                                        name={eachPcType?.pcName}
                                    />
                                    <SelectWithSearch
                                        onChange={(value) =>
                                            handleSelectChange(value, eachPcType?.empTypeName, true, eachPcType?.type)
                                        }
                                        title={t(eachPcType?.empTypeLabel)}
                                        placeHolder={t("Select")}
                                        search={true}
                                        options={getEmployeeTypes(generalData?.[eachPcType?.type]?.data?.[eachPcType?.pcName]?.value, eachPcType?.type) ?? []}
                                        value={generalData?.[eachPcType?.type]?.data?.[eachPcType?.empTypeName]}
                                        isDisabled={false}
                                        isMulti={true}
                                        isMandatory={true}
                                        error={generalTabErrors?.[eachPcType?.type]?.errors?.[eachPcType?.empTypeName]}
                                        name={eachPcType?.empTypeName}
                                    />
                                    <div className="">
                                        <Button
                                            title={generalData?.[eachPcType?.type]?.editIndex !== null ? "Save" : eachPcType?.buttonTitle}
                                            handleClick={() => { handleAddAnother(eachPcType?.type) }}
                                            className="form-button"
                                        />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default General