import React, { useState, useEffect } from "react";
import ModalPopup from "components/atoms/ModalPopup";
import { ApiCall } from "services/ApiServices";
import CustomNotify from "components/atoms/CustomNotify";
import { t } from "pages/microservices/masterData/translation/Translation";
import {
  PATH_CREATE_PURCHASE_ORDER,
  PATH_EDIT_PURCHASE_ORDER,
  PATH_VIEW_PURCHASE_ORDER,
} from "constants/Paths";
import TableActions from "components/organism/Actions/TableAction";
import { useNavigate } from "react-router-dom";
import { PURCHASE_ORDER_DELETE } from "routes/ApiEndpoints";
import { ERROR, M_MASTER_DATA } from "constants/Constants";
import { getFleetOrderStatus, getFleetOrderToolTip } from "services/util/UtilService";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import { fetchOptions } from "../utils/FetchOptions";
import LinkTo from "components/atoms/LinkTo";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import FilterOrganism from "components/organism/FilterOrganism";
import { updatePageFilters } from "store/pageHistory/pageHistorySlice";
import { PurchaseOrderTableHeader } from "TableHeader";
import SortAtomForManagePage from "components/atoms/SortAtomForManagePage";
import PaginationWithPerPage from "components/molecules/PaginationWithPerPage";

interface ManagePurchaseOrderProps {
  data?: any;
  setMaterialData: any;
  handleGetVacany: any;
  dispatch: any;
  history: any;
  pageData: any;
  handleRefresh: () => void;
}

interface CompanyItem {
  value: number;
  label: string;
}

const ManagePurchaseOrder: React.FC<ManagePurchaseOrderProps> = ({
  data,
  setMaterialData,
  dispatch,
  history,
  pageData,
  handleRefresh,
  handleGetVacany,
}) => {
  const [id, setId] = useState<number | undefined>(undefined);
  const [showModal, setShowModal] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    businessUnits: [],
    status: [],
    companies: [],
  });

  const navigate = useNavigate();

  const handleSaveChanges = async () => {
    setShowModal(false);
    let postData = {
      id: id,
    };

    if (id) {
      const response = await ApiCall.service(
        PURCHASE_ORDER_DELETE,
        "POST",
        postData,
        false,
        M_MASTER_DATA
      );

      if (response?.status === 200) {
        CustomNotify({ type: "success", message: response.message });
        handleRefresh();
      } else {
        CustomNotify({ type: "error", message: response.message });
      }
    }
  };

  useEffect(() => {
    getFilterData();
  }, []);

  const getFilterData = async () => {
    try {
      const filterData = await fetchOptions();
      if (filterData?.status == 200) {
        setFilterOptions((prevState: any) => ({
          ...prevState,
          businessUnits: filterData.data.businessUnit,
          status: [
            {
              value: 1,
              label: "Order placed",
            },
            {
              value: 2,
              label: "Received",
            },
            {
              value: 3,
              label: "Partially received"
            }
          ],

        }));
      } else {
        CustomNotify({ type: "error", message: filterData.message });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleModalClose = () => setShowModal(!showModal);
  const handleView = (id: number) => {
    navigate(`${PATH_VIEW_PURCHASE_ORDER}/${id}`);
  };

  const handleEdit = (id: number) => {
    navigate(`${PATH_EDIT_PURCHASE_ORDER}/${id}`);
  };

  const handleDelete = (id: number) => {
    setShowModal(true);
    setId(id);
  };
  const userData = useSelector(selectAuth);
  const filters = [
    { name: 'businessUnits', fieldType: "multiSelect", options: filterOptions?.businessUnits, placeholder: "Business unit", filterType: 'search' },
    { name: 'ownerCompany', fieldType: "text", placeholder: "Company", filterType: 'search' },
    { name: 'brand', fieldType: "text", placeholder: "Brand", filterType: 'search' },
    { name: 'model', fieldType: "text", placeholder: "Model", filterType: 'search' },
    { name: 'status', fieldType: "singleSelect", options: filterOptions?.status, placeholder: "Status", filterType: 'search' },
  ];
  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "PurchaseOrder",
          read: true,
        },
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <div className="form-height-dashboard">
          <div className="search-bar">
            {/* <Title title={t("Purchase order")} /> */}
            <FilterOrganism
              dispatch={dispatch}
              history={history}
              updatePageFilters={updatePageFilters}
              filters={filters}
              class={{
                rowClass: 'd-grid manageCarSearchCol manageSupplierSearchCol',
                searchBtnClass: 'col-2 ms-auto'
              }}
              handleRefresh={handleRefresh}
            />
          </div>
          <div className="position-relative tableMainWrapper">
            <div>
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "PurchaseOrder",
                    create: true,
                  },
                ]}
                override={userData.isSuperAdmin}
              >
                <div className="row">
                  <div className="ManageCreateBtn">
                    <LinkTo
                      pagelink={PATH_CREATE_PURCHASE_ORDER}
                      title={t("Create purchase order")}
                      icon={faPlus}
                    />
                  </div>
                </div>
              </AccessControl>
            </div>
            <div className="table-responsive manage-purchaseOrder tableSection">
              <div className="managePurchaseOrderHeight">
                <table className="table table-hover">
                  <thead>
                    <tr className="TableHeader">
                      {PurchaseOrderTableHeader?.map((header) => (
                        <th key={header.alias}>
                          <>
                            {t(header.name)}
                            {header.issort && (
                              <SortAtomForManagePage
                                value={{ alias: header.alias }}
                                dispatch={dispatch}
                                history={history}
                                updatePageFilters={updatePageFilters}
                                handleRefresh={handleRefresh}
                              />
                            )}
                          </>
                        </th>
                      ))}
                      <th style={{ width: "15%" }}>
                        <AccessControl
                          requiredPermissions={[
                            {
                              permission: "PurchaseOrder",
                              update: true,
                              delete: true,
                            },
                          ]}
                          actions={true}
                          strict={false}
                          override={userData.isSuperAdmin}
                        >
                          {t("Actions")}
                        </AccessControl>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data && data.length > 0 ? (
                      data?.map((record: any, key: number) => {
                        return (
                          <tr key={key}>
                            <td>
                              {record.businessUnits}
                            </td>
                            <td className="align-middle">
                              {record?.ownerCompany}
                            </td>
                            <td className="align-middle">{record.brand}</td>
                            <td className="align-middle">{record.model}</td>
                            <td className="align-middle">
                              <div
                                className={getFleetOrderStatus(record.status)}
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title={getFleetOrderToolTip(record.status)}
                              ></div>
                            </td>
                            <td className="align-middle">
                              <TableActions
                                isAction={true}
                                {...(record.status == 1
                                  ? {
                                    handleEdit: handleEdit,
                                    handleArchive: handleDelete,
                                  }
                                  : { handleView: handleView })}
                                value={record.id}
                                permission={"PurchaseOrder"}
                                signature={record.project_pdf_path}
                              />
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={6} className="text-center">
                          {t("No records found")}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div
                className=""
                style={{ paddingTop: "0.25vw" }}
              >
                <PaginationWithPerPage
                  handleRefresh={handleRefresh}
                  dispatch={dispatch}
                  history={history}
                  pageData={pageData}
                />
              </div>
            </div>
          </div>
        </div>
        <ModalPopup
          show={showModal}
          onHide={handleModalClose}
          title={t("Archive confirmation")}
          body={t("Are you sure want to archive?")}
          onCloseButtonClick={handleModalClose}
          onConfirmButtonClick={handleSaveChanges}
          closeTitle="No"
          confirmTitle="Yes"
        />
      </>
    </AccessControl>
  );
};
export default ManagePurchaseOrder;
