import React, { useEffect, useState } from 'react';
import { useFormContext } from '../../context/Context';
import SelectWithSearch from 'components/atoms/SelectWithSearch';
import { t } from 'pages/microservices/masterData/translation/Translation';
import { Navigation } from '../../form-navigation/Navigation';
import { ValidationRules } from 'utils/TypeAnnotations';
import { validateForm, validateMultiSelectField, validateSelectField } from 'services/validation/ValidationService';
import { pcCategoryOptions } from '../../helpers/PCHelperFunctions';
import { EMPLOYEE_TYPE, UPDATE_ERROR_FIELD, UPDATE_FIELD } from '../../annotations/PCConstants';
import { EmployeeTypeProps } from '../../annotations/PCInterface';
import { useParams } from 'react-router-dom';

export const EmployeeType = () => {
  const { state, dispatch } = useFormContext();
  const params = useParams();
  const pcId = params?.id;
  const [employeeTypeDropdown, setEmployeeTypeDropdown] = useState<EmployeeTypeProps[]>([]);

  useEffect(() => {
    if (pcId) {
      const pcCategory = state.employeeType?.pcCategory;
      const employeeTypeList = state.dropDownData?.employeeTypeList && state.dropDownData?.employeeTypeList?.filter(
        (employeeType: EmployeeTypeProps) => employeeType.category_id === pcCategory?.value
      );
      setEmployeeTypeDropdown(employeeTypeList || []);
    }
  }, [state.dropDownData]);

  const validation = (
    name: string,
    value: any,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      pcCategory: [validateSelectField],
      employeeType: [validateMultiSelectField]
    };
    const validationErrors = validateForm(
      { ...state.employeeType, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    dispatch({
      type: UPDATE_ERROR_FIELD,
      field: name,
      error: validationErrors[name]
    });
    const isFieldValid = Object.keys(validationErrors).length === 0;
    if (isFieldValid) {
      dispatch({
        type: UPDATE_ERROR_FIELD,
        field: name,
        error: ''
      });
    }
    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const handleSelectChange = async (selectedOption: any, fieldName: string) => {
    dispatch({
      type: UPDATE_FIELD,
      tab: EMPLOYEE_TYPE,
      field: fieldName,
      value: selectedOption
    });
    if (fieldName === 'pcCategory') {
      dispatch({
        type: UPDATE_FIELD,
        tab: EMPLOYEE_TYPE,
        field: "employeeType",
        value: null
      });
      const employeeTypeList = state.dropDownData?.employeeTypeList && state.dropDownData?.employeeTypeList?.filter(
        (employeeType: EmployeeTypeProps) => employeeType.category_id === selectedOption?.value
      );
      setEmployeeTypeDropdown(employeeTypeList || []);
    }
    validation(fieldName, selectedOption, true);
  }

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="form-border" style={{ paddingTop: "2vw" }}>
            <div className="row">
              <div className="col">
                <SelectWithSearch
                  title={t("Paritair comitee category")}
                  isMandatory={true}
                  search={true}
                  options={pcCategoryOptions}
                  onChange={(e) => handleSelectChange(e, "pcCategory")}
                  isMulti={false}
                  name="pcCategory"
                  value={state.employeeType?.pcCategory}
                  error={state.errors.pcCategory}
                  placeHolder="Select"
                />
              </div>
              <div className="col">
                <SelectWithSearch
                  title={t("Employee type")}
                  isMandatory={true}
                  search={true}
                  options={employeeTypeDropdown && employeeTypeDropdown.length > 0 ? employeeTypeDropdown : state.dropDownData.employeeTypeList}
                  onChange={(e) => handleSelectChange(e, "employeeType")}
                  isMulti={true}
                  name="employeeType"
                  value={state.employeeType?.employeeType}
                  error={state.errors.employeeType}
                  placeHolder="Select"
                />
              </div>
            </div>
          </div>
          <div style={{ padding: "0.85vw 0" }}>
            <Navigation />
          </div>
        </div>
      </div>
    </>
  );
}