import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import SelectWithSearch from 'components/atoms/SelectWithSearch';
import { t } from 'pages/microservices/masterData/translation/Translation';
import { usePlanningModal, usePlanningsModalDispatch } from '../planning-redux/context/PlanningModalContext/PlanningModalContext';
import { usePlanning } from '../planning-redux/context/PlanningContext/PlanningContext';
import { PLANNING_MODAL_ACTIONS } from '../planning-redux/actions/PlanningModalActions';
import { dateUtils } from '../utils/dateUtils';
import Calender from "components/molecules/Calender";
import LabelField from 'components/atoms/LabelField';
import InputTextField from 'components/atoms/InputTextField';
import RadioField from 'components/atoms/RadioField';
import { M_MASTER_DATA } from 'constants/Constants';
import { ApiCall } from 'services/ApiServices';
import { FETCH_EACH_PLANNING } from 'routes/ApiEndpoints';
import { useFilterOptions } from '../planning-redux/context/FiltersContext/FiltersContext';
import Button from 'components/atoms/Button';
import { useSelector } from 'react-redux';
import { selectAuth } from 'features/auth/AuthSlice';
import AccessControl from "../../../../services/AccessControl";
import { ADD_LEAVE } from "constants/Paths";
import { getList } from "../utils/utils";

const PlanningModal = ({ onClose }) => {
  const userData = useSelector(selectAuth);
  const navigate = useNavigate();
  const { planningState } = usePlanning();
  const { planningModalState } = usePlanningModal();
  const { dispatchPlanningModal } = usePlanningsModalDispatch();
  const { filterOptionsState } = useFilterOptions();
  const radiooptions = ['Sequence', 'Shift'];
  const [selectedoption, setOption] = useState(planningModalState.state.type)
  const [shiftdetails, setShiftDetails] = useState({
    'shifts': null,
    'sequence': null,
    'weeks': null
  })

  const projectlist= getList(filterOptionsState.state.projects, "status", 2, true)
  const comfilteredlist = getList(filterOptionsState.state.companies, "status", 1)
  const [projfilteredlist, setProjectOpptions] = useState(projectlist)
  //to pre-fill the values of the popup with certain data
  useEffect(() => {
    const updatePlanningStateValues = async () => {
      let payload = {
        start_date: dateUtils.preFillDateFormat(planningModalState.dependentState.start_date),
        end_date: dateUtils.preFillDateFormat(planningModalState.dependentState.start_date),
        company: planningModalState.dependentState.company,
        project: planningModalState.dependentState.project
      }
      if (planningModalState.state.id != '') {
        let postdata = { id: planningModalState.state.id }
        const response = await ApiCall.service(
          FETCH_EACH_PLANNING,
          "POST",
          postdata,
          true,
          M_MASTER_DATA
        );
        try {
          if (response && response?.status == 200 && response?.plandetails.length > 0) {
            let plan = response.plandetails[0]
            await handleProjectList(plan.company_id)
            let sequence = await getFilteredShiftsorSequence(plan.project_id, 'sequence')
            let shifts = await getFilteredShiftsorSequence(plan.project_id, 'shifts')
            let weeks = (sequence.length > 0 && plan.type == 'Sequence') ?
              sequence.filter(item => item.value === plan.type_id).map(item => item.weeks).flat() : []
            setShiftDetails({
              shifts: (shifts.length > 0) ? shifts : null,
              sequence: (sequence.length > 0) ? sequence : null,
              weeks: weeks
            })
            setOption(plan.type)
            const startdatestring = plan?.start_date_time?.split(' ');
            const enddatestring = plan?.end_date_time?.split(' ');
            let weeknum = weeks != [] ? weeks.find((option) => option.value === plan.week) : [];
            payload = {
              "company": filterOptionsState.state.companies.find((option) => option.value === plan.company_id),
              "project": filterOptionsState.state.projects.find((option) => option.value === plan.project_id),
              "start_date": startdatestring?.[0],
              "end_date": enddatestring?.[0],
              "typeid": plan.type_id,
              "week_number": weeknum,
              "from_time": (startdatestring?.[1] ?? "00:00"),
              "to_time": (enddatestring?.[1] ?? "00:00"),
              "type": plan.type,
              "plan_status": "active",
              "status": true,
              "id": plan.planid
            }
          }
        } catch (error) {
        }
      } else {
        await handleShiftsOrSequence(planningModalState.dependentState.project);
      }
      dispatchPlanningModal({
        type: PLANNING_MODAL_ACTIONS.BULK_UPDATE_PLANNING_MODEL_FIELD,
        payload: payload
      })
    }
    updatePlanningStateValues()
  }, [])

  const getFilteredShiftsorSequence = (project_id, type) => {
    let data = (filterOptionsState.state.projects).filter(item => item.value === project_id).map(item => item[type]).flat();
    return data;
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    onClose();
  };

  const handleNewPlan = (e) => {
    onClose(e, true)
  };

  const handleApplyLeave = () => {
    console.log(planningModalState.dependentState.employee_id);
    navigate(ADD_LEAVE);
  }

  const handleSelectChange = async (value, fieldName, isMultiSelect) => {
    fetchValues(fieldName, value)
    fieldName = radiooptions.includes(fieldName) ? 'typeid' : fieldName
    updatePlanningModalFiled(fieldName, value)
    updatePlanningModalFiled(fieldName, '', 'UPDATE_PLANNING_MODEL_ERROR_FIELD')
  };


  const updatePlanningModalFiled = (fieldname, value, type = 'UPDATE_PLANNING_MODEL_FIELD') => {
    dispatchPlanningModal({
      type: PLANNING_MODAL_ACTIONS[type],
      payload: {
        field: fieldname,
        value: value
      }
    })
  }

  const fetchValues = async (fieldname, value) => {
    switch (fieldname) {
      case 'company':
        await handleProjectList(value)
        break;
      case 'project':
        // if ((planningModalState.state.company.value == undefined || planningModalState.state.company.value == '')) {
          await handleCompanyList(value)
          dispatchPlanningModal({
            type: PLANNING_MODAL_ACTIONS.BULK_UPDATE_PLANNING_MODEL_FIELD,
            payload: {
              typeid: null,
              week_number: null,
            }
          })
          setOption(null)
        // }
        await handleShiftsOrSequence(value)
        break;
      case 'Sequence':
        let weeks = null;
        if (value.value != '') {
          weeks = shiftdetails.sequence.filter(item => item.value === value.value).map(item => item.weeks).flat();
        }
        setShiftDetails({
          ...shiftdetails,
          weeks: weeks
        })
        break;
      case 'Shift':
        let [start, end] = ['00:00:00', '00:00:00'];
        if (value.value != '') {
          [start, end] = shiftdetails.shifts.filter(item => item.value === value.value).map(item => [item.start, item.end]).flat();
        }
        dispatchPlanningModal({
          type: PLANNING_MODAL_ACTIONS.BULK_UPDATE_PLANNING_MODEL_FIELD,
          payload: {
            from_time: start,
            to_time: end,
          }
        })
        break;
      default:
        break;
    }
    return fieldname;
  }

  const handleProjectList = async (company) => {
    updatePlanningModalFiled("project", [])
    // let projectlist = filterOptionsState.state.projects;
    let list = projectlist;
    let selectedCompanyId = company.value != '' || company.value != undefined ? company.value : company;
    if (selectedCompanyId != '') {
      // const filteredProjects = filterOptionsState.state.companyProjects
      //   .filter((project) => (project.company_id === selectedCompanyId))
      //   .map((project) => project.project_id);
      const filteredProjects = filterOptionsState.state.projects
        .filter((project) => (project.company_id === selectedCompanyId))
        .map((project) => project.value);
      // projectlist = filterOptionsState.state.projects.filter((project) =>
      list = projfilteredlist.filter((project) =>
        filteredProjects.includes(project.value)
      )
    }
    setProjectOpptions(list)
  };

  const handleCompanyList = async (project) => {
    let selectedprojectid = project.value != '' || project.value != undefined ? project.value : project;
    if (selectedprojectid != '') {
      // const filteredcompany = filterOptionsState.state.companyProjects
      // .find((company) => company.project_id === selectedprojectid)?.company_id;
      // let companyval = filterOptionsState.state.companies.find((option) => option.value === filteredcompany)
      let companyval = comfilteredlist.find((option) => option.value === project.company_id)
      updatePlanningModalFiled("company", companyval)
      await handleProjectList(companyval)
    }
  };

  const handleShiftsOrSequence = async (value) => {
    value = value.value == undefined ? value : value.value
    let sequence = getFilteredShiftsorSequence(value, 'sequence')
    let shifts = await getFilteredShiftsorSequence(value, 'shifts')
    setShiftDetails({
      ...shiftdetails,
      shifts: shifts,
      sequence: sequence
    })
  }

  const handleChange = (e) => {
    setOption(e.target.value)
    dispatchPlanningModal({
      type: PLANNING_MODAL_ACTIONS.BULK_UPDATE_PLANNING_MODEL_FIELD,
      payload: {
        type: e.target.value,
        typeid: [],
      }
    })
    updatePlanningModalFiled('type', '', 'UPDATE_PLANNING_MODEL_ERROR_FIELD')
  }

  const handleEmployeeChange = (value) => {
    dispatchPlanningModal({
      type: PLANNING_MODAL_ACTIONS.UPDATE_PLANNING_MODAL_DEPENDENT_FIELD,
      payload: {
        field: "employee_id",
        value: value.value
      }
    })
  }
  
  return (
    <div className="popup-container">
      <div className="popup">
        <form onSubmit={handleSubmit}>
          <div className="d-grid" style={{ gridTemplateColumns: "repeat(2, 1fr)", gap: "0 1vw" }}>
            {planningState.planningOverview.value == 'employee' && 
            <>
            <div>
              <SelectWithSearch
                search={true}
                onChange={(value) => handleSelectChange(value, "project", false)}
                title={t("Project")}
                placeHolder={t("Select")}
                options={projfilteredlist}
                value={planningModalState.state.project}
                isDisabled={planningModalState.state.id == '' ? false : true}
                isMulti={false}
                isMandatory={true}
                error={planningModalState.errorState.project}
                name={"project"}
                id={"project_id"}
              ></SelectWithSearch>
            </div>
            <div>
              <SelectWithSearch
                search={true}
                onChange={(value) => handleSelectChange(value, "company", false)}
                title={t("Company")}
                placeHolder={t("Select")}
                options={comfilteredlist}
                value={planningModalState.state.company}
                isDisabled={planningModalState.state.id == '' ? false : true}
                isMulti={false}
                isMandatory={true}
                error={planningModalState.errorState.company}
                name={"company"}
                id={"companyId"}
              ></SelectWithSearch>
            </div>
            </>}
            {planningState.planningOverview.value == 'project' && 
              <div>
                <SelectWithSearch
                  search={true}
                  onChange={(value) => handleEmployeeChange(value)}
                  title={t("Employees")}
                  placeHolder={t("Select")}
                  options={filterOptionsState.state.employees_list}
                  value={planningModalState.dependentState.employee_id}
                  isDisabled={planningModalState.state.id == '' ? false : true}
                  isMulti={false}
                  isMandatory={true}
                  error={planningModalState.errorState.employee}
                  name={"employee"}
                  id={"employee_id"}
                ></SelectWithSearch>
              </div>
            }
            <div className='d-flex' style={{ gap: "1vw" }}>
              <div className="w-50 position-relative">
                <Calender
                  onChange={(date) => handleSelectChange(date, "start_date", false)}
                  selectedDate={planningModalState.state.start_date}
                  label={t("Start date")}
                  isMandatory={false}
                  name={"start_date"}
                  error={planningModalState.errorState.start_date}
                  isDisabled={planningModalState.state.id == '' ? false : true}
                ></Calender>
              </div>
              <div className="w-50 position-relative">
                <Calender
                  onChange={(date) => handleSelectChange(date, "end_date", false)}
                  selectedDate={planningModalState.state.end_date}
                  label={t("End date")}
                  isMandatory={false}
                  name={"end_date"}
                  error={planningModalState.errorState.end_date}
                  isDisabled={planningModalState.state.id == '' ? false : true}
                ></Calender>
              </div>
            </div>
            {(planningModalState.state.project.value != '' && planningModalState.state.project.value != undefined) &&
              <>
                <div className="addBtnMargin" style={{ paddingTop: "0.6vw" }}>
                  {radiooptions.map((field, index) => (
                    <RadioField
                      key={index}
                      name={field}
                      value={field}
                      ischecked={field === selectedoption}
                      handleChange={handleChange}
                      label={field.charAt(0).toUpperCase() + field.substring(1)}
                      id={field}
                      className='me-3 d-flex align-items-center'
                    />
                  ))}
                  {planningModalState.errorState.type && (
                    <div className="mb-2 height-20 text-danger">
                      {planningModalState.errorState.type !== undefined ? planningModalState.errorState.type : ""}
                    </div>
                  )}
                </div>
                {selectedoption == 'Sequence' &&
                  <>
                    <div>
                      <SelectWithSearch
                        search={true}
                        onChange={(value) => handleSelectChange(value, "Sequence", false)}
                        title={t("Sequence")}
                        placeHolder={t("Select")}
                        options={shiftdetails.sequence}
                        value={planningModalState.state.typeid}
                        isDisabled={false}
                        isMandatory={true}
                        error={planningModalState.errorState.typeid}
                        name={"sequence"}
                        id={"sequenceId"}
                      ></SelectWithSearch>
                    </div>
                    <div>
                      <SelectWithSearch
                        search={true}
                        onChange={(value) => handleSelectChange(value, "week_number", false)}
                        title={t("Week number")}
                        placeHolder={t("Select")}
                        // options={planningModalState.state.sequence.value ? shiftdetails.sequence[planningModalState.state.sequence.label].week_number : []}
                        options={shiftdetails.weeks}
                        value={planningModalState.state.week_number}
                        isDisabled={false}
                        isMandatory={true}
                        name={"week_name"}
                        id={"week_number"}
                        error={planningModalState.errorState.week_number}
                      ></SelectWithSearch>
                    </div>
                  </>
                }
                {selectedoption == 'Shift' &&
                  <>
                    <div>
                      <SelectWithSearch
                        search={true}
                        onChange={(value) => handleSelectChange(value, "Shift", false)}
                        title={t("Shift")}
                        placeHolder={t("Select")}
                        options={shiftdetails.shifts}
                        value={planningModalState.state.typeid}
                        isDisabled={false}
                        isMandatory={true}
                        error={planningModalState.errorState.typeid}
                        name={"shift"}
                        id={"shiftId"}
                      ></SelectWithSearch>
                    </div>
                    <div className='d-flex' style={{ gap: "1vw" }}>
                      <div className="w-50">
                        <LabelField
                          title="From time"
                          isMandatory={false}
                          className='form-label field-label mb-0'
                          htmlfor="from_time"
                        ></LabelField>
                        <InputTextField
                          id="from_time"
                          type="time"
                          className="form-control field-shadow"
                          value={planningModalState.state.from_time}
                          isDisabled={false}
                          // placeholder = ""
                          handleChange={(e) => handleSelectChange(e.target.value, "from_time", false)}
                          name="from_time"
                          error={planningModalState.errorState.from_time}
                        ></InputTextField>
                      </div>

                      <div className="w-50">
                        <LabelField
                          title="To Time"
                          isMandatory={false}
                          className='form-label field-label mb-0'
                          htmlfor="to_time"
                        ></LabelField>

                        <InputTextField
                          id="to_time"
                          type="time"
                          className="form-control field-shadow"
                          value={planningModalState.state.to_time}
                          isDisabled={false}
                          // placeholder = ""
                          handleChange={(e) => handleSelectChange(e.target.value, "to_time", false)}
                          name="to_time"
                          error={planningModalState.errorState.to_time}
                        ></InputTextField>
                      </div>
                    </div>
                  </>
                }
              </>
            }
            {/* <div className="form-row d-flex">
            <RepeatPlannings />
        </div> */}
          </div>
        </form>
      </div>
      <div className='d-flex align-items-center justify-content-end'>
        <AccessControl
          requiredPermissions={[
            {
              permission: "Planning",
              create: true,
            },
          ]}
          override={userData.isSuperAdmin}
        >
          {planningState.planningOverview.value == 'employee' && 
          <div className={`${(planningModalState.state.id != '') && "marginRightPoint5" }`}>
            <Button
              className={"form-button"}
              handleClick={handleApplyLeave}
            >
              {t("Add absence")}
            </Button>
          </div>
          }
        </AccessControl>
        {(planningModalState.state.id != '') &&
          <AccessControl
            requiredPermissions={[
              {
                permission: "Planning",
                create: true,
              },
            ]}
            override={userData.isSuperAdmin}
          >
            <div>
              <Button
                className={"form-button"}
                handleClick={handleNewPlan}
              >
                {t(" Add new plan")}
              </Button>
            </div>
          </AccessControl>
        }
      </div>
    </div>
  );
};

export default PlanningModal;
