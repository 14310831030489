import SelectWithSearch from "components/atoms/SelectWithSearch";
import React from "react";
import { t } from "../../translation/Translation";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import Calender from "components/molecules/Calender";

const HeaderDetails = (props: any) => {
    return (
        <>
            <div className="d-grid createInvoiceHeader">
                <div>
                    <SelectWithSearch
                        title={t("Business unit")}
                        search
                        options={props?.dropdownData?.businessUnits}
                        onChange={(e) => props?.handleSelectChange(e, "businessUnit")}
                        name="businessUnit"
                        value={props?.formData?.businessUnit}
                        placeHolder="Select"
                        isDisabled={props?.disabled}
                    />
                </div>
                <div>
                    <SelectWithSearch
                        title={t("Company")}
                        search
                        options={props?.dropdownData?.companies}
                        onChange={(e) => props?.handleSelectChange(e, "company")}
                        name="company"
                        value={props?.formData?.company}
                        placeHolder="Select"
                        isDisabled={props?.disabled}
                    />
                </div>
                <div>
                    <SelectWithSearch
                        title={t("Project")}
                        search
                        options={props?.dropdownData?.projects}
                        onChange={(e) => props?.handleSelectChange(e, "project")}
                        name="project"
                        value={props?.formData?.project}
                        placeHolder="Select"
                        isDisabled={props?.disabled}
                    />
                </div>
                <div>
                    <LabelWithInputField
                        name="documentNumber"
                        handleChange={(e) => props?.handleFieldChange(e)}
                        id="documentNumber"
                        label={t("Document no.")}
                        type="text"
                        value={props?.formData?.documentNumber}
                        error={props?.errors?.documentNumber}
                        isDisabled={props?.disabled}
                        isMandatory
                    />
                </div>
                <div>
                    <LabelWithInputField
                        name="reference"
                        handleChange={(e) => props?.handleFieldChange(e)}
                        id="reference"
                        label={t("Reference")}
                        type="text"
                        value={props?.formData?.reference}
                        isDisabled={props?.disabled}
                    />
                </div>
                <div>
                    <SelectWithSearch
                        title={t("Type")}
                        search
                        options={props?.dropdownData?.types}
                        onChange={(e) => props?.handleSelectChange(e, "type")}
                        name="type"
                        value={props?.formData?.type}
                        placeHolder="Select"
                        isDisabled={props?.disabled}
                    />
                </div>
                <div>
                    <SelectWithSearch
                        title={t("Status")}
                        search
                        options={props?.dropdownData?.statuses}
                        onChange={(e) => props?.handleSelectChange(e, "status")}
                        name="status"
                        value={props?.formData?.status}
                        placeHolder="Select"
                        isDisabled={props?.disabled}
                    />
                </div>
                <div>
                    <Calender
                        placeHolder="dd-mm-yyyy"
                        onChange={(date) => props?.handleDateChange(date, "invoiceDate")}
                        label={t("Invoice date")}
                        name="invoiceDate"
                        selectedDate={props?.formData?.invoiceDate}
                        maxDate={new Date()}
                        isDisabled={props?.disabled}
                    />
                </div>
                <div>
                    <Calender
                        placeHolder="dd-mm-yyyy"
                        onChange={(date) => props?.handleDateChange(date, "expirationDate")}
                        label={t("Expiration date")}
                        name="expirationDate"
                        selectedDate={props?.formData?.expirationDate}
                        maxDate={new Date()}
                        isDisabled={props?.disabled}
                />
                </div>
                <div>
                    <SelectWithSearch
                        title={t("Payment terms")}
                        search
                        options={props?.dropdownData?.paymentTerms}
                        onChange={(e) => props?.handleSelectChange(e, "paymentTerms")}
                        name="paymentTerms"
                        value={props?.formData?.paymentTerms}
                        placeHolder="Select"
                        isDisabled={props?.disabled}
                    />
                </div>
                <div>
                    <SelectWithSearch
                        title={t("Standard vat rate")}
                        search
                        options={props?.dropdownData?.standardVatRates}
                        onChange={(e) => props?.handleSelectChange(e, "standardVatRate")}
                        name="standardVatRate"
                        value={props?.formData?.standardVatRate}
                        placeHolder="Select"
                        isDisabled={props?.disabled}
                    />
                </div>
                <div>
                    <SelectWithSearch
                        title={t("Sales account")}
                        search
                        options={props?.dropdownData?.standardSalesAccounts}
                        onChange={(e) => props?.handleSelectChange(e, "standardSalesAccount")}
                        name="standardSalesAccount"
                        value={props?.formData?.standardSalesAccount}
                        placeHolder="Select"
                        isDisabled={props?.disabled}
                    />
                </div>
            </div>
        </>
    );
};

export default HeaderDetails;
