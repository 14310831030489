import {
  CAR_CREATE,
  CAR_EDIT,
  FLEET_PREVIOUS_DELETE,
  FLEET_PREVIOUS_SAVE,
} from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "constants/Constants";
import { PATH_MANAGE_CAR, PATH_MANAGE_MATERIAL, PATH_VACANCY_MANAGE } from "constants/Paths";
import { FormProviderWrapper } from "utils/multiTabForm/formWrapper/Index";
import { useLocation, useParams } from "react-router-dom";
import { rentalFunction, setCarStatus, usersSetFunction } from "./customFunctions";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";

export type CustomFnType = (payload: any, dataObj: any) => any;

const AddCar = () => {
  const location = useLocation();
  const path = location.pathname;
  const { id: routeId } = useParams<{ id: string }>();

  const customActions: CustomFnType = (payload: any, dataObj: any) => {
    switch (payload.type) {
      case "rental":
        return rentalFunction(payload, [
          "interCompanyPrice",
          "interEmployeePrice",
          "externalPrice",
        ]);
      case "users":
        return usersSetFunction(payload, dataObj, 'car');
      case "active":
        return setCarStatus(payload);
      default: return "Called with wrong key";
    }
  };

  const permissionType = routeId ? "update" : "create";
  const permissionObject: any = {
    permission: "Car",
  };
  permissionObject[permissionType] = true;
  const userData = useSelector(selectAuth);

  const pathName = location?.pathname?.split('/');
  const formTitle = pathName.includes('clone') ? "Clone car" : (pathName.includes('edit') ? "Edit car" : "Create car");

  return (
    <>
      <AccessControl
        requiredPermissions={[permissionObject]}
        renderNoAccess={true}
        override={userData.isSuperAdmin}
      >
        <div className="addCarWrapper">
          <FormProviderWrapper
            backToManage={"/fleetOverview/car"}
            formName={"Car"}
            dataSubmissionEndPoint={CAR_CREATE}
            microservice={M_MASTER_DATA}
            dataFetchEndPoint={CAR_EDIT}
            entryId={routeId}
            customFn={customActions}
            dataSubmissionForTableStructure={`${FLEET_PREVIOUS_SAVE}/car`}
            deleteDataOfTableStructure={`${FLEET_PREVIOUS_DELETE}/car`}
            formTitle={formTitle}
            mode={pathName.includes('clone') ? 'clone' : (pathName.includes('edit') ? 'edit' : 'create')}
          />
        </div>
      </AccessControl>
    </>
  );
};

export default AddCar;
