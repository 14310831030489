import React, { useState } from "react";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import Button from "components/atoms/Button";
import { ApiCall } from "services/ApiServices";
import LoadingIcon from "utils/LoadingIcon";
import {
  validateEmail,
  validateRequired,
  validateForm,
} from "services/validation/ValidationService";
import { Link, useNavigate } from "react-router-dom";
import { FORGOT_PASSWORD } from "routes/ApiEndpoints";
import ImageField from "components/atoms/ImageField";
import CustomNotify, { NotifyType } from "components/atoms/CustomNotify";

interface ValidationRules {
  [key: string]: Function[];
}
interface DataProps {
  email?: string;
}
export const ForgotPasswordForm = () => {
  const navigate = useNavigate();
  const [data, setdata] = useState<DataProps>({
    email: "",
  });

  const [errors, setErrors] = useState<DataProps>({});
  const [loading, setLoading] = useState(false);

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setdata({ ...data, [e.target.name]: e.target.value });
    validation(e.target.name, e.target.value, true);
  };

  const validation = (
    name: string,
    value: string,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      email: [validateRequired, validateEmail],
    };

    const validationErrors = validateForm(
      { ...data, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation && Object.keys(errors).length > 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }

    return true;
  };

  const submitHandler = async (e: React.FormEvent) => {
    e.preventDefault();
    const { name, value } = e.target as HTMLInputElement;

    if (validation(name, value)) {
      setLoading(true);
      const response = await ApiCall.service(FORGOT_PASSWORD, "POST", data);
      if (response.status === 200) {
        let notificationType: NotifyType = "success";
        setLoading(false);
        setTimeout(() => {
          navigate("/login");
        }, 2000); // Navigate after 5 seconds
        CustomNotify({ type: notificationType, message: response.msg });
      } else if (response.status === 400) {
        setLoading(false);
        setErrors({
          email: response.msg,
        });
      }
    }
  };

  return (
    <>
      <div className="container-fluid login-bg">
        <div className="row">
          <div className="col-6 px-0 vh-100">
            <ImageField
              altText="Banner"
              className="w-100 h-100 img-fluid object-fit-cover"
              src="/static/images/loginImage.jpg"
            />
          </div>
          <div className="col-6 align-self-center">
            <form
              onSubmit={submitHandler}
              style={{ width: "60%", margin: "auto" }}
            >
              <div>
                <div
                  className="col-md-12 text-center"
                  style={{ marginBottom: "3vw" }}
                >
                  <ImageField
                    altText="Logo"
                    className="text-center logo-width"
                    src="static/images/logo.svg"
                  />
                </div>
                <div
                  className="text-center login-title"
                  style={{ marginBottom: "2vw" }}
                >
                  Forgot password?
                </div>
                <p style={{ marginBottom: "1vw" }}>
                  Change your password in three easy steps. This will help you to secure your password!
                </p>
                <ul style={{ paddingLeft: "2.5vw", marginBottom: "1vw" }}>
                  <li>Enter your email address below</li>
                  <li> Our system will send you an email with reset password <link rel="stylesheet" href="" /></li>
                  <li>Use the link to reset your password</li>
                </ul>
              </div>
              <LabelWithInputField
                isMandatory={true}
                name="email"
                handleChange={changeHandler}
                value={data.email}
                id="email"
                label="Email"
                placeholder="Email"
                type="email"
                error={errors.email}
              />
              <div
                className={`d-flex justify-content-between align-items-center`}
              >
                {!loading && (
                  <Link to="/login" className="link-color">
                    Back to login
                  </Link>
                )}
                {!loading ? (
                  <Button
                    title="Reset Password"
                    type="submit"
                    className="form-button"
                  />
                ) : (
                  <LoadingIcon iconType="bars" color="#00a5ce" />
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
