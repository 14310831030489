import SelectWithSearch from "components/atoms/SelectWithSearch";
import React, { useEffect, useState } from "react";
import { Option, ValidationRules } from "utils/TypeAnnotations";
import { t } from "../translation/Translation";
import LabelWithTextAreaField from "components/molecules/LabelWithTextAreaField";
import TitleAtom from "components/atoms/Title";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import { Link, useNavigate, useParams } from "react-router-dom";
import Button from "components/atoms/Button";
import { ApiCall } from "services/ApiServices";
import {
  CREATE_WAGE_PROPOSAL,
  GET_FUNCTION_FOR_PC,
  GET_WAGE_PROPOSAL_DATA,
  GET_WAGE_PROPOSAL_ELEMENTS,
} from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "constants/Constants";
import CustomNotify from "components/atoms/CustomNotify";
import { LabelWithInputFieldAndUnit } from "../templates/atoms/LabelWithInputFieldAndUnit";
import {
  validateForm,
  validateMultiSelectField,
  validateNumber,
  validatePercentage,
  validateRequired,
  validateSelectField,
} from "services/validation/ValidationService";
import { mapToSelect } from "utils/MapToSelect";
import {
  SalaryBenefitProps,
  WageProposalProps,
  initialDropdownData,
  initialSalaryBenefits,
} from "./WageInterfaces";
import { PATH_WAGE_PROPOSAL } from "constants/Paths";
import { exit } from "process";
import EncryptDecryptService from "services/EncryptDecryptService";
import BackButton from "components/atoms/BackButton";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";

const CreactWageProposal = () => {
  const loginUserDetails = useSelector(selectAuth);
  const queryParam = useParams();
  const candidateId = queryParam.candidateId;
  const wageId = queryParam.wageId;
  const queryParams = new URLSearchParams(window.location.search);

  // Check if mode is 'view'
  const isViewMode = queryParams.get("mode") === "view";

  const navigate = useNavigate();
  const initialWageProposal = {
    userId: Number(candidateId),
    employeeContractType: null,
    businessUnit: null,
    paritairComitee: [],
    function: [],
    salaryCategory: null,
    salaryType: null,
    salary: undefined,
    salaryBenefits: null,
    info: "",
  };

  const [formData, setFormData] =
    useState<WageProposalProps>(initialWageProposal);
  const [dropdownData, setDropdownData] = useState(initialDropdownData);
  const [salaryBenefits, setSalaryBenefits] = useState<SalaryBenefitProps[]>([
    initialSalaryBenefits,
  ]);
  const [errors, setErrors] = useState<any>({
    benefitValue: "",
  });

  useEffect(() => {
    //dropdown data
    ApiCall.getService(
      `${GET_WAGE_PROPOSAL_ELEMENTS}/${candidateId}`,
      "GET",
      M_MASTER_DATA,
      true
    ).then((response) => {
      if (response.status === 200) {
        setDropdownData(response.data);
        if (!wageId) {
          setFormData((prevData: any) => ({
            ...prevData,
            employeeContractType: response?.data?.default?.employeeContractType?.value ?? prevData.employeeContractType,
            businessUnit: response?.data?.default?.businessUnit?.value ?? prevData.businessUnit,
            function: response?.data?.default?.function?.map((item: Option) => item.value) ?? prevData.function,
            paritairComitee: response?.data?.default?.functionsToPc?.map((item: Option) => item.value) ?? prevData.paritairComitee,
          }));
        }
      }
    });
    if (wageId) {
      ApiCall.service(
        GET_WAGE_PROPOSAL_DATA,
        "POST",
        { id: wageId },
        false,
        M_MASTER_DATA
      ).then((response) => {
        if (response?.status === 200) {
          const wageProposalData = response?.data?.data;
          setFormData(wageProposalData);
          setSalaryBenefits(wageProposalData?.salaryBenefits);
          if (dropdownData?.paritairComitee.length > 0) {
            const pc: Object = dropdownData?.paritairComitee.filter(
              (pc: Option) => pc.value === wageProposalData?.paritairComitee
            );
            fetchPcRetaltedFunctions(pc);
          }
        }
      });
    }
  }, []);

  const validation = (
    name: any = null,
    value: any = null,
    benefitType: any = null,
    isSingleFieldValidation = false
  ) => {
    let validationRules: ValidationRules = {
      employeeContractType: [validateSelectField],
      businessUnit: [validateSelectField],
      paritairComitee: [validateMultiSelectField],
      function: [validateMultiSelectField],
      salary: [validateRequired],
      salaryType: [validateSelectField],
      salaryCategory: [validateSelectField],
      salaryBenefits: [validateMultiSelectField],
    };
    if (name === "benefitValue") {
      validationRules = {
        ...validationRules,
        benefitValue:
          benefitType === 2 ? [validatePercentage] : [validateNumber],
      };
    }

    const validationErrors = validateForm(
      {
        ...formData,
        [name]: value,
      },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );
    if (isSingleFieldValidation) {
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const fetchPcRetaltedFunctions = async (data: any) => {
    const functionResponse = await ApiCall.service(
      GET_FUNCTION_FOR_PC,
      "POST",
      data[0],
      true,
      M_MASTER_DATA
    );
    if (functionResponse?.status == 200) {
      setDropdownData((prevData: any) => ({
        ...prevData,
        function: functionResponse.data && functionResponse.data?.functions
          ? mapToSelect(functionResponse.data?.functions)
          : [],
      }));
    }
    setFormData((prevData: any) => ({
      ...prevData,
      function: null,
    }));
  };

  const handleSelectChange = async (
    selectedOption: Option | Object[] | any,
    fieldName: string,
    index?: number
  ) => {
    if (fieldName === "paritairComitee") {
      fetchPcRetaltedFunctions(selectedOption);
    }
    if (fieldName === "salaryBenefits") {
      // Handle salary benefits selection
      const selectedBenefits = selectedOption.map((option: any) => ({
        value: option.value,
        label: option.label,
        benefitValueType: option.benefitValueType,
        benefitValue: option.benefitValue,
      }));
      setSalaryBenefits(selectedBenefits);
      setFormData((prevData: WageProposalProps) => ({
        ...prevData,
        salaryBenefits: selectedBenefits,
      }));
      validation(fieldName, selectedOption, null, true);
      return;
    } else if (fieldName === "benefitValueType") {
      // Handle changes to benefit value type or value
      const updatedSalaryBenefits = [...salaryBenefits];
      if (index !== undefined) {
        updatedSalaryBenefits[index] = {
          ...updatedSalaryBenefits[index],
          [fieldName]: selectedOption?.value, // For benefit value, can be a number
        };
      }
      setSalaryBenefits(updatedSalaryBenefits);
      setFormData((prevData: WageProposalProps) => ({
        ...prevData,
        salaryBenefits: updatedSalaryBenefits,
      }));
      return;
    }

    // Update the form data for other fields
    if (fieldName === 'paritairComitee' || fieldName === 'function') {

      setFormData((prevData: WageProposalProps) => ({
        ...prevData,
        [fieldName]: selectedOption,
      }));
    } else {
      setFormData((prevData: WageProposalProps) => ({
        ...prevData,
        [fieldName]: selectedOption?.value,
      }));
    }
    validation(fieldName, selectedOption, null, true);
  };

  const handleFieldChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index?: number
  ) => {
    const { name, value } = e.target;
    if (name === "benefitValue") {
      // Handle changes to benefit value type or value
      const updatedSalaryBenefits = [...salaryBenefits];
      if (index !== undefined) {
        updatedSalaryBenefits[index] = {
          ...updatedSalaryBenefits[index],
          [name]: Number(value), // For benefit value, can be a number
        };
      }
      setSalaryBenefits(updatedSalaryBenefits);
      setFormData((prevData: WageProposalProps) => ({
        ...prevData,
        salaryBenefits: updatedSalaryBenefits,
      }));
      const benefitType =
        index !== undefined
          ? updatedSalaryBenefits[index].benefitValueType
          : null;
      validation(name, value, benefitType, true);
      return;
    }
    setFormData((prevData: WageProposalProps) => ({
      ...prevData,
      [name]: value,
    }));
    validation(name, value, null, true);
  };

  const handleSave = async (e: React.FormEvent) => {
    const { name, value } = e.target as HTMLInputElement;
    if (validation(name, value)) {
      //Check for pc and function
      if ((Array.isArray(formData?.function) && formData?.function?.length > 1) || (Array.isArray(formData?.paritairComitee) && formData?.paritairComitee?.length > 1)) {
        return;
      }
      const filteredFormData = {
        ...formData,
        function: formData?.function?.length > 0
          ? (typeof formData.function[0] === 'number' ? formData.function[0] : formData.function[0]?.value)
          : null,
        paritairComitee: formData?.paritairComitee?.length > 0
          ? (typeof formData.paritairComitee[0] === 'number'
            ? formData.paritairComitee[0]
            : formData.paritairComitee[0]?.value)
          : null
      };

      Object.assign(filteredFormData, { id: Number(wageId) ?? null });

      const response = await ApiCall.service(
        CREATE_WAGE_PROPOSAL,
        "POST",
        filteredFormData,
        false,
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        const { id, business_unit_id, user_id } = response?.data;

        // navigate(`${PATH_WAGE_PROPOSAL}/${}`);
        const data = {
          businessUnit: business_unit_id,
          userId: user_id,
          wageId: id,
          approve: false,
          reject: false,
          contractType:
            formData?.employeeContractType !== null &&
            dropdownData?.employeeContractType?.find(
              (contract: any) =>
                contract?.value === formData?.employeeContractType
            ),
        };
        const append = EncryptDecryptService.encryptData(JSON?.stringify(data));
        const encodedData = encodeURIComponent(append);
        navigate(`${PATH_WAGE_PROPOSAL}/?data=${encodedData}`);
        CustomNotify({ type: "success", message: response.message });
        // navigate(-1);
      }
    }
  };

  const permissionType = candidateId ? "update" : "create";
  const permissionObject: any = {
    permission: "Wage proposal",
  };
  permissionObject[permissionType] = true;

  return (
  //   <AccessControl
  //   requiredPermissions={[permissionObject]}
  //   renderNoAccess={true}
  //   override={loginUserDetails.isSuperAdmin}
  // >
    <>
      <div className="search-bar row">
        <TitleAtom
          title={
            wageId && !isViewMode
              ? t("Edit wage proposal")
              : isViewMode
                ? t("View wage proposal")
                : t("Create wage proposal")
          }
        />
      </div>
      <div className="row">
        <div className="col-12">
          <div className="form-border">
            <div
              className="d-grid"
              style={{ gridTemplateColumns: "repeat(4, 1fr)", gap: "0 1vw" }}
            >
              <div>
                <SelectWithSearch
                  title={t("Employee contract type")}
                  isMandatory={true}
                  name="employeeContractType"
                  search={true}
                  options={dropdownData.employeeContractType}
                  onChange={(e) =>
                    handleSelectChange(e, "employeeContractType")
                  }
                  isMulti={false}
                  value={formData.employeeContractType}
                  error={errors.employeeContractType}
                  placeHolder="Select"
                  isDisabled={isViewMode}
                />
              </div>
              <div>
                <SelectWithSearch
                  title={t("Business unit")}
                  isMandatory={true}
                  name="businessUnit"
                  search={true}
                  options={dropdownData.businessUnit}
                  onChange={(e) => handleSelectChange(e, "businessUnit")}
                  isMulti={false}
                  value={formData.businessUnit}
                  error={errors.businessUnit}
                  placeHolder="Select"
                  isDisabled={isViewMode}
                />
              </div>
              <div>
                <SelectWithSearch
                  title={t("Paritair comitee")}
                  isMandatory={true}
                  name="paritairComitee"
                  search={true}
                  options={dropdownData.paritairComitee}
                  onChange={(e) => handleSelectChange(e, "paritairComitee")}
                  isMulti={true}
                  value={formData.paritairComitee}
                  error={errors.paritairComitee}
                  placeHolder="Select"
                  isDisabled={isViewMode}
                />
                <span className="text-danger">{Array.isArray(formData?.paritairComitee) && formData?.paritairComitee.length > 1 ? "Please select one paritairComitee" : ""}</span>
              </div>
              <div>
                <SelectWithSearch
                  title={t("Function")}
                  isMandatory={true}
                  name="function"
                  search={true}
                  options={dropdownData.function}
                  onChange={(e) => handleSelectChange(e, "function")}
                  isMulti={true}
                  value={formData.function}
                  error={errors.function}
                  placeHolder="Select"
                  isDisabled={isViewMode}
                />
                <span className="text-danger">{Array.isArray(formData?.function) && formData?.function.length > 1 ? "Please select one function" : ""}</span>
              </div>
              <div>
                <SelectWithSearch
                  title={t("Salary category")}
                  isMandatory={true}
                  name="salaryCategory"
                  search={true}
                  options={dropdownData.salaryCategory}
                  onChange={(e) => handleSelectChange(e, "salaryCategory")}
                  isMulti={false}
                  value={formData.salaryCategory}
                  placeHolder="Select"
                  error={errors.salaryCategory}
                  isDisabled={isViewMode}
                />
              </div>
              <div>
                <LabelWithInputField
                  isMandatory={true}
                  name="salary"
                  handleChange={(e) => handleFieldChange(e)}
                  value={formData.salary}
                  id="salary"
                  label={t("Salary")}
                  type="text"
                  error={errors.salary}
                  placeholder="Salary"
                  isDisabled={isViewMode}
                />
              </div>
              <div>
                <SelectWithSearch
                  title={t("Salary type")}
                  isMandatory={true}
                  name="salaryType"
                  search={true}
                  options={dropdownData.salaryType}
                  onChange={(e) => handleSelectChange(e, "salaryType")}
                  isMulti={false}
                  value={formData.salaryType}
                  error={errors.salaryType}
                  placeHolder="Select"
                  isDisabled={isViewMode}
                />
              </div>
              <div>
                <SelectWithSearch
                  title={t("Salary benefits")}
                  isMandatory={true}
                  name="salaryBenefits"
                  search={true}
                  options={dropdownData.salaryBenefits}
                  onChange={(e) => handleSelectChange(e, "salaryBenefits")}
                  isMulti={true}
                  value={formData.salaryBenefits}
                  placeHolder="Select"
                  error={errors.salaryBenefits}
                  isDisabled={isViewMode}
                />
              </div>
              {formData.salaryBenefits &&
                formData.salaryBenefits.length > 0 &&
                formData.salaryBenefits.map(
                  (benefit: SalaryBenefitProps, index: number) => (
                    <div key={index}>
                      <div className="fw-bold">{benefit.label}:</div>
                      <div className="d-grid grid-2">
                        <div>
                          <SelectWithSearch
                            name="benefitValueType"
                            id="benefitValueType"
                            isMandatory={true}
                            search={true}
                            options={[
                              {
                                name: "euro",
                                label: "Salary in euro",
                                value: 1,
                              },
                              {
                                name: "percentage",
                                label: "Salary in percentage",
                                value: 2,
                              },
                            ]}
                            placeHolder="Select"
                            value={benefit.benefitValueType}
                            onChange={(e) =>
                              handleSelectChange(e, "benefitValueType", index)
                            }
                            isMulti={false}
                            className="select-field"
                            containerClassName="col-12 position-relative"
                            isDisabled={isViewMode}
                          />
                        </div>
                        <div>
                          <LabelWithInputFieldAndUnit
                            labelClassName="form-label field-label"
                            name="benefitValue"
                            id="benefitValue"
                            isMandatory={true}
                            handleChange={(e) => handleFieldChange(e, index)}
                            value={benefit.benefitValue ?? ""} // Convert null to an empty string
                            placeholder={t("Select")}
                            className="form-control field-shadow"
                            type="text"
                            // unit={salaryBenefits?.benefitValueType ===2}
                            unit={
                              (benefit && benefit?.benefitValueType === 1) ||
                                (salaryBenefits.length > 0 &&
                                  salaryBenefits[index]?.benefitValueType === 1)
                                ? "€"
                                : "%"
                            }
                            containerClassName="col-12 position-relative"
                            error={errors.benefitValue}
                            isDisabled={isViewMode}
                          />
                        </div>
                      </div>
                    </div>
                  )
                )}
            </div>
            <div className="row">
              <div className="col hideHeight">
                <LabelWithTextAreaField
                  label={t("Info")}
                  name="info"
                  value={formData.info}
                  placeholder={t("info")}
                  isDisabled={isViewMode}
                  isMandatory={false}
                  handleChange={(e) => handleFieldChange(e)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row" style={{ padding: "1vw 0" }}>
        <div className="col-md-4 align-self-center">
          <BackButton />
        </div>
        <div className="col-8">
          {!isViewMode && (
            <Button
              type="submit"
              title={t("Save")}
              handleClick={handleSave}
              className="float-end form-button shadow-none"
            />
          )}
        </div>
      </div>
    </>
   // </AccessControl>
  );
};

export default CreactWageProposal;
