import React from "react";
import { t } from "pages/microservices/masterData/translation/Translation";
// import { useFormContext } from "pages/microservices/project/context/Context";
import { useFormContext } from "pages/microservices/tempAgancy/context/Context";

import Button from "components/atoms/Button";
import CheckBoxField from "components/atoms/CheckBoxField";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import LabelWithTextAreaField from "components/molecules/LabelWithTextAreaField";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";

import { Option } from "components/common/CommonInterfaces";
import {
  scrollToTop,
  validateFormField,
} from "../../../../services/validation/ValidationService";
import {
  SET_FIELD_ERROR_GENERAL_TAB,
  SET_FIELD_GENERAL_TAB,
} from "../context/Constants";
import { FormMode } from "components/common/CommonEnums";
import { getProjectStatusColor } from "services/util/UtilService";
import { GeneralTabData, GeneralTabErrors } from "../context/Interface";
import { GeneralTabValidationRules } from "../validationRules/TempAgencyValidationRules";
import {
  COMPANY_VAT_EXISTENCE,
  COMPANY_VAT_VALIDATION,
} from "routes/ApiEndpoints";
import { M_COMPANY_CREATION, M_WEB_CONNECTOR } from "constants/Constants";
import { ApiCall } from "services/ApiServices";
import CustomNotify from "components/atoms/CustomNotify";

// Define the prop types for the component
interface IProjectFormGeneralTabProps {
  // id?: string | number;
}

// Functional component using TypeScript
const General: React.FC<IProjectFormGeneralTabProps> = () => {
  const { state, dispatch } = useFormContext();
  const selectOptions = state.options;
  const generalData: GeneralTabData = state.general.data;
  const generalErrors: GeneralTabErrors = state.general.errors;
  const handleAddressUpdate = (
    street: string,
    number: string,
    unit: string,
    postal: string,
    city: string,
    countryValue: { value: number; label: string } = {
      value: 29,
      label: "Belgium",
    }
  ) => {
    const addressFields = [
      { field: "street", value: street },
      { field: "number", value: number },
      { field: "box", value: unit },
      { field: "zipCode", value: postal },
      { field: "city", value: city },
      { field: "country", value: countryValue },
    ];

    addressFields.forEach((field) => {
      dispatch({
        type: "UPDATE_ADDRESS_FIELD",
        field: field.field,
        value: field.value,
      });
      // dispatch({
      //   type: "UPDATE_FIELD_ERROR",
      //   fieldName: field.field,
      //   error: "",
      // });
      // dispatch({
      //   type: "UPDATE_ADDRESS_FIELD",
      //   field: `bi${
      //     field.field.charAt(0).toUpperCase() + field.field.slice(1)
      //   }`,
      //   value: field.value,
      // });
      // dispatch({
      //   type: "UPDATE_FIELD_ERROR",
      //   fieldName: `bi${
      //     field.field.charAt(0).toUpperCase() + field.field.slice(1)
      //   }`,
      //   error: "",
      // });
    });
  };
  const handleLocationAddressUpdate = (
    street: string,
    number: string,
    unit: string,
    postal: string,
    city: string,
    countryValue: { value: number; label: string } = {
      value: 29,
      label: "Belgium",
    }
  ) => {
    const locationAddressFields = [
      { field: "locationStreet", value: street },
      { field: "locationNumber", value: number },
      { field: "locationBox", value: unit },
      { field: "locationZipCode", value: postal },
      { field: "locationCity", value: city },
      { field: "locationCountry", value: countryValue },
    ];
    locationAddressFields.forEach((field) => {
      // dispatch({
      //   type: "UPDATE_COMPANYLOCATION_FIELD",
      //   field: field.field,
      //   value: field.value,
      //   index: 0,
      // });
      // dispatch({
      //   type: "UPDATE_COMPANYLOCATION_FIELD",
      //   field: field.field,
      //   error: "",
      // });
    });
  };
  const verifyVatHandler = async (e: any, field: string, value: string) => {
    // validation(field, value, true);
    // const formData = { [field]: value };

    // if (!value) {
    //   // dispatch({ type: "UPDATE_TAB_ERROR", tabIndex: 0, error: true });
    //   // return;
    // }
    // const vatData = {
    //   vat_number: value,
    //   key: `${process.env.REACT_APP_WEBCONNECTOR_UNIQUE_KEY}`,
    // };
    // const vatExistence = await ApiCall.service(
    //   COMPANY_VAT_EXISTENCE,
    //   "POST",
    //   vatData,
    //   false,
    //   M_COMPANY_CREATION
    // );
    // if (vatExistence?.status === 400) {
    // dispatch({
    //   type: "UPDATE_FIELD_ERROR",
    //   fieldName: "vat",
    //   error: vatExistence?.msg,
    // });
    // scrollToTop();
    // return;
    // }
    // const url = COMPANY_VAT_VALIDATION;
    // const response = await ApiCall.service(
    //   url,
    //   "POST",
    //   vatData,
    //   true,
    //   M_WEB_CONNECTOR
    // );
    // if (response?.status !== 200) {
    //   CustomNotify({ type: "error", message: response?.error });
    // dispatch({
    //   type: "UPDATE_BASIC_FIELD",
    //   field: "companyName",
    //   value: "",
    // });
    // dispatch({
    //   type: "UPDATE_BASIC_FIELD",
    //   field: "officialName",
    //   value: "",
    // });
    // handleAddressUpdate("", "", "", "", "");
    // handleLocationAddressUpdate("", "", "", "", "");
    //  dispatch({ type: "UPDATE_TAB_ERROR", tabIndex: 0, error: true });
    scrollToTop();
    //state.fieldError.vat = response?.error;
    // } else {
    //   const value = response?.data?.name;
    //   const { street, number, box, postal, city } = response?.data?.address;

    //   handleAddressUpdate(street, number, box, postal, city);
    //   handleLocationAddressUpdate(street, number, box, postal, city);
    // dispatch({
    //   type: "UPDATE_FIELD_ERROR",
    //   fieldName: "companyName",
    //   error: "",
    // });
    // dispatch({
    //   type: "UPDATE_COMPANYLOCATION_FIELD",
    //   field: "locationName",
    //   value: `${value}-HQ`,
    //   index: 0,
    // });
    // dispatch({ type: "UPDATE_BASIC_FIELD", field: "officialName", value });
    // dispatch({
    //   type: "UPDATE_FIELD_ERROR",
    //   fieldName: "officialName",
    //   error: "",
    // });
    // dispatch({ type: "UPDATE_BASIC_FIELD", field: "companyName", value });
    // CustomNotify({ type: "success", message: "VAT number is verified" });
    //dispatch({ type: "UPDATE_TAB_ERROR", tabIndex: 0, error: false });
    //  }
  };
  const handleFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    if (event.target) {
      const { name, value, type, checked } = event.target as HTMLInputElement;
      if (type === "checkbox") {
        dispatch({
          type: SET_FIELD_GENERAL_TAB,
          field: name,
          value: checked,
        });
      } else {
        dispatch({
          type: SET_FIELD_GENERAL_TAB,
          field: name,
          value: value,
        });
      }
      let validatedFieldError = validateFormField(
        generalData,
        GeneralTabValidationRules,
        name,
        value
      );
      dispatch({
        type: SET_FIELD_ERROR_GENERAL_TAB,
        fieldName: name,
        error: validatedFieldError,
      });
    }
  };

  const handleSelectChange = (
    value: Option | Option[],
    fieldName: string,
    isMultiSelect: boolean = false
  ) => {
    if (fieldName === "businessUnitNumber" && isMultiSelect) {
      const isSelectAllSelected = (value as Option[]).some(
        (option) => option.value === "selectAll"
      );

      if (isSelectAllSelected) {
        const allOptions = selectOptions.businessUnit.filter(
          (option) => option.value !== "selectAll"
        );
        dispatch({
          type: SET_FIELD_GENERAL_TAB,
          field: fieldName,
          value: allOptions,
        });
      } else {
        dispatch({
          type: SET_FIELD_GENERAL_TAB,
          field: fieldName,
          value: value as Option[],
        });
      }
    } else {
      dispatch({
        type: SET_FIELD_GENERAL_TAB,
        field: fieldName,
        value: value,
      });
    }
    let validatedFieldError = validateFormField(
      generalData,
      GeneralTabValidationRules,
      fieldName,
      value
    );
    dispatch({
      type: SET_FIELD_ERROR_GENERAL_TAB,
      fieldName: fieldName,
      error: validatedFieldError,
    });
  };
  return (
    <div className="row">
      <div className="col-12">
        <div className="form-border" style={{ paddingTop: "2vw" }}>
          <div className="row">
            <div className="col-6">
              <LabelWithInputField
                isMandatory={true}
                name="vat"
                handleChange={handleFieldChange}
                value={generalData.vat}
                id="vatId"
                label="VAT number"
                type="text"
                error={generalErrors.vat}
                isDisabled={false}
              />
            </div>
            <div className="col-2 addBtnMargin" style={{ paddingTop: "0.25vw" }}>
              <Button
                type="button"
                title="Verify"
                handleClick={(e) => verifyVatHandler(e, "vat", generalData.vat)}
                className={`form-button`}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <SelectWithSearch
                search={true}
                onChange={(value) => handleSelectChange(value, "company", false)}
                title={t("Company")}
                placeHolder={t("Select")}
                options={selectOptions.company}
                value={generalData.company}
                isDisabled={false}
                isMulti={false}
                isMandatory={true}
                error={generalErrors.company}
                name={"company"}
                id={"companyId"}
              />
            </div>
            <div className="col-sm-12 col-md-6">
              <SelectWithSearch
                onChange={(value) =>
                  handleSelectChange(value, "businessUnitNumber", true)
                }
                title={t("Maxicon Business Unit")}
                placeHolder={t("Select")}
                search={true}
                options={selectOptions.businessUnit}
                value={generalData.businessUnitNumber}
                isDisabled={false}
                isMulti={true}
                isMandatory={true}
                error={generalErrors.businessUnitNumber}
                name={"businessUnitNumber"}
                id={"businessUnitNumberId"}
              />
            </div>
            <div className="col-4">
              <LabelWithInputField
                type={"text"}
                handleChange={handleFieldChange}
                label={t("Name")}
                value={generalData.name}
                readOnly={false}
                isDisabled={false}
                isMandatory={true}
                error={generalErrors.name}
                name={"name"}
                id={"nameId"}
              ></LabelWithInputField>
            </div>
            <div className="col-4">
              <LabelWithInputField
                type={"text"}
                handleChange={handleFieldChange}
                label={t("Code")}
                value={generalData.code}
                readOnly={false}
                isDisabled={false}
                isMandatory={true}
                error={generalErrors.code}
                name={"code"}
                id={"codeId"}
              ></LabelWithInputField>
            </div>
            <div className="col-4">
              <SelectWithSearch
                onChange={(value) => handleSelectChange(value, "type", false)}
                title={t("Type")}
                placeHolder={t("Select")}
                options={selectOptions.tempAgencyTypes}
                value={generalData.type}
                search={true}
                isDisabled={false}
                isMulti={false}
                isMandatory={true}
                error={generalErrors.type}
                name={"type"}
                id={"typeId"}
              ></SelectWithSearch>
            </div>
            <div className="col-sm-12 col-md-12">
              <LabelWithTextAreaField
                label={t("Info")}
                name={"info"}
                value={generalData.info}
                isDisabled={false}
                isMandatory={false}
                handleChange={handleFieldChange}
              />
            </div>
            <div className="col-sm-12 col-md-12">
              <CheckBoxField
                label={t("Active")}
                name={"isActive"}
                id={"isActiveId"}
                isChecked={
                  getProjectStatusColor(generalData.isActive) === "success"
                    ? true
                    : false
                }
                onChangeHandler={handleFieldChange}
                disable={false}
                lineHeight="1.5vw"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default General;
