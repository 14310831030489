import { useFormContext } from "pages/microservices/tempAgancy/context/Context";

import { LabelWithInputField } from "components/molecules/LabelWithInputField";
// import { Navigation } from "../formNavigation/Navigation";
import SelectWithSearch from "components/atoms/SelectWithSearch";

import { ChangeEvent, useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";

import { t } from "pages/microservices/masterData/translation/Translation";
import LabelWithTextAreaField from "components/molecules/LabelWithTextAreaField";
import CloseFile from "static/images/CloseFile";
import Button from "components/atoms/Button";
import { COMPANY_DETAILS, REGISTER_COMPANY } from "routes/ApiEndpoints";
import { M_COMPANY_CREATION } from "constants/Constants";
import { ApiCall } from "services/ApiServices";
import CustomNotify from "components/atoms/CustomNotify";
import {
  scrollToTop,
  validateForm,
  validateFormField,
  validatePhoneNumber,
  validateRequired,
  validateSelectField,
  validateTextFieldAlpha,
} from "services/validation/ValidationService";
import { mapToSelect } from "utils/MapToSelect";
import { ValidationRules } from "utils/TypeAnnotations";
import { LocationTabValidationRules } from "../validationRules/TempAgencyValidationRules";

const Location = () => {
  const { state, dispatch } = useFormContext();
  const navigate = useNavigate();
  const [countryId, setCountryId] = useState<number | null>(null);
  const [validationStatus, setValidationStatus] = useState({
    isValid: false,
    type: "",
  });
  const { companyId } = useParams<{ companyId: string }>();

  const handleFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    // const { name, value, type, checked } = event.target;
    // const processedValue = type === "number" ? String(value) : value;
    // dispatch({
    //   type: "UPDATE_COMPANYLOCATION_FIELD",
    //   field: name,
    //   value: value,
    //   index,
    // });
    // validation(name, processedValue, index, true);
  };
  //   const validation = (
  //     name: string,
  //     value: string | boolean | Date | object[] | undefined,
  //     index: number,
  //     isSingleFieldValidation: boolean = false
  //   ) => {
  //     const validationRules: ValidationRules = {
  //       locationName: [validateRequired],
  //       locationStreet: [validateRequired],
  //       locationNumber: [validateRequired],
  //       //  locationBox: string;
  //       locationZipCode: [validateRequired],
  //       locationCountry: [validateSelectField],
  //       locationCity: [validateRequired],
  //       phoneNumber: [validateRequired],
  //     };
  //     const validationErrors = validateForm(
  //       { ...state.companyLocation[index], [name]: value },
  //       validationRules,
  //       isSingleFieldValidation ? name : undefined
  //     );

  //     const isFieldValid = Object.keys(validationErrors).length === 0;

  //     if (isFieldValid) {
  //       state.dynamicLocationErrors[index][name] = "";
  //     } else {
  //       state.dynamicLocationErrors[index][name] = validationErrors[name];
  //     }

  //     if (Object.keys(validationErrors).length > 0) {
  //       return false;
  //     }

  //     return true;
  //   };

  const handleSelectChange = (
    selectedOption: any,
    name: string,
    index: number
  ) => {
    // dispatch({
    //   type: "UPDATE_COMPANYLOCATION_FIELD",
    //   field: name,
    //   index: index,
    //   value: selectedOption,
    // });
    // validation(name, selectedOption, index, true);
  };

  //   const validStatus = (validation: any) => {
  //     setValidationStatus({
  //       isValid: true,
  //       // isValid: validation.isValid,
  //       type: validation.type,
  //     });
  //   };
  const handleRemoveLocation = (indexToRemove: number) => {
    dispatch({ type: "REMOVE_LOCATION", indexToRemove });
  };

  const handleAddLocation = () => {
    dispatch({ type: "ADD_LOCATION" });
  };

  const handleLocationFieldChange = (
    event: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { name, value, type, checked } = event.target;
    dispatch({
      type: "UPDATE_LOCATION_FIELD",
      field: name,
      index: index,
      value: value,
    });

    const currentFormData = { ...state.location.data[index], [name]: value };

    // Validate the changed field
    const validatedFieldError = validateFormField(
      currentFormData,
      LocationTabValidationRules,
      name,
      value
    );
    console.log(validatedFieldError);
    dispatch({
      type: "SET_FIELD_ERROR_LOCATION_TAB",
      field: name,
      error: validatedFieldError,
      index: index,
    });
  };
  const handleLlocationSelectChange = (
    value: number | string | undefined | null,
    name: string,
    index: number
  ) => {
    // const { name, value, type, checked } = event.target;
    dispatch({
      type: "UPDATE_LOCATION_FIELD",
      field: name,
      index: index,
      value: value,
    });
    const currentFormData = { ...state.location.data[index], [name]: value };

    // Validate the changed field
    const validatedFieldError = validateFormField(
      currentFormData,
      LocationTabValidationRules,
      name,
      value
    );
    dispatch({
      type: "SET_FIELD_ERROR_LOCATION_TAB",
      field: name,
      error: validatedFieldError,
      index: index,
    });
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="form-border" style={{ paddingTop: "2vw" }}>
          {
            <div>
              {state.location.data.map((companyLocation, index) => (
                <div
                  className="bg-white"
                  style={{
                    padding: "2vw 2vw 1vw",
                    marginBottom: "1vw",
                    borderRadius: "1vw",
                  }}
                  key={index}
                >
                  {index > 0 && (
                    <div className="row">
                      <div className="col-md-12 table-action-icons">
                        <span
                          title="Delete"
                          onClick={() => handleRemoveLocation(index)}
                          className="table-action-btn float-end cursor-pointer"
                        >
                          <CloseFile />
                        </span>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div className="col-sm-12 col-md-6">
                      <LabelWithInputField
                        isMandatory={true}
                        name="locationName"
                        handleChange={(event) =>
                          handleLocationFieldChange(event, index)
                        }
                        value={companyLocation.locationName ?? ""}
                        id={`locationName-${index}`}
                        label="Location name"
                        placeholder="Location name"
                        error={state.location.errors[index]?.locationName || ""}
                      />
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <LabelWithInputField
                        isMandatory={true}
                        name="locationEmail"
                        handleChange={(event) =>
                          handleLocationFieldChange(event, index)
                        }
                        value={companyLocation.locationEmail ?? ""}
                        id={`locationEmail-${index}`}
                        label="Email"
                        placeholder="Email"
                        error={state.location.errors[index]?.locationEmail || ""}
                      />
                    </div>

                    <div className="col-sm-12 col-md-6">
                      <LabelWithInputField
                        isMandatory={true}
                        name="locationStreet"
                        handleChange={(event) =>
                          handleLocationFieldChange(event, index)
                        }
                        value={companyLocation.locationStreet ?? ""}
                        id={`street-${index}`}
                        label="Street"
                        placeholder="Street"
                        error={state.location.errors[index]?.locationStreet || ""}
                      />
                    </div>
                    <div className="col-sm-12 col-md-3">
                      <LabelWithInputField
                        isMandatory={true}
                        name="locationNumber"
                        handleChange={(event) =>
                          handleLocationFieldChange(event, index)
                        }
                        value={companyLocation.locationNumber ?? ""}
                        id={`number-${index}`}
                        label="Number"
                        placeholder="Number"
                        error={state.location.errors[index]?.locationNumber || ""}
                      />
                    </div>
                    <div className="col-sm-12 col-md-3">
                      <LabelWithInputField
                        isMandatory={false}
                        name="locationBox"
                        handleChange={(event) =>
                          handleLocationFieldChange(event, index)
                        }
                        value={companyLocation.locationBox}
                        id={`box-${index}`}
                        label="Box"
                        type="text"
                      />
                    </div>
                    <div className="col-sm-12 col-md-4">
                      <LabelWithInputField
                        isMandatory={true}
                        name="locationZipCode"
                        handleChange={(event) =>
                          handleLocationFieldChange(event, index)
                        }
                        value={companyLocation.locationZipCode}
                        id={`zipCode-${index}`}
                        label="Zip code"
                        type="text"
                        error={state.location.errors[index]?.locationZipCode || ""}
                      />
                    </div>
                    <div className="col-sm-12 col-md-4">
                      <LabelWithInputField
                        isMandatory={true}
                        name="locationCity"
                        handleChange={(event) =>
                          handleLocationFieldChange(event, index)
                        }
                        value={companyLocation.locationCity}
                        id={`city-${index}`}
                        label="City"
                        type="text"
                        error={state.location.errors[index]?.locationCity || ""}
                      />
                    </div>
                    <div className="col-sm-12 col-md-4">
                      <SelectWithSearch
                        title="Country"
                        name="locationCountry"
                        isMandatory={true}
                        search={true}
                        options={state.options.countries}
                        placeHolder="Select"
                        value={companyLocation.locationCountry}
                        onChange={(country) =>
                          handleLlocationSelectChange(
                            country,
                            "locationCountry",
                            index
                          )
                        }
                        isMulti={false}
                        className="select-field"
                        error={state.location.errors[index]?.locationCountry || ""}
                      />
                    </div>
                  </div>
                </div>
              ))}
              <div className="row" style={{ paddingTop: "1vw" }}>
                <div className="col-md-12">
                  <Button
                    title={"+ " + t("Add another")}
                    handleClick={handleAddLocation}
                    className="form-button float-end"
                  />
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default Location;
