import {useState} from 'react'
import PageLayout from './planningTemplate';
import PlanningOverview from './organisms/planningOverview';
import { PlanningProvider } from './planning-redux/context/PlanningContext/PlanningContext';
import { EmployeeProvider } from './planning-redux/context/EmployeeContext/EmployeeContext';
import { PlanningModalProvider } from './planning-redux/context/PlanningModalContext/PlanningModalContext';
import { FilterOptionsProvider } from './planning-redux/context/FiltersContext/FiltersContext';
import AccessControl from 'services/AccessControl';
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";

function Planning({type}) {
  const userData = useSelector(selectAuth);

    return (
      <AccessControl
        requiredPermissions={[{
          permission: type === 'hr' ? "Planning" : "Sales planning",
          read: true,
        }]}
        renderNoAccess={true}
        override={userData.isSuperAdmin}
      >
        <FilterOptionsProvider>
          <PlanningProvider>
            <EmployeeProvider>
              <PlanningModalProvider>
                <PageLayout type={type}/>
                  <PlanningOverview key={Math.floor(Math.random() * 1000000)} type={type}/>
              </PlanningModalProvider>
            </EmployeeProvider>
          </PlanningProvider>
        </FilterOptionsProvider>
      </AccessControl>
    );
}

export default Planning
