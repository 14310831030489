import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Button from "components/atoms/Button";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import TableActions from "components/organism/Actions/TableAction";
import {
  PATH_EMPLOYEE_MANAGE,
  PATH_PROJECTS_MANAGE,
  PATH_HR_PLANNING,
  PATH_SALES_PLANNING
} from "constants/Paths";
import { t } from "pages/microservices/masterData/translation/Translation";
import { ApiCall } from "services/ApiServices";
import { M_MASTER_DATA } from "constants/Constants";
import { PAGENAMES } from "store/pageHistory/ConstantPageNames";
import {
  getProjectStatusColor,
  getProjectTooltipMessage,
} from "services/util/UtilService";
import Pagination from "components/atoms/Pagination";
import {
  DELETE_HOTLIST_DATA,
  FETCH_ACTIVE_PROJECT,
  GET_COMPANY_PROJECT_OPTION,
  GET_EMPLOYEE_DETAILS_BY_ID,
  GET_MATCHING_OPTIONS,
  PROJECTS,
  SAVE_HOTLIST_DATA,
} from "routes/ApiEndpoints";
import AddEmployeeModal from "./addEmployeeModel";
import CustomNotify from "components/atoms/CustomNotify";
import ModalPopup from "components/atoms/ModalPopup";
import { MatchingCandidateProfileCardType } from "components/Matching/MatchingCandidateProfileCardType";
import MatchingProfileCard from "components/Matching/MatchingCandidateProfileCard";
import { IconData } from "services/IconData";
import { faCloudDownloadAlt } from "@fortawesome/free-solid-svg-icons";
import ResetBtn from "components/atoms/ResetBtn";
import SearchBtn from "components/atoms/SearchBtn";
import BackButton from "components/atoms/BackButton";
import { useSelector } from "react-redux";
import { addPageToHistory, selectPageHistory, updatePageFilters } from "store/pageHistory/pageHistorySlice";
import { useDispatch } from "react-redux";
import PaginationWithPerPage from "components/molecules/PaginationWithPerPage";
import FilterOrganism from "components/organism/FilterOrganism";
import CommonServices from "services/CommonService";
import CompetenceModal from "./competenceModel";
import TextEllipsis from "components/atoms/TextEllipsis";
import TitleFieldMolecule from "components/molecules/TitleField";
import Popup from "components/molecules/Popup";
import MatchingInfoModal from "components/atoms/MatchingInfoModal";


interface ProjectData {
  id: number;
  isActive: any;
  company: any;
  project: string;
  location: any;
  margin: string;
  marginPercentage: string;
  startdate: string;
  enddate: string;
  contractSign: boolean;
  hotlistStatus: boolean;
  score: any;
  function: any;
  companyId: any;
  addProjectStatus: boolean;
}
interface SearchData {
  company: string;
  project: string;
}
interface DataProps {
  page: number;
  nOR: number;
  search?: Partial<SearchData>;
}
interface Option {
  value: number;
  label: string;
}

const MatchingEmployee: React.FC = ({ }) => {
  const pageHistory = useSelector(selectPageHistory);
  const history = pageHistory?.history?.[pageHistory?.history?.length - 1] ?? {}
  const dispatch = useDispatch();
  const [showAddEmployeeModal, setShowAddEmployeeModal] = useState(false);
  const [showCompetenceModal, setShowCompetenceModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState<string>("");
  const [projectData, setProjectData] = useState<ProjectData[]>([]);
  const navigate = useNavigate();
  const url = useLocation();
  const [id, setId] = useState({
    projectId: null,
    companyId: null,
  });
  const [profileCardData, setProfileCardData] =
    useState<MatchingCandidateProfileCardType>({
      candidateId: null,
      firstName: "",
      lastName: "",
      functions: "",
      mobNumber: "",
      email: "",
      street: "",
      number: "",
      box: "",
      zipCode: "",
      city: "",
      picFileName: "",
      picFilePath: "",
      country: "",
      cvData: "",
      profilePicData: "",
    });
  const [removeStatus, setRemoveStatus] = useState<boolean>(false);
  const urlParts = url.pathname.split("/");
  const employeeId = parseInt(urlParts[urlParts.length - 1]);
  const [option, setOptions] = useState({
    location: [],
    function: [],
    radius: [],
    competences: [],
    contractTypes: [],
  });
  const [pageData, setPageData] = useState({
    totalPages: 0,
    totalRecords: 0,
    refresh: true,
    initialCall: true,
    options: [],
    infopop: false,
  })

  const getSearchData = () => {
    const filteredObject = Object.fromEntries(Object.entries(history?.filters?.competance ?? {}).filter(([key, value]: any) => value?.options?.length > 0)) ?? "";
    return {
      user_id: Number(history?.filters?.user_id ?? null),
      project: history?.filters?.project ?? "",
      company: history?.filters?.company ?? "",
      radius: history?.filters?.radius ?? [],
      location: history?.filters?.location ?? "",
      contractType: history?.filters?.contractType ?? "",
      function: history?.filters?.function ?? "",
      competance: filteredObject,
      employeeId: employeeId,
      startdate: CommonServices?.getTimezoneOffset(history?.filters?.startdate) ?? "",
      enddate: CommonServices?.getTimezoneOffset(history?.filters?.enddate) ?? "",
      // orderBy: history?.filters?.orderBy ?? "id",
      // sortDirection: history?.filters?.sortDirection ?? "desc",
    };
  }

  useEffect(() => {
    const postData = {
      currentPage: Number(history?.filters?.currentPage ?? 1),
      itemsPerPage: Number(history?.filters?.itemsPerPage ?? 10),
      search: getSearchData(),
      initialCall: pageData?.initialCall,
      employeeId: employeeId,
    };
    getProjectData(postData);
  }, [pageData.refresh, pageData.initialCall]);

  const getProjectData = async (postData: any) => {
    let pgdata = { ...pageData };
    try {
      if (pageData?.initialCall) {
        const payload = {
          employeeId, companyId: id?.companyId, projectId: id?.projectId, function_id: history?.filters?.function
        };
        const optionsData = await fetchOptions(payload);
        setOptions(optionsData);
        pgdata = { ...pgdata, initialCall: false };
      }
      const response = await ApiCall.service(FETCH_ACTIVE_PROJECT, "POST", postData, false, M_MASTER_DATA);
      if (response?.status === 200) {
        setProjectData(response?.data);
        pageData?.initialCall && setProfileData(response?.employeeDetails);
        pgdata = { ...pgdata, totalPages: response?.totalPages, totalRecords: response?.totalRecords };
      }
    } catch (error) {
      //
    }
    setPageData(pgdata);
  };
  const fetchOptions = async (postData: any) => {
    const respose = await ApiCall.service(GET_MATCHING_OPTIONS, "POST", postData, false, M_MASTER_DATA);
    if (respose?.status === 200) {
      return respose?.data;
    }
  }
  const setProfileData = (data: any) => {
    setProfileCardData({
      ...data,
      picFileName: data?.image_url ?? "",
      picFilePath: data?.image_url ?? "",
      cvData: data?.cv ?? "",
      profilePicData: data?.image_url ?? "",
    });
  }

  const handleAddPlanning = (value: any) => {
    dispatch(addPageToHistory({
      pageName: PAGENAMES?.SALES_PLANNING,
      filters: {
        project: [value.id],
        company: [],
        employees: [employeeId],
        type: 'project'
      },
      route: PATH_SALES_PLANNING
    }));    
    navigate(`${PATH_SALES_PLANNING}`);
  };

  const handleAddHotlist = async (item: any) => {
    let postData: any = {
      category: "project",
      categoryValue: item.id,
    };
    try {
      if (!item.hotlistStatus) {
        const response = await ApiCall.service(SAVE_HOTLIST_DATA, "POST", postData, false, M_MASTER_DATA);
        if (response?.status === 200) {
          CustomNotify({
            type: "success",
            message: "Project successfully added to hotlist",
          });
        }
      } else {
        postData = {
          id: item.id,
          page: "matching",
        };
        const response = await ApiCall.service(DELETE_HOTLIST_DATA, "POST", postData, false, M_MASTER_DATA);
        if (response.status === 200) {
          CustomNotify({
            type: "success",
            message: "Deleted  successfully from  hotlist",
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
    handleRefresh();
  };

  const handleClose = () => {
    setShowAddEmployeeModal(false);
  };

  function handleAddRemoveEmployee(item: any) {
    setId((prev: any) => ({
      ...prev,
      projectId: item?.id,
      companyId: item?.companyId,
    }));
    setSelectedProject(item?.project);
    setShowAddEmployeeModal(true);
    setRemoveStatus(item?.addProjectStatus ?? false);
  }

  const handleAddCompetence = (selectedOptions: any) => {
    dispatch(updatePageFilters({ filters: { competance: selectedOptions, currentPage: "1" } }));
    setShowCompetenceModal(false);
    handleRefresh();
  };
  const handleModalClose = () => {
    setShowCompetenceModal(false);
  };
  const handleCvDownload = () => {
    try {
      const cvData = JSON.parse(profileCardData.cvData);
      const cvUrl = cvData.accessUrl;
      const fileName = cvData.fileName;
      if (cvUrl) {
        const link = document.createElement("a");
        link.href = cvUrl;
        link.target = "_blank";
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        setTimeout(() => {
          document.body.removeChild(link);
        }, 1000);
      } else {
        CustomNotify({
          type: "error",
          message:
            "The CV for this employee has not been uploaded. Please check the employee's profile",
        });
      }
    } catch (error) {
      CustomNotify({
        type: "error",
        message:
          "The CV for this employee has not been uploaded. Please check the employee's profile",
      });
    }
  };
  const onchangeFunction = async (value: any, filter: any) => {
    dispatch(updatePageFilters({
      filters: {
        [filter?.name]: value, currentPage: "1", competance: {},
      }
    }));
    const postData = { onChangeOptions: true, function_id: value };
    const optionsData = await fetchOptions(postData);
    setOptions((prev) => ({ ...prev, competences: optionsData?.competences }));
    await handleRefresh();
  }
  const handleRefresh = () => setPageData((prev: any) => ({ ...prev, refresh: !pageData?.refresh }));
  const filters = [
    { name: 'project', fieldType: "text", placeholder: "Project", filterType: 'search' },
    { name: 'company', fieldType: "text", placeholder: "Company", filterType: 'search' },
    // {name: 'contractType', fieldType: "multiSelect", options: option?.contractTypes, placeholder: "Contract type", filterType: 'search' },
    { name: 'function', fieldType: "multiSelect", options: option?.function, onchangeCall: onchangeFunction, placeholder: "Function", filterType: 'search' },
    { name: 'location', fieldType: "singleSelect", options: option?.location, placeholder: "Location", filterType: 'search' },
    { name: 'radius', fieldType: "singleSelect", options: option?.radius, placeholder: "Radius", filterType: 'search' },
    { name: 'startdate', fieldType: "date", end: history?.filters?.enddate, placeholder: "Start date", filterType: 'search' },
    { name: 'enddate', fieldType: "date", start: history?.filters?.startdate, placeholder: "End date", filterType: 'search' },
  ];

  return (
    <>
      <div className="search-bar">
        <TitleFieldMolecule title={t("Matching employees")} />
      </div>

      <div style={{ paddingBottom:"1vw", paddingTop:"0.5vw" }}>
        <MatchingProfileCard profileCardProps={profileCardData} />
      </div>
      <div className="row">
        <div className="col-12">
          <FilterOrganism
            dispatch={dispatch}
            history={history}
            updatePageFilters={updatePageFilters}
            filters={filters}
            handleRefresh={handleRefresh}
          />
        </div>
        <div className="col-12">
          <div className="position-relative tableMainWrapper">
            <div className="row">
              <div className="ManageCreateBtn">
                <Button
                  title={"+ " + t("Add competence")}
                  handleClick={() => setShowCompetenceModal(true)}
                  className="form-button shadow-none"
                />
                <Button
                  title={t("Info")}
                  className="form-button ms-3"
                  handleClick={() => setPageData((prev: any) => ({ ...prev, infopop: true }))}
                />
              </div>
            </div>
            {/* Table */}
            <div className="table-responsive manage-matching-list tableSection">
              <div className="matchingEmployeesTableContainer">
                <table className="table table-hover mb-0">
                  <thead>
                    <tr className="TableHeader">
                      <th style={{ width: "11%" }}>{t("Project")}</th>
                      <th style={{ width: "11%" }}>{t("Company")}</th>
                      <th style={{ width: "11%" }}>{t("Function")}</th>
                      <th style={{ width: "11%" }}>{t("Location")}</th>
                      <th style={{ width: "6%" }}>{t("Margin")}</th>
                      <th style={{ width: "6%" }}>{t("Margin%")}</th>
                      <th style={{ width: "10%" }}>{t("Matching score")}</th>
                      <th style={{ width: "6%" }}>{t("Start date")}</th>
                      <th style={{ width: "6%" }}>{t("End date")}</th>
                      {/* <th style={{ width: "10%" }}>{t("Project status")}</th> */}
                      <th style={{ width: "10%" }}>{t("Action")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {projectData && projectData.length > 0 ? (
                      projectData.map((item, index) => ( 
                        <tr key={index}>
                          <td className="align-middle cursor-pointer nameHoverClass" onClick={() => navigate(`/project/view/${item?.id}`)}>
                            <TextEllipsis title={item?.project} label={item?.project} width="11vw" classname="hoverText"/>
                          </td>
                          <td className="align-middle cursor-pointer nameHoverClass" onClick={() => navigate(`/company/view/${item?.companyId}`)}>
                            <TextEllipsis title={item?.company} label={item?.company} width="11vw" classname="hoverText"/>
                          </td>
                          <td>
                            <TextEllipsis title={item?.function} label={item?.function} width="11vw" />
                          </td>
                          <td>
                            <TextEllipsis title={item?.location} label={item?.location} width="11vw" />
                          </td>
                          <td>{item?.margin} &euro;</td>
                          <td>{item?.marginPercentage}%</td>
                          <td data-label={t("Score")} className="progressContainer">
                            <div className="d-flex align-items-center">
                              <div>
                                <span className="color-dark-pink">
                                  {Math.round(item?.score)}%
                                </span>
                              </div>
                              <div className="progress flex-1">
                                <div
                                  className={`progress-bar`}
                                  role="progressbar"
                                  aria-valuenow={item?.score}
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                  style={{ width: `${Math.round(item?.score)}%` }}
                                ></div>
                              </div>
                            </div>
                          </td>
                          <td>{item?.startdate}</td>
                          <td>
                            {item?.enddate}
                          </td>
                          {/* <td className="align-middle">
                        <div
                          className={`${getProjectStatusColor(
                            item.isActive
                          )} `}
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title={getProjectTooltipMessage(item?.isActive)}
                        ></div>
                      </td> */}
                          <td className="align-middle">
                            <TableActions
                              isAction={true}
                              handleAddPlanning={() => handleAddPlanning(item)}
                              handleAddEmployee={() => { handleAddRemoveEmployee(item) }}
                              employeeAddedToProject={item?.addProjectStatus}
                              handleAddHotlist={() => handleAddHotlist(item)}
                              value={item?.hotlistStatus} //if even then true
                              permission={null}
                            />
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className="border-bottom">
                        <td colSpan={9} className="border-0 text-center py-3 px-2">
                          {t("No records")}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div>
                <PaginationWithPerPage
                  handleRefresh={handleRefresh}
                  dispatch={dispatch}
                  history={history}
                  pageData={pageData}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-12">
          {/* Back Button */}
          <div className="d-flex justify-content-between align-items-center" style={{ padding: "0.85vw 0" }}>
            <BackButton />
          </div>
        </div>
      </div>


      {/* Project Modal */}
      {showAddEmployeeModal && (
        <AddEmployeeModal
          handleClose={handleClose}
          projectName={selectedProject}
          url={url?.pathname + "/" + employeeId + "/" + id?.companyId + "/" + id?.projectId}
          removeStatus={removeStatus}
          handleRefresh={handleRefresh}
        />
      )}
      {/* Competence Modal */}
      {showCompetenceModal && (
        <CompetenceModal
          handleClose={handleModalClose}
          handleAdd={handleAddCompetence}
          selectedCompetence={history?.filters?.competance ?? {}}
          competenceData={option?.competences ?? []}
        />
      )}
      {/* Info popup modal */}
      {pageData?.infopop && (
        <Popup
          title={t("Matching info")}
          body={<MatchingInfoModal />}
          cancel={() => setPageData((prev: any) => ({ ...prev, infopop: false }))}
        />
      )}
    </>
  );
};

export default MatchingEmployee;
