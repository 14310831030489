import React from 'react'
import { dateUtils } from '../utils/dateUtils';
import PlanningContextMenu from './planningContextMenu';

// <PlanningBox data={"a"} bg_color={"red"} />

// const PlanningBox = ({ width, date, employee_id, bg_color, color, handleWeeklyClickEventOnPlannedUnit, handleClick, handleRightClick, highlightedDiv, handleAction, planid = '', tooltipPosition, contextMenuId }) => {
const PlanningBox = ({ width, date, employee_id, bg_color, color, handleEvents, highlightedDiv, handleAction, planid = '', tooltipPosition, contextMenuId }) => {

  const isWeekend = dateUtils.checkIsWeekend(date);
  let id = employee_id + '-' + date;
  return (
    <>
      <span className="d-flex align-items-center justify-content-center"
        style={{
          width: `${width}`,
          height: "1.5vw",
          background: isWeekend ? "#a2a2a270" : (bg_color ? bg_color : "#ebebeb"),
          color: `${color ? color : "white"}`,
          borderRadius: "0",
          border: highlightedDiv === id ? "2px solid blue" : "none",
        }}
        id={id}
        // (type, e, id, employee_id, date, eachplan)
        onClick={(e) => handleEvents("singleclick", e, id, employee_id, date)}
        onContextMenu={(e) => handleEvents("rightclick", e, id, employee_id, date)}
        onDoubleClick={(e) => handleEvents("doubleclick", e, id, employee_id, date, planid)}
        date={date}
        employee_id={employee_id}
      >
      </span>
      {contextMenuId === id && (
        <PlanningContextMenu
          handleEvents={handleEvents}
          handleAction={handleAction}
          employee_id={employee_id}
          date={date}
          tooltipPosition={tooltipPosition}
          id={id} />
      )}
    </>
  );
}

export default PlanningBox
