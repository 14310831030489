import { calculateTotalTime } from "services/util/TotalTimeCalculator";
import { DurationAndAllowance, EmployeeTypeDependency, OptionsDependency } from "./Constants";
import { duration } from "@mui/material";

export const MarginReducer = (state: any, action: any) => {
    const { payload, type } = action;
    switch (type) {
        case "UPDATE_INPUT_FIELDS":
            const { value, entityType, name, index, subKey } = payload;
            if (index !== undefined) {
                if ((entityType == 'durationAndAllowance') && (name == 'startTime' || name == 'endTime' || name == 'pauseTime')) {
                    const { startTime, endTime, pauseTime } = state?.data?.durationAndAllowance[index];
                    const sTime = name == 'startTime' ? value : startTime;
                    const eTime = name == 'endTime' ? value : endTime;
                    const pTime = name == 'pauseTime' ? value : pauseTime;
                    const updatedValue = calculateTotalTime(sTime, eTime, pTime)
                    state.data[entityType][index][name] = value;
                    state.data[entityType][index]['hours'] = updatedValue;
                    return {
                        ...state,
                    }
                }
                state.data[entityType][index][name] = value;
                return {
                    ...state,
                }
            }

            if (name == 'project') {
                return {
                    ...state,
                    data: {
                        ...state.data,
                        [entityType]: {
                            ...state.data?.[entityType],
                            [name]: value,
                            timeTable: '', projectParameters: '', hourlyRate: '', noOfKms: '', euroPerKmRate: ''
                        }
                    }

                }
            }

            if (subKey) {
                let dependencyFields: any = {};
                if (payload.entityType == 'statute') {
                    return {
                        ...state,
                        data: {
                            ...state.data,
                            [payload.entityType]: {
                                ...state.data[entityType],
                                [payload.subKey]: {
                                    ...state.data[entityType][subKey],
                                    name: {
                                        ...state.data[entityType][subKey].name,
                                        [name]: value,
                                    }
                                },
                            },
                        }
                    };
                }
                if (Object.keys(EmployeeTypeDependency).includes(payload.entityType) && name == 'name') {
                    for (let type of EmployeeTypeDependency[payload.entityType]) {
                        dependencyFields[type] = {
                            ...state.data[type],
                            [payload.subKey]: {
                                ...state.data[type][subKey],
                                empType: value,
                            }
                        }
                    }
                }

                let statelist = name == 'name' ?
                    ({
                        [name]: value,
                        amount: value.amount,
                        ...(value.coefficient ? { coefficient: value.coefficient } : {})
                    }) : ({ [name]: value })

                return {
                    ...state,
                    data: {
                        ...state.data,
                        [payload.entityType]: {
                            ...state.data[entityType],
                            [payload.subKey]: {
                                ...state.data[entityType][subKey],
                                ...statelist,

                            },
                        },
                        ...dependencyFields,
                    }
                };
            }
            //state.data[entityType][name] = value;
            return {
                ...state,
                data: {
                    ...state.data,
                    [entityType]: {
                        ...state.data[entityType],
                        [name]: value,
                    }
                }
            }

        case "ADD_ANOTHER_ELEMENT":

            const currentValue = state.data[payload.entityType].form.name;
            const values = Array.isArray(currentValue) ? currentValue.map((eachItem: any) => eachItem.value) : [currentValue.value];
            const filteredOptions = state.data.options?.[payload.entityType].filter((eachItem: any) => {
                return !values.includes(eachItem.value);
                return eachItem.value !== state.data[payload.entityType].form.name.value;
            });

            let updatedData: any = {};
            if (Object.keys(OptionsDependency).includes(payload.entityType)) {
                const optionsData = OptionsDependency?.[payload.entityType];
                optionsData.forEach((eachItem: any) => {
                    updatedData[eachItem] = {
                        ...state.data[eachItem],
                        form: {
                            ...state.data[eachItem].form,
                            name: ""
                        }
                    }
                });
            }

            return {
                ...state,
                data: {
                    ...state.data,
                    [payload.entityType]: {
                        ...state.data[payload.entityType],
                        dataList: [
                            ...state.data[payload.entityType].dataList,
                            { ...state.data[payload.entityType].form }
                        ],
                        form: {
                            name: '',
                            amount: '',
                            coefficient: '',
                            perHour: false,
                            invoicing: false,
                        }
                    },
                    ...updatedData,
                    options: {
                        ...state.data.options,
                        [payload.entityType]: filteredOptions,
                    }
                }
            };

        case "REMOVE_DYNAMIC_ELEMENT":
            if (payload.entityType == 'employee') {
                const activeTypes = state.data.statute.form.name;
                const activeObj = [];
                const currentDataList = [...state.data[payload.entityType].dataList];
                const currentEle: any = currentDataList.splice(payload.index, 1);

                let filteredList = null;

                if (Object.keys(activeTypes).length > 0) {
                    for (let [key, value] of Object.entries(activeTypes)) {
                        if (value == true) {
                            activeObj.push(key);
                        }
                    }

                    if (activeObj.includes(currentEle[0]?.name?.empType)) {
                        filteredList = [currentEle[0].name, ...state.data.options[payload.entityType]];
                    }
                    else {
                        filteredList = [...state.data.options[payload.entityType]];
                    }
                }
                else {
                    filteredList = [...state.data.options[payload.entityType]];
                }

                return {
                    ...state,
                    data: {
                        ...state.data,
                        [payload.entityType]: {
                            ...state.data[payload.entityType],
                            dataList: currentDataList
                        },
                        options: {
                            ...state.data.options,
                            [payload.entityType]: filteredList,
                        }
                    }
                }
            }
            else {
                const updatedDataList = [...state.data[payload.entityType].dataList];
                const removedData = updatedDataList.splice(payload.index, 1);
                const filteredList = [
                    ...(Array.isArray(removedData[0].name) ? removedData[0].name : [removedData[0].name]),
                    ...state.data.options[payload.entityType]
                ];

                return {
                    ...state,
                    data: {
                        ...state.data,
                        [payload.entityType]: {
                            ...state.data[payload.entityType],
                            dataList: updatedDataList
                        },
                        options: {
                            ...state.data.options,
                            [payload.entityType]: filteredList,
                        }

                    }
                };
            }

        case "EDIT_DYNAMIC_ELEMENT":
            return {
                ...state,
                data: {
                    ...state.data,
                    [payload.entityType]: {
                        ...state.data[payload.entityType],
                        activeIndex: payload.index,
                        isEdit: true,
                        form: state.data[payload.entityType].dataList[payload.index]
                    }
                }
            };

        case "UPDATE_DATA_LIST":
            const listDetails = [...state.data[payload.entityType].dataList];
            const currentData = { ...state.data[payload.entityType].form };
            const activeIndex = state.data[payload.entityType].activeIndex;
            listDetails.splice(activeIndex, 1, currentData);
            return {
                ...state,
                data: {
                    ...state.data,
                    [payload.entityType]: {
                        ...state.data[payload.entityType],
                        activeIndex: '',
                        isEdit: false,
                        dataList: listDetails,
                        form: {
                            name: '',
                            amount: '',
                            coefficient: '',
                            perHour: false,
                            invoicing: false,
                        }
                    }
                }
            };

        case "UPDATE_FIELD_ERRORS":
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [action.payload.id]: action.payload.errors,
                },
            };

        case "UPDATE_OPTIONS":
            if (payload?.key == 'timeTable') {
                const durationAndAllowance = [
                    ...state?.data?.durationAndAllowance
                ];
                const updatedState = durationAndAllowance.map((eachItem: any, index: number) => {
                    return { ...eachItem, ...payload.data?.['shifts']?.[index] };
                });
                return {
                    ...state,
                    data: {
                        ...state.data,
                        durationAndAllowance: updatedState,
                    }
                }
            }

            if (payload?.key == 'project') {
                return {
                    ...state,
                    data: {
                        ...state.data,
                        options: {
                            ...state.data.options,
                            ...payload.data,
                        },
                        fixedProjectParameters: {
                            ...state.data?.fixedProjectParameters,
                            ...payload.data?.fixedProjectParameters,
                            projectParameters: payload.data?.template?.[0],
                        },
                        durationAndAllowance: DurationAndAllowance,
                    },
                }
            }

            if (payload?.key == 'employee') {
                const optionsData = OptionsDependency[payload.subKey];
                console.log(optionsData);

                let updatedData: any = {};
                optionsData.forEach((eachItem: any) => {
                    updatedData[eachItem] = {
                        ...state.data[eachItem],
                        form: {
                            ...state.data[eachItem].form,
                            name: payload.data[eachItem]
                        }
                    }
                });
                return {
                    ...state,
                    data: {
                        ...state.data,
                        ...updatedData
                    }
                }
            }
            const currentVal = state.data[payload.subKey].dataList.map((eachList: any) => {
                return eachList?.name?.value;
            });



            const updatedOptions = payload.data[payload.subKey].filter((eachList: any) => {
                return !currentVal.includes(eachList.value);
            });
            return {
                ...state,
                data: {
                    ...state.data,
                    options: {
                        ...state.data.options,
                        [payload.subKey]: updatedOptions,
                    }
                }
            }


        case "UPDATE_OVERALL_ERRORS":
            return {
                ...state,
                errors: {
                    ...state.errors,
                    ...payload.errors,
                }
            }
        case "UPDATE_MARGIN_DATA":
            return {
                ...state,
                data: {
                    ...state.data,
                    ...payload.data,
                }
            }
        case "UPDATE_ALL_OPTIONS":
            return {
                ...state,
                data: {
                    ...state.data,
                    options: {
                        ...state.data.options,
                        ...payload.data,
                    }
                }
            }

        default:
            return state;

    }
}