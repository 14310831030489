import React, { useState, useEffect } from "react";
import { ValidationRules, OptionProps } from "utils/TypeAnnotations";
import { ApiCall } from "services/ApiServices";
import { mapToSelect } from "utils/MapToSelect";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  validateForm,
  validateRequired,
  validateSelectField,
  Option,
  validateLocationPhone,
  validateNumber,
} from "services/validation/ValidationService";
import { LOCATION_DETAILS, ADD_LOCATION } from "routes/ApiEndpoints";
import Button from "components/atoms/Button";
import CustomNotify from "components/atoms/CustomNotify";
import {
  MANAGE_LOCATION,
  M_COMPANY_CREATION,
} from "../../../../constants/Constants";
import { t } from "pages/microservices/masterData/translation/Translation";
import LocationFormOrganism, {
  LocationFormOrganismErrors,
} from "components/organism/location/LocationFormOrganism";

import { FormMode } from "components/common/CommonEnums";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import Title from "components/atoms/Title";
import BackButton from "components/atoms/BackButton";
import axios from "axios";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import LabelWithTextAreaField from "components/molecules/LabelWithTextAreaField";

interface LocationProps {
  company: Option | null;
  location: string;
  phone: string;
  street: string;
  number: string;
  box: string;
  zipcode: string;
  city?: string;
  country: Option | null;
  info: string;
  initialLat: string;
  initialLn: string;
}

const Location: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const text = id ? t("Update") : t("Create");
  const formMode = id ? FormMode.UPDATE : FormMode.CREATE;
  const [formData, setFormData] = useState<LocationProps>({
    company: null,
    location: "",
    phone: "",
    street: "",
    number: "",
    box: "",
    zipcode: "",
    city: "",
    country: null,
    initialLat: "",
    initialLn: "",
    info: "",
  });

  const [errors, setErrors] = useState<LocationFormOrganismErrors>({
    company: "",
    location: "",
    street: "",
    number: "",
    box: "",
    zipcode: "",
    city: "",
    country: "",
    phone: "",
    info: "",
  });

  // const [errors, setErrors] = useState<{ [key: string]: string }>({
  //   company: "",
  //   location: "",
  //   street: "",
  //   number: "",
  //   box: "",
  //   zipcode: "",
  //   city: "",
  //   country: "",
  // });

  const [selectedCompany, setSelectedCompany] = useState<Option | null>(null);

  const validation = (
    name: string,
    value: string | boolean | object | Option,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      company: [validateSelectField],
      location: [validateRequired],
      phone: [validateLocationPhone],
      street: [validateRequired],
      number: [validateNumber],
      zipcode: [validateRequired],
      city: [validateRequired],
      country: [validateSelectField],
    };

    const validationErrors = validateForm(
      { ...formData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation && Object.keys(errors).length > 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors as LocationFormOrganismErrors);
    }
    if (Object.keys(validationErrors).length > 0) {
      return false;
    }

    return true;
  };

  const [options, setOptions] = useState({
    companyOptions: [] as OptionProps[],
    countryOptions: [] as OptionProps[],
  });

  useEffect(() => {
    const fetchLocationDetails = async () => {
      const url = id ? LOCATION_DETAILS + `/${id}` : LOCATION_DETAILS;
      const response = await ApiCall.getService(url, "GET", M_COMPANY_CREATION);
      if (response?.status === 200) {
        const { locationDetails, companyDetails, countries } = response.data;

        if (locationDetails && Object.keys(locationDetails).length > 0) {
          // const selCompany = companyDetails?.find(
          //   (company: any) => company.id === locationDetails?.companyId
          // );
          // const formattedSelectedCompany = selCompany
          //   ? {
          //       value: selCompany?.id,
          //       label: selCompany?.name,
          //     }
          //   : null;

          const selCountry = countries?.find(
            (country: any) => country.id === Number(locationDetails?.countryId)
          );

          const formattedSelectedCountry = selCountry
            ? {
                value: selCountry?.id,
                label: selCountry?.name,
              }
            : null;
          setFormData({
            ...formData,
            company: locationDetails.company,
            location: locationDetails.location,
            street: locationDetails.street,
            number: locationDetails.number,
            phone: locationDetails.phone,
            box: locationDetails.box,
            zipcode: locationDetails.zipcode,
            city: locationDetails.city,
            info: locationDetails.info,
            country: formattedSelectedCountry,
            initialLat: locationDetails.latitude,
            initialLn: locationDetails.longitude,
          });

          //   setSelectedCompany(selCompany ? selCompany.id : null);
        }
        const companyOptions = mapToSelect(companyDetails);
        const countryOptions = mapToSelect(countries);

        setOptions((prevData) => ({
          ...prevData,
          companyOptions: companyOptions,
          countryOptions: countryOptions,
        }));
      }
    };
    fetchLocationDetails();
  }, [id]);

  const handleSelectChange = (selectedOption: any, name: string) => {
    if (name === "company") {
      const id = selectedOption ? selectedOption?.value : null;
      setSelectedCompany(id);
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: selectedOption !== null ? selectedOption : "",
    }));
    validation(name, selectedOption, true);
  };
  const getCityAndCountry = async (zipcode: string) => {
    try {
      if (zipcode.length === 4) {
        const response = await axios.get(
          `https://api.zippopotam.us/be/${zipcode}`
        );
        // Check if the response contains places data and is in the expected format
        if (
          response.data &&
          response.data.places &&
          response.data.places.length > 0
        ) {
          return {
            city: response.data.places?.[0]?.["place name"] || "Not found",
            country: response.data?.country || "Belgium",
          };
        } else {
          return { city: "Not found", country: "Belgium" };
        }
      } else {
        return { city: "", country: "" };
      }
    } catch (error) {
      return { city: "Error", country: "Error" };
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "zipcode") {
      const city = getCityAndCountry(value);
      city.then((response: any) => {
        const { city, country } = response;
        setFormData((prevData) => ({
          ...prevData,
          ["city"]: city,
        }));
        const countryValue: any =
          country !== "Error"
            ? options.countryOptions.find(
                (coun: any) =>
                  coun?.label?.toLowerCase() === country?.toLowerCase()
              )
            : null;
        setFormData((prevData) => ({
          ...prevData,
          ["country"]: countryValue,
        }));
      });
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    validation(name, value, true);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const { name, value } = event.target as HTMLInputElement;
    if (validation(name, value)) {
      const url = id ? ADD_LOCATION + `/${id}` : ADD_LOCATION;
      const response = await ApiCall.service(
        url,
        "POST",
        formData,
        false,
        M_COMPANY_CREATION
      );
      if (response?.status === 200) {
        CustomNotify({ type: "success", message: response.msg });
        navigate(`${MANAGE_LOCATION}`);
      }
    }
  };

  const permissionType = id ? "update" : "create";
  const permissionObject: any = {
    permission: "Location",
  };
  permissionObject[permissionType] = true;
  const userData = useSelector(selectAuth);
  const handleSaveGoogleMapData = (googleMapAddress: any) => {
    setFormData((prevState) => ({
      ...prevState,
      street: googleMapAddress.street,
      number: googleMapAddress.number?.toString() || "", // Ensure it's a string
      box: googleMapAddress.box || "",
      zipcode: googleMapAddress.zipcode || "",
      city: googleMapAddress.city || "",
      country: googleMapAddress.country || null,
      initialLat: googleMapAddress.initialLat.toString() || "",
      initialLn: googleMapAddress.initialLn.toString() || "",
    }));
  };
  return (
    <AccessControl
      requiredPermissions={[permissionObject]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <div className="row">
        <div className="col-12">
          <Title title={`${text} ${t("location")}`} />
          <form action="" onSubmit={handleSubmit} className="form-group">
            <LocationFormOrganism
              formMode={formMode}
              formOptions={options}
              formData={formData}
              formErrors={errors}
              handleChange={handleChange}
              handleSelectChange={handleSelectChange}
              handleSaveGoogleMapData={handleSaveGoogleMapData}
            ></LocationFormOrganism>

            {/* <div className="form-height">
            <div className="form-border p-3 mb-4 pb-0">
              <div className="row mb-3">
                <div className="col-lg-12 col-md-6">
                  <SelectWithSearch
                    title="Company"
                    placeHolder="Select"
                    name="company"
                    isMandatory={true}
                    search={true}
                    options={options.companyOptions}
                    value={formData.company}
                    onChange={(e) => handleSelectChange(e, "company")}
                    isMulti={false}
                    className="select-field"
                    error={errors.company}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <LabelWithInputField
                    label="Location name"
                    name="location"
                    isMandatory={true}
                    value={formData.location}
                    handleChange={handleChange}
                    id="location"
                    type="text"
                    error={errors.location}
                  />
                </div>
                <div className="col-lg-6 col-md-12">
                  <LabelWithInputField
                    label="Phone number"
                    name="phone"
                    isMandatory={true}
                    value={formData.phone}
                    handleChange={handleChange}
                    id="phone"
                    type="text"
                    error={errors.phone}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-5 col-md-6">
                  <LabelWithInputField
                    label="Street"
                    name="street"
                    isMandatory={true}
                    value={formData.street}
                    handleChange={handleChange}
                    id="street"
                    type="text"
                    error={errors.street}
                  />
                </div>
                <div className="col-lg-5 col-md-6">
                  <LabelWithInputField
                    label="Number"
                    name="number"
                    isMandatory={true}
                    value={formData.number}
                    handleChange={handleChange}
                    id="number"
                    type="text"
                    error={errors.number}
                  />
                </div>
                <div className="col-lg-2 col-md-6">
                  <LabelWithInputField
                    label="Box"
                    name="box"
                    isMandatory={false}
                    value={formData.box}
                    handleChange={handleChange}
                    id="box"
                    type="text"
                    error={errors.box}
                  />
                </div>
                <div className="col-lg-6 col-md-6">
                  <LabelWithInputField
                    label="Zipcode"
                    name="zipcode"
                    isMandatory={true}
                    value={formData.zipcode}
                    handleChange={handleChange}
                    id="zipcode"
                    type="text"
                    error={errors.zipcode}
                  />
                </div>
                <div className="col-lg-6 col-md-6">
                  <LabelWithInputField
                    label="City"
                    name="city"
                    isMandatory={true}
                    value={formData.city}
                    handleChange={handleChange}
                    id="city"
                    type="text"
                    error={errors.city}
                  />
                </div>
                <div className="row mb-3">
                  <div className="col-lg-12 col-md-6">
                    <SelectWithSearch
                      title="Country"
                      name="country"
                      placeHolder="Select"
                      isMandatory={true}
                      search={true}
                      options={options.countryOptions}
                      value={formData.country}
                      onChange={(e) => handleSelectChange(e, "country")}
                      isMulti={false}
                      className="select-field"
                      error={errors.country}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-lg-12 col-md-6">
                    <LabelWithTextAreaField
                      label="Info"
                      name="info"
                      value={formData.info}
                      placeholder="info"
                      isDisabled={false}
                      isMandatory={false}
                      handleChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
             */}
            <div
              className="d-flex align-items-center justify-content-between"
              style={{ padding: "1vw 0" }}
            >
              <div>
                <BackButton />
              </div>
              <div className="text-end">
                <Button
                  title={`${text} location`}
                  type="submit"
                  className="form-button float-end"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </AccessControl>
  );
};

export default Location;
