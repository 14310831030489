import SelectWithSearch from "components/atoms/SelectWithSearch"
import { t } from "pages/microservices/masterData/translation/Translation"
import { useFormContext } from "../context/Context"
import { billingType, businessTypes, correctionSettlementType, InfoTabInvoiceTypes, UPDATE_FIELD_ERRORS, UPDATE_FIELD_VALUE } from "../context/Constants";
import CheckBoxField from "components/atoms/CheckBoxField";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import Calender from "components/molecules/Calender";
import LabelWithCKEditorField from "components/molecules/LabelWithCKEditorField";
import { InfoTabFields, InfoTabFieldsErrors } from "../context/Interface";
import { validateForm } from "services/validation/ValidationService";
import { InfoTabValidationRules } from "../validationRules/ValidationRules";

const Info = () => {
    const { state, dispatch } = useFormContext();

    const infoData: any = state?.info?.data;
    const infoFieldErrors: InfoTabFieldsErrors = state?.info?.errors;

    const validationFunction = (value: any, name: string) => {
        const validationErrors = validateForm({ [name]: value }, InfoTabValidationRules)

        dispatch({
            type: UPDATE_FIELD_ERRORS,
            field: name,
            tab: 'info',
            error: validationErrors.hasOwnProperty(name) ? validationErrors[name] : '',
        });
    }

    const handleSelectChange = (value: any, name: string, validate: boolean) => {
        dispatch({
            type: UPDATE_FIELD_VALUE,
            field: name,
            tab: 'info',
            value
        });
        validationFunction(value, name);
    }

    const handleChange = (event: any) => {
        const { name, value, checked, type } = event.target;
        let fieldValue = value;
        if (type == 'checkbox') {
            fieldValue = checked;
        }

        dispatch({
            type: UPDATE_FIELD_VALUE,
            field: name,
            tab: 'info',
            value: fieldValue,
        });
    }

    const handleDateChange = (event: any, name: string) => {
        if (event) {
            const date = `${event.getFullYear()}-${event.getMonth() + 1 < 10
                ? "0" + (event.getMonth() + 1)
                : event.getMonth() + 1
                }-${event.getDate() < 10 ? "0" + event.getDate() : event.getDate()}`;
            dispatch({
                type: UPDATE_FIELD_VALUE,
                field: name,
                tab: 'info',
                value: date,
            });
        }
    }

    const handleCKEditorChange = (
        event: any,
        editor: any,
        fieldName: string
    ) => {
        const content = editor.getData(); // Get the content from the editor
        const name = fieldName; // Use the fieldName parameter
        dispatch({
            type: UPDATE_FIELD_VALUE,
            field: name,
            tab: 'info',
            value: content,
        });
    };

    return (
        <div className="row">
            <div className="col-12">
                <div className="form-border" style={{ paddingTop: "3vw" }}>
                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <SelectWithSearch
                                onChange={(value) =>
                                    handleSelectChange(value, "noOfDays", true)
                                }
                                title={t("Number of days payment term (standard: 14)")}
                                placeHolder={t("Select")}
                                search={true}
                                options={state?.options?.paymentTerms}
                                value={infoData.noOfDays}
                                isDisabled={false}
                                isMulti={false}
                                isMandatory={true}
                                error={infoFieldErrors?.noOfDays}
                                name={"noOfDays"}
                                id={"noOfDays"}
                            />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <SelectWithSearch
                                onChange={(value) =>
                                    handleSelectChange(value, "billingType", true)
                                }
                                title={t("Billing type")}
                                placeHolder={t("Select")}
                                search={true}
                                options={billingType}
                                value={infoData.billingType}
                                isDisabled={false}
                                isMulti={false}
                                isMandatory={true}
                                error={infoFieldErrors?.billingType}
                                name={"billingType"}
                                id={"billingType"}
                            />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <SelectWithSearch
                                onChange={(value) =>
                                    handleSelectChange(value, "settlementType", true)
                                }
                                title={t("How should corrections be settled?")}
                                placeHolder={t("Select")}
                                search={true}
                                options={correctionSettlementType}
                                value={infoData.settlementType}
                                isDisabled={false}
                                isMulti={false}
                                isMandatory={false}
                                error={""}
                                name={"settlementType"}
                                id={"settlementType"}
                            />
                        </div>
                        {InfoTabInvoiceTypes.map((eachItem: any) => {
                            return (
                                <div className="col-sm-6 col-lg-3">
                                    <CheckBoxField
                                        label={t(
                                            eachItem?.label
                                        )}
                                        name={eachItem?.name}
                                        id={eachItem?.name}
                                        isChecked={infoData?.[eachItem?.name]}
                                        onChangeHandler={(event) => { handleChange(event) }}
                                        lineHeight="1.6vw"
                                    />
                                </div>
                            )
                        })}
                        {infoData?.paymentByDirectDebit && (
                            <>
                                <div className="row">
                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                        <LabelWithInputField
                                            type={"text"}
                                            handleChange={(event) => { handleChange(event) }}
                                            label={t("Bank Account No")}
                                            value={infoData?.accountNo}
                                            isDisabled={false}
                                            isMandatory={false}
                                            error={""}
                                            placeholder={t("IBAN")}
                                            name={"accountNo"}
                                            id={"accountNo"}
                                        />
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                        <LabelWithInputField
                                            type={"text"}
                                            handleChange={(event) => { handleChange(event) }}
                                            label={t("Mandate reference")}
                                            value={infoData?.mandateReference}
                                            isDisabled={false}
                                            isMandatory={false}
                                            error={""}
                                            placeholder={t("Mandate reference")}
                                            name={"mandateReference"}
                                            id={"mandateReference"}
                                        />
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                        <SelectWithSearch
                                            onChange={(value) =>
                                                handleSelectChange(value, "type", true)
                                            }
                                            title={t("Type")}
                                            placeHolder={t("Select")}
                                            search={true}
                                            options={businessTypes}
                                            value={infoData?.type}
                                            isDisabled={false}
                                            isMulti={false}
                                            isMandatory={false}
                                            error={""}
                                            name={"type"}
                                            id={"type"}
                                        />
                                    </div>
                                    <div className="col-sm-12 col-md-6 col-lg-3 position-relative">
                                        <Calender
                                            onChange={(event) => {
                                                handleDateChange(event, "date");
                                            }}
                                            selectedDate={infoData?.date}
                                            label={t("Date")}
                                            isMandatory={true}
                                            name={"holiday_date"}
                                            error={""}
                                            isDisabled={false}
                                            //maxDate={new Date()}
                                            placeHolder="dd-mm-yyyy"
                                        />
                                    </div>

                                </div>
                            </>
                        )}
                        <div className="col-md-12">
                            <LabelWithCKEditorField
                                label={t("Are there any comments?")}
                                name="comments"
                                value={infoData?.comments}
                                placeholder={t("Type here") + "..."}
                                handleChange={(event, editor) =>
                                    handleCKEditorChange(
                                        event,
                                        editor,
                                        "comments"
                                    )
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Info