import Targets from "./Targets";
import "../../src/static/css/welcome-dashboard.css";
import { useSelector } from "react-redux";
import * as CONST from "constants/Constants";
import Budget from "./Budget";
import { selectAuth } from "features/auth/AuthSlice";
import TodoDashboard from "./todoDashboard/TodoDashboard";
import ImageField from "components/atoms/ImageField";
import { Link } from "react-router-dom";
import { t } from "pages/microservices/masterData/translation/Translation";
import Count from "components/atoms/Count";
import RecruitmentCard from "components/atoms/RecruitmentCard";
import CardTitle from "components/atoms/CardTitle";
import HomePage from "pages/dashboards/HomePage";
import AccessControl from "services/AccessControl";

const WelcomeDashboard: React.FC = () => {
  const userAuth = useSelector(selectAuth);
  const commonParams = {
    //common params needed for all components
    userAuth: userAuth,
    CONST: CONST,
  };

  return (
    <>
      {userAuth?.role?.includes("SUPER_ADMIN") || userAuth?.role?.includes("ADMIN") ? (
        <HomePage />
      ) : (
        <>
          {/* Welcome text */}
          <div className="row marginBotttom1">
            <div className="col-12">
              {/* {userAuth.role && !userAuth.role?.includes("ADMIN") && (userAuth.role?.includes("SALES") || userAuth.role?.includes("HR")) && ( */}
              <AccessControl
                key={"welcome_message"}
                requiredPermissions={[
                  { permission: "Welcome message", read: true },
                ]}
                override={userAuth.isSuperAdmin}
              >
                <div className="d-flex align-items-center">
                  <span className="welcome-text marginRightPoint5 color-dark-purple">
                    <strong>{t("Hi") + ","}</strong>
                  </span>
                  <div className="position-relative">
                    <span className="name-text color-dark-pink ">
                      {userAuth.name}.
                    </span>
                  </div>
                </div>
                <div className="welcome-message color-dark-purple">
                  {t("Welcome back to your dashboard")}
                  {/* <br />
                  <div className="position-relative">
                    {t("your dashboard")}
                  </div> */}
                </div>
              </AccessControl>
              {/* )} */}
            </div>
          </div>
          {/* Targets and budget */}
          <div className="d-flex" style={{ gap: "1vw" }}>
            <div className="w-75 recruiter-action-tabs">
              <div className="d-grid dashboardGrid">
                <Targets />
              </div>
            </div>
            {/* Render Budget only if the user role is not EMPLOYER, ADMIN, or THREECX */}
            {/* {!userAuth.role?.includes("ADMIN") && (userAuth.role?.includes("SALES") || userAuth.role?.includes("HR")) && ( */}
            <AccessControl
              key={"my_budget"}
              requiredPermissions={[{ permission: "My budget", read: true }]}
              override={userAuth.isSuperAdmin}
            >
              <Budget params={commonParams} />
            </AccessControl>
            {/* )} */}
          </div>

          <div className="row marginBotttom1 marginTop1">
            <div className="col-5">
              <div className="new-vacancies-cadidates d-grid grid-2 marginBotttom1">
                <AccessControl
                  key={"new_vacancies"}
                  requiredPermissions={[
                    { permission: "New vacancies", read: true },
                  ]}
                  override={userAuth.isSuperAdmin}
                >
                  <div className="recruiter-action-tabs">
                    <Link to={"/vacancies"}>
                      <RecruitmentCard
                        className={`card border-0 card-shadow justify-content-center new-vacancies-cadidates-card `}
                      >
                        <div className="row">
                          <div className="col-md-12 align-self-center">
                            <CardTitle
                              title={t("New vacancies")}
                              className={`recruitment-card-title`}
                            />
                            <Count
                              value={0}
                              totalValue={t("go")}
                              value_punctuation={t("to")}
                              width={"0.2vw"}
                              height={"1.8vw"}
                              backgroundColor="var(--color-light-skyblue)"
                              className="recruitment-card-num"
                            ></Count>
                          </div>
                        </div>
                      </RecruitmentCard>
                    </Link>
                  </div>
                </AccessControl>
                <AccessControl
                  key={"new_candidates"}
                  requiredPermissions={[
                    { permission: "New candidates", read: true },
                  ]}
                  override={userAuth.isSuperAdmin}
                >
                  <div className="recruiter-action-tabs">
                    <Link to={"/manage-candidates"}>
                      <RecruitmentCard
                        className={`card border-0 card-shadow justify-content-center new-vacancies-cadidates-card `}
                      >
                        <div className="row">
                          <div className="col-md-12 align-self-center">
                            <CardTitle
                              title={t("New recruitments")}
                              className={`recruitment-card-title`}
                            />
                            <Count
                              value={0}
                              totalValue={t("go")}
                              value_punctuation={t("to")}
                              width={"0.2vw"}
                              height={"1.8vw"}
                              backgroundColor="var(--color-light-skyblue)"
                              className="recruitment-card-num"
                            ></Count>
                          </div>
                        </div>
                      </RecruitmentCard>
                    </Link>
                  </div>
                </AccessControl>
              </div>
              <AccessControl
                key={"image"}
                requiredPermissions={[{ permission: "Image", read: true }]}
                override={userAuth.isSuperAdmin}
              >
                <ImageField
                  altText="Banner"
                  className="dashboardBannerImage"
                  src="/static/images/loginImage.jpg"
                />
              </AccessControl>
            </div>
            <AccessControl
              key={"todo_dashboard"}
              requiredPermissions={[
                { permission: "Todo dashboard", read: true },
              ]}
              override={userAuth.isSuperAdmin}
            >
              <div className="col-7">
                <TodoDashboard />
              </div>
            </AccessControl>
          </div>
        </>
      )}
    </>
  );
};

export default WelcomeDashboard;
