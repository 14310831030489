import React, { useEffect, useState } from "react";
import { ApiCall } from "services/ApiServices";
import { useNavigate } from "react-router-dom";
import { PC_ARCHIVE, PC_GET } from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "constants/Constants";
import CustomNotify from "components/atoms/CustomNotify";
import { t } from "pages/microservices/masterData/translation/Translation";
import Search from "components/atoms/Search";
import DynamicTableStructure from "components/atoms/DynamicTableStructure";
import { PCHeader } from "TableHeader";
import ModalPopup from "components/atoms/ModalPopup";
import Pagination from "components/atoms/Pagination";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Title from "components/atoms/Title";
import ResetBtn from "components/atoms/ResetBtn";
import SearchBtn from "components/atoms/SearchBtn";
import LinkTo from "components/atoms/LinkTo";
import { useDispatch, useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import BackButton from "components/atoms/BackButton";
import AccessControl from "services/AccessControl";
import { selectPageHistory, updatePageFilters } from "store/pageHistory/pageHistorySlice";
import FilterOrganism from "components/organism/FilterOrganism";
import { PATH_MANAGE_FUNCTION_DOCUMENTS, PATH_PC_CREATE } from "constants/Paths";

export interface PC {
  id?: number;
  name: string;
}

interface DataProps {
  page: number;
  search?: any;
}

const ManagePC: React.FC = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const [pcData, setPcData] = useState<PC[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [submit, setSubmit] = useState(true);
  const [search, setSearch] = useState({
    name: queryParams.get("name") ?? "",
  });
  const [showModal, setShowModal] = useState(false);
  const [deleteID, setDeleteId] = useState<number | undefined>();

  const navigate = useNavigate();
  const userData = useSelector(selectAuth);

  const pageHistory = useSelector(selectPageHistory);
  const history = pageHistory?.history?.[pageHistory?.history?.length - 1] ?? {}
  const dispatch = useDispatch();
  const [pageData, setPageData] = useState({
    totalPages: 0,
    totalRecords: 0,
    refresh: true,
    initialCall: true
  });
  const filters = [
    { name: 'pc_number', fieldType: "text", placeholder: "Paritair comitee number", filterType: 'search' },
    { name: 'pc_name', fieldType: "text", placeholder: "Paritair comitee name", filterType: 'search' }
  ];

  const getSearchData = () => {
    return {
      pcNumber: history?.filters?.pc_number ?? "",
      pcName: history?.filters?.pc_name ?? ""
    }
  };

  useEffect(() => {
    const data: any = {
      currentPage: Number(history?.filters?.currentPage ?? 1),
      itemsPerPage: Number(history?.filters?.itemsPerPage ?? 10),
      search: getSearchData(),
      initialCall: pageData?.initialCall
    };
    fetchData(data);
  }, [pageData?.refresh, pageData?.initialCall]);

  const fetchData = async (data: any) => {
    const response = await ApiCall.service(PC_GET, "POST", data, false, M_MASTER_DATA);
    if (response?.status === 200) {
      setPcData(response.data);
      setTotalPages(response.totalPages);
    }
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleDelete = (PC: PC | any) => {
    setDeleteId(PC.id);
    setShowModal(true);
  };

  const handleModalClose = () => setShowModal(false);

  const handleSaveChanges = async () => {
    if (deleteID) {
      const url = `${PC_ARCHIVE}/${deleteID}`;
      const response = await ApiCall.getService(url, "GET", M_MASTER_DATA);
      if (response?.status === 200) {
        setPcData((prevData) => prevData.filter((pc) => pc.id !== deleteID));
        CustomNotify({ type: "success", message: response.message });
      }
    }
    setShowModal(false);
  };

  const handleRefresh = () => setPageData((prev: any) => ({ ...prev, refresh: !pageData?.refresh }));

  return (
    <AccessControl
      requiredPermissions={[{ permission: "Paritair commitee", read: true }]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <div className="search-bar">
        <div className="row">
          <Title title={t("Paritair committee")} />
        </div>
        <div className="row">
          <div className="col-12">
            <div className="position-relative tableMainWrapper">
              <div className="row">
                <div className="ManageCreateBtn">
                  <LinkTo
                    pagelink={PATH_PC_CREATE}
                    title={t("Create paritair comitee")}
                    icon={faPlus}
                    className="d-block m-auto"
                  />
                </div>
              </div>
              <div className="table-responsive tableSection managePc">
                <FilterOrganism
                  dispatch={dispatch}
                  history={history}
                  updatePageFilters={updatePageFilters}
                  filters={filters}
                  handleRefresh={handleRefresh}
                />
                <div className="mamagePcHeight">
                  <DynamicTableStructure
                    isAction
                    headers={PCHeader}
                    data={pcData}
                    handleSalaryBenefits={(pc: PC) => navigate(`/salary-benefit/${pc.id}`)}
                    handleEdit={(pc: PC) => navigate(`/pc/edit/${pc.id}`)}
                    handleDocuments={(pc: PC) => navigate(`${PATH_MANAGE_FUNCTION_DOCUMENTS}/${pc.id}`)}
                    handleArchive={handleDelete}
                    permission={"Paritair commitee"}
                  />
                  <div className="pagination justify-content-center align-items-center" style={{ paddingTop: "0.25vw" }}>
                    <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="row backPadding">
          <div className="col-md-6 align-self-center">
            <BackButton />
          </div>
        </div> */}
        <ModalPopup
          show={showModal}
          onHide={handleModalClose}
          title={t("Archive confirmation")}
          body={t("Are you sure want to archive?")}
          onCloseButtonClick={handleModalClose}
          onConfirmButtonClick={handleSaveChanges}
          closeTitle={t("No")}
          confirmTitle={t("Yes")}
        />
      </div>
    </AccessControl>
  );
};

export default ManagePC;
