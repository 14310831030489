import React from 'react'
import { t } from '../../translation/Translation';
import TextEllipsis from 'components/atoms/TextEllipsis';

const AttachmentsTab = (props: any) => {
    const attachmentsHeader = [
        { name: "Date", alias: "date" },
        { name: "Work order no.", alias: "week_number" },
        { name: "Customer", alias: "company_name" },
        { name: "Project", alias: "project_name" },
        { name: "Employee", alias: "employee_name" },
        { name: "Type of performance", alias: "type" },
        { name: "Number", alias: "number" },
        { name: "Price", alias: "price" },
        { name: "Rate", alias: "rate" },
        { name: "Total", alias: "total" },
    ];

    const renderRow = (timesheet: any, record: any, key: any) => {
        return (
            <tr key={key}>
                <td></td>
                <td className="align-middle">
                    <TextEllipsis
                        label={timesheet?.week_number}
                        title={timesheet?.week_number}
                        width="5vw"
                    />
                </td>
                <td className="align-middle">
                    <TextEllipsis
                        label={timesheet?.company_name}
                        title={timesheet?.company_name}
                        width="8vw"
                    />
                </td>
                <td className="align-middle">
                    <TextEllipsis
                        label={timesheet?.project_name}
                        title={timesheet?.project_name}
                        width="8vw"
                    />
                </td>
                <td className="align-middle">
                    <TextEllipsis
                        label={timesheet?.employee_name}
                        title={timesheet?.employee_name}
                        width="8vw"
                    />
                </td>
                <td className="align-middle">
                    <TextEllipsis
                        label={record?.type}
                        title={record?.type}
                        width="8vw"
                    />
                </td>
                <td className="align-middle">
                    <TextEllipsis
                        label={record?.numbers}
                        title={record?.numbers}
                        width="8vw"
                    />
                </td>
                <td className="align-middle">
                    <TextEllipsis
                        label={record?.price}
                        title={record?.price}
                        width="8vw"
                    />
                </td>
                <td className="align-middle">
                    <TextEllipsis
                        label={record?.percentage ?? 0}
                        title={record?.percentage ?? 0}
                        width="8vw"
                    />
                </td>
                <td className="align-middle">
                    <TextEllipsis
                        label={record?.totalPrice}
                        title={record?.totalPrice}
                        width="8vw"
                    />
                </td>
            </tr>
        );
    }

    return (
        <>
            <div className="table-responsive attachmentsTable tableWithSearchBar tablePadding marginTop1 mb-0 border createInvoiceTable">
                <table className="table table-hover mb-0">
                    <thead>
                        <tr className="TableHeader">
                            {attachmentsHeader?.map((header) => (
                                <th key={header?.alias} className={"table_actions"}>
                                    <>{t(header?.name)}</>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {props?.tabData?.timesheetData && props?.tabData?.timesheetData?.length > 0 ? (
                            props?.tabData?.timesheetData?.map((timesheet: any, key: any) => {
                                return (
                                    <>
                                        <tr key={"plan_date"} >
                                            <td colSpan={10}>{timesheet?.plan_date}</td>
                                        </tr>
                                        {timesheet?.performance?.map((record: any, index: number) => renderRow(timesheet, record,  "performance" + index))}
                                        {timesheet?.reimbursement?.map((record: any, index: number) => renderRow(timesheet, record, "reimbursement" + index))}
                                    </>
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan={9} className="text-center">
                                    {t("No records found")}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default AttachmentsTab