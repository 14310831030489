import { Option } from "utils/TypeAnnotations";
import { ADD_NEW_EMPLOYEE_TYPE, ADD_NEW_PC_FUNCTION, CLONE_FUNCTION_TYPE, CoefficientInitialState, DELETE_EMPLOYEE_TYPE, DELETE_FUNCTION_TYPE, EDIT_OR_DELETE_PARTICULAR_PC, EmpTypeData, FirstDependencyFields, FunctionData, pcList, SecondDependencyFields, SET_OPTIONS, SWITCH_TAB, UPDATE_COEFFCIENT_TAB_FIELD, UPDATE_COEFFICIENTS, UPDATE_CONTACT_PERSONS_ERRORS, UPDATE_FETCH_DATA, UPDATE_FIELD_ERRORS, UPDATE_FIELD_VALUE, UPDATE_GENERAL_TAB_DATA, UPDATE_INFO_TAB_DATA, UPDATE_NEXT_OR_PREVIOUS_TAB, UPDATE_PCS_FIELD_ERROR, UPDATE_PCS_TO_TABLE, UPDATE_SPECIAL_AGREEMENT_FIELD, UPDATE_SPECIAL_AGREEMENT_TAB, UPDATE_TAB_ERROR, UPDATE_TABS_FIELDS_ERRORS } from "./Constants";
import { CooperationTabs } from "./Interface";

type CooperationReducerProps = {
    state: any;
    action: any;
};

export const CooperationReducer = (state: CooperationReducerProps['state'],
    action: CooperationReducerProps['action']
) => {
    switch (action?.type) {
        case SWITCH_TAB:
            const { field, value } = action;
            const tabs = state?.tabs;
            const updatedTab = tabs?.find((eachTab: CooperationTabs) => (eachTab?.id == value));

            if (updatedTab !== undefined) {
                return {
                    ...state,
                    [field]: updatedTab?.id,
                }
            }
            return { ...state };

        case SET_OPTIONS:
            return {
                ...state,
                options: {
                    ...state?.options,
                    ...action?.data
                }
            }

        case UPDATE_FIELD_VALUE:
            return action?.entityType ? updatePCForEmployeeType(state, action) : updateFieldsData(state, action)
        case UPDATE_FIELD_ERRORS:
            return {
                ...state,
                [action?.tab]: {
                    ...state?.[action?.tab],
                    errors: {
                        ...state?.[action?.tab]?.errors,
                        [action?.field]: action?.error,
                    }
                }
            }
        case UPDATE_CONTACT_PERSONS_ERRORS:
            return {
                ...state,
                [action?.tab]: {
                    ...state?.[action?.tab],
                    errors: {
                        ...state?.[action?.tab]?.errors,
                        ...action?.errors
                    }
                }
            }
        case ADD_NEW_PC_FUNCTION:
            const updatedList = [...state?.[action?.tab]?.[action?.mainIndex]?.funEmpType, ...FunctionData];

            const updatedFuncData = [...state?.[action?.tab]].map((eachItem: any, index: number) => {
                if (index == action?.mainIndex) {

                    return { ...eachItem, funEmpType: updatedList };
                }
                return eachItem;
            })

            return {
                ...state,
                [action?.tab]: updatedFuncData
            }
        case ADD_NEW_EMPLOYEE_TYPE:
            const subDataList = [...state?.[action?.tab]?.[action?.mainIndex]?.funEmpType?.[action?.subIndex]?.empTypeData, EmpTypeData];

            const updatedState = [...state?.[action?.tab]].map((eachItem: any, index: number) => {
                if (index == action?.mainIndex) {
                    const updatedSubList = [...state?.[action?.tab]?.[action?.mainIndex]?.funEmpType].map((eachSub: any, subIndex: number) => {

                        if (subIndex == action?.subIndex) {
                            return { ...eachSub, empTypeData: subDataList };
                        }
                        return eachSub;
                    });
                    return { ...eachItem, funEmpType: updatedSubList };
                }
                return eachItem;
            });

            return {
                ...state,
                [action?.tab]: updatedState,
            }

        case CLONE_FUNCTION_TYPE:
            const originalData = [...state?.coefficient?.[action?.pcIndex]?.funEmpType,
            state?.coefficient?.[action?.pcIndex]?.funEmpType?.[action?.funcIndex]];

            const updatedData = [...state?.coefficient].map((eachPc: any, index: number) => {
                if (index == action?.pcIndex) {
                    return { ...eachPc, funEmpType: originalData };
                }
                return eachPc;
            })

            return {
                ...state,
                coefficient: updatedData,
            }

        case DELETE_FUNCTION_TYPE:
            const newProfileData = [...state?.coefficient?.[action?.pcIndex]?.funEmpType];
            newProfileData.splice(action?.funcIndex, 1);

            const updatedLists = [...state?.coefficient]?.map((eachPc: any, index: number) => {
                if (index == action?.pcIndex) {
                    return { ...eachPc, funEmpType: newProfileData };
                }
                return eachPc;
            });

            return {
                ...state,
                coefficient: updatedLists,
            }

        case DELETE_EMPLOYEE_TYPE:
            const newFuncEmps = [...state?.coefficient?.[action?.pcIndex]?.funEmpType?.[action?.funcIndex]?.empTypeData];
            newFuncEmps.splice(action?.empRowIndex, 1);

            const updatedEmpTypes = [...state?.coefficient].map((eachItem: any, index: number) => {
                if (index == action?.pcIndex) {
                    const updatedEmps = eachItem?.funEmpType?.map((eachSub: any, subIndex: number) => {
                        if (subIndex == action?.funcIndex) {
                            return { ...eachSub, empTypeData: newFuncEmps };
                        }
                        return eachSub;
                    });
                    return { ...eachItem, funEmpType: updatedEmps };
                }
                return eachItem;
            });

            return {
                ...state,
                coefficient: updatedEmpTypes,
            }

        case UPDATE_PCS_TO_TABLE:
            let previousData = [...state?.general?.data?.[action?.entityType]?.pcsList];
            const currentPcs = state?.general?.data?.[action?.entityType];
            const currentInd = currentPcs?.editIndex;

            if (currentInd !== null) {
                previousData.splice(currentInd, 1);
            }

            previousData = [...previousData, currentPcs?.data];

            return {
                ...state,
                [action?.tab]: {
                    ...state?.[action?.tab],
                    data: {
                        ...state?.[action?.tab]?.data,
                        [action?.entityType]: {
                            ...state?.[action?.tab]?.data?.[action?.entityType],
                            data: {
                                ...state?.[action?.tab]?.data?.[action?.entityType]?.data,
                                pc: "",
                                employeeType: "",
                            },
                            editIndex: null,
                            pcsList: previousData,
                        }
                    }
                }
            }

        case UPDATE_PCS_FIELD_ERROR:
            return {
                ...state,
                [action?.tab]: {
                    ...state?.[action?.tab],
                    errors: {
                        ...state?.[action?.tab]?.errors,
                        [action?.entityType]: {
                            ...state?.[action?.tab]?.errors?.[action?.entityType],
                            errors: action?.errors,
                        }
                    }
                }
            }

        case EDIT_OR_DELETE_PARTICULAR_PC:
            if (action?.delete) {
                const currentPcList = [...state?.[action?.tab]?.data?.[action?.entityType]?.pcsList];
                currentPcList.splice(action?.index, 1);

                return {
                    ...state,
                    [action?.tab]: {
                        ...state?.[action?.tab],
                        data: {
                            ...state?.[action?.tab]?.data,
                            [action?.entityType]: {
                                ...state?.[action?.tab]?.data?.[action?.entityType],
                                pcsList: currentPcList,
                            }
                        }
                    }
                }
            }
            else {
                const pcData = state?.[action?.tab]?.data?.[action?.entityType]?.pcsList?.[action?.index];

                return {
                    ...state,
                    [action?.tab]: {
                        ...state?.[action?.tab],
                        data: {
                            ...state?.[action?.tab]?.data,
                            [action?.entityType]: {
                                ...state?.[action?.tab]?.data?.[action?.entityType],
                                data: pcData,
                                editIndex: action?.index,
                            }
                        }
                    }
                }
            }

        case UPDATE_COEFFCIENT_TAB_FIELD:
            const { coeffield, coeffValue, empRowIndex, coeffIndex, entityType } = action;

            //Dependencies are there in constants and field name is added in that dependency
            const coefficientData = [...state?.coefficient]?.map((eachItem: any, pIndex: number) => {
                if (pIndex !== null && pIndex == action?.pcIndex) {
                    const pcFunction = eachItem?.funEmpType?.map((eachFunc: any, subIndex: number) => {
                        if ((subIndex == action?.funcIndex)) {
                            if (FirstDependencyFields.includes(coeffield)) {
                                return { ...eachFunc, [coeffield]: coeffValue }
                            }
                            const empTypes = eachFunc?.empTypeData?.map((eachEmp: any, empIndex: number) => {
                                if (empIndex == empRowIndex &&
                                    (SecondDependencyFields.includes(coeffield) || SecondDependencyFields.includes(entityType))) {
                                    if (entityType) {
                                        const coefficients = eachEmp?.[entityType]?.map((eachCoeff: any, coefIndex: number) => {
                                            if (coefIndex == coeffIndex) {
                                                return { ...eachCoeff, [coeffield]: coeffValue };
                                            }
                                            return eachCoeff;
                                        });
                                        return { ...eachEmp, [entityType]: coefficients };
                                    }
                                    return { ...eachEmp, [coeffield]: coeffValue };
                                }
                                return eachEmp;
                            });
                            return { ...eachFunc, empTypeData: empTypes };

                        }
                        return eachFunc;
                    });
                    return { ...eachItem, funEmpType: pcFunction };
                }
                return eachItem;
            });

            return {
                ...state,
                coefficient: coefficientData,
            };

        case UPDATE_SPECIAL_AGREEMENT_FIELD:
            const splAgreement = [...state?.[action?.tab]];
            const updatedSplAgree = splAgreement?.map((eachAgree: any, index: number) => {
                if (index == action?.index) {
                    return { ...eachAgree, [action?.fieldName]: action?.fieldValue };
                }
                return eachAgree;
            });

            return {
                ...state,
                [action?.tab]: updatedSplAgree,
            };

        case UPDATE_NEXT_OR_PREVIOUS_TAB:
            const presentTab = state?.currentTab;
            let currentTab = null;
            const currentIndex = [...state?.tabs].findIndex((eachItem: any) => eachItem.id == presentTab);
            if (action?.navigationType == 'next') {
                const tabData = state?.tabs?.[currentIndex + 1];
                if (tabData) {
                    currentTab = tabData?.id;
                }
            }
            else {
                const tabData = state?.tabs?.[currentIndex - 1];
                currentTab = tabData?.id
            }

            return {
                ...state,
                currentTab,
            }

        case UPDATE_TABS_FIELDS_ERRORS:
            return {
                ...state,
                [action?.tab]: {
                    ...state?.[action?.tab],
                    errors: {
                        ...state?.[action?.tab].errors,
                        ...action?.errors,
                    }
                }
            }

        case UPDATE_SPECIAL_AGREEMENT_TAB:
            return {
                ...state,
                [action?.tab]: action?.data
            }

        case UPDATE_COEFFICIENTS:
            const newCoefficients = [...state?.coefficient]?.map((eachItem: any, pIndex: number) => {
                if (pIndex == action?.pcIndex) {
                    const updatedData = eachItem?.funEmpType?.map((eachFunc: any, fIndex: number) => {
                        if (action?.funcIndex == fIndex) {
                            const empTypeData = eachFunc?.empTypeData?.map((eachEmp: any, eIndex: number) => {
                                if (eIndex == action?.empRowIndex) {
                                    return { ...eachEmp, coefficient: action?.data };
                                }
                                return eachEmp;
                            });
                            return { ...eachFunc, empTypeData: empTypeData };
                        }
                        return eachFunc;
                    });
                    return { ...eachItem, funEmpType: updatedData };
                }
                return eachItem;
            });

            return {
                ...state,
                coefficient: newCoefficients,
            }

        case UPDATE_TAB_ERROR:
            const updatedTabs = [...state?.tabs]?.map((eachTab: any) => {
                if (eachTab?.id == action?.tab) {
                    return { ...eachTab, error: action?.error };
                }
                return eachTab;
            });

            return {
                ...state,
                tabs: updatedTabs
            }

        case UPDATE_GENERAL_TAB_DATA:
            return {
                ...state,
                general: {
                    data: {
                        ...state?.general?.data,
                        ...action?.data,
                        whiteCollarPcs: {
                            ...state?.general?.data?.whiteCollarPcs,
                            ...action?.data?.whiteCollarPcs,
                        },
                        blueCollarPcs: {
                            ...state?.general?.data?.blueCollarPcs,
                            ...action?.data?.blueCollarPcs,
                        }
                    }
                }
            }

        case UPDATE_INFO_TAB_DATA:
            const { invoice_options, ...remainingData } = action?.data;
            return {
                ...state,
                info: {
                    ...state?.info,
                    data: {
                        ...state?.info?.data,
                        ...remainingData,
                        ...invoice_options,
                    }
                }
            }

        case UPDATE_FETCH_DATA:
            return {
                ...state,
                fetchFunction: action?.isFetchData
            }

        default:
            return { ...state }
    }
}

export const updatePCForEmployeeType = (state: any, action: any) => {
    const updatedState = {
        ...state,
        [action?.tab]: {
            ...state?.[action?.tab],
            data: {
                ...state?.[action?.tab]?.data,
                [action?.entityType]: {
                    ...state?.[action?.tab]?.data?.[action?.entityType],
                    data: {
                        ...state?.[action?.tab]?.data?.[action?.entityType]?.data,
                        [action?.field]: action?.value,
                    }
                }
            }
        }
    }
    return updatedState;
}

export const updateFieldsData = (state: any, action: any) => {
    const updatedState = {
        ...state,
        [action?.tab]: {
            ...state?.[action?.tab],
            data: {
                ...state?.[action?.tab]?.data,
                [action?.field]: action?.value
            }
        }
    };
    return updatedState;
}