import ResetBtn from 'components/atoms/ResetBtn'
import FilterMolecule from 'components/molecules/FilterMolecule'
import React from 'react'
import { handleClear } from 'services/util/UtilService'

const FilterOrganism = (props: any) => {
    return (
        <>
            <table className="table filterTable border-0 marginBottomPointTwo5">
                <thead>
                    <tr className={`tableSearchFields ${props?.class?.rowClass}`}>
                        <FilterMolecule
                            dispatch={props?.dispatch}
                            searchData={props?.history?.filters}
                            filters={props?.filters}
                            colClass={props?.class?.colClass}
                            handleRefresh={props?.handleRefresh}
                        />
                        <th className={`border-0 ${props?.class?.searchBtnClass}`}>
                            <div className={`d-flex align-items-center w-100 ${props?.class?.searchBtnClass && ' justify-content-end'}`}>
                                <ResetBtn
                                    handleResetClick={(e) => handleClear({
                                        dispatch: props?.dispatch,
                                        updatePageFilters: props?.updatePageFilters,
                                        handleRefresh: props?.handleRefresh,
                                    })} 
                                />
                            {/* <AdvancedSearchBtn
                                    handleSearch={handleSearchClick}
                                    clearAdvSearchFilter={clearAdvSearchFilter}
                                    filterData={advSearchData}
                                    /> */}
                            </div>
                        </th>
                    </tr>
                </thead>
            </table>
        </>
    )
}

export default FilterOrganism