const handleCalculations = async (props: any) => {
    let btw: any = {};
    let totals = { subtotal: 0, btw: 0, total: 0 };

    let ruleData = props?.tabData?.ruleData?.map((record: any) => {
        let vatrate = props?.dropdownData?.standardVatRates?.find((item: any) => item?.value == (record?.vatrateid ?? props?.formData?.standardVatRate));
        record.vatrate = vatrate?.percentage ?? 1;
        record.subtotal = calculateSubTotal(record);
        record.btw = calculateBtw(record);
        record.total_price = calculateTotal(record);
        
        let vatrateId = Number(vatrate?.value ?? props?.formData?.standardVatRate ?? 0);
        
        btw[vatrateId] = {
            ...btw[vatrateId],
            code: vatrate?.label ?? "0",
            basis: (btw[vatrateId]?.basis ?? 0) + record.subtotal,
            btw: (btw[vatrateId]?.btw ?? 0) + record.btw,
        };

        totals.subtotal += record.subtotal;
        totals.btw += record.btw;
        totals.total += record.total_price;

        return record;
    });

    props?.setTabData((prev: any) => ({...prev, ruleData: ruleData ?? [], totals, btw}));
};

const calculateSubTotal = ({ number, price }: any) => {
    return Math.round((parseFloat(number ?? 0) * parseFloat(price ?? 0)));
};

const calculateBtw = ({ subtotal, vatrate }: any) => {
    return Math.round(((subtotal / 100) * parseFloat(vatrate ?? 0)));
};

const calculateTotal = ({ subtotal, btw }: any) => {
    return Math.round((subtotal + btw) * 100) / 100;
};

export {
    handleCalculations,
    calculateSubTotal,
    calculateBtw,
    calculateTotal,
};
