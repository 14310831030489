import React from "react";

interface TitleAtomProps {
  title?: any;
  className?: string;
}

const TitleAtom: React.FC<TitleAtomProps> = ({ title, className = "col-md-12" }) => {
  return (
    <div className={className}>
      <h1 className="page-title mb-0" style={{ paddingBottom: "0.5vw" }}>
        {title}
      </h1>
    </div>
  );
};
export default TitleAtom;
