import MultiSelectAtom from "components/atoms/MultiSelectAtom";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { OptionProps } from "utils/TypeAnnotations";
import { LabelWithInputField } from "./LabelWithInputField";
import { t } from "pages/microservices/masterData/translation/Translation";
import { InputTextFieldWithDebounce } from "pages/microservices/planning/molecules/InputTextFieldWithDebounce";
import { useDispatch } from "react-redux";
import { updatePageFilters } from "store/pageHistory/pageHistorySlice";
import { Option } from "react-multi-select-component";
import CheckBoxField from "components/atoms/CheckBoxField";
import Calender from "./Calender";

interface FilterMoleculeProps {
  searchData: any;
  filters: Array<{
    label?: string;
    name: string;
    options?: OptionProps[];
    fieldType: string;
    placeholder?: string;
    isMulti?: boolean;
    isTranslate?: boolean;
    fieldPrefix?: string;
    type?: string;
    filterType?: string;
    autoComplete?: string;
    end?: any;
    start?: any;
    default?: any;
    isDisable?: any;
    value?: any;
    onchangeCall?: (value: any, filter: any) => void;
  }>;
  colClass?: string;
  dispatch: any;
  handleRefresh?: () => void;
}

const FilterMolecule: React.FC<FilterMoleculeProps> = ({
  searchData,
  filters,
  handleRefresh,
  dispatch,
  colClass = ""
}) => {
  const handleChange = (value: any, filter: any) => {
    switch (filter?.fieldType) {
      case 'singleSelect':
        value = value?.value ?? "";
        break;

      case 'date':
        if (value) {
          value = `${value.getFullYear()}-${value.getMonth() + 1 < 10
            ? "0" + (value.getMonth() + 1)
            : value.getMonth() + 1
            }-${value.getDate() < 10 ? "0" + value.getDate() : value.getDate()}`;
        }
        else {
          value = ""
        }
        break

      case 'default':
        value = value;
        break;
    }
    if (filter?.onchangeCall) {
      filter?.onchangeCall(value, filter);
    } else {
      dispatch(updatePageFilters({ filters: { [filter?.name]: value, currentPage: "1" } }));
      handleRefresh && handleRefresh();
    }
  }

  const renderFilter = (filter: any) => {
    switch (filter?.fieldType) {
      case "multiSelect":
        return (
          <MultiSelectAtom
            onChange={(selectedOption) => handleChange(selectedOption, filter)}
            name={filter.name}
            options={filter.options ?? []}
            value={searchData?.[filter?.name] ?? []}
            placeHolder={t(filter.placeholder ?? "")}
          />
        );

      case "singleSelect":
        return (
          <SelectWithSearch
            search={true}
            options={filter.options ?? []}
            onChange={(selectedOption) => handleChange(selectedOption, filter)}
            name={filter?.name}
            value={filter?.value == undefined ? ((searchData?.[filter?.name] && searchData?.[filter?.name] != "")
              ? searchData?.[filter?.name] : (filter?.default ?? null)) : filter?.value}
            placeHolder={t(filter.placeholder ?? "")}
            isDisabled={filter?.isDisable}
          />
        );

      case "text":
        return (
          <InputTextFieldWithDebounce
            name={filter.name}
            handleChange={(e: any) => handleChange(e, filter)}
            value={searchData?.[filter.name] ?? ""}
            id={filter.name}
            type={filter.type ?? "text"} // Input field type is text
            placeholder={t(filter.placeholder ?? "")} // Use placeholder if provided
          />
        );

      case "textWithPrefix":
        return (
          <div className="input-group position-relative prefixWrapper">
            <div className="position-absolute searchFieldPrefix">
              {filter.fieldPrefix}
            </div>
            <InputTextFieldWithDebounce
              name={filter.name}
              handleChange={(e: any) => handleChange(e, filter)}
              value={searchData?.[filter.name] ?? ""}
              id={filter.name}
              type={filter.type ?? "text"} // Input field type is text
              placeholder={t(filter.placeholder ?? "")} // Use placeholder if provided
            />
          </div>
        );

      case "checkbox":
        return (
          <div className="input-group position-relative prefixWrapper">
            <div className="">
              {filter.label}
            </div>
            <CheckBoxField
              name={filter.name}
              isChecked={searchData?.[filter.name] ?? false}
              onChangeHandler={(e) => handleChange(e.target.checked, filter)}
            />
          </div>
        );

      case "date":
        return (
          <Calender
            onChange={(date) => handleChange(date, filter)}
            selectedDate={searchData?.[filter.name] ?? null}
            name={filter.name}
            maxDate={filter?.end ? new Date(filter?.end) : null}
            minDate={filter?.start ? new Date(filter?.start) : null}
            placeHolder={t(filter.placeholder ?? "")}
          />
        );

      case "empty":
        return ""

      default:
        return ""; // For unsupported types, return nothing
    }
  }

  const renderFilters = (filters: any) => {
    return filters?.map((filter: any) => {
      if (filter?.filterType === "multiple" && filter?.filters) {
        return (
          <th key={filter?.name} className={`FilterMolecule border-0 ${colClass}`}>
            <div className="d-flex align-items-center filterGroupedWrapper">
              {filter?.filters?.map((nestedFilter: any) => (
                <div key={nestedFilter?.name} className="d-inline-block">
                  {renderFilter(nestedFilter)}
                </div>
              ))}
            </div>
          </th>
        );
      } else if (filter?.filterType === "search") {
        return (
          <th key={filter?.name} className={`FilterMolecule border-0 ${colClass}`}>
            {renderFilter(filter)}
          </th>
        );
      }
      return null;
    });
  };

  return <>{filters && renderFilters(filters)}</>;
};

export default FilterMolecule;