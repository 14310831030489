import React, { useEffect, useState } from 'react';
import { t } from '../translation/Translation';
import { Option, ValidationRules } from 'utils/TypeAnnotations';
import Title from 'components/atoms/Title';
import { ApiCall } from 'services/ApiServices';
import { useNavigate, useParams } from 'react-router-dom';
import { GET_INVOICES, UPDATE_INVOICE } from 'routes/ApiEndpoints';
import { M_MASTER_DATA } from 'constants/Constants';
import Button from 'components/atoms/Button';
import CustomNotify from 'components/atoms/CustomNotify';
import { PATH_MANAGE_INVOICE } from 'constants/Paths';
import { validateForm, validateRequired } from 'services/validation/ValidationService';
import InvoiceTab from './molecules/InvoiceTab';
import AttachmentsTab from './molecules/AttachmentsTab';
import HeaderDetails from './molecules/HeaderDetails';
import { handleCalculations } from './utils/utils';
import PopUps from './molecules/PopUps';
import BackButton from 'components/atoms/BackButton';
import AccessControl from 'services/AccessControl';
import { useSelector } from 'react-redux';
import { selectAuth } from 'features/auth/AuthSlice';

interface CreateInvoiceProps {
    businessUnit: any;
    company: string;
    project: any;
    documentNumber: string;
    reference: string;
    type: any;
    status: any;
    invoiceDate: Date | null;
    expirationDate: Date | null;
    paymentTerms: any;
    standardVatRate: any;
    standardSalesAccount: any;
}

interface DropdownDataProps {
    businessUnits: Option[];
    projects: Option[];
    types: Option[];
    statuses: Option[];
    paymentTerms: Option[];
    standardVatRates: Option[];
    standardSalesAccounts: Option[];
    companies: Option[];
}

export const CreateInvoice = () => {
    const { id } = useParams();
    const userData = useSelector(selectAuth);
    const navigate = useNavigate();
    const initialInvoiceProps: CreateInvoiceProps = {
        businessUnit: null,
        company: '',
        project: null,
        documentNumber: '',
        reference: '',
        type: null,
        status: null,
        invoiceDate: null,
        expirationDate: null,
        paymentTerms: null,
        standardVatRate: null,
        standardSalesAccount: null,
    };
    const [formData, setFormData] = useState<CreateInvoiceProps>(initialInvoiceProps);
    const [dropdownData, setDropdownData] = useState<DropdownDataProps>({
        businessUnits: [],
        projects: [],
        types: [],
        statuses: [],
        paymentTerms: [],
        standardVatRates: [],
        standardSalesAccounts: [],
        companies: [],
    });
    const initialErrors: { [key: string]: string } = {};
    const [errors, setErrors] = useState<{ [key: string]: string }>(initialErrors);
    const [tabData, setTabData] = useState<any>({
        currectTab: 'invoice',
        tabs: [
            {id: 'invoice', name: 'Invoice'},
            {id: 'attachments', name: 'Attachments'},
        ],
        popup: {
            name: null,
            data: null,
            errors: null,
        },
        timesheetData: [],
        ruleData: [],
        btw: {},
        totals: {
            subtotal: "0",
            btw: "0",
            total: "0",
            tobepaid: "0"
        },
        calcRefresh: false,
        initialCall: false,
        tobepaid: 0,
    });

    useEffect(() => {
        if (tabData?.initialCall === true) {
            handleCalculations({tabData, formData, setTabData, dropdownData})
        } else {
            fetchInvoiceData();
        }
    }, [tabData?.calcRefresh, tabData?.initialCall]);

    const validation = (name: any = null, value: any = null, isSingleFieldValidation = false) => {
        const validationRules: ValidationRules = {
            documentNumber: [validateRequired],
        };
        const validationErrors = validateForm({ ...formData, [name]: value }, validationRules, isSingleFieldValidation ? name : undefined);
        if (isSingleFieldValidation) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: validationErrors[name],
            }));
        } else {
            setErrors(validationErrors);
        }
        if (Object.keys(validationErrors).length > 0) {
            return false;
        }
        return true;
    };

    const fetchInvoiceData = async () => {
        const url = id ? `${GET_INVOICES}/${id}` : GET_INVOICES;
        const response = await ApiCall.service(url, 'POST', {}, true, M_MASTER_DATA);
        if (response?.status === 200) {
            setDropdownData(response?.data?.dropdownData);
            if (id && !tabData?.initialCall) {
                setFormData(response?.data?.invoiceData ?? []);
                setTabData((prev: any) => ({
                    ...prev,
                    initialCall: true,
                    calcRefresh: !tabData?.calcRefresh,
                    tobepaid: response?.data?.tobepaid ?? 0,
                    ruleData: response?.data?.rulesData ?? [],
                    timesheetData: response?.data?.timesheetData ?? [],
                }));
            }
        }
    }

    const handleSelectChange = (selectedOption: Option | any, fieldName: string) => {
        if (fieldName === 'standardVatRate') {
            setTabData((prev: any) => ({
                ...prev, calcRefresh: !tabData?.calcRefresh,
                ruleData: prev?.ruleData?.map((item: any) => {
                    return item?.ruletype
                        ? {...item, vatrateid: selectedOption?.value ?? null}
                        : {...item};
                })
            }))
        }
        if (fieldName === 'standardSalesAccount') {
            setTabData((prev: any) => ({
                ...prev, calcRefresh: !tabData?.calcRefresh,
                ruleData: prev?.ruleData?.map((item: any) => {
                    return item?.ruletype
                        ? {...item, salesaccountid: selectedOption?.value ?? null}
                        : {...item};
                })
            }))
        }
        setFormData((prevData: CreateInvoiceProps) => ({
            ...prevData,
            [fieldName]: selectedOption?.value ?? null,
        }));
    }

    const handleFieldChange = (event: any) => {
        const { name, value } = event.target;
        setFormData((prevData: CreateInvoiceProps) => ({
            ...prevData,
            [name]: value,
        }));
        validation(name, value, true);
    }

    const handleDateChange = (date: Date | null, fieldName: string) => {
        setFormData((prevData: CreateInvoiceProps) => ({
            ...prevData,
            [fieldName]: date,
        }));
    }

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const { name, value } = e.target as HTMLInputElement;
        const validationResult = validation(name, value);
        if (validationResult) {
            const postData = {
                invoiceData: {...formData},
                rulesData: tabData?.ruleData,
            };
            const response = await ApiCall.service(UPDATE_INVOICE, 'POST', postData, true, M_MASTER_DATA);
            if (response?.status === 200) {
                CustomNotify({ type: 'success', 'message': response?.message });
                navigate(`${PATH_MANAGE_INVOICE}`);
            }
        }
    }

    const renderTabData = () => {
        let component = <></>;
        switch (tabData?.currectTab) {
            case 'invoice':
                component = <>
                    <InvoiceTab
                        dropdownData={dropdownData}
                        formData={formData}
                        tabData={tabData}
                        setTabData={setTabData}
                        userData={userData}
                    />
                </>
                break;
            case 'attachments':
               component = <>
                    <AttachmentsTab
                        formData={formData}
                        dropdownData={dropdownData}
                        tabData={tabData}
                        setTabData={setTabData}
                        userData={userData}
                    />
                </>
                break;
            default:
                break;
        }
        return component;
    }

    const handleTabClick = (item: any) => setTabData((prev: any) => ({ ...prev, currectTab: item?.id }));

    const handleClosePopup = () => setTabData((prev: any) => ({ ...prev, popup: {...prev?.popup, name: '', data: null} }));

    return (
        <AccessControl
            requiredPermissions={[
                {
                    permission: "Invoices",
                    read: true,
                },
            ]}
            override={userData.isSuperAdmin}
            renderNoAccess={true}
        >
            <div className="search-bar">
                <div>
                    <Title title={t("Create invoice")} />
                </div>
                <HeaderDetails
                    disabled={id}
                    handleSelectChange={handleSelectChange}
                    dropdownData={dropdownData}
                    handleFieldChange={handleFieldChange}
                    errors={errors}
                    formData={formData}
                    handleDateChange={handleDateChange}
                />
                <div className="d-flex align-items-center justify-content-between">
                    <div className='createNavTabs'>
                        {tabData?.tabs?.map((item: any) => (
                            <>
                                <Button 
                                    title={t(item?.name)}
                                    handleClick={(e) => handleTabClick(item)}
                                    className={`link ${item?.id == tabData?.currectTab ? "active" : ""} nav-subtab marginRightPoint5 border-0 bg-transparent ps-0 btn cursor-pointer`}
                                />
                            </>
                        ))}
                    </div>
                    {/* If status is closed then do not show add payment and new rule button */}
                    {formData?.status != 1 && <div className='d-flex justify-content-end'>
                        <Button 
                            title={"+ " + t("New rule")}
                            handleClick={() => setTabData((prev: any) => ({
                                    ...prev, popup: {...prev?.popup, name: 'newrule',
                                    data: {
                                        quantity: "0,0", price: "0,0", btw: formData?.standardVatRate,
                                        salesaccount: formData?.standardSalesAccount, description: "",
                                    },
                                    errors: {quantity: "", price: "", btw: "",salesaccount: "", description: ""}
                                } 
                            }))}
                            className="form-button"
                        />
                        <Button 
                            title={"+ " + t("Add payment")}
                            handleClick={() => setTabData((prev: any) => ({
                                    ...prev, popup: {...prev?.popup, name: 'payment',
                                    data: {amount: "0,0", paidon: new Date()},
                                    errors: {amount: "", paidon: ""}
                                } 
                            }))}
                            className="form-button ms-3"
                        />
                    </div>}
                </div>
            </div>
            {renderTabData()}
            <div className='d-flex justify-content-between backPadding'>
                <BackButton />
                <Button
                    title={t("Save")}
                    handleClick={(e) => handleSubmit(e)}
                    className="form-button"
                />
            </div>
            <PopUps 
                formData={formData}
                dropdownData={dropdownData}
                tabData={tabData}
                userData={userData}
                setTabData={setTabData}
                handleClosePopup={() => handleClosePopup()}
            />
        </AccessControl>
);
}