import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "components/atoms/Button";
import ModalPopup from "components/atoms/ModalPopup";
import { ApiCall } from "services/ApiServices";
import CustomNotify from "components/atoms/CustomNotify";
import { t } from "pages/microservices/masterData/translation/Translation";
import { PATH_CREATE_CLIENT, PATH_EDIT_CLIENT, PATH_EMPLOYEE_FLEET_OVERVIEW } from "constants/Paths";
import TableActions from "components/organism/Actions/TableAction";
import { useNavigate } from "react-router-dom";
import { CLIENT_DELETE } from "routes/ApiEndpoints";
import { ERROR, M_MASTER_DATA } from "constants/Constants";
import Pagination from "components/atoms/Pagination";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import FilterElementsRender from "../FilterElement";
import { fetchOptions } from "../utils/FetchOptions";
import { IconData } from "services/IconData";
import Title from "components/atoms/Title";
import LabelField from "components/atoms/LabelField";
import ResetBtn from "components/atoms/ResetBtn";
import SearchBtn from "components/atoms/SearchBtn";
import LinkTo from "components/atoms/LinkTo";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { getStatusColorForClients, getTooltipForClientAndSupplier } from "services/util/UtilService";
import FilterOrganism from "components/organism/FilterOrganism";
import { updatePageFilters } from "store/pageHistory/pageHistorySlice";
import { ClientTableHeader } from "TableHeader";
import SortAtomForManagePage from "components/atoms/SortAtomForManagePage";
import PaginationWithPerPage from "components/molecules/PaginationWithPerPage";

interface ManageClientProps {
  data?: any;
  setMaterialData: any;
  handleGetVacany: any;
  dispatch: any;
  history: any;
  pageData: any;
  handleRefresh: () => void;
}

interface CompanyItem {
  value: number;
  label: string;
}

const ManageClient: React.FC<ManageClientProps> = ({
  data,
  setMaterialData,
  dispatch,
  history,
  pageData,
  handleRefresh,
  handleGetVacany,
}) => {
  const [id, setId] = useState<number | undefined>(undefined);
  const [showModal, setShowModal] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    clientType: [],
    status: [],
  });
  const navigate = useNavigate();
  const handleSaveChanges = async () => {
    setShowModal(false);
    let postData = {
      id: id,
    };

    if (id) {
      const response = await ApiCall.service(
        CLIENT_DELETE,
        "POST",
        postData,
        false,
        M_MASTER_DATA
      );

      if (response?.status === 200) {
        CustomNotify({ type: "success", message: response.message });
        handleRefresh();
      } else {
        CustomNotify({ type: "error", message: response.message });
      }
    }
  };

  useEffect(() => {
    getFilterData();
  }, [id]);

  const getFilterData = async () => {
    try {
      const filterData = await fetchOptions();
      if (filterData?.status == 200) {
        setFilterOptions((prevState: any) => ({
          ...prevState,
          clientType: filterData.data.clients,
          status: [
            {
              value: '0', label: "Draft"
            },
            {
              value: '1', label: "Active"
            },
            {
              value: '2', label: "Inactive"
            }
          ],
        }));
      } else {
        CustomNotify({ type: "error", message: filterData.message });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleModalClose = () => setShowModal(!showModal);

  const handleEdit = (id: number) => {
    navigate(`${PATH_EDIT_CLIENT}/${id}`);
  };

  const handleFleet = (id: number) => {
    navigate(`${PATH_EMPLOYEE_FLEET_OVERVIEW}/${id}/1`);
  }

  const handleArchive = (id: number) => {
    setShowModal(true);
    setId(id);
  };
  const userData = useSelector(selectAuth);
  const filters = [
    { name: 'typeName', fieldType: "multiSelect", options: filterOptions?.clientType, placeholder: "Client type", filterType: 'search' },
    { name: 'name', fieldType: "text", placeholder: "Name", filterType: 'search' },
    { name: 'vatNumber', fieldType: "text", placeholder: "Vat number", filterType: 'search' },
    { name: 'email', fieldType: "text", placeholder: "Email", filterType: 'search' },
    { name: 'status', fieldType: "singleSelect", options: filterOptions?.status, placeholder: "Status", filterType: 'search' },
  ];
  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Client",
          read: true,
        },
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <div className="search-bar">
          {/* <Title title={t("Client")} /> */}
          <FilterOrganism
            dispatch={dispatch}
            history={history}
            updatePageFilters={updatePageFilters}
            filters={filters}
            class={{
              rowClass: 'd-grid manageCarSearchCol manageSupplierSearchCol',
              searchBtnClass: 'col-2 ms-auto'
            }}
            handleRefresh={handleRefresh}
          />
        </div>
        <div className="position-relative tableMainWrapper">
          <div>
            <AccessControl
              requiredPermissions={[
                {
                  permission: "Client",
                  create: true,
                },
              ]}
              override={userData.isSuperAdmin}
            >
              <div className="row">
                <div className="ManageCreateBtn">
                  <LinkTo
                    pagelink={PATH_CREATE_CLIENT}
                    title={t("Create client")}
                    icon={faPlus}
                  />
                </div>
              </div>
            </AccessControl>
          </div>
          <div className="table-responsive manage-clients tableSection">
            <div className="manageClientHeight">
              <table className="table table-hover">
                <thead>
                  <tr className="TableHeader">
                    {ClientTableHeader?.map((header) => (
                      <th key={header.alias}>
                        <>
                          {t(header.name)}
                          {header.issort && (
                            <SortAtomForManagePage
                              value={{ alias: header.alias }}
                              dispatch={dispatch}
                              history={history}
                              updatePageFilters={updatePageFilters}
                              handleRefresh={handleRefresh}
                            />
                          )}
                        </>
                      </th>
                    ))}
                    <th style={{ width: "15%" }}>
                      <AccessControl
                        requiredPermissions={[
                          {
                            permission: "Client",
                            update: true,
                            delete: true,
                          },
                        ]}
                        actions={true}
                        strict={false}
                        override={userData.isSuperAdmin}
                      >
                        {t("Actions")}
                      </AccessControl>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data && data.length > 0 ? (
                    data.map((record: any, key: any) => {
                      return (
                        <tr key={key}>
                          <td className="align-middle">{record.typeName}</td>
                          <td className="align-middle">{record.name}</td>
                          <td className="align-middle">{record.vatNumber}</td>
                          <td className="align-middle">{record.email}</td>
                          <td className="align-middle">
                            <div
                              className={getStatusColorForClients(record.status)}
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title={getTooltipForClientAndSupplier(record.status)}
                            ></div>
                          </td>
                          <td className="align-middle">
                            <TableActions
                              isAction={true}
                              handleEdit={handleEdit}
                              handleArchive={handleArchive}
                              value={record.id}
                              permission={"Client"}
                              {...(record.status == '1' && { handleFleet: handleFleet })}
                            />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={5} className="text-center">
                        {t("No records found")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div
              className=""
              style={{ paddingTop: "0.25vw" }}
            >
              <PaginationWithPerPage
                handleRefresh={handleRefresh}
                dispatch={dispatch}
                history={history}
                pageData={pageData}
              />
            </div>
          </div>
        </div>
        <ModalPopup
          show={showModal}
          onHide={handleModalClose}
          title={t("Archive confirmation")}
          body={t("Are you sure want to archive?")}
          onCloseButtonClick={handleModalClose}
          onConfirmButtonClick={handleSaveChanges}
          closeTitle="No"
          confirmTitle="Yes"
        />
      </>
    </AccessControl>
  );
};
export default ManageClient;
