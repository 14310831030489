import React, { useState } from "react";
import { t } from "pages/microservices/masterData/translation/Translation";
import { useFormContext } from "pages/microservices/tempAgancy/context/Context";

import Button from "components/atoms/Button";
import CheckBoxField from "components/atoms/CheckBoxField";
import SelectWithSearch from "components/atoms/SelectWithSearch";

import Calender from "components/molecules/Calender";
import LabelWithTextAreaField from "components/molecules/LabelWithTextAreaField";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";

import { Option } from "components/common/CommonInterfaces";
import {
  validateDateRange,
  validateForm,
  validateFormField,
  validateMultiSelectField,
  validateSelectField,
  validateTimeRange,
} from "../../../../services/validation/ValidationService";
import {
  ADD_BLUE_COLLAR_FIELD,
  ADD_WHITECOLLAR_FIELD,
  REMOVE_BLUE_PC,
  REMOVE_WHITE_PC,
  SET_COEFFICIENT_DATA,
  UPDATE_WHITE_COLLOR_FIELD_ERROR,
} from "../context/Constants";
import BlueCollarSection from "./BlueCollarSection";
import WhiteCollarSection from "./WhiteCollarSection";
import { ValidationRules } from "utils/TypeAnnotations";
import TableData from "./TableData";

// Define the prop types for the component
interface IProjectFormGeneralTabProps {
  // id?: string | number;
}
interface pcProps {
  pc: Option | null;
  employeeType: Option | null;
}

// Functional component using TypeScript
const EmployeeTypeAndCoeffcient: React.FC<IProjectFormGeneralTabProps> = () => {
  const { state, dispatch } = useFormContext();
  const [PCWhite, setPCWhite] = useState<pcProps>({
    pc: null,
    employeeType: null,
  });

  const [PCBlue, setPCBlue] = useState<pcProps>({
    pc: null,
    employeeType: null,
  });
  const getWhiteCollarPCs = () => {
    const selectedPCs = state.employeeCoefficient.whiteCollar.map(
      (item: any) => item.pc?.value
    );
    const PCs = state.options.whiteCollar.filter(
      (item) => !selectedPCs.includes(item.value)
    );
    return PCs;
    // const pcOptions = FormatPCList(PCs);
    // return pcOptions;
  };

  const getBlueCollarPCs = () => {
    const selectedPCs = state.employeeCoefficient.blueCollar.map(
      (item: any) => item.pc?.value
    );
    const PCs = state.options.blueCollar.filter(
      (item) => !selectedPCs.includes(item.value)
    );
    return PCs;
  };
  const validation = (
    name: string,
    value:
      | string
      | number
      | boolean
      | Date
      | Option
      | object[]
      | null
      | undefined
      | object,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      whitePc: [validateSelectField],
      whiteEmployeeType: [validateMultiSelectField],
      bluePc: [validateSelectField],
      blueEmployeeType: [validateMultiSelectField],
    };

    const validationErrors = validateForm(
      { ...state.employeeCoefficient.errors, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );
    dispatch({
      type: UPDATE_WHITE_COLLOR_FIELD_ERROR,
      field: name,
      error: validationErrors[name],
    });
    const isFieldValid = Object.keys(validationErrors).length === 0;

    if (isFieldValid) {
      state.employeeCoefficient.errors.whiteEmployeeType = "";
    }
    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };
  const handlePCFields = (selectedOption: any, name: string) => {
    if (name === "pc" || name === "employeeType") {
      setPCWhite({
        ...PCWhite,
        [name]: selectedOption,
      });
      validation(
        name === "pc" ? "whitePc" : "whiteEmployeeType", // Conditionally set the name
        selectedOption,
        true
      );
    } else {
      const fieldName = name === "PC2" ? "pc" : "employeeType";
      setPCBlue({
        ...PCBlue,
        [fieldName]: selectedOption,
      });
      validation(
        name === "PC2" ? "bluePc" : "blueEmployeeType", // Conditionally set the name
        selectedOption,
        true
      );
    }
  };
  const addParitiarComitee = (type: string) => {
    let hasValidationErrors = false;
    if (type === "whiteCollar") {
      if (PCWhite.pc === null || PCWhite.pc.value === null) {
        dispatch({
          type: UPDATE_WHITE_COLLOR_FIELD_ERROR,
          field: "whitePc",
          error: t("This field is required"),
        });
        hasValidationErrors = true;
      }
      if (
        PCWhite.employeeType === null ||
        (typeof PCWhite.employeeType === "object" &&
          Object.keys(PCWhite.employeeType).length === 0)
      ) {
        dispatch({
          type: UPDATE_WHITE_COLLOR_FIELD_ERROR,
          field: "whiteEmployeeType",
          error: t("This field is required"),
        });
        hasValidationErrors = true;
      }

      if (
        !hasValidationErrors &&
        (state.employeeCoefficient.errors.bluePc === "" ||
          state.employeeCoefficient.errors.bluePc === undefined) &&
        (state.employeeCoefficient.errors.blueEmployeeType === "" ||
          state.employeeCoefficient.errors.blueEmployeeType === undefined)
      ) {
        const transformState = (PCWhite: any) => {
          return [
            {
              pc: {
                label: PCWhite.pc.label,
                value: PCWhite.pc.value,
                categoryId: PCWhite.pc.categoryId,
              },
              employeeType: PCWhite.employeeType,
              pcFunctions: PCWhite.pc.pcFunctions,
            },
          ];
        };

        const transformedData = transformState(PCWhite);

        // Dispatch actions with transformed data
        dispatch({ type: ADD_WHITECOLLAR_FIELD, newData: PCWhite });

        dispatch({
          type: SET_COEFFICIENT_DATA,
          field: "companyParitairCommittee",
          value: transformedData,
        });
        setPCWhite({
          pc: null,
          employeeType: null,
        });
      }
    } else {
      if (PCBlue.pc === null) {
        dispatch({
          type: UPDATE_WHITE_COLLOR_FIELD_ERROR,
          field: "bluePc",
          error: t("This field is required"),
        });
        hasValidationErrors = true;
      }
      if (
        PCBlue.employeeType === null ||
        (typeof PCBlue.employeeType === "object" &&
          Object.keys(PCBlue.employeeType).length === 0)
      ) {
        dispatch({
          type: UPDATE_WHITE_COLLOR_FIELD_ERROR,
          field: "blueEmployeeType",
          error: t("This field is required"),
        });
        hasValidationErrors = true;
      }

      if (
        !hasValidationErrors &&
        (state.employeeCoefficient.errors.bluePc === "" ||
          state.employeeCoefficient.errors.bluePc === undefined) &&
        (state.employeeCoefficient.errors.blueEmployeeType === "" ||
          state.employeeCoefficient.errors.blueEmployeeType === undefined)
      ) {
        const transformState = (PCBlue: any) => {
          return [
            {
              pc: {
                label: PCBlue.pc.label,
                value: PCBlue.pc.value,
                categoryId: PCBlue.pc.categoryId,
              },
              employeeType: PCBlue.employeeType,
              pcFunctions: PCBlue.pc.pcFunctions,
            },
          ];
        };

        const transformedData = transformState(PCBlue);

        dispatch({ type: ADD_BLUE_COLLAR_FIELD, newData: PCBlue });
        dispatch({
          type: SET_COEFFICIENT_DATA,
          field: "companyParitairCommittee",
          value: transformedData,
        });
        setPCBlue({
          pc: null,
          employeeType: null,
        });
      }
    }
  };
  const pcChangeHandler = (id: number, type: string) => {
    if (type === "whiteCollar") {
      const record = state.employeeCoefficient.whiteCollar.filter(
        (item, index) => index === id
      );
      let data: pcProps = record[0] as pcProps;
      setPCWhite(data);
      dispatch({
        type: UPDATE_WHITE_COLLOR_FIELD_ERROR,
        field: "whiteemployeeType",
        error: "",
      });
      dispatch({
        type: UPDATE_WHITE_COLLOR_FIELD_ERROR,
        field: "whitePc",
        error: "",
      });
    } else {
      const record = state.employeeCoefficient.blueCollar.filter(
        (item, index) => index === id
      );
      let data: pcProps = record[0] as pcProps;
      setPCBlue(data);
      dispatch({
        type: UPDATE_WHITE_COLLOR_FIELD_ERROR,
        field: "blueEmployeeType",
        error: "",
      });
      dispatch({
        type: UPDATE_WHITE_COLLOR_FIELD_ERROR,
        field: "bluePc",
        error: "",
      });
    }
  };
  const pcDeleteHandler = (indexToRemove: number, type: string) => {
    if (type === "whiteCollar") {
      dispatch({ type: REMOVE_WHITE_PC, indexToRemove });
    } else {
      dispatch({ type: REMOVE_BLUE_PC, indexToRemove });
    }
  };
  return (
    <div className="row">
      <div className="col-12">
        <div className="form-border" style={{ paddingTop: "2vw" }}>
          <div className="row">
            {/* {WhiteCollar && state.general.type !== 2 && ( */}
            <WhiteCollarSection
              optionsList={getWhiteCollarPCs()}
              selectedValue={PCWhite.pc}
              handlePCFields={handlePCFields}
              state={state}
              addParitiarComitee={addParitiarComitee}
              pcChangeHandler={pcChangeHandler}
              pcDeleteHandler={pcDeleteHandler}
              selectedEmpType={PCWhite.employeeType}
              employeeTypes={[]}
              isWhiteCollar={false}
              isBlueCollar={true}
            />
            {/* )} */}
            {/* {BlueCollar && ( */}
            <BlueCollarSection
              optionsList={getBlueCollarPCs()}
              selectedValue={PCBlue.pc}
              handlePCFields={handlePCFields}
              state={state}
              addParitiarComitee={addParitiarComitee}
              pcChangeHandler={pcChangeHandler}
              pcDeleteHandler={pcDeleteHandler}
              selectedEmpType={PCBlue.employeeType}
              employeeTypes={[]}
              isBlueCollar={false}
              isWhiteCollar={true}
            />
            {/* )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeTypeAndCoeffcient;
