import CheckBoxField from 'components/atoms/CheckBoxField';
import { t } from 'pages/microservices/masterData/translation/Translation';
import React, { useState } from 'react';
import { Option } from 'utils/TypeAnnotations';
import { useFormContext } from '../../context/Context';
import { INTERNAL_INFO, UPDATE_FIELD } from '../../annotations/CandidateConstants';
import SelectWithSearch from 'components/atoms/SelectWithSearch';
import { LabelWithInputField } from 'components/molecules/LabelWithInputField';
import LabelWithTextAreaField from 'components/molecules/LabelWithTextAreaField';
import Navigation from '../form-navigation/Navigation';
import { recruitmentStatus } from '../../helpers/CandidateHelperFunctions';

export const InternalInfoTab = () => {
    const { state, dispatch } = useFormContext();
    const [validationStatus, setValidationStatus] = useState({
        isValid: false,
        type: "",
    });
    const validStatus = (validation: any) => {
        setValidationStatus({
            isValid: validation.isValid,
            type: validation.type,
        });
    };
    const handleFieldChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value, type, checked } = event.target as HTMLInputElement;
        let updatedValue: string | number | null | Option;
        if (type === "radio") {
            updatedValue = parseInt(value);
        } else if (type === "checkbox") {
            updatedValue = checked ? 1 : 0;
            if (name === "blocked" && updatedValue === 0) {
                resetFields();
            }
        } else {
            updatedValue = value;
        }

        dispatch({
            type: UPDATE_FIELD,
            tab: INTERNAL_INFO,
            field: name,
            value: updatedValue,
        });
    };

    const resetFields = () => {
        const fields = [
            { field: "reasonForBlocking", value: "" },
            { field: "blockedBy", value: null },
            { field: "infoBlocking", value: "" },
        ];

        fields.forEach((field) => {
            dispatch({
                type: UPDATE_FIELD,
                tab: INTERNAL_INFO,
                field: field.field,
                value: field.value,
            });
        });
    };

    const handleSelectChange = (selectedOption: any, name: string) => {
        dispatch({
            type: UPDATE_FIELD,
            tab: INTERNAL_INFO,
            field: name,
            value: selectedOption,
        });
    };

    return (
        <>
            <div
                className={`form-border`}
                style={{ paddingTop: "2vw" }}
            >
                <div className="col-md-12">
                    <CheckBoxField
                        label={t("Active")}
                        name="active"
                        onChangeHandler={handleFieldChange}
                        isChecked={state.internalInfo.active === 1}
                        id="active"
                        lineHeight="1.1vw"
                    />
                </div>
                <div className="row" style={{ marginTop: "1vw" }}>
                    <div className="col-lg-4">
                        <SelectWithSearch
                            title={t("Inflow channel")}
                            search={true}
                            options={state.dropDownData.inflowChannelList}
                            onChange={(e) => handleSelectChange(e, "inflowChannel")}
                            isMulti={false}
                            name="inflowChannel"
                            value={state.internalInfo.inflowChannel}
                            placeHolder={t("Inflow channel")}
                        />
                    </div>
                    <div className="col-lg-4">
                        <LabelWithInputField
                            isMandatory={false}
                            name="externalReference"
                            handleChange={handleFieldChange}
                            value={state.internalInfo.externalReference}
                            isDisabled={false}
                            id="externalReference"
                            label={t("External reference")}
                            type="text"
                            placeholder={t("External reference")}
                        />
                    </div>
                    <div className="col-lg-4">
                        <SelectWithSearch
                            title={t("Selection partner")}
                            isMandatory={false}
                            search={true}
                            options={state.dropDownData.selectionPartnerList}
                            onChange={(e) => handleSelectChange(e, "selectionPartner")}
                            isMulti={false}
                            name="selectionPartner"
                            value={state.internalInfo.selectionPartner}
                            placeHolder={t("Selection partner")}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 mb-3 hotlistWrapper">
                        <CheckBoxField
                            label={t("Blocked")}
                            name="blocked"
                            onChangeHandler={handleFieldChange}
                            isChecked={state.internalInfo?.blocked === 1}
                            className="blocked-checkbox"
                            id="blocked"
                            lineHeight="1.1vw"
                        />
                    </div>
                    <div className="col-lg-4">
                        <LabelWithInputField
                            isMandatory={false}
                            name="reasonForBlocking"
                            handleChange={handleFieldChange}
                            value={state.internalInfo.reasonForBlocking}
                            isDisabled={state.internalInfo.blocked === 0}
                            id="reasonForBlocking"
                            label={t("Reason for blocking")}
                            type="text"
                            placeholder={t("Reason for blocking")}
                        />
                    </div>
                    <div className="col-lg-4">
                        <SelectWithSearch
                            title={t("Blocked by")}
                            isMandatory={false}
                            search={true}
                            isDisabled={state.internalInfo.blocked === 0}
                            options={state.dropDownData.usersList}
                            onChange={(e) => handleSelectChange(e, "blockedBy")}
                            isMulti={false}
                            name="blockedBy"
                            value={state.internalInfo.blockedBy}
                            placeHolder={t("Blocked by")}
                        />
                    </div>
                    <div className="col-lg-6">
                        <LabelWithTextAreaField
                            name="infoBlocking"
                            handleChange={handleFieldChange}
                            label={t("Additional info blocking ")}
                            isDisabled={state.internalInfo.blocked === 0}
                            isMandatory={false}
                            value={state.internalInfo.infoBlocking}
                        />
                    </div>

                    <div className="col-lg-6">
                        <LabelWithTextAreaField
                            name="internalInfo"
                            handleChange={handleFieldChange}
                            label={t("Internal info")}
                            isMandatory={false}
                            value={state.internalInfo.internalInfo}
                        />
                    </div>
                </div>
                <div className="row">
                   <div className="col-6">
                   <SelectWithSearch
                        title={t("Recruitment status")}
                        search={true}
                        options={recruitmentStatus}
                        onChange={(e) => handleSelectChange(e, "recruitmentStatus")}
                        isMulti={false}
                        name="status"
                        value={state.internalInfo.recruitmentStatus}
                        isDisabled={false}
                        // error={errors?.recruitmentStatus}
                        placeHolder={t("Recruitment status")}
                    />
                   </div>
                </div>
                <div className="row marginTop1">
                    <div className="col">
                        {t("Any form of discrimination based on sex, perceived race, colour, descent, nationality or ethnic origin, sexual orientation, marital status, birth, property, age, religious or philosophical beliefs, current or future state of health, disability or physical characteristic is prohibited.")}
                    </div>
                </div>
            </div>
            <div style={{ padding: "1vw 0" }}>
                <Navigation validStatus={validStatus} />
            </div>
        </>
    );
}