const ROLES = {
    ADMIN: "ADMIN",
    MAXICON: "MAXICON",
    EMPLOYEE: "EMPLOYEE",
    CONTACT_PERSON: "CONTACT_PERSON",
    SUPER_ADMIN: "SUPER_ADMIN",
    HR: "HR",
    EMPLOYER: "EMPLOYER",
    TEMP_WORKER: "TEMP_WORKER",
    TEMP_AGENCY_ADMIN: 'TEMP_AGENCY_ADMIN',
}

const TimesheetRolePriority: any = [
    ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.HR, ROLES.EMPLOYER, ROLES.TEMP_AGENCY_ADMIN, ROLES.EMPLOYEE,
];

const TimesheetRoleMap: any = {
    SUPER_ADMIN: ROLES.MAXICON,
    ADMIN: ROLES.MAXICON,
    HR: ROLES.MAXICON,
    EMPLOYEE: ROLES.EMPLOYEE,
    EMPLOYER: ROLES.CONTACT_PERSON,
    TEMP_AGENCY_ADMIN: ROLES.TEMP_WORKER,
};

const checkForPermission = (type: any, permission = [ROLES.MAXICON]) => permission?.includes(type);

export {
    TimesheetRoleMap, TimesheetRolePriority, ROLES, checkForPermission
}