import React, { useState } from "react";
import { useTimesheetModalContext, useTimesheetModalDispatch } from "../timesheets-redux/context/TimesheetModalContext/TimesheetModalContext";
import { TIMESHEET_MODAL_ACTIONS } from "../timesheets-redux/actions/TimesheetModalActions";
import { getWeekDays } from "../utils/utils";
import CommonServices from "services/CommonService";
import { calculateTotalForWholeWeek } from "../utils/calculateUtils";
import { useFilterOptions } from "../timesheets-redux/context/FiltersContext/FiltersContext";
import { useTimesheetDispatchContext } from "../timesheets-redux/context/TimesheetContext/TImesheetContex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalculator } from "@fortawesome/free-solid-svg-icons";
import { t } from "pages/microservices/masterData/translation/Translation";

const PerformanceAndInvoice = (props) => {
  const { title, performanceAndInvoiceData, modalName, isDisabled } = props;
  const { initialTimesheetModalState } = useTimesheetModalContext();
  const { timesheetModalDispatch } = useTimesheetModalDispatch();
  const { filterOptionsState } = useFilterOptions()
  const { timesheetDispatch } = useTimesheetDispatchContext();

  const handleModalDispatch = (modalStatus, index, planId, item = [], editIndex = null) => {            
    const actualDate = new Date(filterOptionsState?.selectedState?.currentDate);
    let weekDays = getWeekDays(actualDate);
    const date = new Date(weekDays[index]);
    const yy = String(date.getFullYear());
    const mm = String(date.getMonth() + 1).padStart(2, "0");
    const dd = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${yy}-${mm}-${dd}`;
    timesheetModalDispatch({
      type: TIMESHEET_MODAL_ACTIONS.OPEN_AND_CLOSE_MODAL,
      payload: {
        status: !modalStatus,
        modalType: modalName,
        date: formattedDate,
        planId: planId,
        data: item ?? [],
        index: editIndex ?? null,
      },
    });
  };
  
  const isValid = performanceAndInvoiceData && performanceAndInvoiceData?.length > 0;
  
  const renderItems = () => {
    const weekDays = [
      <td key={"heading1"} className={`py-3  text-start ${(isValid && !isDisabled) ? 'position-relative' : ""}`} style={{ height: '4vw' }}>
        {t(title)}
        {(isValid && !isDisabled && modalName === 'performance') && (<span
          onClick={() => calculateTotalForWholeWeek({
            data: performanceAndInvoiceData, parameters: filterOptionsState?.optionState?.prjectParameters,
            options: filterOptionsState?.optionState, dispatch: timesheetDispatch, strict: true
          })}
          className="position-absolute calculatorPosition cursor-pointer"
          title={t("Calculate for whole week")}
        >
          <FontAwesomeIcon icon={faCalculator} />
        </span>)}
      </td>,
      <td key={"heading2"} className=""></td>,
    ];    
    const dataToRender = isValid ? performanceAndInvoiceData : Array(7).fill({});
   
    dataToRender?.map((item, i) => {     
      weekDays.push(
        item?.plan_id ? (
          <td
            key={i}
            className=" performanceTableData"
          >
            {(item?.[modalName] && Object?.values(item?.[modalName])?.length > 0) ? (
              <div className="d-flex flex-column justify-content-between h-100">
                {Object?.values(item?.[modalName])?.map((value, index) => (                                  
                  <div className="timesheethovermain cursor-pointer marginBottomPoint5 text-white rounded" key={index} onClick={() => handleModalDispatch(initialTimesheetModalState[modalName]?.isOpen, i, item?.plan_id, value, index)}>
                    {
                      props?.values?.map((val) => (
                        val?.options && <div className="">{val?.options?.find((opt) => value?.[val?.key] == opt?.value)?.label}</div>
                      ))
                    }
                    <div className="timesheethoverchild cursor-pointer">
                      {
                        props?.values?.map((val) => (
                          val?.options
                            ?(<div className="d-flex justify-content-start w-100">
                                <span className="marginRightPoint5">{val?.name + ": "}</span>
                                <span>{val?.options?.find((opt) => value?.[val?.key] == opt?.value)?.label}</span>
                              </div>)
                            :
                              (<div className="d-flex justify-content-start w-100">
                                <span className="marginRightPoint5">{val?.name + ": "} </span>
                                <span>{value[val?.key] ?? ""}</span>
                              </div>)
                        ))
                      }
                    </div>
                  </div>
                ))}
                {!isDisabled && <div title={t("Add new")} className="cursor-pointer" onClick={() => handleModalDispatch(initialTimesheetModalState[modalName]?.isOpen, i, item?.plan_id)}>
                  <div className="bg-white">+</div>
                </div>}
              </div>
            ) : (
              <>
                {!isDisabled && <div title={t("Add new")} className="cursor-pointer" onClick={() => handleModalDispatch(initialTimesheetModalState[modalName]?.isOpen, i, item?.plan_id)}>
                  <div className="bg-white">+</div>
                </div>}
              </>
            )}
          </td>
        ) : (
          <td key={i} className=""></td>
        )
      );
    });
    return weekDays;
  };

  return (
    <tr>
      {renderItems()}
      <td></td>
    </tr>
  );
};
export default PerformanceAndInvoice;
