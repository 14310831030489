import TitleFieldMolecule from "components/molecules/TitleField";
import { t } from "../translation/Translation";
import { Link } from "react-router-dom";
import { MANAGE_EMPLOYEE_LEAVES } from "constants/Paths";
import { useEffect, useState } from "react";
import { ApiCall } from "services/ApiServices";
import { GET_EMPLOYEE_LEAVE_COUNT } from "routes/ApiEndpoints";
import { M_MASTER_DATA } from "constants/Constants";
import CustomNotify from "components/atoms/CustomNotify";
import Pagination from "components/atoms/Pagination";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import TitleAtom from "components/atoms/Title";
import BackButton from "components/atoms/BackButton";
import LabelField from "components/atoms/LabelField";
interface EmployeeLeaveCount {
  leaves: any;
  all: any;
  category: any;
  used: any;
  requested: any;
  balance: any;
}

const LeaveCountOfEmployee = () => {
  const [leaveCountDetails, setLeaveCountDetails] = useState<
    EmployeeLeaveCount[]
  >([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const userId = useSelector(selectAuth).userId;
  useEffect(() => {
    fetchLeaveCountData();
  }, [currentPage]);

  const fetchLeaveCountData = async () => {
    try {
      const response = await ApiCall.service(
        GET_EMPLOYEE_LEAVE_COUNT,
        "POST",
        { id: userId, page: currentPage },
        false,
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        setLeaveCountDetails(response?.data);
        setTotalPages(response?.totalPages);
      } else {
        CustomNotify({ type: "warning", message: response?.message });
      }
    } catch (error) {
      console.error("Error", error);
    }
  };
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };
  return (
    <>
      <div className="search-bar">
        <div className="row">
          <TitleAtom title={t("Leave counts")} />
        </div>
        <div className="row">
          <LabelField
            title={`${t("Year")}: ${new Date().getFullYear()}`}
            className="tab-subtitle color-dark-skyblue pb-0"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="tableMainWrapper mt-0">
            <div className="table-responsive manage-leave-count tableSection">
              <table className="table table-hover">
                <thead>
                  <tr className="TableHeader">
                    <th style={{ width: "10%" }}>{t("Holiday code")}</th>
                    <th style={{ width: "10%" }}>{t("Unit")}</th>
                    <th style={{ width: "10%" }}>{t("All")}</th>
                    <th style={{ width: "10%" }}>{t("Used")}</th>
                    <th style={{ width: "10%" }}>{t("Requested")}</th>
                    <th style={{ width: "10%" }}>{t("Balance")}</th>
                  </tr>
                </thead>
                <tbody>
                  {leaveCountDetails.length > 0 ? (
                    leaveCountDetails.map((eachLeaveData, index) => {
                      const leaveUnit = eachLeaveData.category
                        .slice(0, 1)
                        .toLowerCase();
                      return (
                        <tr key={index}>
                          <td>{eachLeaveData.leaves}</td>
                          <td>{eachLeaveData.category}</td>
                          <td>{eachLeaveData.all}</td>
                          <td>{eachLeaveData.used}</td>
                          <td>{eachLeaveData.requested}</td>
                          <td>{eachLeaveData.balance}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={8} className="text-center">
                        {t("No data available")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="pagination justify-content-center align-items-center my-3 my-lg-0">
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
          </div>
          <div className="backPadding">
            <BackButton />
          </div>
        </div>
      </div>
    </>
  );
};
export default LeaveCountOfEmployee;
