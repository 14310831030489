import React, { useEffect, useRef, useState } from "react";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import PhoneInput from "react-phone-number-input";
import LabelField from "components/atoms/LabelField";
import Button from "components/atoms/Button";
import { ApiCall } from "services/ApiServices";
import CustomNotify from "components/atoms/CustomNotify";
import { useNavigate } from "react-router-dom";
import {
    validateForm,
    validateRequired,
    validateTextFieldAlpha,
    validatePhoneNumber,
    validateSelectField,
} from "services/validation/ValidationService";
import { t } from "pages/microservices/masterData/translation/Translation";
import { E164Number } from "libphonenumber-js";
import TitleAtom from "components/atoms/Title";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { Option, ValidationRules } from "utils/TypeAnnotations";
import { M_IDENTITY_MANAGER } from "constants/Constants";
import { GET_PROFILE, SAVE_PROFILE } from "routes/ApiEndpoints";
import MaxiconFileUpload from "components/molecules/MaxiconFileUpload";
import ImageField from "components/atoms/ImageField";
import ReactSignatureCanvas from "react-signature-canvas";
import SignatureModal from "pages/microservices/project/Proposals/SignatureModel";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import { genderOptions } from "pages/microservices/CandidateCreation/helpers/CandidateHelperFunctions";
import { handleSingleFileUpload } from "utils/S3Bucket/Index";
import { UploadCertificate } from "pages/microservices/CandidateCreation/annotations/CandidateInterface";
import { PATH_HOME } from "constants/Paths";

interface ProfileFormProps {
    id?: number | null;
    firstName?: string;
    lastName?: string;
    email?: string;
    phoneNumber?: string | E164Number;
    country?: Option[];
    city?: string;
    gender?: string;
    dateOfBirth?: string;
    roles?: Option[];
    language?: Option[];
    profilePic?: UploadCertificate | null;
    signature?: string;
}

const Profile: React.FC<ProfileFormProps> = () => {
    const userData = useSelector(selectAuth);
    const navigate = useNavigate();
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [loading, setLoading] = useState(false);
    let [formData, setFormData] = useState<ProfileFormProps>({
        id: userData?.userId,
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "" as E164Number,
        country: [],
        city: "",
        gender: "",
        dateOfBirth: "",
        roles: [],
        language: [],
        profilePic: {
            accessUrl: '',
            fileName: ''
        },
        signature: "",
    });
    const signatureRef = useRef<ReactSignatureCanvas | null>(null);
    const [showSignatureModal, setSignatureModal] = useState(false);
    const [signatureErrorMessage, setSignatureErrorMessage] = useState("");
    const [signatureData, setSignatureData] = useState<any>(null);
    let uploadedUrl = "";

    const [dropdownData, setDropdownData] = useState<{
        countryList: Option[];
        roleList: Option[];
        languageList: Option[];
    }>({
        countryList: [],
        roleList: [],
        languageList: [],
    });

    useEffect(() => {
        fetchProfileData();
    }, []);

    const fetchProfileData = async () => {
        const response = await ApiCall.service(GET_PROFILE, "POST", formData, true, M_IDENTITY_MANAGER);
        if (response?.status === 200) {
            setDropdownData({
                countryList: response?.dropdownData?.countryList || [],
                roleList: response?.dropdownData?.roleList || [],
                languageList: response?.dropdownData?.languageList || [],
            });
            const editData = response?.data;
            setFormData({
                id: userData?.userId || null,
                firstName: editData?.firstName,
                lastName: editData?.lastName,
                email: editData?.email,
                phoneNumber: editData?.phoneNumber,
                country: editData?.country,
                city: editData?.city,
                gender: editData?.gender,
                dateOfBirth: editData?.dateOfBirth,
                roles: editData?.roles,
                language: editData?.language,
                profilePic: editData?.profilePic,
                signature: editData?.signature,
            });
            if (editData?.signature) {
                setSignatureData(editData.signature);
            }
        }
    };

    const validation = (
        name: string,
        value: string | boolean | Option | E164Number | undefined,
        isSingleFieldValidation: boolean = false
    ) => {
        const validationRules: ValidationRules = {
            firstName: [validateRequired, validateTextFieldAlpha],
            lastName: [validateRequired, validateTextFieldAlpha],
            phoneNumber: [validatePhoneNumber],
            country: [validateSelectField],
            gender: [validateSelectField],
            city: [validateRequired],
            dateOfBirth: [validateRequired],
            language: [validateSelectField],
        };

        const validationErrors = validateForm(
            { ...formData, [name]: value },
            validationRules,
            isSingleFieldValidation ? name : undefined
        );

        setErrors(validationErrors);

        if (Object.keys(validationErrors).length > 0) {
            return false;
        }

        return true;
    };

    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type } = event.target;
        if (type === "radio") {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }

        validation(name, value, true);
    };

    const handlePhoneNumberChange = (e: string | E164Number | undefined) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            phoneNumber: e,
        }));
        validation("phoneNumber", e, true);
    };

    const handleSelectChange = (selectedOption: Option, fieldName: string) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [fieldName]: selectedOption,
        }));
        validation(fieldName, selectedOption, true);
    };

    const handleFileChange = async (event: any, field: any) => {
        let fileData: any = null;
        if (event !== null) {
            fileData = await handleSingleFileUpload(event, "profilePic");
            uploadedUrl = fileData?.accessUrl;
        }
        setFormData((prevFormData) => ({
            ...prevFormData,
            [field]: fileData,
        }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        setLoading(true);
        e.preventDefault();
        const { name, value } = e.target as HTMLInputElement;
        if (validation(name, value)) {
            const response = await ApiCall.service(SAVE_PROFILE, "POST", formData, true, M_IDENTITY_MANAGER);
            if (response.status === 200) {
                CustomNotify({ type: "success", message: response?.message });
                navigate(PATH_HOME);
            }
        }
        setLoading(false);
    };

    const handleAddSignature = () => {
        setSignatureModal(true);
    };

    const handleModalClose = () => {
        setSignatureModal(false);
    };

    const handleClearSignature = () => {
        signatureData ? setSignatureData(null) : signatureRef.current?.clear();
    };

    const handleSaveSignature = () => {
        if (!signatureRef.current?.isEmpty()) {
            const signatureImage = signatureRef.current?.toDataURL();
            setFormData((prevFormData: any) => ({
                ...prevFormData,
                signature: signatureImage,
            }));
            setSignatureData(signatureImage);
            handleModalClose();
            setSignatureErrorMessage("");
        } else {
            setSignatureErrorMessage(t("Please add signature before saving."));
        }
    };

    return (
        <div>
            <TitleAtom title={t("Profile")} />
            <div className="form-border">
                <div className="row">
                    <div className="col-6">
                    <LabelField title={t("Profile pic")} className="d-block" />

                        {formData.profilePic?.accessUrl !== '' && (
                            <ImageField
                                altText="Profile picture"
                                className={`img-fluid me-3 profilePictureDesign`}
                                src={formData?.profilePic?.accessUrl}
                            />
                        )}
                        <div className="w-100 profilePictureWrapper marginTopPoint5">
                            <MaxiconFileUpload
                                label={t("Profile Pic")}
                                name="profilePic"
                                id={"profilePic"}
                                isMandatory={false}
                                edit={false}
                                fileId={null}
                                fileObj={JSON.stringify(formData?.profilePic)}
                                multiple={false}
                                uploadPath="user-profile"
                                formats={[]}
                                handleChange={(e: any) => handleFileChange(e, "profilePic")}
                                maxFileSize={10000}
                            />
                        </div>
                    </div>
                    <div className="col-6 marginBotttom1">
                        <LabelField title={t("Signature")} className="d-block" />
                        {formData?.signature && (
                            <div
                                className="border rounded me-2 bg-white rounded-circle d-inline-block"
                                style={{ height: "10vw", width: "10vw" }}
                            >
                                <ImageField
                                    altText="Signature"
                                    className={`img-fluid signatureImage`}
                                    src={formData.signature}
                                />
                            </div>
                        )}
                        <div className="h-100">
                            <Button
                                type="button"
                                title={
                                    formData.signature ? t("Edit Signature") : t("Add Signature")
                                }
                                handleClick={handleAddSignature}
                                className="form-button marginTopPoint5"
                            />
                        </div>
                        {showSignatureModal && (
                            <>
                                <SignatureModal
                                    handleClose={handleModalClose}
                                    handleClearSignature={handleClearSignature}
                                    handleSaveSignature={handleSaveSignature}
                                    signatureRef={signatureRef}
                                    signatureErrorMessage={signatureErrorMessage}
                                    signatureData={signatureData}
                                />
                            </>
                        )}
                    </div>
                    <div className="col-6">
                        <LabelWithInputField
                            isMandatory={true}
                            name="firstName"
                            handleChange={changeHandler}
                            value={formData.firstName}
                            label={t("First name")}
                            placeholder="First name"
                            error={errors.firstName}
                        />
                    </div>
                    <div className="col-6">
                        <LabelWithInputField
                            isMandatory={true}
                            name="lastName"
                            handleChange={changeHandler}
                            value={formData.lastName}
                            label={t("Last name")}
                            placeholder="Last name"
                            error={errors.lastName}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <LabelWithInputField
                            isMandatory={true}
                            name="email"
                            handleChange={changeHandler}
                            value={formData.email}
                            label={t("Email")}
                            placeholder="Email"
                            isDisabled={true}
                            error={errors.email}
                        />
                    </div>
                    <div className="col-6">
                        <div className="col">
                            <LabelField
                                title={t("Phone number")}
                                isMandatory={false}
                                key="PhoneInput"
                            />
                            <div className="form-control field-shadow">
                                <PhoneInput
                                    defaultCountry="BE"
                                    international
                                    placeholder="Enter phone number"
                                    onChange={(mobileNumber) =>
                                        handlePhoneNumberChange(mobileNumber)
                                    }
                                    value={formData?.phoneNumber}
                                />
                            </div>
                            <div>
                                {errors?.phoneNumber &&
                                    <span className="text-danger">{errors?.phoneNumber}</span>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <SelectWithSearch
                            search={true}
                            name={"country"}
                            title={t("Country")}
                            isMandatory={true}
                            options={dropdownData.countryList}
                            onChange={(e) => handleSelectChange(e, "country")}
                            value={formData.country}
                            error={errors?.country}
                            placeHolder={t("Select")}
                        />
                    </div>
                    <div className="col-6">
                        <LabelWithInputField
                            isMandatory={true}
                            name="city"
                            handleChange={changeHandler}
                            value={formData.city}
                            label={t("City")}
                            placeholder="City"
                            error={errors.city}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <SelectWithSearch
                            search={true}
                            name={"gender"}
                            title={t("Gender")}
                            isMandatory={true}
                            options={genderOptions}
                            onChange={(e) => handleSelectChange(e, "gender")}
                            value={formData.gender}
                            error={errors?.gender}
                            placeHolder={t("Select")}
                        />
                    </div>
                    <div className="col-6">
                        <LabelWithInputField
                            isMandatory={true}
                            name="dateOfBirth"
                            handleChange={changeHandler}
                            value={formData.dateOfBirth}
                            label={t("Date of Birth")}
                            placeholder={t("YYYY/MM/DD")}
                            error={errors.dateOfBirth}
                            type="date"
                        />
                    </div>
                    <div className="col-6">
                        <SelectWithSearch
                            search={true}
                            name={"roles"}
                            title={t("Role")}
                            isMandatory={true}
                            options={dropdownData.roleList}
                            onChange={(e) => handleSelectChange(e, "role")}
                            value={formData.roles}
                            error={errors?.roles}
                            placeHolder={t("Select")}
                            isMulti={true}
                            isDisabled={true}
                        />
                    </div>
                    <div className="col-6">
                        <SelectWithSearch
                            search={true}
                            name={"language"}
                            title={t("Language")}
                            isMandatory={true}
                            options={dropdownData.languageList}
                            onChange={(e) => handleSelectChange(e, "language")}
                            value={formData.language}
                            error={errors?.language}
                            placeHolder={t("Select")}
                            isMulti={false}
                        />
                    </div>
                </div>
            </div>
            <div className="footer-btn pointer-cursor">
                <Button className="form-button float-end mt-3"
                    title={"Save"}
                    handleClick={handleSubmit}
                    disabled={loading}
                />
            </div>
        </div>
    );
};

export default Profile;
