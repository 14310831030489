import { useFormContext } from "pages/microservices/companyCreation/context/Context";
import CheckBoxField from "components/atoms/CheckBoxField";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import { Navigation } from "../formNavigation/Navigation";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { ValidationRules } from "utils/TypeAnnotations";
import {
  Option,
  scrollToTop,
  validateForm,
  validateNumber,
  validateRequired,
  validateSelectField,
} from "services/validation/ValidationService";
import { useState } from "react";
import { AddressProps } from "pages/microservices/companyCreation/annotations/CompanyAnnotations";
import { ApiCall } from "services/ApiServices";
import { REGISTER_COMPANY } from "routes/ApiEndpoints";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import CustomNotify from "components/atoms/CustomNotify";
import { mapToSelect } from "utils/MapToSelect";
import { M_COMPANY_CREATION } from "../../../../../constants/Constants";
import LabelField from "components/atoms/LabelField";
import { t } from "pages/microservices/masterData/translation/Translation";
import axios from "axios";
import ActiveLocation from "static/images/ActiveLocation";
import AddAddressLoactionViaMap, {
  AddressDetails,
} from "utils/AddAddressLoactionViaMap";

const Address = () => {
  const { state, dispatch } = useFormContext();
  const navigate = useNavigate();
  const [countryId, setCountryId] = useState<number | null>(null);
  const [validationStatus, setValidationStatus] = useState({
    isValid: false,
    type: "",
  });
  const { companyId } = useParams<{ companyId: string }>();
  const location = useLocation();
  // Check if 'mode=view' is present in the query string
  const isViewMode = location.search.includes("mode=view");

  const validation = (
    name: string,
    value: string | boolean | Date | object[] | null | Option | number,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      // location: [validateRequired],
      street: [validateRequired],
      number: [validateNumber],
      zipCode: [validateRequired],
      city: [validateRequired],
      country: [validateSelectField],
      biStreet: [validateRequired],
      biZipCode: [validateRequired],
      biNumber: [validateNumber],
      biCity: [validateRequired],
      biCountry: [validateSelectField],
    };
    const locationValidationRules: ValidationRules = {
      locationStreet: [validateRequired],
      locationNumber: [validateNumber],
      locationZipCode: [validateRequired],
      locationCity: [validateRequired],
      locationCountry: [validateSelectField],
    };

    const validationErrors = validateForm(
      { ...state.address, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    dispatch({
      type: "UPDATE_FIELD_ERROR",
      fieldName: name,
      error: validationErrors[name],
    });
    const isFieldValid = Object.keys(validationErrors).length === 0;

    if (isFieldValid) {
      state.fieldError[name] = "";
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const copyBillingFieldsFromOfficial = (isChecked: boolean) => {
    if (isChecked) {
      const billingFields = [
        "street",
        "number",
        "box",
        "zipCode",
        "city",
        "country",
        "initialLat",
        "initialLn",
      ];
      billingFields.forEach((fieldName) => {
        const billingFieldName =
          "bi" + fieldName.charAt(0).toUpperCase() + fieldName.slice(1);
        const fieldValue = state.address[fieldName as keyof AddressProps];
        dispatch({
          type: "UPDATE_ADDRESS_FIELD",
          field: billingFieldName,
          value: fieldValue,
        });
        validation(billingFieldName, fieldValue, true);
      });
    } else {
      const billingFields = [
        "biStreet",
        "biNumber",
        "biBox",
        "biZipCode",
        "biCity",
        "biCountry",
        "biInitialLat",
        "biInitialLn",
      ];
      billingFields.forEach((fieldName) => {
        dispatch({
          type: "UPDATE_ADDRESS_FIELD",
          field: fieldName,
          value: "",
        });
        validation(fieldName, "", true);
      });
    }
  };
  const handleLocationAddressUpdate = (isChecked: boolean) => {
    if (isChecked) {
      const locationAddressFields = [
        { field: "locationStreet", value: state.address.street },
        { field: "locationNumber", value: state.address.number },
        { field: "locationBox", value: state.address.box },
        { field: "locationZipCode", value: state.address.zipCode },
        { field: "locationCity", value: state.address.city },
        { field: "locationCountry", value: state.address.country },
        { field: "initialLat", value: state.address.initialLat },
        { field: "initialLn", value: state.address.initialLn },
      ];
      dispatch({
        type: "UPDATE_COMPANYLOCATION_FIELD",
        field: "locationName",
        value: `${state.basic.companyName}-HQ`,
        // index: 0,
      });
      locationAddressFields.forEach((field) => {
        dispatch({
          type: "UPDATE_COMPANYLOCATION_FIELD",
          field: field.field,
          value: field.value,
          // index: 0,
        });
        const locationAddressFields = [
          { field: "locationPhoneNumber", value: "" },
          { field: "locationName", value: "" },
          { field: "locationStreet", value: "" },
          { field: "locationNumber", value: "" },
          { field: "locationBox", value: "" },
          { field: "locationZipCode", value: "" },
          { field: "locationCity", value: "" },
          { field: "locationCountry", value: "" },
        ];
        locationAddressFields.forEach((field) => {
          dispatch({
            type: "UPDATE_FIELD_ERROR",
            fieldName: field.field,
            error: field.value,
          });
        });
        // validation(field.field, field.field, true);
      });
    } else {
      const locationAddressFields = [
        { field: "locationStreet", value: "" },
        { field: "locationNumber", value: "" },
        { field: "locationBox", value: "" },
        { field: "locationZipCode", value: "" },
        { field: "locationCity", value: "" },
        { field: "locationCountry", value: "" },
      ];
      locationAddressFields.forEach((field) => {
        dispatch({
          type: "UPDATE_COMPANYLOCATION_FIELD",
          field: field.field,
          value: field.value,
        });
      });
    }
  };
  const getCityAndCountry = async (zipcode: string) => {
    try {
      if (zipcode.length === 4) {
        const response = await axios.get(
          `https://api.zippopotam.us/be/${zipcode}`
        );
        // Check if the response contains places data and is in the expected format
        if (
          response.data &&
          response.data.places &&
          response.data.places.length > 0
        ) {
          return {
            city: response.data.places?.[0]?.["place name"] || "Not found",
            country: response.data?.country || "Belgium",
          };
        } else {
          return { city: "Not found", country: "Belgium" };
        }
      } else {
        return { city: "", country: "" };
      }
    } catch (error) {
      return { city: "Error", country: "Error" };
    }
  };

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;
    if (type === "checkbox") {
      const newValue = checked ? 1 : 0;
      dispatch({ type: "UPDATE_ADDRESS_FIELD", field: name, value: newValue });
      validation(name, checked, true);
      if (name === "sameAddress") {
        copyBillingFieldsFromOfficial(checked);
      }
      if (name === "hqAddress") {
        handleLocationAddressUpdate(checked);
      }
    } else {
      if (name === "zipCode") {
        const city = getCityAndCountry(value);
        city.then((response: any) => {
          const { city, country } = response;
          dispatch({
            type: "UPDATE_ADDRESS_FIELD",
            field: "city",
            value: city,
          });
          const countryValue: any =
            country !== "Error"
              ? state.countryList.find(
                  (coun: any) =>
                    coun?.label?.toLowerCase() === country?.toLowerCase()
                )
              : null;
          dispatch({
            type: "UPDATE_ADDRESS_FIELD",
            field: "country", // Convert to domFieldName
            value: countryValue,
          });
        });
      }
      dispatch({ type: "UPDATE_ADDRESS_FIELD", field: name, value });
      validation(name, value, true);
      if (state.address.sameAddress) {
        const billingFieldName =
          "bi" + name.charAt(0).toUpperCase() + name.slice(1);
        dispatch({
          type: "UPDATE_ADDRESS_FIELD",
          field: billingFieldName,
          value,
        });
        const city = getCityAndCountry(value);
        city.then((response: any) => {
          const { city, country } = response;
          dispatch({
            type: "UPDATE_ADDRESS_FIELD",
            field: "biCity",
            value: city,
          });
          const countryValue: any =
            country !== "Error"
              ? state.countryList.find(
                  (coun: any) =>
                    coun?.label?.toLowerCase() === country?.toLowerCase()
                )
              : null;
          dispatch({
            type: "UPDATE_ADDRESS_FIELD",
            field: "biCountry", // Convert to domFieldName
            value: countryValue,
          });
        });
        validation(billingFieldName, value, true);
      }
    }
  };

  const handleSelectChange = (selectedOption: any, name: string) => {
    dispatch({
      type: "UPDATE_ADDRESS_FIELD",
      field: name,
      value: selectedOption,
    });

    if (state.address.sameAddress) {
      const billingFieldName =
        "bi" + name.charAt(0).toUpperCase() + name.slice(1);

      dispatch({
        type: "UPDATE_ADDRESS_FIELD",
        field: billingFieldName,
        value: selectedOption,
      });
      validation(billingFieldName, selectedOption, true);
    }
    validation(name, selectedOption, true);
  };

  const validStatus = (validation: any) => {
    setValidationStatus({
      isValid: true,
      // isValid: validation.isValid,
      type: validation.type,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const formData = {
      basic: state.basic,
      address: state.address,
    };
    formData.basic.companyId = companyId;
    if (state.basic.vat !== "" && state.basic.companyName !== "") {
      const url = !companyId
        ? REGISTER_COMPANY
        : `${REGISTER_COMPANY}/${companyId}`;
      const response = await ApiCall.service(
        url,
        "POST",
        formData,
        false,
        M_COMPANY_CREATION
      );
      if (response?.status === 200) {
        if (validationStatus.type === "draft") {
          CustomNotify({
            type: "success",
            message: "Draft saved successfully!",
          });
          navigate("/manage-companies");
        } else {
          navigate(`/company/${response.companyId}`);
        }
      } else if (response?.status === 400) {
        CustomNotify({ type: "error", message: response.msg.vat_number });
        dispatch({ type: "UPDATE_TAB_ERROR", tabIndex: 0, error: true });
        scrollToTop();
        state.fieldError.vat = response.msg.vat_number;
      }
    }
  };
  const handleConfirm = (details: AddressDetails | null) => {
    if (details) {
      const { street, nr, bus, postal, city, country, coordinates } = details;
      let companyOption: any = []; // Declare it as an empty array or object

      // Find the country option if country is not empty
      if (country !== "") {
        companyOption =
          state.countryList.find((option: any) =>
            option.label.toLowerCase().includes(country.toLowerCase())
          ) || {}; // If no match found, default to an empty object
      }

      // Ensure coordinates is in the correct format before processing
      if (
        coordinates &&
        coordinates.includes("Lat: ") &&
        coordinates.includes("Lng: ")
      ) {
        const [lat, lng] = coordinates
          .replace("Lat: ", "")
          .replace("Lng: ", "")
          .split(", ")
          .map(Number); // Convert the values to numbers
        handleAddressUpdate(
          street,
          nr,
          bus,
          postal,
          city,
          companyOption,
          lat,
          lng
        );
      } else {
        console.error("Invalid coordinates format");
      }
    }

    setShowPopup(false); // Close the popup after confirming the address
  };

  const handleBillingConfirm = (details: AddressDetails | null) => {
    if (details) {
      const { street, nr, bus, postal, city, country, coordinates } = details;
      let companyOption: any = []; // Declare it as an empty array or object

      // Find the country option if country is not empty
      if (country !== "") {
        companyOption =
          state.countryList.find((option: any) =>
            option.label.toLowerCase().includes(country.toLowerCase())
          ) || {}; // If no match found, default to an empty object
      }

      // Ensure coordinates is in the correct format before processing
      if (
        coordinates &&
        coordinates.includes("Lat: ") &&
        coordinates.includes("Lng: ")
      ) {
        const [lat, lng] = coordinates
          .replace("Lat: ", "")
          .replace("Lng: ", "")
          .split(", ")
          .map(Number); // Convert the values to numbers
        handleBillingAddressUpdate(
          street,
          nr,
          bus,
          postal,
          city,
          companyOption,
          lat,
          lng
        );
      } else {
        console.error("Invalid coordinates format");
      }
    }

    setShowBillingPopup(false); // Close the popup after confirming the address
  };
  const [showPopup, setShowPopup] = useState(false);
  const [showBillingPopup, setShowBillingPopup] = useState(false);
  const handleAddPopup = () => {
    setShowPopup(true); // Close the popup without saving
  };
  const handleClosePopup = () => {
    setShowPopup(false); // or whatever logic you have to close the popup
  };
  const handleAddressUpdate = (
    street: string,
    number: string,
    unit: string,
    postal: string,
    city: string,
    countryValue: string,
    lat: number | string | any,
    lng: number | string | any
  ) => {
    const addressFields = [
      { field: "street", value: street },
      { field: "number", value: number },
      { field: "box", value: unit },
      { field: "zipCode", value: postal },
      { field: "city", value: city },
      { field: "country", value: countryValue },
      { field: "initialLat", value: lat },
      { field: "initialLn", value: lng },
    ];

    addressFields.forEach((field) => {
      dispatch({
        type: "UPDATE_ADDRESS_FIELD",
        field: field.field,
        value: field.value,
      });
      dispatch({
        type: "UPDATE_FIELD_ERROR",
        fieldName: field.field,
        error: "",
      });
      if (state.address.sameAddress === 1 || state.address.sameAddress === true)
        dispatch({
          type: "UPDATE_ADDRESS_FIELD",
          field: `bi${
            field.field.charAt(0).toUpperCase() + field.field.slice(1)
          }`,
          value: field.value,
        });
      dispatch({
        type: "UPDATE_FIELD_ERROR",
        fieldName: `bi${
          field.field.charAt(0).toUpperCase() + field.field.slice(1)
        }`,
        error: "",
      });
    });
  };
  const handleBillingAddressUpdate = (
    street: string,
    number: string,
    unit: string,
    postal: string,
    city: string,
    countryValue: string,
    lat: number | string | any,
    lng: number | string | any
  ) => {
    const addressFields = [
      { field: "biStreet", value: street },
      { field: "biNumber", value: number },
      { field: "biBox", value: unit },
      { field: "biZipCode", value: postal },
      { field: "biCity", value: city },
      { field: "biCountry", value: countryValue },
      { field: "biInitialLat", value: lat },
      { field: "biInitialLn", value: lng },
    ];

    addressFields.forEach((field) => {
      dispatch({
        type: "UPDATE_ADDRESS_FIELD",
        field: field.field,
        value: field.value,
      });
      dispatch({
        type: "UPDATE_FIELD_ERROR",
        fieldName: field.field,
        error: "",
      });
    });
  };

  const renderAddressGoogleMap = () => {
    return (
      <div style={{ marginLeft: "1vw" }}>
        <div className="d-flex align-items-center">
          <Link
            to={""}
            title={t("Search address via google map...")}
            onClick={handleAddPopup}
            className="color-dark-pink marginRightPoint5"
          >
            <ActiveLocation />
          </Link>
          <Link to={""} className="link-color" onClick={handleAddPopup}>
            {t("Search address via google map...")}
          </Link>
        </div>
        <AddAddressLoactionViaMap
          initialLat={
            state.address.initialLat === ""
              ? 50.8503
              : parseFloat(state.address.initialLat)
          } // Replace with your initial latitude
          initialLng={
            state.address.initialLn === ""
              ? 4.3517
              : parseFloat(state.address.initialLn)
          } // Replace with your initial longitude
          onConfirm={handleConfirm}
          showAddPopup={showPopup}
          onHide={handleClosePopup}
        />
      </div>
    );
  };
  const handleBillingAddPopup = () => {
    setShowBillingPopup(true);
  };
  const handleBilligClosePopup = () => {
    setShowBillingPopup(false);
  };
  const renderBillingAddressGoogleMap = () => {
    return (
      <div style={{ marginLeft: "1vw" }}>
        <div className="d-flex align-items-center">
          <Link
            to={""}
            title={t("Search address via google map...")}
            onClick={handleBillingAddPopup}
            className="color-dark-pink marginRightPoint5"
          >
            <ActiveLocation />
          </Link>
          <Link to={""} className="link-color" onClick={handleBillingAddPopup}>
            {t("Search address via google map...")}
          </Link>
        </div>
        <AddAddressLoactionViaMap
          initialLat={
            state.address.biInitialLat === ""
              ? 50.8503
              : parseFloat(state.address.biInitialLat)
          } // Replace with your initial latitude
          initialLng={
            state.address.biInitialLn === ""
              ? 4.3517
              : parseFloat(state.address.biInitialLn)
          } // Replace with your initial longitude
          onConfirm={handleBillingConfirm}
          showAddPopup={showBillingPopup}
          onHide={handleBilligClosePopup}
        />
      </div>
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12">
          <div
            className="form-border"
            style={{ paddingTop: "2vw", paddingBottom: "1vw" }}
          >
            <div className="row marginBotttom1">
              <div className="col-md-12">
                <div className="d-flex align-items-center">
                  <LabelField
                    title={t("Official address")}
                    className="tab-subtitle pb-0"
                  />
                  {!isViewMode && renderAddressGoogleMap()}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-6">
                <LabelWithInputField
                  isMandatory={true}
                  name="street"
                  handleChange={handleFieldChange}
                  value={state.address.street}
                  id="street"
                  label="Street"
                  type="text"
                  error={state.fieldError.street}
                  isDisabled={isViewMode}
                />
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="row">
                  <div className="col-sm-12 col-md-8">
                    <LabelWithInputField
                      isMandatory={true}
                      name="number"
                      handleChange={handleFieldChange}
                      value={state.address.number}
                      id="number"
                      label="Number"
                      type="text"
                      error={state.fieldError.number}
                      isDisabled={isViewMode}
                    />
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <LabelWithInputField
                      isMandatory={false}
                      name="box"
                      handleChange={handleFieldChange}
                      value={state.address.box}
                      id="box"
                      label="Box"
                      type="text"
                      isDisabled={isViewMode}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-4">
                <LabelWithInputField
                  isMandatory={true}
                  name="zipCode"
                  handleChange={handleFieldChange}
                  value={state.address.zipCode}
                  id="zipCode"
                  label="Zip code"
                  type="text"
                  error={state.fieldError.zipCode}
                  isDisabled={isViewMode}
                />
              </div>
              <div className="col-sm-12 col-md-4">
                <LabelWithInputField
                  isMandatory={true}
                  name="city"
                  handleChange={handleFieldChange}
                  value={state.address.city}
                  id="city"
                  label="City"
                  type="text"
                  error={state.fieldError.city}
                  isDisabled={isViewMode}
                />
              </div>
              <div className="col-sm-12 col-md-4">
                <SelectWithSearch
                  title="Country"
                  name="country"
                  isMandatory={true}
                  search={true}
                  options={state.countryList}
                  placeHolder="Select"
                  value={state.address.country}
                  onChange={(e) => handleSelectChange(e, "country")}
                  isMulti={false}
                  className="select-field"
                  error={state.fieldError.country}
                  isDisabled={isViewMode}
                />
              </div>

              <div className="col-md-12 mt-2" style={{ marginBottom: "1vw" }}>
                <CheckBoxField
                  label="This is the Headquarters address"
                  name="hqAddress"
                  onChangeHandler={handleFieldChange}
                  isChecked={state.address.hqAddress === 1}
                  disable={isViewMode}
                  id="hqAdress"
                  lineHeight="1.6vw"
                />
              </div>
              <div className="col-md-12">
                <CheckBoxField
                  label="Same as official address"
                  name="sameAddress"
                  onChangeHandler={handleFieldChange}
                  isChecked={
                    state.address.sameAddress === 1 ||
                    state.address.sameAddress === true
                  }
                  disable={isViewMode}
                  id="sameAddress"
                  lineHeight="1.6vw"
                />
              </div>
              <div className="col-md-12" style={{ marginTop: "1vw" }}>
                <div className="d-flex align-items-center">
                  <LabelField
                    title={t("Billing addressss")}
                    className="tab-subtitle pb-0"
                  />
                  {state.address.sameAddress !== 1 &&
                    state.address.sameAddress !== true &&
                    !isViewMode &&
                    renderBillingAddressGoogleMap()}
                </div>
              </div>

              <div className="col-sm-12 col-md-6">
                <LabelWithInputField
                  isMandatory={true}
                  name="biStreet"
                  handleChange={handleFieldChange}
                  value={state.address.biStreet}
                  isDisabled={
                    state.address.sameAddress === 1 ||
                    state.address.sameAddress === true ||
                    isViewMode
                  }
                  id="street"
                  label="Street"
                  type="text"
                  error={state.fieldError.biStreet}
                />
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="row">
                  <div className="col-sm-12 col-md-8">
                    <LabelWithInputField
                      isMandatory={true}
                      name="biNumber"
                      handleChange={handleFieldChange}
                      value={state.address.biNumber}
                      isDisabled={
                        state.address.sameAddress === 1 ||
                        state.address.sameAddress === true ||
                        isViewMode
                      }
                      id="number"
                      label="Number"
                      type="text"
                      error={state.fieldError.biNumber}
                    />
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <LabelWithInputField
                      isMandatory={false}
                      name="biBox"
                      handleChange={handleFieldChange}
                      value={state.address.biBox}
                      isDisabled={
                        state.address.sameAddress === 1 ||
                        state.address.sameAddress === true ||
                        isViewMode
                      }
                      id="box"
                      label="Box"
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-4">
                <LabelWithInputField
                  isMandatory={true}
                  name="biZipCode"
                  handleChange={handleFieldChange}
                  value={state.address.biZipCode}
                  isDisabled={
                    state.address.sameAddress === 1 ||
                    state.address.sameAddress === true ||
                    isViewMode
                  }
                  id="zipCode"
                  label="Zip code"
                  type="text"
                  error={state.fieldError.biZipCode}
                />
              </div>
              <div className="col-sm-12 col-md-4">
                <LabelWithInputField
                  isMandatory={true}
                  name="biCity"
                  handleChange={handleFieldChange}
                  value={state.address.biCity}
                  isDisabled={
                    state.address.sameAddress === 1 ||
                    state.address.sameAddress === true ||
                    isViewMode
                  }
                  id="biCity"
                  label="City"
                  type="text"
                  error={state.fieldError.biCity}
                />
              </div>
              <div className="col-sm-12 col-md-4">
                <SelectWithSearch
                  title="Country"
                  name="biCountry"
                  isMandatory={true}
                  search={true}
                  placeHolder="Select"
                  options={state.countryList}
                  isDisabled={
                    state.address.sameAddress === 1 ||
                    state.address.sameAddress === true ||
                    isViewMode
                  }
                  value={state.address.biCountry}
                  onChange={(e) => handleSelectChange(e, "biCountry")}
                  isMulti={false}
                  error={state.fieldError.biCountry}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Navigation validStatus={validStatus} />
    </form>
  );
};

export default Address;
