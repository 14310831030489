import React, { useState } from "react";
import { t } from "pages/microservices/masterData/translation/Translation";

import SelectWithSearch from "components/atoms/SelectWithSearch";
import { FormMode } from "components/common/CommonEnums";
import { Option } from "components/common/CommonInterfaces";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import LabelWithTextAreaField from "components/molecules/LabelWithTextAreaField";
import AddressFormOrganism from "../address/AddressFormOrganism";
import { Link } from "react-router-dom";
import ActiveLocation from "static/images/ActiveLocation";
import AddAddressLoactionViaMap, {
  AddressDetails,
} from "utils/AddAddressLoactionViaMap";

// export type CompanyLocationProps = {
export type LocationFormOrganismData = {
  company?: Option | null;
  location: string;
  phone?: string;
  street: string;
  number: string;
  box: string;
  zipcode: string;
  city?: string;
  country: Option | null;
  initialLat?: string;
  initialLn?: string;
  info: string;
};

// export type CompanyLocationValidationErrorProps = {
export type LocationFormOrganismErrors = {
  company?: string;
  location: string;
  phone: string;
  street: string;
  number: string;
  box: string;
  zipcode: string;
  city: string;
  country: string;
  info: string;
};

export type LocationFormOrganismOptions = {
  companyOptions?: Option[];
  countryOptions: Option[];
};

export interface ILocationFormOrganismProps {
  formMode: FormMode;
  formData: LocationFormOrganismData;
  formErrors: LocationFormOrganismErrors;
  formOptions: LocationFormOrganismOptions;
  tab?: "general";
  company?: any;
  formErrorCompany?: any;
  handleSelectChange: (value: any, fieldName: string) => void;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSaveGoogleMapData?: (value: any) => void;
}
/**
 * @returns
 * Location form fields, without submit buttons
 * 
 * @example
 * 
<LocationFormOrganism
  formMode={}
  formOptions={{
    companyOptions: X,
    countryOptions: X,
  }}
  formData={{
    company: null,
    location: "",
    phone: "",
    street: "",
    number: "",
    box: "",
    zipcode: "",
    city: "",
    country: null,
    info: "",
  }}
  formErrors={{
    company: "",
    location: "",
    street: "",
    number: "",
    box: "",
    zipcode: "",
    city: "",
    country: "",
    phone: "",
    info: "",
  }}
  handleChange={handleChange}
  handleSelectChange={handleSelectChange}
/>;
 */
const LocationFormOrganism: React.FC<ILocationFormOrganismProps> = ({
  formMode,
  formOptions,
  formData,
  tab,
  company,
  formErrors,
  handleSelectChange,
  handleChange,
  formErrorCompany,
  handleSaveGoogleMapData,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const handleClosePopup = () => {
    setShowPopup(false); // or whatever logic you have to close the popup
  };

  const handleAddPopup = () => {
    setShowPopup(true);
  };
  const renderAddressGoogleMap = () => {
    return (
      <div style={{ marginLeft: "1vw" }}>
        <div className="d-flex align-items-center">
          <Link
            to={""}
            title={t("Search address via google map...")}
            onClick={handleAddPopup}
            className="color-dark-pink marginRightPoint5"
          >
            <ActiveLocation />
          </Link>
          <Link to={""} className="link-color" onClick={handleAddPopup}>
            {t("Search address via google map...")}
          </Link>
        </div>
        <AddAddressLoactionViaMap
          initialLat={
            formData?.initialLat && !isNaN(parseFloat(formData.initialLat))
              ? parseFloat(formData.initialLat)
              : 50.8503 // Default latitude
          }
          initialLng={
            formData?.initialLn && !isNaN(parseFloat(formData.initialLn))
              ? parseFloat(formData.initialLn)
              : 4.3517 // Default longitude
          }
          onConfirm={handleConfirm}
          showAddPopup={showPopup}
          onHide={handleClosePopup}
        />
      </div>
    );
  };
  const handleConfirm = (details: AddressDetails | null) => {
    if (details) {
      const { street, nr, bus, postal, city, country, coordinates } = details;
      let companyOption: any = []; // Declare it as an empty array or object

      // Find the country option if country is not empty
      if (country !== "") {
        companyOption =
          formOptions.countryOptions.find((option: any) =>
            option.label.toLowerCase().includes(country.toLowerCase())
          ) || {}; // If no match found, default to an empty object
      }

      // Ensure coordinates is in the correct format before processing
      if (
        coordinates &&
        coordinates.includes("Lat: ") &&
        coordinates.includes("Lng: ")
      ) {
        const [lat, lng] = coordinates
          .replace("Lat: ", "")
          .replace("Lng: ", "")
          .split(", ")
          .map(Number); // Convert the values to numbers
        const address = {
          street,
          number: nr, // Rename nr to number
          box: bus, // Rename bus to box
          zipcode: postal, // Rename postal to zipcode
          city,
          country: companyOption,
          initialLat: lat,
          initialLn: lng,
        };
        if (handleSaveGoogleMapData) {
          // Only call handleSaveGoogleMapData if it's defined
          handleSaveGoogleMapData(address);
        }
      } else {
        console.error("Invalid coordinates format");
      }
    }

    setShowPopup(false); // Close the popup after confirming the address
  };
  return (
    <div className="form-border">
      <div className="row">
        {renderAddressGoogleMap()}
        <div className="col-4">
          <SelectWithSearch
            title="Company"
            placeHolder={t("Select")}
            name="company"
            isMandatory={true}
            search={true}
            options={formOptions?.companyOptions}
            value={tab === "general" ? company : formData?.company}
            onChange={(e) => handleSelectChange(e, "company")}
            isMulti={false}
            className="select-field"
            isDisabled={tab === "general"}
            error={
              tab === "general"
                ? formErrorCompany?.company
                : formErrors?.company
            }
          />
        </div>
        <div className="col-4">
          <LabelWithInputField
            label={t("Location name")}
            name="location"
            isMandatory={true}
            value={formData.location}
            handleChange={handleChange}
            id="location"
            type="text"
            error={formErrors.location}
          />
        </div>
        <div className="col-4">
          <LabelWithInputField
            label={t("Phone number")}
            name="phone"
            isMandatory={true}
            value={formData.phone}
            handleChange={handleChange}
            id="phone"
            type="text"
            error={formErrors.phone}
          />
        </div>
      </div>
      <AddressFormOrganism
        formMode={formMode}
        formOptions={{ countries: formOptions.countryOptions }}
        formData={{
          street: formData.street,
          number: formData.number,
          box: formData.box,
          zipcode: formData.zipcode,
          city: formData.city,
          country: formData.country,
          initialLat: formData.initialLat,
          initialLn: formData.initialLn,
        }}
        formErrors={{
          street: formErrors.street,
          number: formErrors.number,
          box: formErrors.box,
          zipcode: formErrors.zipcode,
          city: formErrors.city,
          country: formErrors.country,
        }}
        handleSelectChange={(e) => handleSelectChange(e, "country")}
        handleChange={handleChange}
      ></AddressFormOrganism>

      {/* <p>ADDRESS</p>
          <div className="col-lg-5 col-md-6">
            <LabelWithInputField
              label={t("Street")}
              name="street"
              isMandatory={true}
              value={formData.street}
              handleChange={handleChange}
              id="street"
              type="text"
              error={formErrors.street}
            />
          </div>
          <div className="col-lg-5 col-md-6">
            <LabelWithInputField
              label={t("Number")}
              name="number"
              isMandatory={true}
              value={formData.number}
              handleChange={handleChange}
              id="number"
              type="text"
              error={formErrors.number}
            />
          </div>
          <div className="col-lg-2 col-md-6">
            <LabelWithInputField
              label={t("Box")}
              name="box"
              isMandatory={false}
              value={formData.box}
              handleChange={handleChange}
              id="box"
              type="text"
              error={formErrors.box}
            />
          </div>
          <div className="col-lg-6 col-md-6">
            <LabelWithInputField
              label={t("Zipcode")}
              name="zipcode"
              isMandatory={true}
              value={formData.zipcode}
              handleChange={handleChange}
              id="zipcode"
              type="text"
              error={formErrors.zipcode}
            />
          </div>
          <div className="col-lg-6 col-md-6">
            <LabelWithInputField
              label={t("City")}
              name="city"
              isMandatory={true}
              value={formData.city}
              handleChange={handleChange}
              id="city"
              type="text"
              error={formErrors.city}
            />
          </div>
          <div className="row mb-3">
            <div className="col-lg-12 col-md-6">
              <SelectWithSearch
                title={t("Country")}
                name="country"
                placeHolder={t("Select")}
                isMandatory={true}
                search={true}
                options={formOptions.countryOptions}
                // options={formOptions.countries}
                value={formData.country}
                onChange={(e) => handleSelectChange(e, "country")}
                isMulti={false}
                className="select-field"
                error={formErrors.country}
              />
            </div>
          </div>
           <p>ADDRESS</p>
           */}
      <div className="row">
        <div className="col-lg-12 col-md-6">
          <LabelWithTextAreaField
            label={t("Info")}
            name="info"
            value={formData.info}
            // placeholder={t("info")}
            isDisabled={false}
            isMandatory={false}
            handleChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
};

export default LocationFormOrganism;

