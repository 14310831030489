import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as ENDPOINTS from "routes/ApiEndpoints";
import ViewIcon from "static/images/ViewIcon";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
// import "../questions/questions.css";
import { Spinner } from "react-bootstrap";
import MatchingProfileCard, {
  MatchingCandidateProfileCardType,
} from "components/Matching/MatchingCandidateProfileCard";
import { OptionProps } from "utils/TypeAnnotations";
import { ApiCall } from "services/ApiServices";
import { mapToSelect } from "utils/MapToSelect";
import CustomNotify from "components/atoms/CustomNotify";
import AccessControl from "services/AccessControl";
import Title from "components/atoms/Title";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import Calender from "components/molecules/Calender";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import Button from "components/atoms/Button";
import { t } from "../translation/Translation";
import Reset from "static/images/ResetIcon";
import Pagination from "components/atoms/Pagination";
import { M_MASTER_DATA } from "constants/Constants";
import BackButton from "components/atoms/BackButton";
import { EDIT_CANDIDATE, MANAGE_CANDIDATES, PATH_CANDIDATE_CORNER, PATH_MANAGE_APPLICANTS } from "constants/Paths";
import SearchBtn from "components/atoms/SearchBtn";
import ResetBtn from "components/atoms/ResetBtn";

interface manageData {
  screenedBy: string;
  date: string;
  screeningId: number;
  //   officeName: string;
}

interface searchData {
  date: Date | null;
  screenedBy: OptionProps[];
  //   office: OptionProps[];
}

const ManageScreening: React.FC = () => {
  //initiliaze the formData state for search fields
  const [searchData, setSearchData] = useState<searchData>({
    date: null,
    screenedBy: [],
    // office: [],
  });
  const navigate = useNavigate();

  const [profileCardData, setProfileCardData] =
    useState<MatchingCandidateProfileCardType>({
      id: null,
      firstName: "",
      lastName: "",
      functions: "",
      mobNumber: "",
      email: "",
      street: "",
      number: "",
      box: "",
      zipCode: "",
      city: "",
      picFileName: "",
      picFilePath: "",
      country: "",
      cvData: "",
      profilePicData: "",
      isScreening: true,
      source: "manageScreening",
    });

  const [formData, setFormData] = useState([] as manageData[]);

  //state for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [loading, setLoading] = useState(true);
  const { candidateId } = useParams<{ candidateId: string }>();

  //initiliaze the state for dropdown values
  const [dropDowns, setDropDowns] = useState({
    screenedBy: [] as OptionProps[]
    // offices: [] as OptionProps[],
  });

  useEffect(() => {
    fetchScreeningDataBySearch();
  }, []);

  //API call for to fetch countries
  const fetchScreeningDataBySearch = async (searchData = {}, page = 1) => {
    const requestData = {
      ...searchData,
      page: page,
    };

    const response = await ApiCall.service(
      `${ENDPOINTS.MANAGE_SCREENING}/${candidateId}`,
      "POST",
      requestData,
      false,
      M_MASTER_DATA
    );

    if (response?.status === 200) {
      setProfileCardData((prev) => ({
        ...prev,
        ...response.candidateData.candidateInfo.general['candidateInfo'][0],
        country: response.candidateData.candidateInfo.general.country,
        // ...{ cvData: response.candidateData.candidateInfo?.cvData },
        // ...{
        profilePicData: response.candidateData.candidateInfo?.profilePicData,
        // },
        ...{ functions: response.candidateData.candidateInfo?.functions },
      }));
      setFormData(response.data);
      setDropDowns((prevData) => ({
        ...prevData,
        screenedBy: mapToSelect(response.screenedBy),
      }));
      setTotalPages(response.totalPages);
      setLoading(false);
    }
  };

  const handleSubmit = (e: React.FormEvent, reset: boolean) => {
    e.preventDefault();
    if (reset) {
      setSearchData((prevData) => ({
        ...prevData,
        date: null,
        screenedBy: []
      }));
      fetchScreeningDataBySearch();

    }
    if (
      searchData.date === null &&
      searchData.screenedBy === undefined
      //    &&
      //   searchData.office.length === 0
    ) {
      CustomNotify({
        type: "warning",
        message: t("Search input not provided."),
      });
      fetchScreeningDataBySearch();
    } else {
      fetchScreeningDataBySearch(searchData, 1);
    }
  };

  const handleReset = () => {
    // Clear the selected values in the dropdowns
    setSearchData({
      date: null,
      screenedBy: [],
      //   office: [],
    });
    setCurrentPage(1);
    fetchScreeningDataBySearch();
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    fetchScreeningDataBySearch(searchData, newPage);
  };

  const handleDateChange = (date: Date | null, name: string) => {
    const updatedDate = date !== null ? date : new Date();
    setSearchData((prevData) => ({
      ...prevData,
      [name]: updatedDate,
    }));
  };

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setSearchData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  //changehandler for search fields
  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    setSearchData((prevData) => ({
      ...prevData,
      [fieldName]: selectedOption,
    }));
  };

  const handleClick = (value: boolean) => {
    let candidateResult = true;
    if (value) {
      candidateResult = true;
      navigate(`${EDIT_CANDIDATE}/${candidateId}?candidateId=${candidateId}`);
    } else {
      candidateResult = false;
      navigate(`${MANAGE_CANDIDATES}`);
    }
    const postData = {
      id: candidateId,
      candidateResult: candidateResult
    }
    ApiCall.service(`${ENDPOINTS.CANDIDATE_RESULT}`, "POST", postData, true, M_MASTER_DATA).then((response) => {
      if (response?.status === 200) {
        CustomNotify({ type: "success", message: response.message });
      }
    });
  }

  return (
    <>
      {/* <AccessControl
        requiredPermissions={[
          {
            permission: "Candidates",
            read: true,
          },
        ]}
        renderNoAccess={true}
      > */}
      <div className="search-bar">
        <Title title={t("Screening")} />
      </div>
      <div style={{ paddingTop: "0.25vw" }}>
        <div className="row marginBottomPointTwo5">
          <div className="col-md-12">
            <MatchingProfileCard profileCardProps={profileCardData} />
            <div className="text-end">
              {formData && formData.length > 0 &&
                <>
                  <Button
                    title={t("Selected")}
                    type="submit"
                    handleClick={() => handleClick(true)}
                    className="form-button shadow-none marginRightPoint5"
                  />
                  <Button
                    title={t("Rejected")}
                    type="submit"
                    handleClick={() => handleClick(false)}
                    className="form-button shadow-none"
                  />
                </>
              }
            </div>
          </div>
        </div>
        <div className="form-height-dashboard">
          <div className="search-bar">
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <SelectWithSearch
                  search={true}
                  options={dropDowns.screenedBy}
                  title={t("Screened by")}
                  onChange={(e) => handleSelectChange(e, "screenedBy")}
                  isMulti={false}
                  name="screenedBy"
                  value={searchData.screenedBy}
                  placeHolder={t("Screened by")}
                />
              </div>
              <div className="position-relative col-md-3">
                <Calender
                  onChange={handleDateChange}
                  selectedDate={searchData.date}
                  label={t("Date")}
                  isMandatory={false}
                  name="date"
                  placeHolder="dd-mm-yyyy"
                />
              </div>
              {/* <div className="col-md-3">
                          <SelectWithSearch
                            search={true}
                            options={dropDowns.offices}
                            title={t("Office")}
                            onChange={(e) => handleSelectChange(e, "office")}
                            isMulti={true}
                            name="office"
                            value={searchData.office}
                            placeHolder={""}
                          />
                        </div> */}
              <div className="col-lg-3 col-xxl-3 align-self-end">
                <div className="d-flex align-items-center h-100">
                  <ResetBtn handleResetClick={(e) => handleSubmit(e, true)} />
                  <SearchBtn handleSearchClick={(e) => handleSubmit(e, false)} />
                </div>
              </div>
            </div>
          </div>
          <div className="position-relative tableMainWrapper">
            <div className="table-responsive  tableSection">
              <div className="manageScreeningTable">
                <table className="table table-hover mb-0">
                  <thead>
                    <tr className="TableHeader">
                      <th className="align-middle ps-lg-4">
                        {t("Screened by")}
                      </th>
                      <th className="align-middle ps-lg-4">
                        {t("Date of screening")}
                      </th>
                      {/* <th className="align-middle ps-lg-4">
                            {t("Office")}
                          </th> */}
                      <th className="align-middle">{t("Actions")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr className="border-0">
                        <td
                          className="text-center border-0 spinner-wrapper"
                          colSpan={3}
                        >
                          <Spinner size="sm" className="me-2" />{" "}
                          {t("Loading")}
                        </td>
                      </tr>
                    ) : (
                      <>
                        {formData && formData.length > 0 ? (
                          formData.map((screening) => (
                            <tr
                              key={screening.screeningId}
                              className="border-bottom mb-3 box-shadow align-middle"
                            >
                              <>
                                <td
                                  data-label={t("screenedBy")}
                                  className="align-middle ps-lg-4"
                                >
                                  {screening.screenedBy}
                                </td>
                                <td
                                  data-label={t("date")}
                                  className="align-middle ps-lg-4"
                                >
                                  {screening.date}
                                </td>
                                {/* <td
                                    data-label={t("office")}
                                    className="align-middle ps-lg-4"
                                  >
                                    {screening.officeName}
                                  </td> */}
                              </>

                              <td className="table-action-icons px-2 align-middle">
                                <Link
                                  to={`/screening/assessment/${screening.screeningId}/${candidateId}`}
                                  className="table-action-btn shadow-none"
                                  title={t("View")}
                                >
                                  <ViewIcon />
                                </Link>

                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr className="border rounded-3">
                            <td
                              colSpan={7}
                              className="border-0 text-center py-3 no-records"
                            >
                              <span className="text-danger w-100 d-block text-center">
                                {t("No records")}
                              </span>
                            </td>
                          </tr>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {totalPages > 1 && (
              <div className="pagination justify-content-center align-items-center mt-4">
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={(newPage) => handlePageChange(newPage)}
                />
              </div>
            )}
          </div>
        </div>
        <div className="col-md-6 align-self-center backPadding">
          <BackButton />
        </div>
      </div>
      {/* </AccessControl> */}
    </>
  );
};

export default ManageScreening;
