import React, { useState } from "react";
import { t } from "pages/microservices/masterData/translation/Translation";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import RadioField from "components/atoms/RadioField";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import InputTextfield from "components/atoms/InputTextField";
import InputTextAreaField from "components/atoms/InputTextAreaField";
import { useFormContext } from "../context/Context";
import { CoefficientTypes, UPDATE_SPECIAL_AGREEMENT_FIELD } from "../context/Constants";

// Define the prop types for the component
interface IProjectFormGeneralTabProps {
    // id?: string | number;
}

// Functional component using TypeScript
const SpecialAgreements: React.FC<IProjectFormGeneralTabProps> = () => {
    const { state, dispatch } = useFormContext();
    const splAgreement = state?.specialAgreement;

    const options = state?.options;

    const [loading, setLoading] = useState(false);
    const userAuth = useSelector(selectAuth);
    const [validationStatus, setValidationStatus] = useState({
        isValid: false,
        type: "",
    });

    const changeHandler = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        index: number
    ) => {
        const { name, value, type, id } = e.target;
        if (name === "niceToHave") {
            const regex = /^[\d,.]*$/;
            let matches = value.match(regex);
            if (matches || value === "") {
                dispatch({
                    type: UPDATE_SPECIAL_AGREEMENT_FIELD,
                    fieldName: name,
                    fieldValue: value,
                    index: index,
                    tab: 'specialAgreement',
                });
            }
        } else {
            const newValue = type === "radio" ? id.split('-')[0] : value;
            const newName = type == "radio" ? name.split('-')[0] : name
            //const fieldName = type === "radio" ? "inclusiveOption" : name;
            dispatch({
                type: UPDATE_SPECIAL_AGREEMENT_FIELD,
                fieldName: newName,
                fieldValue: newValue,
                index: index,
                tab: 'specialAgreement',
            });
        }
    };

    const handleSelectChange = (
        selectedOption: any,
        fieldName: string,
        index: number
    ) => {
        dispatch({
            type: UPDATE_SPECIAL_AGREEMENT_FIELD,
            fieldName: fieldName,
            fieldValue: selectedOption,
            index: index,
            tab: 'specialAgreement',
        });
    };
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div
                        className="agreement-wage-element form-border"
                        style={{ paddingTop: "3vw" }}
                    >
                        <table className="table composition-co-efficient-table table-hover">
                            <thead>
                                <tr className="border-0 TableHeader bg-white">
                                    <th className="border-0" style={{ width: "25%" }}>
                                        {t("Composition co-efficient")}
                                    </th>
                                    <th className="border-0 text-center" style={{ width: "10%" }}>
                                        {t("Included")}
                                    </th>
                                    <th className="border-0 text-center" style={{ width: "10%" }}>
                                        {t("Not-included")}
                                    </th>
                                    <th className="border-0" style={{ width: "10%" }}>
                                        {t("Value")}
                                    </th>
                                    <th className="border-0">{t("Remarks")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {splAgreement &&
                                    splAgreement.map((item: any, index: number) => (
                                        <tr
                                            className="border agreementData"
                                            key={index}
                                        >
                                            <td
                                                className="border-0 text-break align-middle"
                                                data-label={t("Composition co-efficient")}
                                            >
                                                {t(`${item.name}`)}
                                            </td>
                                            <td
                                                className="border-0 text-break text-center align-middle"
                                                data-label={t("Included")}
                                            >
                                                <RadioField
                                                    handleChange={(e) => changeHandler(e, index)}
                                                    label=""
                                                    ischecked={item.include == 'include'}
                                                    name={`include-${index}`}
                                                    id={`include-${index}`}
                                                //value={1}
                                                />
                                            </td>
                                            <td
                                                className="border-0 text-break text-center align-middle"
                                                data-label={t("Not-included")}
                                            >
                                                <RadioField
                                                    handleChange={(e) => changeHandler(e, index)}
                                                    ischecked={item.include === 'notInclude'}
                                                    label=""
                                                    name={`include-${index}`}
                                                    id={`notInclude-${index}`}
                                                />
                                            </td>
                                            {/* <td className="border-0 text-break align-middle" data-label={t("Value")} style={{ width: "10%" }}>
                                            <SelectWithSearch
                                                key={item.value}
                                                search={true}
                                                options={options?.benefit_type}
                                                onChange={(e) =>
                                                    handleSelectChange(e, 'benefit_type', index)
                                                }
                                                isMulti={false}
                                                name="benefit_type"
                                                value={item.benefit_type}
                                                className={`${item.errorStatus ? "error-border" : ""}`}
                                                placeHolder={""}
                                            />
                                        </td> */}
                                            <td style={{ width: "10%" }}>
                                                {item.type == 1 ? (
                                                    <SelectWithSearch
                                                        key={item.value}
                                                        search={true}
                                                        options={CoefficientTypes}
                                                        onChange={(e) =>
                                                            handleSelectChange(e, 'benefit_value', index)
                                                        }
                                                        isMulti={false}
                                                        name="benefit_value"
                                                        value={item.benefit_value}
                                                        className={`${item.errors?.type !== "" ? "error-border" : ""}`}
                                                        placeHolder={""}
                                                    />
                                                ) : (
                                                    <div className={`input-group ${item?.errors?.benefit_value !== ""
                                                        ? "error-border"
                                                        : "rounded-end-0"
                                                        }  `}>
                                                        <InputTextfield
                                                            name="benefit_value"
                                                            handleChange={(event) =>
                                                                changeHandler(event, index)
                                                            }
                                                            value={item.benefit_value ?? ""}
                                                            id={`benefit_value-${index}`}
                                                            className={`form-control field-shadow`}
                                                            title={item?.errors?.benefit_value}
                                                            isDisabled={item?.wageId == 29}
                                                        />
                                                        <div className="input-group-append euroSignAgreement units">
                                                            {item?.type == 0 ? '€' : '%'}
                                                        </div>
                                                    </div>
                                                )}
                                            </td>
                                            {/* <td style={{ width: "10%" }}>
                                                
                                            </td>
                                            <td style={{ width: "10%" }}>
                                                <div className={`input-group ${item?.errors?.benefit_value !== ""
                                                    ? "error-border"
                                                    : "rounded-end-0"
                                                    }  `}>
                                                    <InputTextfield
                                                        name="benefit_value"
                                                        handleChange={(event) =>
                                                            changeHandler(event, index)
                                                        }
                                                        value={item.benefit_value ?? ""}
                                                        id={`benefit_value-${index}`}
                                                        className={`form-control field-shadow`}
                                                        title={item?.errors?.benefit_value}
                                                    />
                                                    <div className="input-group-append euroSignAgreement units">
                                                        {item?.type == 1 ? '€' : '%'}
                                                    </div>
                                                </div>
                                            </td> */}
                                            <td className="border-0 text-break" data-label={t("Remarks")}>
                                                <div className="row remarksTextArea">
                                                    <div>
                                                        <InputTextAreaField
                                                            handleChange={(e) => changeHandler(e, index)}
                                                            name="remarks"
                                                            value={item.remarks ?? ""}
                                                            rows={1}
                                                        />
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SpecialAgreements;
