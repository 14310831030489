import React, { useState } from "react";
import { getWeekDays } from "../utils/utils";
import { useFilterOptions } from "../timesheets-redux/context/FiltersContext/FiltersContext";

const WeekCalendar = () => {
    const { filterOptionsState } = useFilterOptions();
    const currentDate = new Date(); // Get current date

    const renderWeekDays = () => {
        const days = getWeekDays(filterOptionsState?.selectedState?.currentDate);        
        const weekdays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
        return weekdays.map((weekday, index) => {
            const isCurrentDay = days[index].getDate() === currentDate.getDate(); // Check if it's the current day
            return (
                <th
                    key={index}
                    style={{
                        backgroundColor: isCurrentDay ? "#00a5ce" : "#f6f6f8",
                        color: isCurrentDay ? "white" : "#000" 
                    }}
                    className="border-0"
                >
                    <div className="text-center font-weight-bold weekDaysCol">
                        {weekday}
                        <span className="ps-1">
                            {days[index]?.toLocaleDateString("en-US", {day: "numeric", month: "numeric",})}
                        </span>
                    </div>
                </th>
            );
        });
    };

    return (
        <tr>
            <th className="border-0"></th>
            <th className="border-0"></th>
            {renderWeekDays()}
            <th className="border-0"></th>
        </tr>
    );
};

export default WeekCalendar;
