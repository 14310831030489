import React from "react";
import { t } from "pages/microservices/masterData/translation/Translation";
// import { useFormContext } from "pages/microservices/project/context/Context";
import { useFormContext } from "pages/microservices/tempAgancy/context/Context";
import SelectWithSearch from "components/atoms/SelectWithSearch";

import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import {
  ADD_EMPLOYEE_TYPE,
  ADD_FUNCTION_PROFILE,
  CLONE_FUNCTION_TYPE,
  DELETE_EMPLOYEE_TYPE,
  DELETE_FUNCTION_TYPE,
  SET_FIELD_GENERAL_TAB,
  UPDATE_COEFFCIENT_TAB_FIELD,
} from "../context/Constants";
import { FormMode } from "components/common/CommonEnums";
import CustomNotify from "components/atoms/CustomNotify";
import TableData from "./TableData";
import "./css/tempAgency.css";

// Define the prop types for the component
interface IProjectFormGeneralTabProps {
  // id?: string | number;
}

// Functional component using TypeScript
const Coefficient: React.FC<IProjectFormGeneralTabProps> = () => {
  const { state, dispatch } = useFormContext();
  const addFunctionProfile = (pcIndex: number) => {
    dispatch({ type: ADD_FUNCTION_PROFILE, pcIndex });
  };
  const addEmployeeType = (pcIndex: number, funcIndex: number) => {
    dispatch({ type: ADD_EMPLOYEE_TYPE, pcIndex, funcIndex });
  };

  const deleteEmployeeType = (
    pcIndex: number,
    funcIndex: number,
    empRowIndex: number
  ) => {
    dispatch({ type: DELETE_EMPLOYEE_TYPE, pcIndex, funcIndex, empRowIndex });
  };

  const cloneFunctionType = (pcIndex: number, funcIndex: number) => {
    dispatch({ type: CLONE_FUNCTION_TYPE, pcIndex, funcIndex });
  };

  const deleteFunctionType = (pcIndex: number, funcIndex: number) => {
    dispatch({ type: DELETE_FUNCTION_TYPE, pcIndex, funcIndex });
  };
  const handleFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    pcIndex: number | null,
    funcIndex: number | null,
    empRowIndex: number | null,
    coeffIndex: number | null
  ) => {
    const { name, value } = event.target;
    if (name === "retentionCount") {
      dispatch({
        type: SET_FIELD_GENERAL_TAB,
        field: name,
        value: value,
      });
    } else {
      const regex = /^(\d{1,2})(,\d{0,4})?$/;
      let matches = regex.exec(value);
      if (matches || value === "") {
        dispatch({
          type: UPDATE_COEFFCIENT_TAB_FIELD,
          coeffield: name,
          coeffValue: value,
          pcIndex,
          funcIndex,
          empRowIndex,
          coeffIndex,
        });
      }
    }
  };

  const handleRetentionSelectChange = (e: any, name: string) => {
    dispatch({
      type: SET_FIELD_GENERAL_TAB,
      field: name,
      value: e,
    });
  };

  const handleSelectChange = (
    selectedOption: any,
    title: string,
    pcIndex: number,
    funcIndex: number | null,
    empRowIndex: number | null
  ) => {
    dispatch({
      type: UPDATE_COEFFCIENT_TAB_FIELD,
      coeffield: title,
      coeffValue: selectedOption,
      pcIndex,
      funcIndex,
      empRowIndex,
      coeffIndex: null,
    });
  };

  const handleDateChange = (
    date: Date | null,
    name: string,
    pcIndex: number,
    funcIndex: number,
    empRowIndex: number
  ) => {
    dispatch({
      type: UPDATE_COEFFCIENT_TAB_FIELD,
      coeffield: name,
      coeffValue: date,
      pcIndex,
      funcIndex,
      empRowIndex,
      coeffIndex: 0,
    });
  };
  return (
    <div className="row">
      <div className="col-12">
        <div className="form-border" style={{ paddingTop: "2vw" }}>
          <div>
            {state.coefficient.length > 0 ? (
              state.coefficient.map((item, pcIndex) => (
                <React.Fragment key={`retention-${pcIndex}`}>
                  {pcIndex === 0 && (
                    <div className="row">
                      <div className="col-xl-10 col-md-9">
                        <LabelWithInputField
                          isMandatory={true}
                          name="retentionCount"
                          handleChange={(event) =>
                            handleFieldChange(event, pcIndex, null, null, null)
                          }
                          value={state.general.data.retentionCount ?? ""}
                          label={t(
                            "Duration after which the candidate can be employed under company or customer payroll"
                          )}
                          type="number"
                        />
                      </div>
                      <div className="col-xl-2 col-md-3 billing-days-dropdwon searchBtnWrapper">
                        <SelectWithSearch
                          onChange={(e) =>
                            handleRetentionSelectChange(e, "retentionPeriod")
                          }
                          title={t("")}
                          placeHolder={t("Select")}
                          search={true}
                          options={state.options.retentionPeriod}
                          value={state.general.data.retentionPeriod ?? ""}
                          isDisabled={false}
                          isMulti={false}
                          isMandatory={true}
                          error={""}
                          name={"retentionPeriod"}
                          id={"retentionPeriod"}
                        />
                      </div>
                    </div>
                  )}
                  <div key={`pc-${pcIndex}`}>
                    <div className="pcName color-dark-skyblue fw-bold tab-subtitle pb-1">{item?.pc?.label ?? ""}</div>
                    <div className="table-responsive">
                      <table className="table table-bordered coefficientTableWrapper cooperation-billing-table">
                        <thead>
                          <tr className="TableHeader bg-white">
                            <th className="text-center" rowSpan={2}>
                              {t("Profile")}
                            </th>
                            <th className="text-center" rowSpan={2}>
                              {t("Employee type")}
                            </th>
                            <th className="text-center" rowSpan={2}>
                              {t("Level")}
                            </th>
                            {state.options.coefficient &&
                              state.options.coefficient.map((coeff, index) => (
                                <th
                                  key={`coeff-type-${index}`}
                                  rowSpan={2}
                                  className="text-center"
                                >
                                  {t(`${coeff.name}`)}
                                </th>
                              ))}
                            <th style={{ width: "15%" }}>{t("Action")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Array.isArray(item.functions) &&
                            item.functions.map((func, funcIndex) => (
                              <React.Fragment
                                key={`tbody-${pcIndex}-func-${funcIndex}`}
                              >
                                {Array.isArray(func.employeeTypes) &&
                                  func.employeeTypes.map((empRow, empRowIndex) => (
                                    <React.Fragment
                                      key={`tbody-${pcIndex}-func-${funcIndex}-emp-${empRowIndex}`}
                                    >
                                      {Array.isArray(empRow.coefficients) &&
                                        empRow.coefficients.map(
                                          (coeff, coeffIndex) => (
                                            <tr
                                              className="align-middle border-bottom"
                                              key={`tbody-${pcIndex}-func-${funcIndex}-emp-${empRowIndex}-coeff-${coeffIndex}`}
                                            >
                                              <TableData
                                                pcIndex={pcIndex}
                                                funcData={func}
                                                funcDropdown={
                                                  item.functionDropdownValues
                                                }
                                                empTypeDropdown={
                                                  item.empTypeDropdownValues
                                                }
                                                funcIndex={funcIndex}
                                                empTypeData={empRow}
                                                empRowIndex={empRowIndex}
                                                coeffIndex={coeffIndex}
                                                dropDown={item}
                                                coeffData={coeff}
                                                coeffOption={
                                                  state.options.coefficient
                                                }
                                                addFunctionProfile={
                                                  addFunctionProfile
                                                }
                                                cloneFunctionType={
                                                  cloneFunctionType
                                                }
                                                deleteFunctionType={
                                                  deleteFunctionType
                                                }
                                                addEmployeeType={addEmployeeType}
                                                deleteEmployeeType={
                                                  deleteEmployeeType
                                                }
                                                handleSelectChange={
                                                  handleSelectChange
                                                }
                                                handleFieldChange={
                                                  handleFieldChange
                                                }
                                                handleDateChange={handleDateChange}
                                                role={""}
                                              />
                                            </tr>
                                          )
                                        )}
                                    </React.Fragment>
                                  ))}
                              </React.Fragment>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </React.Fragment>
              ))
            ) : (
              <div>
                {state.employeeCoefficient.blueCollar.length === 0 &&
                  state.employeeCoefficient.whiteCollar.length === 0 ? (
                  <div className="text-danger">
                    {t("Please select at least one Paritair committee")}
                  </div>
                ) : (
                  <div>{t("Loading")}</div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Coefficient;
