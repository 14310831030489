import React from 'react'

const RefusedCompany: React.FC = () => {
    return (
        <>
            <svg version="1.1" viewBox="0 0 2048 2048" height="512" xmlns="http://www.w3.org/2000/svg" width="512" fill="currentColor">
                <path transform="translate(798)" d="m0 0h85v1l47 8 36 9 33 11 24 10 26 12 22 12 24 15 17 12 13 10 10 8 13 11 12 11 26 26 9 11 12 14 14 19 16 24 9 15 13 24 13 28 9 23 10 30 8 32 5 27 3 23 2 27v49l-2 27-5 35-8 36-9 30-10 27-7 17-8 16-13 25-14 23-9 13-8 11-10 13-9 11-9 10-7 8-5 6h-2v2h-2l-2 4h-2l-2 4h-2v2l-8 7-12 11-11 9-10 8-15 11-18 12-18 11-22 12-22 11-27 11-36 12-26 7-36 7-26 3 46 4 39 5 44 8 42 10 40 12 29 10 30 12 25 11 29 14 27 14 24 14 24 15 25 17 16 12v3l-22 14-19 13-18 13-8 7-10 8-13 11-4 4h-2l-1 3-8 7-22 22-7 8-10 11-8 10-10 12-8 11-13 19-10 15-9 15-9 16-14 27-12 27-11 29-10 32-8 32-6 31-4 29-2 26-1 20v26l2 38 4 34 6 34 6 25 8 28 11 32 11 26 12 26 15 28 20 32 13 18 9 12 11 14 11 13 12 13 9 10 17 17 8 7 11 10 11 9 9 8v1h-1208l-15-7-11-8-8-8-8-13-4-10v-284l2-11 6-49 7-40 9-40 9-32 8-26 12-33 12-29 11-24 13-27 12-22 15-26 15-23 12-18 10-14 9-12 14-18 9-11 11-13 7-8 10-11 7-8 13-13 5-6 8-7 14-14 8-7 11-10 11-9 8-7 14-11 15-12 14-10 18-13 17-11 22-14 24-14 24-13 15-8 23-11 29-13 31-12 28-10 35-11 38-10 37-8 35-6 47-6 32-3-27-3-29-5-30-7-22-6-36-12-29-12-28-14-19-11-22-14-16-12-14-11-10-9-11-9-26-26-9-11-9-10-14-19-12-17-11-18-10-18-9-17-11-25-10-27-7-21-8-32-6-32-4-32-2-31v-31l2-32 4-31 5-27 6-26 11-35 10-26 9-21 10-19 12-22 14-21 12-17 13-16 8-10 3-4h2l2-4 31-31 11-9 16-13 18-13 22-14 17-10 15-8 31-14 29-11 33-10 30-7 32-6z" />
                <path d="m0 0h38l26 2 30 4 34 7 24 7 26 9 26 11 30 15 20 12 18 12 18 13 17 14 10 9 8 7 23 23 7 8 11 13 9 12 13 18 12 19 11 19 9 17 14 32 10 28 8 29 7 34 3 18 2 1v91l-2 2-4 28-7 37-8 30-11 32-10 24-12 25-10 18-12 19-12 17-10 13-11 13-7 8-5 6h-2l-2 4-9 9-8 7-12 11-14 11-15 11-15 10-20 12-20 11-31 14-27 10-31 9-26 6-30 5-37 4h-50l-38-4-36-6-32-8-36-12-24-10-27-13-24-14-24-16-17-13-14-12-10-9-8-7-11-11-7-8-12-14-12-15-14-20-13-21-15-28-14-32-8-23-8-26-7-29-6-35-4-35-1-15v-29l3-33 4-26 6-28 8-28 10-28 11-25 8-16 9-17 11-18 13-19 16-21 12-14 7-8 8-9h2l2-4 4-4h2l1-3 8-7 13-12 14-11 19-14 15-10 18-11 18-10 25-12 25-10 27-9 27-7 25-5 29-4zm-99 273-13 2-12 5-11 8-7 7-8 14-4 13-1 14 3 16 7 14 9 11 65 65 3 2-1 5-71 71-7 10-5 11-2 8v22l4 13 7 12 7 8 10 7 11 5 12 3h15l13-3 15-8 12-11 65-65 4 2 68 68 12 9 11 5 12 3h16l15-4 11-6 10-9 5-6 8-16 2-10v-17l-3-12-6-12-8-10-70-70 2-4 6-5 6-7 60-60 7-11 4-10 2-9v-17l-3-12-7-14-11-12-14-8-12-4-17-1-12 2-9 4-12 7-75 75-7-6-67-67-10-7-11-5-7-2z" transform="translate(1584,1156)"
                 fill="currentColor" />
                <path transform="translate(1586,2047)" d="m0 0h26v1h-26z" />
                <path transform="translate(1617,2047)" d="m0 0" />
            </svg>
        </>
    )
}

export default RefusedCompany;
