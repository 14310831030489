import {
  validateEmail,
  validateRequired,
  validateTextFieldAlpha,
  validatePhoneNumber,
  validateSelectField,
  validateTimeFormat,
  validateDate,
  validateNumber,
  validateCkeditorData,
  validateMultiSelectField,
  validateForUnits,
  validateAmount,
} from "services/validation/ValidationService";

export const UserFormValidation = {
  firstName: [validateRequired, validateTextFieldAlpha],
  lastName: [validateRequired, validateTextFieldAlpha],
  email: [validateRequired, validateEmail],
  phone_number: [validatePhoneNumber],
  role: [validateMultiSelectField],
  businessUnit: [validateMultiSelectField],
  userStatus: [validateRequired],
};

export const EditFormValidation = {
  firstName: [validateRequired, validateTextFieldAlpha],
  lastName: [validateRequired, validateTextFieldAlpha],
  email: [validateRequired, validateEmail],
  phone_number: [validatePhoneNumber],
  role: [validateMultiSelectField],
  businessUnit: [validateMultiSelectField],
  userStatus: [validateRequired],
};

export const BusinessFormValidation = {
  name: [validateRequired, validateTextFieldAlpha],
  number: [validateRequired],
  company: [validateSelectField],
  template: [validateRequired],
  mobNumber: [validateRequired, validatePhoneNumber],
  email: [validateRequired, validateEmail],
  street: [validateRequired],
  houseNumber: [validateRequired],
  box: [],
  zipcode: [validateRequired],
  city: [validateRequired],
  country: [validateSelectField],
};

export const ShiftValidation = {
  name: [validateRequired, validateTextFieldAlpha],
  start: [validateRequired, validateTimeFormat],
  stop: [validateRequired, validateTimeFormat],
  minimum: [validateRequired],
  maximum: [validateRequired],
};

export const ProfileValidation = {
  firstname: [validateRequired, validateTextFieldAlpha],
  lastname: [validateRequired, validateTextFieldAlpha],
  phone_number: [validatePhoneNumber],
  country: [validateSelectField],
  city: [validateRequired],
  gender: [validateSelectField],
  dateOfBirth: [validateRequired],
  language: [validateSelectField],
};

export const EditProfileValidation = {
  firstname: [validateRequired, validateTextFieldAlpha],
  lastname: [validateRequired, validateTextFieldAlpha],
  phone_number: [validatePhoneNumber],
  country: [validateSelectField],
  city: [validateRequired],
  gender: [validateSelectField],
  language: [validateSelectField],
};

export const AppConfigsValidation = {
  pagination: [validateNumber],
  recaptcha: [validateNumber],
  adminsignature: [validateSelectField],
};

export const ContractValidation = {
  contractType: [validateSelectField],
  documentType: [validateSelectField],
  businessUnit: [validateSelectField],
  template: [validateCkeditorData],
};
export const CandidateValidation = {
  firstName: [validateRequired, validateTextFieldAlpha],
  lastName: [validateRequired, validateTextFieldAlpha],
  email: [validateRequired, validateEmail],
  mobNumber: [validatePhoneNumber],
  role: [validateSelectField],
  street: [validateRequired],
  houseNumber: [validateRequired],
  zipcode: [validateRequired],
  city: [validateRequired],
  country: [validateSelectField],
}

export const ClothesValidation = {
  ownerCompany: [validateSelectField],
  businessUnit: [validateMultiSelectField],
  brand: [validateRequired],
  model: [validateRequired],
  noOfUnits: [validateForUnits],
  purchaseDate: [validateRequired],
  purchasePrice: [validateRequired, validateAmount],
}

export const PurchaseOrderValidation = {
  ownerCompany: [validateSelectField],
  businessUnit: [validateMultiSelectField],
  purchaseDate: [validateRequired],
  purchasePrice: [validateRequired, validateAmount],
  brand: [validateRequired],
  model: [validateRequired],
  noOfUnits: [validateForUnits],
  material: [validateSelectField],
  type: [validateSelectField],
  status: [validateSelectField],
  supplier: [validateSelectField]
}
