import React from "react";
import CommonBox from "../atoms/commonBox";
import { useFilterOptions, useFilterOptionsDispatch } from "../timesheets-redux/context/FiltersContext/FiltersContext";
import { t } from "pages/microservices/masterData/translation/Translation";
import { TIMESHEET_FILTER_ACTIONS } from "../timesheets-redux/actions/TimesheetFilterActions";
import { updatePageFilters } from "store/pageHistory/pageHistorySlice";
import { useDispatch } from "react-redux";
const TimesheetsEmployees = () => {
  const { filterOptionsState } = useFilterOptions();
  const dispatch = useDispatch();
  const { dispatchFilterOptions } = useFilterOptionsDispatch();
  const tableBodyStyle = {
    // maxHeight: "900px",
    // overflowY: "scroll",
    // display: "block",
  };

  const handleEmployeeClick = async (employee_id) => {
    dispatch(updatePageFilters({ filters: { employee_id: employee_id } }));
    dispatchFilterOptions({
      type: TIMESHEET_FILTER_ACTIONS.SET_SELECTED_STATE,
      payload: {name: 'employee_id', value: employee_id }
    });
    dispatchFilterOptions({
      type: TIMESHEET_FILTER_ACTIONS.SET_SELECTED_STATE,
      payload: {name: 'refresh', value: !filterOptionsState?.selectedState?.refresh }
    });
  }

  return (
    <table className="w-100 h-100 overflow-auto">
      <thead className="position-sticky top-0">
        <tr>
          <th className="bg-lightskyblue">{t("Employee")}</th>
        </tr>
      </thead>
      <tbody style={tableBodyStyle}>
        {filterOptionsState?.filteredOptions?.employees?.map((employee, index) => (
          <tr key={employee?.value} className="border-0 d-block cursor-pointer">
            <td style={{ minHeight: "2vw" }} className="d-block">
              <CommonBox
                data={employee?.label ?? ""}
                bg_color={
                  (filterOptionsState?.selectedState?.employee_id == employee?.value)
                    ? "#00a5ce"
                    : "#fff"
                }
                color={
                  (filterOptionsState?.selectedState?.employee_id == employee?.value)
                    ? "white"
                    : "black"
                }
                handleBoxClick={() => handleEmployeeClick(employee?.value)}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TimesheetsEmployees;
