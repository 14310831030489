import Button from "components/atoms/Button";
import { t } from "pages/microservices/masterData/translation/Translation";
import React from "react";
import Modal from "react-bootstrap/Modal";
import SignatureCanvas from "react-signature-canvas";

interface SignatureModalProps {
  handleClose: () => void;
  handleClearSignature: () => void;
  handleSaveSignature: () => void;
  signatureRef: React.RefObject<SignatureCanvas>;
  signatureErrorMessage: string;
  signatureData?: string;
}

const SignatureModal: React.FC<SignatureModalProps> = ({
  handleClose,
  handleClearSignature,
  handleSaveSignature,
  signatureRef,
  signatureErrorMessage,
  signatureData,
}) => {
  return (
    <Modal
      size="lg"
      show={true}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
      className="modalPopupClass"
    >
      <Modal.Header
        closeButton
        className="color-dark-skyblue font-CalbriBold pb-2 px-0"
        style={{ fontSize: "1vw" }}
      >
        <Modal.Title>{t("Add Signature")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row justify-content-center">
          <div className="col-md-12">
            {signatureData ? (
              <img
                src={signatureData}
                alt="Signature"
                width="400"
                height="200"
                style={{ border: "1px solid #000" }}
              />
            ) : (
              <SignatureCanvas
                ref={signatureRef}
                canvasProps={{
                  className: "signature-canvas",
                  style: { border: "1px solid #000" },
                }}
              />
            )}
          </div>
        </div>
        {signatureErrorMessage && (
          <p style={{ color: "red" }}>{signatureErrorMessage}</p>
        )}
        <div className="row mt-3 justify-content-between">
          <div className="col-md-4">
            <Button
              type="button"
              title={t("Clear")}
              handleClick={handleClearSignature}
              className="close-button"
            />
          </div>
          <div className="col-md-4">
            <Button
              type="button"
              title={t("Save")}
              handleClick={handleSaveSignature}
              className="form-button float-end"
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SignatureModal;
