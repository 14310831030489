import React from "react";
import TimeField from "react-simple-timefield";
import LabelField from "../atoms/LabelField";

interface TimeFieldWithLabelProps {
  label?: string;
  value: string;
  handleChange: (value: string) => void;
  name?: string;
  id?: string;
  isMandatory?: boolean;
  placeholder?: string;
  isDisabled?: boolean;
  customStyle?: React.CSSProperties;
  labelClassName?: string;
  error?: string;
  errorclass?: string;
}

const TimeFieldWithLabel: React.FC<TimeFieldWithLabelProps> = ({
  label,
  value,
  handleChange,
  name,
  id = "",
  isMandatory,
  placeholder,
  isDisabled,
  customStyle,
  labelClassName,
  error,
  errorclass,
}) => {
    return (
        <div className="col-md-12 shiftTimingshiftInputCell marginBotttom1">
            {label && (
                <LabelField
                    title={label}
                    className={labelClassName}
                    isMandatory={isMandatory}
                    htmlfor={id}
                />
            )}
            <div>
                <TimeField
                    // name={name}
                    value={value}
                    onChange={(event, value) => handleChange(value)}
                    colon=":"
                    showSeconds={false}
                    style={{
                        width: '100%',
                        height:"100%",
                        border:"0.052vw solid #fff",
                        outline:"0",
                        pointerEvents: isDisabled ? "none" : "auto",
                        borderColor: (error && error != "") ? "red" : "",
                        textAlign:"left",paddingLeft:"0.5vw"
                    }}
                />
            </div>
            {error && <div className={`text-danger ${errorclass}`}>{error}</div>}
        </div>
    );
};

export default TimeFieldWithLabel;
