import { faCopy } from "@fortawesome/free-solid-svg-icons";
import BackButton from "components/atoms/BackButton";
import Button from "components/atoms/Button";
import CustomNotify from "components/atoms/CustomNotify";
import LabelField from "components/atoms/LabelField";
import ModalPopup from "components/atoms/ModalPopup";
import Pagination from "components/atoms/Pagination";
import ResetBtn from "components/atoms/ResetBtn";
import Search from "components/atoms/Search";
import SearchBtn from "components/atoms/SearchBtn";
import SortAtomForManagePage from "components/atoms/SortAtomForManagePage";
import TitleAtom from "components/atoms/Title";
import PaginationWithPerPage from "components/molecules/PaginationWithPerPage";
import TableActions from "components/organism/Actions/TableAction";
import FilterOrganism from "components/organism/FilterOrganism";
import { M_MASTER_DATA } from "constants/Constants";
import {
  CREATE_HOLIDAY_CODES,
  EDIT_HOLIDAY_CODE,
  HOLIDAY_CONFIGS,
} from "constants/Paths";
import { t } from "pages/microservices/masterData/translation/Translation";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  DELETE_HOLIDAY_CODE,
  GET_HOLIDAY_CODES_DATA,
  GET_OPTIONS_FOR_HOLIDAY_CODES,
} from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";
import { IconData } from "services/IconData";
import { selectPageHistory, updatePageFilters } from "store/pageHistory/pageHistorySlice";
import { HolidayCodeTableHeader } from "TableHeader";
import '../css/holiday.css';
interface HolidaysData {
  contract_type: any;
  holiday_type: any;
  employee_category: any;
}

interface DataProps {
  page: number;
  search?: any;
}

const ManageHolidayCodes = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const pageHistory = useSelector(selectPageHistory);
  const history = pageHistory?.history?.[pageHistory?.history?.length - 1] ?? {}
  const dispatch = useDispatch();
  const [pageData, setPageData] = useState({
    totalPages: 0,
    totalRecords: 0,
    initialCall: true,
    refresh: true,
  });
  const getSearchData = () => {
    return {
      holiday_name: history?.filters?.holiday_name ?? "",
      holiday_code: history?.filters?.holiday_code ?? "",
      contract_type: history?.filters?.contract_type ?? "",
      holiday_type: history?.filters?.holiday_type ?? "",
      employee_category: history?.filters?.employee_category ?? "",
      orderBy: history?.filters?.orderBy ?? "id",
      sortDirection: history?.filters?.sortDirection ?? "desc",
    }
  };
  const [showModal, setShowModal] = useState(false);

  const [holidayCodesData, setHolidaysData] = useState<any>([]);
  const [id, setId] = useState<number | undefined>(undefined);
  const [options, setOptions] = useState<any>({
    contract_types: [],
    holiday_type: [],
    employee_category: [],
  });
  useEffect(() => {
    fetchOptions();
  }, []);

  const fetchOptions = async () => {
    try {
      const response = await ApiCall.getService(
        GET_OPTIONS_FOR_HOLIDAY_CODES,
        "GET",
        M_MASTER_DATA,
        false
      );
      if (response?.status === 200) {
        const { contractTypes, holidayType, employeeCategory } = response.data;
        setOptions((prevState: any) => ({
          ...prevState,
          contract_types: [...contractTypes],
          holiday_type: [...holidayType],
          employee_category: [...employeeCategory],
        }));
      } else {
        CustomNotify({ type: "warning", message: response.message });
      }
    } catch (error) {
      console.error("Error occured ", error);
    }
  };

  useEffect(() => {
    const data: any = {
      currentPage: Number(history?.filters?.currentPage ?? 1),
      itemsPerPage: Number(history?.filters?.itemsPerPage ?? 10),
      search: getSearchData(),
    };
    fetchHolidayCodesData(data);
  }, [pageData?.refresh, pageData?.initialCall]);

  const fetchHolidayCodesData = async (data: DataProps) => {
    try {
      const response = await ApiCall.service(
        GET_HOLIDAY_CODES_DATA,
        "POST",
        data,
        false,
        M_MASTER_DATA
      );
      if (response?.status === 200) {
        const { data, totalPages } = response.data;
        setHolidaysData(data);
        setPageData((prev: any) => ({ ...prev, totalPages: response.totalPages, totalRecords: response?.totalRecords, initialCall: false }));
      } else {
        CustomNotify({ type: "warning", message: response.message });
      }
    } catch (error) {
      console.log("Error occured ", error);
    }
  };

  //onClick edit handler
  const handleEdit = (id: number) => {
    navigate(`${EDIT_HOLIDAY_CODE}/${id}`);
  };

  const handleModalClose = () => setShowModal(!showModal);

  const handleDelete = (entityID: number) => {
    setId(entityID);
    setShowModal(true);
  };

  const handleSaveChanges = async () => {
    setShowModal(false);
    if (id) {
      const response = await ApiCall.service(
        DELETE_HOLIDAY_CODE,
        "POST",
        { id: id },
        false,
        M_MASTER_DATA
      );

      if (response?.status === 200) {
        CustomNotify({ type: "success", message: response.message });
        handleRefresh();
      } else {
        CustomNotify({ type: "error", message: response.message });
      }
    }
  };

  const handleRefresh = () => setPageData((prev: any) => ({ ...prev, refresh: !pageData?.refresh }));
  const filters = [
    { name: 'holiday_name', fieldType: "text", placeholder: "Holiday name", filterType: 'search' },
    { name: 'holiday_code', fieldType: "text", placeholder: "Holiday code", filterType: 'search' },
    { name: 'contract_type', fieldType: "singleSelect", options: options?.contract_types, placeholder: "Contract type", filterType: 'search' },
    { name: 'holiday_type', fieldType: "singleSelect", options: options?.holiday_type, placeholder: "Holiday type", filterType: 'search' },
    { name: 'employee_category', fieldType: "singleSelect", options: options?.employee_category, placeholder: "Employee category", filterType: 'search' },
    { name: '', fieldType: "", filterType: 'search' },
  ];

  return (
    <>
      <div className="search-bar">
        <div className="row">
          <TitleAtom title={t("Holiday codes")} />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="position-relative tableMainWrapper">
            <div className="row">
              <div className="ManageCreateBtn">
                <Link to={CREATE_HOLIDAY_CODES} className="form-button">
                  + {t("Add holiday code")}
                </Link>
              </div>
            </div>
            <div className="table-responsive manage-holiday-codes tableSection">
              <FilterOrganism
                dispatch={dispatch}
                history={history}
                updatePageFilters={updatePageFilters}
                filters={filters}
                handleRefresh={handleRefresh}
              />
              <div className="TableHeight">
                <table className="table table-hover">
                  <thead>
                    <tr className="TableHeader">
                      {HolidayCodeTableHeader?.map((value: any) => (
                        <th key={value.name} className="border-bottom-0">
                          {value.name}{" "}
                          {value?.issort && (
                            <SortAtomForManagePage value={value} dispatch={dispatch} history={history} updatePageFilters={updatePageFilters} handleRefresh={handleRefresh} />
                          )}
                        </th>
                      ))}
                      <th style={{ width: "10%" }}>{t("Action")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {holidayCodesData && holidayCodesData.length > 0 ? (
                      holidayCodesData.map((record: any, key: number) => {
                        return (
                          <tr key={key}>
                            <td className="align-middle">{record?.holiday_name}</td>
                            <td className="align-middle">{record?.holiday_code}</td>
                            <td className="align-middle">
                              {record?.contract_type ?? ""}
                            </td>
                            <td className="align-middle">
                              {record?.holiday_type ?? ""}
                            </td>
                            <td className="align-middle">
                              {record?.employee_category ?? ""}
                            </td>
                            <td className="align-middle">
                              {record?.holiday_count + (record?.count_type == 1 ? " Hours" : " Days")}
                            </td>
                            <td className="align-middle">
                              <TableActions
                                isAction={true}
                                handleEdit={handleEdit}
                                handleDelete={handleDelete}
                                value={record.id}
                                permission={"Holiday codes"}
                              />
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={7} className="text-center">
                          {t("No records found")}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div
                className=""
                style={{ paddingTop: "0.25vw" }}
              >
                <PaginationWithPerPage
                  handleRefresh={handleRefresh}
                  dispatch={dispatch}
                  history={history}
                  pageData={pageData}
                />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-md-6 align-self-center backPadding">
          <BackButton />
        </div> */}
      </div>
      <ModalPopup
        show={showModal}
        onHide={handleModalClose}
        title="Delete confirmation"
        body="Are you sure want to delete?"
        onCloseButtonClick={handleModalClose}
        onConfirmButtonClick={handleSaveChanges}
        closeTitle="No"
        confirmTitle="Yes"
      />
    </>
  );
};
export default ManageHolidayCodes;
