import React, { useMemo, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import HelpInfoIcon from "static/images/HelpIcon";
import { t } from "pages/microservices/masterData/translation/Translation";
import CustomRangeSlider from "components/atoms/CustomRangeSlider";
import TextEllipsis from "components/atoms/TextEllipsis";
import { COMPETENCE_CHANGE } from "../../annotation/VacancyConstants";
import { RANGE_VALUE, RANGE_VALUE_FOR_GENERAL } from "constants/Constants";
import { TextAreaMolecule } from "components/molecules/TextAreaMolecule";
import Navigation from "../form-navigation/Navigation";

interface Option {
  label: string;
  value: string;
  range: string;
}

interface CompetenceProps {
  competenceOptions: any;
  state: any;
  dispatch: any;
  type?: any;
}
const ProjectCompetences: React.FC<CompetenceProps> = ({
  competenceOptions,
  state,
  dispatch,
  type,
}) => {
  const handleSelect = (selectedId: number, section: string, selected: any) => {
    let selects = selected?.target?.name ?? undefined;
    if (!selects) {
      selects = selected.map((eachItem: any) => {return { ...eachItem }});
    }
    dispatch({
      type: COMPETENCE_CHANGE,
      field: "SELECT",
      payload: {
        selection: section,
        selected: selected.length > 0 ? selects : [],
        id: selectedId,
        additional_info: selected?.target?.name ? selected?.target?.value : undefined,
      },
    });
  };
  const [validationStatus, setValidationStatus] = useState({
    isValid: false,
    type: "",
  });

  const validStatus = (validation: any) => {
    setValidationStatus({ isValid: validation.isValid, type: validation.type });
  };
  
  let data = state.competences?.competence;
  
  const getTitleForRating = (range: string) => {
    switch (parseInt(range)) {
      case 1:
        return "A1";
      case 2:
        return "A2";
      case 3:
        return "B1";
      case 4:
        return "B2";
      case 5:
        return "C1";
      case 6:
        return "C2";
      default:
        return "A1";
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    section: string,
    index: number
  ) => {
    dispatch({
      type: COMPETENCE_CHANGE,
      payload: { value: e.target.value, section: section, index: index },
    });
  };

  const className = type == "View" ? "form-border disabled" : "form-border";
  // const activeKeys = data?.filter((section: any) => section?.options?.length > 0).map((section: any) => section?.key);
  const activeKeys = useMemo(() => {
    return competenceOptions?.map((section: any) => section?.key);
  }, [competenceOptions]);
  
  return (
    <div className="vacancyEmployeementTab">
      <div className={`${className}`} style={{ paddingTop: "2vw" }}>
        <Accordion defaultActiveKey={activeKeys} alwaysOpen>
          {competenceOptions &&
            competenceOptions.map((section: any) => {
              return (
                <Accordion.Item key={section?.key} eventKey={section?.key}>
                  <Accordion.Header>{section?.label}</Accordion.Header>
                  <Accordion.Body>
                    <div className="hideHeight">
                      <SelectWithSearch
                        name={section.key}
                        isMandatory={false}
                        search={true}
                        placeHolder="Select"
                        options={section.options}
                        value={data?.filter((item: any) => item.key == section.key)?.[0]
                          ?.options?.map((item: any) => item?.value)}
                        onChange={(selected) =>
                          handleSelect(section.competence_id, section.key, selected)
                        }
                        isMulti={true}
                        className="select-field"
                        error={""}
                      />
                    </div>
                    <div className="row">
                      {data?.length > 0 &&
                        data.find((item: any) => item.key === section.key)?.options && (
                          <div
                            className="text-end"
                            style={{
                              marginTop: "0.5vw",
                            }}
                            data-bs-toggle="tooltip"
                            data-bs-placement="left"
                            title={t(section?.info)}
                          >
                            <HelpInfoIcon />
                          </div>
                        )}
                      {data?.length > 0 &&
                        data?.find((item: any) => item.key === section.key)
                          ?.options?.map((option: any, index: any) => {                            
                            return (
                              <div key={index} className="col-3">
                                <div className="row">
                                  <div className="col-4 pe-0">
                                    <span className="fw-bolder">
                                      <TextEllipsis
                                        title={option?.label}
                                        label={option?.label}
                                        width="8vw"
                                      />{" "}
                                    </span>
                                  </div>
                                  <div className="col-8">
                                    <CustomRangeSlider
                                      min={1}
                                      max={section?.maxRange ?? "6"}
                                      step={1} // Step size should match the range
                                      title={getTitleForRating(
                                        option?.range === "0" ||
                                          option?.range === 0
                                          ? "1"
                                          : option?.range
                                      )}
                                      value={
                                        option?.range === "0" ||
                                        option?.range === 0
                                          ? "1"
                                          : option?.range
                                      }
                                      handleChange={(e) =>
                                        handleChange(e, section.key, index)
                                      }
                                    />
                                    <div className="d-flex justify-content-between">
                                      {(section?.maxRange == 4
                                        ? RANGE_VALUE_FOR_GENERAL
                                        : RANGE_VALUE
                                      ).map((value, index) => (
                                        <span key={value}>{value}</span>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                    </div>
                    <TextAreaMolecule
                      handleChange={(e) => handleSelect(section.competence_id, section.key, e)}
                      isDisabled={!data?.some((item: any) => item?.key == section?.key)}
                      label={t("Additional info")}
                      id={'additional_info'}
                      placeholder={t("Additional info")}
                      name="additional_info"
                      value={data?.filter((item: any) => item?.key == section?.key)?.[0]?.additional_info ?? ""}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
        </Accordion>
      </div>
      <Navigation validStatus={validStatus} />
    </div>
  );
};

export default ProjectCompetences;
