import React, { createContext, useContext, useReducer, ReactNode } from "react";
import { CooperationState } from "./Interface";
import { Dispatch } from "./Actions";
import { initialState } from "./State";
import { CooperationReducer } from "./CoorperationReducer";


const FormContext = createContext<
    | {
        state: CooperationState;
        dispatch: Dispatch;
    }
    | undefined
>(undefined);

interface FormProviderProps {
    children: ReactNode;
}

export const useFormContext = () => {
    const context = useContext(FormContext);
    if (!context) {
        throw new Error("useFormContext must be used within a FormProvider");
    }
    return context;
};

export const FormProvider: React.FC<FormProviderProps> = ({ children }) => {
    const [state, dispatch] = useReducer(CooperationReducer, initialState);

    return (
        <FormContext.Provider value={{ state, dispatch }}>
            {children}
        </FormContext.Provider>
    );
};
