import { FormMode } from "components/common/CommonEnums";

export const initialState = {
  mode: FormMode.CREATE,
  tabs: [
    {
      id: "general",
      title: "General",
      showStatus: true,
      error: false,
      draft: false,
    },
    {
      id: "contactsPersons",
      title: "Contacts Persons",
      showStatus: true,
      error: false,
      draft: false,
    },
    {
      id: "projectParameters",
      title: "Project Parameters",
      showStatus: true,
      error: false,
      draft: false,
    },
    {
      id: "competences",
      title: "Competences",
      showStatus: false,
      error: false,
      draft: false,
    },
    {
      id: "question",
      title: "Question",
      showStatus: false,
      error: false,
      draft: false,
    },
    {
      id: "shifts",
      title: "Shifts",
      showStatus: true,
      error: false,
      draft: false,
    },
    {
      id: "sequence",
      title: "Sequence",
      showStatus: true,
      error: false,
      draft: false,
    },
    {
      id: "billing",
      title: "Billing",
      showStatus: true,
      error: false,
      draft: false,
    },
  ],
  tabErrors: [],
  general: {
    data: {
      id: "",
      businessUnitNumber: "",
      consultant: "",
      projectName: "",
      projectCode: "",
      company: "",
      locations: "",
      startDate: null,
      endDate: null,
      createLocationIsVisible: false,
      info: "",
      isActive: true,
    },
    errors: {
      businessUnitNumber: "",
      projectName: "",
      consultant: "",
      projectCode: "",
      company: "",
      locations: "",
      startDate: "",
      endDate: "",
      info: "",
    },
  },
  competences: {
    competence: [],
    // candidateLevels: [],
    // childCompetences: {},
  },
  projectLocation: {
    data: {
      company: null,
      location: "",
      phone: "",
      street: "",
      number: "",
      box: "",
      zipcode: "",
      city: "",
      country: null,
      initialLat: "",
      initialLn: "",
      info: "",
    },
    errors: {
      company: null,
      location: "",
      phone: "",
      street: "",
      number: "",
      zipcode: "",
      city: "",
      country: null,
    },
  },

  contactsPersons: {
    data: {
      selectedContactPersons: [],
      contactSubformIsVisible: false,
      contactsPersons: {
        tittle: null,
        fName: "",
        lName: "",
        email: "",
        company: null,
        mobNumber: "",
        language: null,
        functionData: "",
        linkedIn: "",
        roles: [],
        dob: "",
        locations: [],
        businessUnit: [],
        info: "",
      },
      // timeSheet: [],
      // billing: [],
      // leave: [],
      // sickness: [],
      // holiday: [],
      // safety: [],
    },
    errors: {
      selectedContactPersons: "",
      contactsPersons: {
        fName: "",
        lName: "",
        email: "",
        company: "",
        mobNumber: "",
        language: "",
        roles: "",
        locations: "",
        info: "",
      },
      // timeSheet: "",
      // billing: "",
      // leave: "",
      // sickness: "",
      // holiday: "",
      // safety: "",
    },
  },

  responsiblePerson: {
    data: {
      timeSheet: [],
      billing: [],
      leave: [],
      sickness: [],
      holiday: [],
      safety: [],
    },
    errors: {
      timeSheet: "",
      billing: "",
      leave: "",
      sickness: "",
      holiday: "",
      safety: "",
    },
  },
  projectParameters: {
    data: {
      templates: "",
      fromdate: "",
      fetchData: false,
      sections: [],
    },
    errors: {
      templates: "",
      fromdate: "",
    },
  },
  shifts: {
    data: {
      shifts: [],
      shiftTable: [],
    },
    errors: {
      name: "",
      shifts: [],
    },
  },
  sequence: {
    data: {
      sequenceName: "",
      weeklyShifts: {},
      sequence: [],
      edit: "",
      deleteModal: false,
    },
    errors: {},
  },
  billing: {
    data: {
      vatRate: "",
      billingStatus: "",
      paymentTerm: "",
      email: [],
      proformaInvoice: false,
      splitInvoicePerPerson: false,
      referenceRequired: false,
      poNumber: "",
      reference: "",
    },
    errors: {
      vatRate: "",
      billingStatus: "",
      paymentTerm: "",
    },
  },

  currentTab: "general",
  projectOverview: false,
  signatureImage: "",

  options: {
    businessUnit: [],
    projectTypes: [],
    company: [],
    locations: [],
    companyUsers: [],
    gender: [],
    language: [],
    roles: [],
    functions: [],
    templates: [],
    shifts: [],
    vatRate: [],
    billingRate: [],
    paymentTerm: [],
    countries: [],
    Competence: [],
    PcFunctions: [],
    companyBusinessUnit: [],
    tittle: [],
    consultant: [],
    Question: [],
    QuestionType: [],
  },
};
