
import React from 'react'

const Company: React.FC = () => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 90 69.944">
  <path id="Union_161" data-name="Union 161" d="M63.624,69.9a.34.34,0,0,1-.321-.276l-.212-1.73a4.782,4.782,0,0,1-1.087-.664l-1.63.625a.328.328,0,0,1-.4-.147l-1.262-2.28a.33.33,0,0,1,.082-.418l1.4-1.058a5,5,0,0,1-.035-.645,4.656,4.656,0,0,1,.057-.633l-1.358-1.106a.33.33,0,0,1-.067-.421l1.341-2.235a.328.328,0,0,1,.4-.133l1.607.682a4.983,4.983,0,0,1,1.109-.625l.272-1.721a.337.337,0,0,1,.33-.274l2.6.045a.34.34,0,0,1,.32.276l.212,1.73a4.783,4.783,0,0,1,1.088.664l1.628-.625a.328.328,0,0,1,.4.147l1.263,2.28a.33.33,0,0,1-.081.419l-1.4,1.058a5.106,5.106,0,0,1,.035.644,4.742,4.742,0,0,1-.058.633l1.358,1.107a.33.33,0,0,1,.067.421l-1.341,2.235a.329.329,0,0,1-.4.133l-1.607-.681a4.955,4.955,0,0,1-1.109.624l-.272,1.722a.338.338,0,0,1-.33.274Zm-.846-6.547a2.262,2.262,0,1,0,2.3-2.232h-.041A2.266,2.266,0,0,0,62.778,63.352ZM4.506,65.8H0V20H31.764v4.465H4.507V65.8Zm25.015,0H24.384V56.124h5.138V65.8h0Zm-8.349,0H16.035V56.124h5.137V65.8h0Zm-8.348,0H7.686V56.124h5.138V65.8h0Zm44.555,0H38.512V7.742H64.2V55.626l-.655-.012a.4.4,0,0,0-.394.327L62.825,58a5.962,5.962,0,0,0-1.324.745l-1.919-.813a.391.391,0,0,0-.48.159l-1.6,2.669a.393.393,0,0,0,.08.5L59.2,62.583a5.774,5.774,0,0,0-.068.757,6.083,6.083,0,0,0,.042.77l-1.666,1.264a.4.4,0,0,0-.129.426h0Zm-11.8-2.58H56.493V54.189H45.576ZM43.007,49.028H59.7V45.157h-16.7Zm0-7.741H59.7V37.416h-16.7Zm0-7.741H59.7v-3.87h-16.7Zm0-7.741H59.7V21.934h-16.7Zm0-7.741H59.7V14.193h-16.7ZM29.521,52.253H24.384V47.093h5.138v5.16h0Zm-8.349,0H16.035V47.093h5.137v5.16h0Zm-8.348,0H7.686V47.093h5.138v5.16h0Zm16.7-8.386H24.384V38.707h5.138v5.16h0Zm-8.349,0H16.035V38.707h5.137v5.16h0Zm-8.348,0H7.686V38.707h5.138v5.16h0Zm16.7-8.386H24.384V30.32h5.138v5.16h0Zm-8.349,0H16.035V30.32h5.137v5.16h0Zm-8.348,0H7.686V30.32h5.138v5.16h0Zm5.088-20H13.6V0H47.708V4.277h-29.8V15.484h0Z" fill="currentColor"/>
</svg>
  )
}

export default Company