import React from "react";
import { FormMode } from "components/common/CommonEnums";
import { Option } from "components/common/CommonInterfaces";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import { t } from "pages/microservices/masterData/translation/Translation";

export type AddressFormOrganismData = {
  street: string;
  number: string;
  box: string;
  zipcode: string;
  city?: string;
  country: Option | Option[] | null | string;
  initialLat?: string;
  initialLn?: string;
};

export type AddressFormOrganismErrors = {
  street: string;
  number: string;
  box?: string;
  zipcode: string;
  city?: string;
  country: string;
};

export type AddressFormOrganismOptions = {
  countries: Option[];
};

export interface IAddressFormOrganismProps {
  formMode?: FormMode;
  formData: AddressFormOrganismData;
  formErrors: AddressFormOrganismErrors;
  formOptions: AddressFormOrganismOptions;

  handleSelectChange: (value: any, fieldName: string) => void;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

/**
 * @returns
 * Address form fields, without submit buttons
 *
 * @example
<AddressFormOrganism
  formMode={}
  formOptions={{ countries: X }}
  formData={{
    street: formData.street,
    number: formData.number,
    box: formData.box,
    zipcode: formData.zipcode,
    city: formData.city,
    country: formData.country,
  }}
  formErrors={{
    street: formErrors.street,
    number: formErrors.number,
    box: formErrors.box,
    zipcode: formErrors.zipcode,
    city: formErrors.city,
    country: formErrors.country,
  }}
  handleSelectChange={(e) => handleSelectChange(e, "country")}
  handleChange={handleChange}
></AddressFormOrganism>;
 */
const AddressFormOrganism: React.FC<IAddressFormOrganismProps> = ({
  formMode,
  formOptions,
  formData,
  formErrors,
  handleSelectChange,
  handleChange,
}) => {
  return (
    <div className="row">
      <div className="col-6">
        <LabelWithInputField
          label={t("Street")}
          name="street"
          isMandatory={true}
          value={formData.street}
          handleChange={handleChange}
          id="street"
          type="text"
          error={formErrors.street}
        />
      </div>
      <div className="col-3">
        <LabelWithInputField
          label={t("Number")}
          name="number"
          isMandatory={true}
          value={formData.number}
          handleChange={handleChange}
          id="number"
          type="text"
          error={formErrors.number}
        />
      </div>
      <div className="col-3">
        <LabelWithInputField
          label={t("Box")}
          name="box"
          isMandatory={false}
          value={formData.box}
          handleChange={handleChange}
          id="box"
          type="text"
          error={formErrors.box}
        />
      </div>
      <div className="col-4">
        <LabelWithInputField
          label={t("Zipcode")}
          name="zipcode"
          isMandatory={true}
          value={formData.zipcode}
          handleChange={handleChange}
          id="zipcode"
          type="text"
          error={formErrors.zipcode}
        />
      </div>
      <div className="col-4">
        <LabelWithInputField
          label={t("City")}
          name="city"
          isMandatory={true}
          value={formData.city}
          handleChange={handleChange}
          id="city"
          type="text"
          error={formErrors.city}
        />
      </div>
      <div className="col-4">
        <SelectWithSearch
          title={t("Country")}
          name="country"
          placeHolder={t("Select")}
          isMandatory={true}
          search={true}
          options={formOptions.countries}
          value={formData.country}
          onChange={(e) => handleSelectChange(e, "country")}
          isMulti={false}
          className="select-field"
          error={formErrors.country}
        />
      </div>
    </div>
  );
};

export default AddressFormOrganism;
