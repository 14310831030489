import { useFormContext } from "pages/microservices/companyCreation/context/Context";

import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import { Navigation } from "../formNavigation/Navigation";
import SelectWithSearch from "components/atoms/SelectWithSearch";

import { useEffect, useState } from "react";

import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import { t } from "pages/microservices/masterData/translation/Translation";
import LabelWithTextAreaField from "components/molecules/LabelWithTextAreaField";
import Button from "components/atoms/Button";
import { COMPANY_DETAILS, REGISTER_COMPANY } from "routes/ApiEndpoints";
import { M_COMPANY_CREATION } from "constants/Constants";
import { ApiCall } from "services/ApiServices";
import CustomNotify from "components/atoms/CustomNotify";
import {
  scrollToTop,
  validateForm,
  validateNumber,
  validateRequired,
  validateSelectField,
} from "services/validation/ValidationService";
import { Option, ValidationRules } from "utils/TypeAnnotations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { companyLocationProps } from "../../annotations/CompanyAnnotations";
import EditIcon from "static/images/EditIcon";
import DeleteIcon from "static/images/DeleteIcon";
import ViewIcon from "static/images/ViewIcon";
import axios from "axios";
import ActiveLocation from "static/images/ActiveLocation";
import AddAddressLoactionViaMap, {
  AddressDetails,
} from "utils/AddAddressLoactionViaMap";

const Address = () => {
  const { state, dispatch } = useFormContext();
  const navigate = useNavigate();
  const [countryId, setCountryId] = useState<number | null>(null);
  const [validationStatus, setValidationStatus] = useState({
    isValid: false,
    type: "",
  });
  const { companyId } = useParams<{ companyId: string }>();
  const location = useLocation();
  const getCityAndCountry = async (zipcode: string) => {
    try {
      if (zipcode.length === 4) {
        const response = await axios.get(
          `https://api.zippopotam.us/be/${zipcode}`
        );
        // Check if the response contains places data and is in the expected format
        if (
          response.data &&
          response.data.places &&
          response.data.places.length > 0
        ) {
          return {
            city: response.data.places?.[0]?.["place name"] || "Not found",
            country: response.data?.country || "Belgium",
          };
        } else {
          return { city: "Not found", country: "Belgium" };
        }
      } else {
        return { city: "", country: "" };
      }
    } catch (error) {
      return { city: "Error", country: "Error" };
    }
  };
  // Check if 'mode=view' is present in the query string
  const isViewMode = location.search.includes("mode=view");
  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;
    const processedValue = type === "number" ? String(value) : value;
    if (name === "locationZipCode") {
      const city = getCityAndCountry(value);
      city.then((response: any) => {
        const { city, country } = response;
        dispatch({
          type: "UPDATE_COMPANYLOCATION_FIELD",
          field: "locationCity",
          value: city,
        });
        const countryValue: any =
          country !== "Error"
            ? state.countryList.find(
              (coun: any) =>
                coun?.label?.toLowerCase() === country?.toLowerCase()
            )
            : null;
        dispatch({
          type: "UPDATE_COMPANYLOCATION_FIELD",
          field: "locationCountry", // Convert to domFieldName
          value: countryValue,
        });
      });
    }
    dispatch({
      type: "UPDATE_COMPANYLOCATION_FIELD",
      field: name,
      value: value,
    });
    validation(name, processedValue, true);
  };
  const validation = (
    name: string,
    value: any,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      locationName: [validateRequired],
      locationStreet: [validateRequired],
      locationNumber: [validateNumber],
      //  locationBox: string;
      locationZipCode: [validateRequired],
      locationCountry: [validateSelectField],
      locationCity: [validateRequired],
      locationPhoneNumber: [validateRequired],
    };

    const validationErrors = validateForm(
      { ...state.companyLocation, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );
    dispatch({
      type: "UPDATE_FIELD_ERROR",
      fieldName: name,
      error: validationErrors[name],
    });
    const isFieldValid = Object.keys(validationErrors).length === 0;

    if (isFieldValid) {
      state.fieldError[name] = "";
    }
    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };
  const handleSelectChange = (selectedOption: any, name: string) => {
    dispatch({
      type: "UPDATE_COMPANYLOCATION_FIELD",
      field: name,
      value: selectedOption,
    });
    validation(name, selectedOption, true);
  };

  const validStatus = (validation: any) => {
    setValidationStatus({
      isValid: true,
      // isValid: validation.isValid,
      type: validation.type,
    });
  };
  const validateAllFields = (): boolean => {
    // Track overall validity
    let allFieldsValid = true;

    Object.keys(state.companyLocation).forEach((key) => {
      const value = state.companyLocation[key as keyof companyLocationProps];
      const isFieldValid = validation(key, value, true);
      allFieldsValid = allFieldsValid && isFieldValid; // Combine results
    });

    return allFieldsValid; // Return overall validity
  };

  const handleAddLocation = () => {
    const isValid = validateAllFields();
    if (isValid) {
      dispatch({
        type: "UPDATE_COMPANYLOCATION_TABLE_FIELD",
        value: state.companyLocation,
      });
      const locationAddressFields = [
        { field: "locationId", value: "" },
        { field: "locationPhoneNumber", value: "" },
        { field: "locationName", value: "" },
        { field: "locationStreet", value: "" },
        { field: "locationNumber", value: "" },
        { field: "locationBox", value: "" },
        { field: "locationZipCode", value: "" },
        { field: "locationCity", value: "" },
        { field: "locationCountry", value: "" },
        { field: "initialLat", value: "" },
        { field: "initialLn", value: "" },
      ];
      locationAddressFields.forEach((field) => {
        dispatch({
          type: "UPDATE_COMPANYLOCATION_FIELD",
          field: field.field,
          value: field.value,
        });
      });
    }
  };

  const fetchDataFromUrl = async () => {
    try {
      const url = !companyId
        ? COMPANY_DETAILS
        : `${COMPANY_DETAILS}/${companyId}`;
      const response = await ApiCall.getService(
        url,
        "GET",
        M_COMPANY_CREATION,
        false
      );

      if (response?.status === 200) {
        const { locations } = response.data;
        dispatch({
          type: "SET_DROPDOWN",
          dropdownValues: locations,
          field: "locationList",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    let finalCompanyLocation = null;
    if (state.companyLocationTable.length === 0) {
      finalCompanyLocation = [state.companyLocation];
    } else {
      const specificLocationFields: Array<keyof companyLocationProps> = [
        "locationName",
        "locationPhoneNumber",
        "locationStreet",
        "locationNumber",
        "locationZipCode",
        "locationCountry",
        "locationCity",
      ];

      // Check if all specific fields are filled
      const areSpecificFieldsFilled = specificLocationFields.every((field) => {
        const value = state.companyLocation[field];

        // Check if value is a string and apply trim if needed
        if (typeof value === "string") {
          return value.trim() !== ""; // Check for non-empty strings after trimming
        }

        // Otherwise, check for non-null and non-undefined values (for other types)
        return value !== null && value !== undefined;
      });

      // Initialize finalCompanyContact with existing entries
      finalCompanyLocation = [...state.companyLocationTable];

      // Add to finalCompanyContact only if all of the specific fields are filled
      if (areSpecificFieldsFilled) {
        finalCompanyLocation.push(state.companyLocation);
      }
    }
    const formData = {
      basic: state.basic,
      address: state.address,
      companyLocation: finalCompanyLocation,
    };
    formData.basic.companyId = companyId;
    if (state.basic.vat !== "" && state.basic.companyName !== "") {
      const url = !companyId
        ? REGISTER_COMPANY
        : `${REGISTER_COMPANY}/${companyId}`;
      const response = await ApiCall.service(
        url,
        "POST",
        formData,
        false,
        M_COMPANY_CREATION
      );
      if (response?.status === 200) {
        if (validationStatus.type === "draft") {
          CustomNotify({
            type: "success",
            message: t("Draft saved successfully") + "!",
          });
          navigate("/manage-companies");
        } else {
          const locationResponse = response?.locationId;
          locationResponse &&
            locationResponse.length > 0 &&
            locationResponse.map((item: any, index: number) =>
              dispatch({
                type: "UPDATE_COMPANYLOCATION_FIELD",
                field: "id",
                value: item.locationId,
              })
            );
          fetchDataFromUrl();
          navigate(`/company/${response.companyId}`);
        }
      } else if (response?.status === 400) {
        CustomNotify({ type: "error", message: response.msg.vat_number });
        dispatch({ type: "UPDATE_TAB_ERROR", tabIndex: 0, error: true });
        scrollToTop();
        state.fieldError.vat = response.msg.vat_number;
      }
    }
  };
  const handleEdit = (entryId: number) => {
    dispatch({
      type: "EDIT_COMPANYLOCATION_FIELD",
      index: entryId,
    });
    const locationAddressFields = [
      { field: "locationId", value: "" },
      { field: "locationPhoneNumber", value: "" },
      { field: "locationName", value: "" },
      { field: "locationStreet", value: "" },
      { field: "locationNumber", value: "" },
      { field: "locationBox", value: "" },
      { field: "locationZipCode", value: "" },
      { field: "locationCity", value: "" },
      { field: "locationCountry", value: "" },
    ];
    locationAddressFields.forEach((field) => {
      dispatch({
        type: "UPDATE_FIELD_ERROR",
        fieldName: field.field,
        error: field.value,
      });
    });
  };

  const handleArchive = (deleteId: number) => {
    dispatch({
      type: "DELETE_COMPANYLOCATION_INDEX",
      index: deleteId,
    });
  };
  const handleSaveParticularData = () => {
    dispatch({
      type: "UPDATE_COMPANYLOCATION_EDITED_FIELD",
      value: state.companyLocation,
    });
    const locationAddressFields = [
      { field: "locationId", value: "" },
      { field: "id", value: "" },
      { field: "locationPhoneNumber", value: "" },
      { field: "locationName", value: "" },
      { field: "locationStreet", value: "" },
      { field: "locationNumber", value: "" },
      { field: "locationBox", value: "" },
      { field: "locationZipCode", value: "" },
      { field: "locationCity", value: "" },
      { field: "locationCountry", value: "" },
    ];
    locationAddressFields.forEach((field) => {
      dispatch({
        type: "UPDATE_COMPANYLOCATION_FIELD",
        field: field.field,
        value: field.value,
      });
    });
  };
  const [showPopup, setShowPopup] = useState(false);
  const handleAddPopup = () => {
    setShowPopup(true); // Close the popup without saving
  };
  const handleClosePopup = () => {
    setShowPopup(false); // or whatever logic you have to close the popup
  };
  const handleConfirm = (details: AddressDetails | null) => {
    if (details) {
      const { street, nr, bus, postal, city, country, coordinates } = details;
      let companyOption: any = {}; // Declare as an empty object

      // Find the country option if country is not empty
      if (country !== "") {
        companyOption =
          state.countryList.find((option: any) =>
            option.label.toLowerCase().includes(country.toLowerCase())
          ) || {}; // If no match found, default to an empty object
      }

      // Parse coordinates and ensure they're numbers
      //** set lat and lang /
      let lat = "";
      let lng = "";

      if (coordinates !== "" && coordinates) {
        [lat, lng] = coordinates
          .replace("Lat: ", "")
          .replace("Lng: ", "")
          .split(", ");
      }

      // Prepare the fields for dispatching
      const locationAddressFields = [
        { field: "locationStreet", value: street },
        { field: "locationNumber", value: nr },
        { field: "locationBox", value: bus },
        { field: "locationZipCode", value: postal },
        { field: "locationCity", value: city },
        { field: "locationCountry", value: companyOption },
        { field: "initialLat", value: lat },
        { field: "initialLn", value: lng },
      ];

      // Dispatch company name
      // dispatch({
      //   type: "UPDATE_COMPANYLOCATION_FIELD",
      //   field: "locationName",
      //   value: `${state.basic.companyName}-HQ`,
      // });

      // Dispatch location address fields
      locationAddressFields.forEach((field) => {
        dispatch({
          type: "UPDATE_COMPANYLOCATION_FIELD",
          field: field.field,
          value: field.value,
        });
      });

      setShowPopup(false); // Close the popup after confirming the address
    }
  };

  const renderAddressGoogleMap = () => {
    return (
      <div style={{ marginBottom: "1vw" }}>
        <div className="d-flex align-items-center">
          <Link
            to={""}
            title={t("Search address via google map...")}
            onClick={handleAddPopup}
            className="color-dark-pink marginRightPoint5"
          >
            <ActiveLocation />
          </Link>
          <Link to={""} className="link-color" onClick={handleAddPopup}>
            {t("Search address via google map...")}
          </Link>
        </div>
        <AddAddressLoactionViaMap
          initialLat={
            state.companyLocation.initialLat === ""
              ? 50.8503
              : parseFloat(state.companyLocation.initialLat)
          } // Replace with your initial latitude
          initialLng={
            state.companyLocation.initialLn === ""
              ? 4.3517
              : parseFloat(state.companyLocation.initialLn)
          } // Replace with your initial longitude
          onConfirm={handleConfirm}
          showAddPopup={showPopup}
          onHide={handleClosePopup}
        />
      </div>
    );
  };
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12">
            <div className="form-border" style={{ paddingTop: "2vw" }}>
              {state.companyLocationTable.length > 0 && (
                <div className="bg-white locationBg">
                  <table className="table table-hover">
                    <thead className="TableHeader">
                      <tr>
                        <th>{t("Location name")}</th>
                        <th>{t("Street")}</th>
                        <th>{t("ZipCode")}</th>
                        <th>{t("Number")}</th>
                        <th>{t("City")}</th>
                        <th>{t("Country")}</th>
                        <th>{t("Actions")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {state.companyLocationTable.map(
                        (eachLocationData: any, index: number) => (
                          <tr key={index}>
                            <td className="align-middle">
                              {eachLocationData.locationName}
                            </td>
                            <td className="align-middle">
                              {eachLocationData.locationStreet}
                            </td>
                            <td className="align-middle">
                              {eachLocationData.locationZipCode}
                            </td>
                            <td className="align-middle">
                              {eachLocationData.locationNumber}
                            </td>
                            <td className="align-middle">
                              {eachLocationData.locationCity}
                            </td>
                            <td className="align-middle">
                              {eachLocationData.locationCountry.label}
                            </td>
                            <td className="table-action-icons">
                              <span
                                onClick={() => handleEdit(index)}
                                className="table-action-btn cursor-pointer marginRightPoint5"
                                title={isViewMode ? t("View") : t("Edit")}
                              >
                                {isViewMode ? <ViewIcon /> : <EditIcon />}
                              </span>
                              {!isViewMode && (
                                <span
                                  onClick={() => handleArchive(index)}
                                  className="table-action-btn cursor-pointer"
                                  title={t("Delete")}
                                >
                                  <DeleteIcon />
                                </span>
                              )}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              )}
              <div className="row">
                {!isViewMode && renderAddressGoogleMap()}
                {!isViewMode && (
                  <>
                    <div className="col-sm-12 col-md-6">
                      <LabelWithInputField
                        isMandatory={true}
                        name="locationName"
                        handleChange={(event) => handleFieldChange(event)}
                        value={state.companyLocation.locationName ?? ""}
                        id={`locationName`}
                        label="Location name"
                        placeholder="Location name"
                        error={state.fieldError.locationName}
                        isDisabled={isViewMode}
                      />
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <LabelWithInputField
                        isMandatory={true}
                        name="locationPhoneNumber"
                        handleChange={(event) => handleFieldChange(event)}
                        value={state.companyLocation.locationPhoneNumber ?? ""}
                        id={`phoneNumber`}
                        label="Phone number"
                        placeholder="Phone number"
                        error={state.fieldError.locationPhoneNumber}
                        isDisabled={isViewMode}
                      />
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <LabelWithInputField
                        isMandatory={true}
                        name="locationStreet"
                        handleChange={(event) => handleFieldChange(event)}
                        value={state.companyLocation.locationStreet ?? ""}
                        id={`street`}
                        label="Street"
                        placeholder="Street"
                        error={state.fieldError.locationStreet}
                        isDisabled={isViewMode}
                      />
                    </div>
                    <div className="col-sm-12 col-md-3">
                      <LabelWithInputField
                        isMandatory={true}
                        name="locationNumber"
                        handleChange={(event) => handleFieldChange(event)}
                        value={state.companyLocation.locationNumber ?? ""}
                        id={`number`}
                        label="Number"
                        placeholder="Number"
                        error={state.fieldError.locationNumber}
                        isDisabled={isViewMode}
                      />
                    </div>
                    <div className="col-sm-12 col-md-3">
                      <LabelWithInputField
                        isMandatory={false}
                        name="locationBox"
                        handleChange={(event) => handleFieldChange(event)}
                        value={state.companyLocation.locationBox}
                        id={`box`}
                        label="Box"
                        type="text"
                        isDisabled={isViewMode}
                      />
                    </div>
                    <div className="col-sm-12 col-md-4">
                      <LabelWithInputField
                        isMandatory={true}
                        name="locationZipCode"
                        handleChange={(event) => handleFieldChange(event)}
                        value={state.companyLocation.locationZipCode}
                        label="Zip code"
                        type="text"
                        error={state.fieldError.locationZipCode}
                        isDisabled={isViewMode}
                      />
                    </div>
                    <div className="col-sm-12 col-md-4">
                      <LabelWithInputField
                        isMandatory={true}
                        name="locationCity"
                        handleChange={(event) => handleFieldChange(event)}
                        value={state.companyLocation.locationCity}
                        label="City"
                        type="text"
                        error={state.fieldError.locationCity}
                        isDisabled={isViewMode}
                      />
                    </div>
                    <div className="col-sm-12 col-md-4">
                      <SelectWithSearch
                        title="Country"
                        name="locationCountry"
                        isMandatory={true}
                        search={true}
                        options={state.countryList}
                        placeHolder="Select"
                        value={state.companyLocation.locationCountry}
                        onChange={(e) =>
                          handleSelectChange(e, "locationCountry")
                        }
                        isMulti={false}
                        className="select-field"
                        error={state.fieldError.locationCountry}
                        isDisabled={isViewMode}
                      />
                    </div>
                    <div className="col-lg-12">
                      <LabelWithTextAreaField
                        name="locationInfo"
                        handleChange={(event) => handleFieldChange(event)}
                        value={state.companyLocation.locationInfo ?? ""}
                        label="Info"
                        isDisabled={isViewMode}
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="row">
                <div className="col-md-12">
                  {!isViewMode && (
                    <Button
                      title={
                        state.companyLocation.id !== "" &&
                          state.companyLocation.id !== null
                          ? t("Save")
                          : t("+ Add another")
                      }
                      handleClick={
                        state.companyLocation.id !== "" &&
                          state.companyLocation.id !== null
                          ? handleSaveParticularData
                          : handleAddLocation
                      }
                      className="form-button float-end"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Navigation validStatus={validStatus} />
      </form>
    </>
  );
};

export default Address;
