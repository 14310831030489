import React, { ReactNode, MouseEventHandler, CSSProperties } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
interface Props {
  title?: ReactNode;
  icon?: IconProp;
  handleClick?: MouseEventHandler<HTMLButtonElement>;
  className?: string;
  disabled?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
  tooltip?: string;
  children?: React.ReactNode;
  style?: CSSProperties;
  buttonTitle?: string;
}
/**
 * @param param0 
 * @returns
 * 
 * @example
 * 
  <Button
    title={t("")}
    handleClick={}
    className={"btn form-button  text-uppercase rounded-0 shadow-none "}
    tooltip={t("")}
    disabled={false}
    type={"button"}
    icon={faPlus}
  ></Button>;
 */
const Button: React.FC<Props> = ({
  title,
  handleClick,
  className = "",
  tooltip,
  disabled = false,
  type = "button",
  icon,
  children,
  style = {},
  buttonTitle,
}) => {
  return (
    <>
      <button
        type={type}
        className={className}
        onClick={handleClick}
        disabled={disabled}
        title={tooltip}
        style={style}
      >
        {buttonTitle ? <span className={`${buttonTitle} bg-none`}>
          {title}
        </span> : <>
          <span>{title}</span>
        </>}
        {icon && <span><FontAwesomeIcon icon={icon} /></span>}
        {/* Render the icon if it exists */}
        {children}
      </button>
    </>
  );
};

export default Button;
