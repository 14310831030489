export const SET_INITIAL_STATE = "INITIAL_STATE";
export const SWITCH_TAB = "SWITCH_TAB";
export const UPDATE_FIELD_VALUE = "UPDATE_FIELD_VALUE";
export const UPDATE_FIELD_ERRORS = "UPDATE_FIELD_ERRORS";
export const UPDATE_SHOW_CONTACT_PERSON = "UPDATE_SHOW_CONTACT_PERSON";
export const UPDATE_CONTACT_PERSONS_ERRORS = "UPDATE_CONTACT_PERSONS_ERRORS";
export const ADD_NEW_PC_FUNCTION = "ADD_NEW_PC_FUNCTION";
export const ADD_NEW_EMPLOYEE_TYPE = "ADD_NEW_EMPLOYEE_TYPE";

export const ADD_EMPLOYEE_TYPE = "ADD_EMPLOYEE_TYPE";
export const DELETE_EMPLOYEE_TYPE = "DELETE_EMPLOYEE_TYPE";
export const ADD_FUNCTION_PROFILE = "ADD_FUNCTION_PROFILE";
export const DELETE_FUNCTION_TYPE = "DELETE_FUNCTION_TYPE";
export const CLONE_FUNCTION_TYPE = "CLONE_FUNCTION_TYPE";
export const UPDATE_COEFFCIENT_TAB_FIELD = "UPDATE_COEFFCIENT_TAB_FIELD"
export const UPDATE_EMPLOYEE_TYPE_PC = "UPDATE_EMPLOYEE_TYPE_PC";
export const UPDATE_COEFFICIENT_TAB = "UPDATE_COEFFICIENT_TAB";
export const UPDATE_PCS_TO_TABLE = "UPDATE_PCS_TO_TABLE";
export const DELETE_PCS_TO_TABLE = "DELETE_PCS_TO_TABLE";
export const UPDATE_PCS_FIELD_ERROR = "UPDATE_PCS_FIELD_ERROR";
export const EDIT_OR_DELETE_PARTICULAR_PC = "EDIT_OR_DELETE_PARTICULAR_PC";
export const UPDATE_SPECIAL_AGREEMENT_FIELD = "UPDATE_SPECIAL_AGREEMENT_FIELD";
export const UPDATE_NEXT_OR_PREVIOUS_TAB = "UPDATE_NEXT_OR_PREVIOUS_TAB";
export const UPDATE_TABS_FIELDS_ERRORS = "UPDATE_TAB_ERRORS";
export const UPDATE_TAB_ERROR = "UPDATE_TAB_ERROR";
export const SET_OPTIONS = "SET_OPTIONS";
export const UPDATE_SPECIAL_AGREEMENT_TAB = "UPDATE_SPECIAL_AGREEMENT_TAB";
export const UPDATE_CONTACT_PERSON_TAB = "UPDATE_CONTACT_PERSON_TAB";
export const UPDATE_COEFFICIENTS = "UPDATE_COEFFICIENTS";
export const UPDATE_GENERAL_TAB_DATA = "UPDATE_GENERAL_TAB_DATA";
export const UPDATE_INFO_TAB_DATA = "UPDATE_INFO_TAB_DATA";
export const UPDATE_FETCH_DATA = "UPDATE_FETCH_DATA";


export const InfoTabInvoiceTypes = [
    {
        label: "Separate invoice for worker/employee",
        name: "selectionForEmpOrWorker",
    },
    {
        label: "Separate invoice per location",
        name: "invoiceForLocation",
    }, {
        label: "Separate invoice per employee",
        name: "selectionForEmployee",
    },
    {
        label: "Separate invoice for selection/payroll",
        name: "invoiceForPayroll",
    },
    {
        label: "Subtotals per cost center",
        name: "subTotals",
    },
    {
        label: "Add total hours worked by cost center",
        name: "totalHours",
    },
    {
        label: "Merge detail lines per UZK",
        name: "mergeDetails",
    },
    {
        label: "Add total line of hours worked",
        name: "lineOfHoursWorked",
    },
    {
        label: "Add function of contract",
        name: "functionOfContract",
    },
    {
        label: "Payment by direct debit",
        name: "paymentByDirectDebit",
    }
];

export const dependencyFields = {
    info: ['paymentByDirectDebit'],
}

export const correctionSettlementType = [
    {
        value: 1,
        label: "Correction on one document"
    },
    {
        value: 2,
        label: "Credit note and new invoice"
    }
]

export const businessTypes = [
    {
        value: 1,
        label: "B2B"
    },
    {
        value: 2,
        label: "Core"
    }
]

export const billingType = [
    {
        value: 1,
        label: "Month",
    },
    {
        value: 2,
        label: "Week"
    }
]

export const typesBased = [
    {
        value: 1,
        label: "Coefficient based",
        name: "coefficientBased"
    },
    {
        value: 2,
        label: "Fee based",
        name: "feeBased"
    }
]

export const pcList = [
    {
        title: "Paritair committee for white collar",
        pcLabel: "Paritair committee (PC) 1",
        pcName: "pc",
        type: "whiteCollarPcs",
        empTypeLabel: "Selection of employee types (statuut) that can be used",
        empTypeName: "employeeType",
        buttonTitle: "Add paritair commitee for white collar",
        empTypeOption: "whiteEmpType",
    },
    {
        title: "Paritair committee for blue collar",
        pcLabel: "Paritair committee (PC) 2",
        pcName: "pc",
        type: "blueCollarPcs",
        empTypeLabel: "Selection of employee types (statuut) that can be used",
        empTypeName: "employeeType",
        buttonTitle: "Add paritair commitee for blue collar",
        empTypeOption: "blueEmpType",
    }
];

export const INITIAL_CONTACT_PERSON = {
    tittle: null,
    userId: null,
    fName: "",
    lName: "",
    email: "",
    company: null,
    mobNumber: "",
    language: null,
    functionData: "",
    linkedIn: "",
    roles: [],
    dob: null,
    locations: [],
    businessUnit: [],
    info: "",
}

export const optionsData = [
    {
        value: 1,
        label: "Option 1"
    },
    {
        value: 2,
        label: "Option 2"
    },
    {
        value: 3,
        label: "Option 3"
    },
    {
        value: 4,
        label: "Option 4"
    }
];

export const coefficientKeys: any = {
    selection: "selection_max",
    selectionReduced: "selection_reduced_max",
    payroll: "payroll_max",
    selectionCent: "selection_reduced_max",
}

export const CoefficientHeaders = [
    { value: 1, label: 'Selection', typeKey: 'selection' },
    { value: 2, label: 'Selection reduced', typeKey: 'selectionReduced' },
    { value: 3, label: "Payroll", typeKey: 'payroll' },
    { value: 4, label: "Selection %", typeKey: 'selectionCent' },
];

export const DurationTypes = [
    { value: 1, label: "Hours" },
    { value: 2, label: "Days" },
    { value: 3, label: "Weeks" },
    { value: 4, label: "Months" }
]

export const salaryBenefitTypes = [
    {
        "value": 1,
        "label": "Euro"
    },
    {
        "value": 2,
        "label": "Percentage"
    }
]


export const EmpTypeData = {
    employeeType: "",
    level: "",
    startDate: null,
    coefficient: [{
        selection: "",
        selectionReduced: "",
        payroll: "",
        selectionCent: ""
    },],
    errors: {
        employeeType: "",
        level: "",
        type: "",
        duration: "",
    },
    coeffErrors: {
        selection: "",
        selectionReduced: "",
        payroll: "",
        selectionCent: "",
    }
}

export const FunctionData = [
    {
        function: "",
        empTypeData: [
            EmpTypeData
        ],
        errors: {
            function: '',
        },
    }
]

export const CoefficientInitialState = [
    {
        pcName: "",
        funEmpType: [
            FunctionData,
        ]
    }
]

export const FirstDependencyFields = [
    "function"
]

export const SecondDependencyFields = [
    "employeeType", "level", "startDate", "duration", "type", "coefficient"
]

export const ThirdDependencyFields = [
    "coefficient"
]

export const SpecialAgreement = [
    {
        name: "tyuu",
        include: true,
        remarks: "",
        selected_coeff: "",
        value: "",
    }
]

export const ExperienceLevel = [
    { value: 1, label: "Starter" },
    { value: 2, label: "Middle" },
    { value: 3, label: "High" }
]

export const CoefficientTypes = [
    {
        value: 1,
        label: "Payroll"
    },
    {
        value: 2,
        label: "Reduced"
    },
    {
        value: 3,
        label: "Selection"
    },
    {
        value: 4,
        label: "Selection%"
    }
]
