import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "components/atoms/Button";
import ModalPopup from "components/atoms/ModalPopup";
import { ApiCall } from "services/ApiServices";
import CustomNotify from "components/atoms/CustomNotify";
import { t } from "pages/microservices/masterData/translation/Translation";
import { PATH_CREATE_DOCUMENT_TYPE, PATH_EDIT_DOCUMENT_TYPE } from "constants/Paths";
import TableActions from "components/organism/Actions/TableAction";
import { useNavigate } from "react-router-dom";
import {
  DELETE_DOCUMENT_TYPE,
  GET_DOCUMENT_MODULE_OPTIONS,
} from "routes/ApiEndpoints";
import { ERROR, M_MASTER_DATA } from "constants/Constants";
import AccessControl from "services/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import Title from "components/atoms/Title";
import LinkTo from "components/atoms/LinkTo";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import FilterOrganism from "components/organism/FilterOrganism";
import { updatePageFilters } from "store/pageHistory/pageHistorySlice";
import { DocumentTypeHeader, FleetLocationTableHeader } from "TableHeader";
import SortAtomForManagePage from "components/atoms/SortAtomForManagePage";
import PaginationWithPerPage from "components/molecules/PaginationWithPerPage";
import BackButton from "components/atoms/BackButton";

interface ManageDocumentTypeProps {
  data?: any;
  setDocumentData: any;
  handleGetVacany: any;
  dispatch: any;
  history: any;
  pageData: any;
  handleRefresh: () => void;
}

const ManageDocumentType: React.FC<ManageDocumentTypeProps> = ({
  data,
  setDocumentData,
  dispatch,
  history,
  pageData,
  handleRefresh
}) => {
  const [id, setId] = useState<number | undefined>(undefined);
  const [showModal, setShowModal] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    documentModule: [],
  });

  const navigate = useNavigate();

  const handleSaveChanges = async () => {
    setShowModal(false);
    let postData = {
      id: id,
    };

    if (id) {
      const response = await ApiCall.service(
        DELETE_DOCUMENT_TYPE,
        "POST",
        postData,
        false,
        M_MASTER_DATA
      );

      if (response?.status === 200) {
        CustomNotify({ type: "success", message: response.message });
        handleRefresh();
      } else {
        CustomNotify({ type: "error", message: response.message });
      }
    }
  };

  useEffect(() => {
    getFilterData();
  }, []);

  const getFilterData = async () => {
    try {
      const optionsResponse = await ApiCall.getService(
        GET_DOCUMENT_MODULE_OPTIONS,
        'GET',
        M_MASTER_DATA,
        false,
      );
      if (optionsResponse?.status == 200) {
        setFilterOptions((prevOptions) => ({ ...prevOptions, documentModule: optionsResponse?.data?.documentModule }))
      }
      else {
        CustomNotify({ type: "warning", message: optionsResponse.message })
      }
    }
    catch (error) {
      console.log("Error");
    }
  };

  const handleModalClose = () => setShowModal(!showModal);

  const handleEdit = (id: number) => {
    navigate(`${PATH_EDIT_DOCUMENT_TYPE}/${id}`);
  };

  const handleArchive = (id: number) => {
    setShowModal(true);
    setId(id);
  };
  const userData = useSelector(selectAuth);
  const filters = [
    { name: 'documentModule', fieldType: "singleSelect", options: filterOptions?.documentModule, placeholder: "Document module type", filterType: 'search' },
    { name: 'documentType', fieldType: "text", placeholder: "Document type", filterType: 'search' },
  ];
  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Document type",
          read: true,
        },
      ]}
      renderNoAccess={true}
      override={userData.isSuperAdmin}
    >
      <>
        <div className="search-bar">
          <div className="row">
            <Title title={t("Document type")} />

          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="position-relative tableMainWrapper">
              <div>
                <AccessControl
                  requiredPermissions={[
                    {
                      permission: "FleetLocation",
                      create: true,
                    },
                  ]}
                  override={userData.isSuperAdmin}
                >
                  <div className="row">
                    <div className="ManageCreateBtn">
                      <LinkTo
                        pagelink={PATH_CREATE_DOCUMENT_TYPE}
                        title={t("Create document type")}
                        icon={faPlus}
                      />
                    </div>
                  </div>
                </AccessControl>
              </div>
              <div className="table-responsive manageDocumentType tableSection">
                <FilterOrganism
                  dispatch={dispatch}
                  history={history}
                  updatePageFilters={updatePageFilters}
                  filters={filters}
                  handleRefresh={handleRefresh}
                />
                <div className="manageDocumentTypeHeight">
                  <table className="table table-hover">
                    <thead>
                      <tr className="TableHeader">
                        {DocumentTypeHeader?.map((header) => (
                          <th key={header.alias} style={{ width: "40%" }}>
                            <>
                              {t(header.name)}
                              {header.issort && (
                                <SortAtomForManagePage
                                  value={{ alias: header.alias }}
                                  dispatch={dispatch}
                                  history={history}
                                  updatePageFilters={updatePageFilters}
                                  handleRefresh={handleRefresh}
                                />
                              )}
                            </>
                          </th>
                        ))}
                        <th style={{ width: "20%" }}>
                          <AccessControl
                            requiredPermissions={[
                              {
                                permission: "FleetLocations",
                                update: true,
                                delete: true,
                              },
                            ]}
                            actions={true}
                            strict={false}
                            override={userData.isSuperAdmin}
                          >
                            {t("Action")}
                          </AccessControl>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data && data?.length > 0 ? (
                        data?.map((record: any, key: any) => {
                          return (
                            <tr key={key}>
                              <td className="align-middle">
                                {record?.documentModule?.map((eachModule: any) => (eachModule?.label)).join(', ')}</td>
                              <td className="align-middle">{record?.documentType}</td>
                              <td className="align-middle">
                                <TableActions
                                  isAction={true}
                                  handleEdit={handleEdit}
                                  handleArchive={handleArchive}
                                  value={record.id}
                                  permission={"FleetLocations"}
                                />
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={5} className="text-center">
                            {t("No records found")}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div
                  className=""
                  style={{ paddingTop: "0.25vw" }}
                >
                  <PaginationWithPerPage
                    handleRefresh={handleRefresh}
                    dispatch={dispatch}
                    history={history}
                    pageData={pageData}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row backPadding">
          <div className="col-md-6 align-self-center">
            <BackButton />
          </div>
        </div> */}
        <ModalPopup
          show={showModal}
          onHide={handleModalClose}
          title={t("Archive confirmation")}
          body={t("Are you sure want to archive?")}
          onCloseButtonClick={handleModalClose}
          onConfirmButtonClick={handleSaveChanges}
          closeTitle="No"
          confirmTitle="Yes"
        />
      </>
    </AccessControl>
  );
};

export default ManageDocumentType