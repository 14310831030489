import SelectWithSearch from "components/atoms/SelectWithSearch";
import React from "react";
import { t } from "../../translation/Translation";
import { LabelWithInputField } from "components/molecules/LabelWithInputField";
import LabelWithTextAreaField from "components/molecules/LabelWithTextAreaField";
import { validateForm, validateRequired } from "services/validation/ValidationService";
import Button from "components/atoms/Button";
import Calender from "components/molecules/Calender";
import CustomNotify from "components/atoms/CustomNotify";
import { ApiCall } from "services/ApiServices";
import { M_MASTER_DATA } from "constants/Constants";
import { CREATE_PAYMENTS } from "routes/ApiEndpoints";
import CommonServices from "services/CommonService";

const PaymentPopUp = (props: any) => {

    const handleFieldChange = (e: any, name: any) => {
        let value = e?.target?.value ?? e ?? null;
        switch (name) {
            case 'amount':
                value = value?.replace(/[^0-9.,]/g, '').replace(/(\..*)\./g, '$1').replace(/(,.*),/g, '$1');
                break;

            default:
                break;
        }
        
        const errors: any = validateInput(name, value, true);
        
        props?.setTabData((prev: any) => ({
            ...prev, popup: {
                ...prev?.popup,
                errors: {...prev?.popup?.errors, [name]: errors},
                data: {...prev?.popup?.data, [name]: value},
            }
        }));
    }

    const validateInput = (name: string | any, value: string | boolean | any, isSingleFieldValidation: boolean = false) => {
        const validationRules: any = {
            amount: [validateRequired],
            paidon: [validateRequired],
        };
    
        // Validate the form data based on the rules
        const validationErrors = validateForm({ ...props?.tabData?.popup?.data, [name]: value }, validationRules);
    
        // Update errors state based on the validation results
        if (isSingleFieldValidation) {
            return validationErrors[name] ?? "";
        } else {
            props?.setTabData((prev: any) => ({
                ...prev, popup: {
                    ...prev?.popup,
                    errors: {...prev?.popup?.errors, ...validationErrors},
                }
            }));
        }
    
        // Check if there are any validation errors
        return !(Object?.keys(validationErrors).length > 0)
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const isFormValid = validateInput("amount", props?.tabData?.popup?.data?.amount);
        if (isFormValid) {
            try {
                const postData = {
                    invoice_id: props?.formData?.id ?? 3,
                    amount: props?.tabData?.popup?.data?.amount,
                    date: CommonServices?.getTimezoneOffset(props?.tabData?.popup?.data?.paidon ?? new Date()),
                    user_id: props?.userData?.userId ?? null,
                }
                const response = await ApiCall.service(CREATE_PAYMENTS, 'POST', postData, true, M_MASTER_DATA);
                if (response?.status === 200) {
                    CustomNotify({ type: 'success', 'message': response?.message });
                    props?.setTabData((prev: any) => ({
                        ...prev, calcRefresh: !props?.tabData?.calcRefresh,
                        tobepaid: response?.data?.tobepaid,
                        popup: { name: '', data: null, errors: null},
                    }))
                }
            } catch (error) {
                console.log(error);  
            }
        }
    }
    
    return (
        <>
            <div className="d-grid grid-col-2">
                <div>
                    <LabelWithInputField
                        isMandatory
                        name="amount"
                        handleChange={(e) => handleFieldChange(e, 'amount')}
                        id="amount"
                        label={t("Amount")}
                        type="text"
                        value={props?.tabData?.popup?.data?.amount}
                        error={props?.tabData?.popup?.errors?.amount}
                    />
                </div>
                <div>
                    <Calender
                        placeHolder="dd-mm-yyyy"
                        onChange={(date) => handleFieldChange(date, "paidon")}
                        label={t("Pain on")}
                        name="paidon"
                        isMandatory
                        selectedDate={props?.tabData?.popup?.data?.paidon}
                        error={props?.tabData?.popup?.errors?.paidon}
                        maxDate={new Date()}
                    />
                </div>
            </div>
            <div className="d-flex justify-content-between backPadding align-items-center">
                <Button
                  title={t("Cancel")}
                  handleClick={props?.handleClosePopup}
                  className="close-button float-end shadow-none"
                />
                <Button
                  title={t("Save")}
                  handleClick={handleSubmit}
                  className="form-button float-end shadow-none"
                />
            </div>
        </>
    );
};

export default PaymentPopUp;
