import TitleFieldMolecule from "components/molecules/TitleField";
import { M_MASTER_DATA } from "constants/Constants";
import React, { useEffect, useState } from "react";
import {
  ARCHIVE_WAGE_PROPOSAL,
  GET_PROJECT_DATA_FOR_PROPOSAL,
  GET_WAGE_PROPOSAL_DATA,
} from "routes/ApiEndpoints";
import { ApiCall } from "services/ApiServices";
//import { t } from "../translation/Translation";
import EditIcon from "static/images/EditIcon";
import LinkTo from "components/atoms/LinkTo";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import Button from "components/atoms/Button";
import {
  CREATE_WAGE_PROPOSAL,
  PATH_PROJECT_PROPOSAL,
  PATH_WAGE_PROPOSAL,
} from "constants/Paths";
import ProfileCard from "utils/ProfileCard";
import ArchieveIcon from "static/images/ArchiveIcon";
import CustomNotify from "components/atoms/CustomNotify";
import ModalPopup from "components/atoms/ModalPopup";
import FilterOrganism from "components/organism/FilterOrganism";
import { useDispatch, useSelector } from "react-redux";
import {
  selectPageHistory,
  updatePageFilters,
} from "store/pageHistory/pageHistorySlice";
import { Option } from "utils/TypeAnnotations";
import PaginationWithPerPage from "components/molecules/PaginationWithPerPage";
import SendMailIcon from "static/images/sendMailIcon";
import EncryptDecryptService from "services/EncryptDecryptService";
import DownloadWageIcon from "static/images/wageProposalDownloadOcon";
import Download from "static/images/downloadIcon";
import BackButton from "components/atoms/BackButton";
import ViewIcon from "static/images/ViewIcon";
import { t } from "../masterData/translation/Translation";
interface ManageProjectProposalProps {
  id: number | null;
  project_id: number | null;
  projectName: string;
  parameter: string;
  shifts: string;
  status: number | null;
  businessUnitName: string | null;
  businessUnitId: number | null;
  startDate: Date | string | null;
  endDate: Date | string | null;
  projectDocumentPath: string | null;
  isParameterActive: any;
  proposalStatus: any;
}
interface manageDropdownData {
  name: Option | null;
  templates: Option | null;
  shift: Option | null;
  status: Option | null;
  businessUnit: Option | null;
  //startData: string | Date | null;
  //endData: string | Date | null;
}

const ManageProjectProposal = () => {
  const initialPageDetails = {
    totalPages: 0,
    totalRecords: 0,
    refresh: true,
    initialCall: true,
  };
  const initialManageData = {
    name: null,
    templates: null,
    shift: null,
    status: null,
    businessUnit: null,
  };

  const queryParam = useParams();
  const projectId = queryParam.id;
  const navigate = useNavigate();
  const [archievePopup, setArchievePopup] = useState(false);
  const dispatch = useDispatch();
  const [manageData, setManageData] = useState<ManageProjectProposalProps[]>(
    []
  );

  const pageHistory = useSelector(selectPageHistory);
  const history =
    pageHistory?.history?.[pageHistory?.history?.length - 1] ?? {};
  const [pageData, setPageData] = useState(initialPageDetails);
  const [dropdownData, setDropdownData] =
    useState<manageDropdownData>(initialManageData);
  const [archieveId, setarchieveId] = useState<number | undefined>();

  const getSearchData = () => {
    return {
      businessUnits: history?.filters?.businessUnits ?? [],
      projectName: history?.filters?.projectName ?? "",
      parameter: history?.filters?.employeeContractType ?? "",
      shift: history?.filters?.salary ?? "",
      startDate: history?.filters?.salary ?? "",
      endDate: history?.filters?.salary ?? "",
      status: history?.filters?.status ?? "",
      orderBy: history?.filters?.orderBy ?? "id",
      sortDirection: history?.filters?.sortDirection ?? "desc",
    };
  };

  useEffect(() => {
    const postData = {
      projectId: projectId,
      currentPage: Number(history?.filters?.currentPage ?? 1),
      itemsPerPage: Number(history?.filters?.itemsPerPage ?? 10),
      search: getSearchData(),
      initialCall: pageData?.initialCall,
    };
    ApiCall.service(
      GET_PROJECT_DATA_FOR_PROPOSAL,
      "POST",
      postData,
      false,
      M_MASTER_DATA
    ).then((response) => {
      if (response?.status === 200) {
        setDropdownData(response?.options);
        setManageData(response?.data);
        setPageData((prev: any) => ({
          ...prev,
          totalPages: response?.data?.totalPages,
          totalRecords: response?.data?.totalRecords,
          initialCall: false,
        }));
      }
    });
  }, [pageData?.refresh, pageData?.initialCall]);

  const filters = [
    {
      name: "businessUnit",
      fieldType: "singleSelect",
      options: dropdownData?.businessUnit,
      placeholder: "Business Unit",
      filterType: "search",
    },
    {
      name: "projectName",
      fieldType: "text",
      options: dropdownData?.name,
      placeholder: "Project name",
      filterType: "search",
    },
    {
      name: "parameter",
      fieldType: "singleSelect",
      options: dropdownData?.templates,
      placeholder: "Parameter",
      filterType: "search",
    },
    {
      name: "shift",
      fieldType: "multiSelect",
      options: dropdownData?.shift,
      placeholder: "shifts",
      filterType: "search",
    },
    {
      name: "Start date",
      fieldType: "date",
      options: dropdownData?.status,
      placeholder: "Start date",
      filterType: "search",
    },
    {
      name: "End date",
      fieldType: "date",
      options: dropdownData?.status,
      placeholder: "End date",
      filterType: "search",
    },
    {
      name: "status",
      fieldType: "singleSelect",
      options: dropdownData?.status,
      placeholder: "Status",
      filterType: "search",
    },
  ];

  const handleSendProjectProposal = (
    projectId: number,
    clientMailStatus: any,
    managerMailStatus: any,
    user: any
  ) => {
    const userIds = user.map((item: { user_id: any }) => item.user_id);
    const data = {
      id: projectId,
      clientMailStatus: clientMailStatus,
      ManagerMailStatus: managerMailStatus,
      users: userIds,
      throughMail: false,
    };
    const append = EncryptDecryptService.encryptData(JSON?.stringify(data));
    const encodedData = encodeURIComponent(append);
    navigate(`${PATH_PROJECT_PROPOSAL}/?data=${encodedData}`);
  };
  const handleProposalDownload = (proposalPdfPath: string | undefined) => {
    if (!proposalPdfPath) {
      console.error("Invalid PDF path");
      return;
    }
    // Open the PDF in a new tab
    window.open(proposalPdfPath, "_blank");
  };
  const handleView = (id: any, userId: any) => {
    // navigate(`${CREATE_WAGE_PROPOSAL}/${userId}/${id}?mode=view`);
  };

  const handleRefresh = () => {};
  // setPageData((prev: any) => ({ ...prev, refresh: !pageData?.refresh }));

  return (
    <>
      <div className="search-bar">
        <TitleFieldMolecule title={t("Project Proposal")} />
      </div>
      <div className="position-relative tableMainWrapper">
        <div className="row">
          <div className="col-12">
            <div className="table-responsive Manage-wage-proposal tableSection">
              <FilterOrganism
                dispatch={dispatch}
                history={history}
                updatePageFilters={updatePageFilters}
                filters={filters}
                handleRefresh={handleRefresh}
              />
              <div className="wageProposalTable">
                <table className="table table-hover">
                  <thead>
                    <tr className="TableHeader  hchehewc">
                      <th>{t("Business unit")}</th>
                      <th>{t("Project")}</th>
                      <th>{t("Parameter")}</th>
                      <th>{t("shift")}</th>
                      <th>{t("Start date")}</th>
                      <th>{t("End date")}</th>
                      <th>{t("status")}</th>
                      <th>{t("Actions")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {manageData && manageData.length > 0 ? (
                      manageData.map((data: any, index: number) => (
                        <tr key={index}>
                          <td>{data.businessUnitName}</td>
                          <td>{data.name}</td>
                          <td>{data.parameter}</td>
                          <td>{data.shifts}</td>
                          <td>{data.startdate}</td>
                          <td>{data.enddate}</td>
                          {/* <td>{data.status}</td> */}
                          <td>
                            <span
                              className={`d-inline-block rounded-circle status-icon ${
                                data.isParameterActive === 0
                                  ? "danger"
                                  : "success"
                              }`}
                              data-toggle="tooltip"
                              title={
                                data.isParameterActive === 0
                                  ? t("Inactive")
                                  : t("Active")
                              }
                            ></span>
                          </td>

                          <td>
                            {data.isParameterActive == 1 &&  data.proposal_status!=1 &&(
                              <span
                                onClick={() =>
                                  handleSendProjectProposal(
                                    data.id,
                                    data.mail_to_client,
                                    data.mail_to_manager,
                                    data.project_contact_persons
                                  )
                                }
                                title={t("Send mail")}
                                className="table-action-btn"
                              >
                                <SendMailIcon />
                              </span>
                            )}
                            {data.projectDocumentPath && (
                              <span
                                onClick={() =>
                                  handleProposalDownload(
                                    data.projectDocumentPath
                                  )
                                }
                                title={t("Download")}
                                className="table-action-btn marginRightPoint5"
                              >
                                <Download />
                              </span>
                            )}
                            {/* <span
                              onClick={() => handleView(data.id, data.userId)}
                              title={t("View")}
                              className="table-action-btn"
                            >
                              <ViewIcon />
                            </span> */}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className="border">
                        <td colSpan={7} className="border-0 text-center py-3">
                          <span className="text-danger fw-bold">
                            {t("No records")}
                          </span>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="">
                <PaginationWithPerPage
                  handleRefresh={handleRefresh}
                  dispatch={dispatch}
                  history={history}
                  pageData={pageData}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {archievePopup && (
        <ModalPopup
          show={archievePopup}
          onHide={handleArcheivePopup}
          title={t("Archive confirmation")}
          body={t("Are you sure you want to archive wage proposal")}
          confirmTitle={t("Yes")}
          closeTitle={t("No")}
          onConfirmButtonClick={() => handleArcheive(archieveId, "apiCall")}
          onCloseButtonClick={handleArcheivePopup}
        />
      )} */}

      <div className="row" style={{ padding: "0.85vw 0" }}>
        <div className="col-md-6 align-self-center">
          <BackButton />
        </div>
      </div>
    </>
  );
};

export default ManageProjectProposal;
