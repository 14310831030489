import { GenerateRefreshToken } from "./RefreshTokenService";
import EncryptDecryptService from "./EncryptDecryptService";
import { M_IDENTITY_MANAGER } from "../constants/Constants";
/*
 *Call to the API
 *@param urlendpoint=urlendpoint of the API
 *@param httpmethod=METHOD
 *@param data=data to the API
 *@returns response from the API
 */

type Headers = {
  "Content-Type": string;
  Authorization: any;
};

async function getService(endpoint: string = "", method: string = "GET", microService = M_IDENTITY_MANAGER, loadingParam: boolean = false) {
  const externalElement = document.getElementById("overlay") as HTMLDivElement | null;
  if (externalElement && loadingParam) {
    externalElement.style.display = "flex";
  }
  let decrypteddata = [];
  let token = await GenerateRefreshToken();
  const headers: Headers = {
    "Content-Type": "application/json",
    // "Access-Control-Allow-Origin": "*",
    Authorization: token,
  };
  try {
    const url = `${process.env.REACT_APP_SERVICE_REGISTRY}service/${microService}/${endpoint}`;
    const response = await fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        method: "GET",
        data: {},
      }),
    });
    const data = await response.json();
    decrypteddata = await JSON.parse(EncryptDecryptService.decryptData(data));
  } catch (error) {
    //
  }
  if (externalElement && loadingParam) {
    externalElement.style.display = "none";
  }
  return decrypteddata;
}

async function service(
  endpoint: string = "",
  method: string = "POST",
  dataObj: any = "",
  loadingParam: boolean = false,
  microService: string = M_IDENTITY_MANAGER, 
  entryptDecrypt: boolean = true
) {
  let decrypteddata = [];
  const externalElement = document.getElementById("overlay") as HTMLDivElement | null;
  if (externalElement && loadingParam) {
    externalElement.style.display = "flex";
  }
  try {
    let token = await GenerateRefreshToken();
    const headers: Headers = {
      "Content-Type": "application/json",
      // "Access-Control-Allow-Origin": "*",
      Authorization: token,
    };
    const url = `${process.env.REACT_APP_SERVICE_REGISTRY}service/${microService}/${endpoint}`;    
    const response = await fetch(url, {
      method: method,
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      referrer: "no-referrer",
      redirect: "follow",
      body: JSON.stringify({
        method: "POST",
        data: entryptDecrypt ? EncryptDecryptService.encryptData(JSON.stringify(dataObj)) : dataObj,
      }),
      headers: headers,
    });
    const data = await response.json();
    decrypteddata = entryptDecrypt ? await JSON?.parse(EncryptDecryptService?.decryptData(data)) : data;
  } catch (error) {
    //
  }
  if (externalElement && loadingParam) {
    externalElement.style.display = "none";
  }
  return decrypteddata;
}

export const ApiCall = {
  service,
  getService,
};
