import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "pages/microservices/masterData/translation/Translation";
import { useFormContext } from "utils/multiTabForm/context/Context";
import DynamicPopup from "./DynamicPopup";
import Button from "components/atoms/Button";
import { EDIT_PREVIOUS_DATA_IN_TABLE, UPDATE_DELETE_POPUP, UPDATE_PREVIOUS_DATA } from "utils/multiTabForm/context/Constants";
import ModalPopup from "components/atoms/ModalPopup";
import CustomNotify from "components/atoms/CustomNotify";

const TableStructureComponent = (props: any) => {
    const { tableData,
        fieldHandler,
        customFn,
        handleSavePreviousData,
        handleButtonClick,
        handleDeletePreviousData } = props;
    const { state, dispatch } = useFormContext();

    const currentTab = state?.currentTab;

    const handleChangeModalStatus = async (currentData = []) => {
        console.log(currentData)
        if (Object.keys(currentData).length > 0) {
            for (let mainIndex = 0; mainIndex < tableData.data.form.length; mainIndex++) {
                for (let subIndex = 0; subIndex < tableData.data.form[mainIndex].length; subIndex++) {
                    let eachField = tableData.data.form[mainIndex][subIndex];

                    if (eachField?.dependencyAction) {
                        let payload = {
                            type: eachField.dependencyAction,
                            state: state.form[currentTab],
                            fieldData: {
                                field: eachField.name,
                                value: currentData[eachField.name],
                                error: eachField.error,
                                index: currentTab,
                                dynamic: true,
                                mainIndex,
                                subIndex,
                            },
                        };

                        const result = await customFn(payload, currentData);
                    }

                    dispatch({
                        type: EDIT_PREVIOUS_DATA_IN_TABLE,
                        payload: {
                            index: currentTab,
                            currentData,
                            clearPopup: false,
                        },
                    })
                }
            }
        }
        else {
            dispatch({
                type: EDIT_PREVIOUS_DATA_IN_TABLE,
                payload: {
                    index: currentTab,
                    currentData,
                    clearPopup: false,
                },
            })
        }
    }

    const handleDelete = async () => {
        try {
            const currentData = tableData?.data?.currentData;
            const response = await handleDeletePreviousData(currentData, tableData?.data?.data);
            if (response?.status == 200) {
                dispatch({
                    type: UPDATE_PREVIOUS_DATA,
                    payload: {
                        data: response.data,
                        index: currentTab,
                    }
                });
                CustomNotify({ type: "success", message: response?.message });
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleHidePopup = () => {
        dispatch({
            type: UPDATE_DELETE_POPUP,
            payload: {
                index: state?.currentTab,
                currentData: [],
                deletePopUp: false
            }
        })
    }

    const handleDeletePopUp = (data: any) => {
        dispatch({
            type: UPDATE_DELETE_POPUP,
            payload: {
                index: state?.currentTab,
                currentData: data,
                deletePopUp: true,
            }
        })
    }

    const filterEmptyData = (previousData: any) => {
        const { id, ...remainingData } = previousData;
        return Object.entries(remainingData)?.some(([key, value]) => {
            if (typeof value === 'object' && value !== null) {
                return (value as { label?: string }).label !== '' && (value as { label?: string }).label !== null;
            }
            return value !== '' && value !== null;
        });
    };

    const displayAddButton = tableData?.data?.data?.filter((eachData: any) => eachData?.status == true);

    return (
        <div className="table-responsive tableSection p-0 border-0 rounded-0">
            <table className="table table-hover">
                <thead className="TableHeader">
                    <tr>
                        {tableData.data.header.map((eachHeader: any, ind: number) => {
                            return <th key={ind}>{eachHeader?.name}</th>
                        })}
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>


                    {tableData?.data?.data?.map((eachData: any, index: number) => {
                        if (filterEmptyData(eachData)) {
                            return (
                                <tr key={index}>
                                    {tableData?.data?.header.map((eachItem: any, subIndex: number) => {
                                        return (
                                            <td key={subIndex}>
                                                {typeof eachData[eachItem?.alias] == 'object'
                                                    ? eachData[eachItem?.alias]?.label
                                                    : eachData[eachItem?.alias]}
                                            </td>
                                        );
                                    })}
                                    <td>
                                        {(eachData?.status == true || tableData?.data?.addNew == true) ? (
                                            <>
                                                <FontAwesomeIcon
                                                    icon={faEdit}
                                                    onClick={() => handleChangeModalStatus(eachData)}
                                                    style={{ cursor: "pointer", color: "#808080", marginRight: 10 }}
                                                    data-bs-toggle="tooltip"
                                                    data-bs-placement="top"
                                                    title={t("Edit")}
                                                />
                                                <FontAwesomeIcon
                                                    icon={faTrash}
                                                    onClick={() => handleDeletePopUp(eachData)}
                                                    style={{ cursor: "pointer", color: "#808080" }}
                                                    data-bs-toggle="tooltip"
                                                    data-bs-placement="top"
                                                    title={t("Delete")}
                                                />
                                            </>
                                        ) : null}
                                    </td>
                                </tr>
                            );
                        }
                        return null;
                    })}
                    <ModalPopup
                        show={tableData.data.deleteModal}
                        onHide={handleHidePopup}
                        title={t("Delete confirmation")}
                        body={t("Are you sure want to delete?")}
                        onCloseButtonClick={handleHidePopup}
                        onConfirmButtonClick={handleDelete}
                        closeTitle="No"
                        confirmTitle="Yes"
                    />
                </tbody>
            </table>
            {/* If add form is true then display add button else if false check if status true is there then hide the button */}
            {
                displayAddButton?.length == 0 ? (<Button
                    type="button"
                    title={t("Add")}
                    handleClick={() => handleChangeModalStatus()}
                    className={"form-button"}
                />) : (<></>)
            }
            {tableData.data.editModal && (
                <DynamicPopup form={tableData.data.form}
                    data={tableData.data.currentData}
                    pageData={tableData.data}
                    fieldHandler={fieldHandler}
                    handleButtonClick={handleButtonClick}
                    customFn={customFn}
                    handleSavePreviousData={handleSavePreviousData}
                    handleDeletePreviousData={handleDeletePreviousData}
                />
            )}
        </div>
    )
}
export default TableStructureComponent;