import React from "react";
import TimeField from "react-simple-timefield";

interface ShiftInputCellProps {
  value: string;
  type: any;
  disabled?: boolean;
  onChange: any;
  error?: any;
}

const ShiftInputCell: React.FC<ShiftInputCellProps> = ({
  value,
  type,
  disabled = false,
  onChange,
  error
}) => {
  const inputStyle: React.CSSProperties = {
    width: "100%",
    textAlign: "left",
    height:"100%"
  };

  const tdStyle: React.CSSProperties = {
    textAlign: "left",
  };

  const isDisabled = (disabled || type === "total") ? true : false;

  return (
    <tr>
      <td style={tdStyle} className={`border-end shiftInputCell ${error && "timetable-error-border"}`}>
        {/* <TimeField
          name={type}
          type={"time"}
          customStyle={inputStyle}
          handleChange={onChange}
          isDisabled={isTotal}
          value={value}
          title={((error && error != "") ? error : "")}
          className={"form-control rounded-0 py-0" + ((error && error != "") ? " border-danger" : "")}
        /> */}
        <span title={((error && error != "") ? error : "")}>
          <TimeField
              // name={type}
              value={value}
              onChange={(event, value) => onChange(value)}
              colon=":"
              showSeconds={false}
              style={{
                width: '100%',
                height:"100%",
                border:"0.052vw solid #fff !important",
                outline:"0",
                pointerEvents: isDisabled ? "none" : "auto",
                textAlign:"center"
              }}
              // className={"form-control rounded-0 py-0" + ((error && error != "") ? " border-danger" : "")}
              // isDisable={isTotal}
            />
        </span>
      </td>
    </tr>
  );
};

export default ShiftInputCell;
